import { DeleteFilled, DeleteOutlined, EyeFilled, VerticalAlignTopOutlined } from "@ant-design/icons"
import { Modal, Progress, Tooltip, Upload, message, Image as AntdImage } from "antd"
import type { RcFile, UploadFile } from "antd/es/upload/interface"
import { useRef, useState } from "react"
import { ImageUploaderProps } from "./interface/ImageUploaderProps"
import useUpdateEffect from "../../hooks/useUpdateEffect"
import { useTranslation } from "react-i18next"
import { Multimedia } from "./enum/Multimedia.enum"
import LoadingSpinner from "../../assets/icons/loading"
import PlayIcon from "../../assets/icons/play.icon"
import PauseIcon from "../../assets/icons/pause.icon"
import SoundOffIcon from "../../assets/icons/soundOff.icon"
import SoundOnIcon from "../../assets/icons/soundOn.icon"
import { motion } from "framer-motion"

const FileContainer = ({
	children,
	disabled,
	containerStyle,
	type,
	displayActions,
	videoMuted,
	videoPlaying,
	onHoverStart,
	onHoverEnd,
	onMute,
	onUnmute,
	onPreview,
	onDelete,
	toolTipTitle
}: {
	children: React.ReactNode
	disabled?: boolean
	containerStyle?: React.CSSProperties
	type: Multimedia
	displayActions: boolean
	videoMuted?: boolean
	videoPlaying?: boolean
	onHoverStart?: () => void
	onHoverEnd?: () => void
	onMute?: () => void
	onUnmute?: () => void
	onPreview: () => void
	onDelete: () => void
	toolTipTitle?: string
}) => (
	<Tooltip title={toolTipTitle}>
		<div
			className={`w-[253px] h-[450px] relative rounded-xl ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
			onMouseEnter={() => onHoverStart && onHoverStart()}
			onMouseLeave={() => onHoverEnd && onHoverEnd()}
			onClick={() => onPreview()}
			style={containerStyle}
		>
			<PlayerActions
				onMute={() => onMute && onMute()}
				onUnmute={() => onUnmute && onUnmute()}
				displayActions={displayActions}
				type={type}
				videoMuted={videoMuted}
				videoPlaying={videoPlaying}
				onDelete={onDelete}
				onPreview={onPreview}
			/>
			{children}
		</div>
	</Tooltip>
)

//TODO: MOVE TO SEPARATE FILE
interface PlayerActionsProps {
	type: Multimedia
	videoPlaying?: boolean
	videoMuted?: boolean
	displayActions: boolean
	onPreview: () => void
	onDelete: () => void
	onMute?: () => void
	onUnmute?: () => void
}
const PlayerActions = ({
	type,
	videoPlaying,
	videoMuted,
	displayActions,
	onPreview,
	onMute,
	onUnmute,
	onDelete
}: PlayerActionsProps) => {
	return (
		<div
			id="actions"
			className={`flex pb-2 items-end justify-center absolute z-10 w-full h-full opacity-0 transition-all delay-75 ${
				displayActions ? "opacity-100" : ""
			}`}
		>
			<div
				onClick={e => e.stopPropagation()}
				className="bg-white/40 rounded-full flex gap-4 items-center justify-center pb-1 pt-2 px-4 backdrop-blur-sm"
			>
				{type === Multimedia.Video && (
					<>
						{!videoPlaying ? (
							<motion.div
								key={"playIcon"}
								initial={{ opacity: 0, scale: 0.5 }}
								exit={{ scale: 0, opacity: 0 }}
								animate={{ opacity: 1, scale: 1 }}
								transition={{ duration: 0.3 }}
							>
								<PlayIcon
									className="w-7 h-7 text-gray-700 hover:text-white hover:cursor-pointer transition-all"
									onClick={() => onPreview()}
								/>
							</motion.div>
						) : (
							<motion.div
								key={"pauseIcon"}
								initial={{ opacity: 0, scale: 0.5 }}
								exit={{ scale: 0, opacity: 0 }}
								animate={{ opacity: 1, scale: 1 }}
								transition={{ duration: 0.3 }}
							>
								<PauseIcon
									className="w-7 h-7 relative right-[.1rem] text-gray-700 hover:text-white hover:cursor-pointer transition-all"
									onClick={() => onPreview()}
								/>
							</motion.div>
						)}
						{videoMuted ? (
							<motion.div
								key={"soundOffIcon"}
								initial={{ opacity: 0, scale: 0.5 }}
								exit={{ scale: 0, opacity: 0 }}
								animate={{ opacity: 1, scale: 1 }}
								transition={{ duration: 0.3 }}
							>
								<SoundOffIcon
									className="w-7 h-7 text-gray-700 hover:text-white hover:cursor-pointer transition-all"
									onClick={e => onUnmute && onUnmute()}
								/>
							</motion.div>
						) : (
							<motion.div
								key={"soundOnIcon"}
								initial={{ opacity: 0, scale: 0.5 }}
								exit={{ scale: 0, opacity: 0 }}
								animate={{ opacity: 1, scale: 1 }}
								transition={{ duration: 0.3 }}
							>
								<SoundOnIcon
									className="w-7 h-7 text-gray-700 hover:text-white hover:cursor-pointer transition-all"
									onClick={e => onMute && onMute()}
								/>
							</motion.div>
						)}
					</>
				)}

				{type === Multimedia.Image && (
					<motion.div
						key={"soundOffIcon"}
						initial={{ opacity: 0, scale: 0.5 }}
						exit={{ scale: 0, opacity: 0 }}
						animate={{ opacity: 1, scale: 1 }}
						transition={{ duration: 0.3 }}
					>
						<EyeFilled
							className="text-[1.5rem] relative bottom-[.1rem] text-gray-700 hover:text-white hover:cursor-pointer transition-all"
							onClick={() => onPreview()}
						/>
					</motion.div>
				)}

				<DeleteFilled
					className="text-[1.5rem] relative bottom-1 text-gray-700 hover:text-red-500 hover:cursor-pointer transition-all"
					onClick={e => onDelete && onDelete()}
				/>
			</div>
		</div>
	)
}

const MultimediaUploader = ({
	disabled,
	mediaUrl,
	mediaPreview,
	onChange,
	onFinish,
	onClean,
	action,
	customRequest,
	manualUpload,
	containerStyle,
	label,
	sizeLimit,
	previewWidth,
	videoSizeLimit,
	type = Multimedia.Image,
	imageRatio,
	loading
}: ImageUploaderProps) => {
	const { t } = useTranslation("components", { keyPrefix: "imageUploader" })
	const { t: tCommon } = useTranslation("common")
	useUpdateEffect(() => {
		if (type === Multimedia.Video) return
		if (mediaUrl && typeof mediaUrl === "string") {
			setFile({ uid: Math.random().toString(), name: "Preview", response: { url: mediaUrl } })
		} else {
			mediaUrl?.preview ? setPreviewImage(mediaUrl?.preview) : setFile(undefined)
		}
	}, [mediaUrl, type])
	const [loadingVideo, setLoadingVideo] = useState(true)
	const videoRef = useRef<HTMLVideoElement>(null)
	const [displayActions, setDisplayActions] = useState(false)
	const [videoPlaying, setVideoPlaying] = useState(false)
	const [videoMuted, setVideoMuted] = useState(true)
	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewImage, setPreviewImage] = useState("")
	const [previewTitle, setPreviewTitle] = useState("")
	const [file, setFile] = useState<UploadFile | undefined>(
		mediaUrl ? { name: "Preview", response: { url: mediaUrl } } : ([] as any)
	)
	const getBase64 = (file: RcFile): Promise<string> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result as string)
			reader.onerror = error => reject(error)
		})

	const handleProgress = async (newFile: UploadFile) => {
		setDisplayActions(false)
		setFile(newFile)
		if ((newFile.status === "uploading" && !newFile.preview) || (manualUpload && type === Multimedia.Image)) {
			const fileBlob = newFile.originFileObj ?? newFile
			newFile.preview = await getBase64(fileBlob as RcFile)
			setPreviewImage(newFile.preview)
			setPreviewTitle(newFile.name)
		}
		if (newFile.status === "error") {
			message.error(`${newFile.name} file upload failed.`, 3)
		}
		if (newFile.status === "done") {
			onFinish && onFinish(newFile)
		}
		onChange && onChange(newFile)
	}

	const handlePreview = async (file: UploadFile) => {
		if (videoRef.current) {
			if (videoRef.current.paused) {
				videoRef.current.play()
			} else {
				videoRef.current.pause()
			}
			return
		}
		if (type === Multimedia.Video) return window.open(file.url || file.preview, "_blank")
		if (!file.url && !file.preview) {
			file.preview = mediaUrl || (await getBase64(file as RcFile))
		}
		setPreviewImage(file.url || (file.preview as string))
		setPreviewOpen(true)
		setPreviewTitle(file.name || file.url!.substring(file?.url!.lastIndexOf("/") + 1))
	}

	const handleClean = () => {
		setFile(undefined)
		onClean && onClean()
	}

	function validateImageDimensions(file: RcFile): Promise<{ width: number; height: number }> {
		if (!sizeLimit && !imageRatio) return Promise.resolve({ width: 0, height: 0 })
		const promise = new Promise<{ width: number; height: number }>((resolve, reject) => {
			const fileReader = new FileReader()
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				const image = new Image()
				image.src = fileReader.result as string
				image.onload = () => {
					const imageHeight = image.height
					const imageWidth = image.width
					if (sizeLimit) {
						const heightLimit = {
							min: sizeLimit.height - (sizeLimit.height * 10) / 100,
							max: sizeLimit.height + (sizeLimit.height * 10) / 100
						}
						const widthLimit = {
							min: sizeLimit.width - (sizeLimit.width * 10) / 100,
							max: sizeLimit.width + (sizeLimit.width * 10) / 100
						}
						if (
							imageHeight < heightLimit.min ||
							imageWidth < widthLimit.min ||
							imageWidth > widthLimit.max ||
							imageHeight > heightLimit.max
						) {
							reject(
								t("validations.resolutionLimit", { width: sizeLimit.width, height: sizeLimit.height })
							)
							return
						}
						// Resolve promise with the width and height
						resolve({ width: imageWidth, height: imageHeight })
					} else {
						const ratio = image.width / image.height
						const diff = Math.abs((imageRatio ?? 0) - ratio)
						const epsilon = 0.2
						if (diff <= epsilon) resolve({ width: imageWidth, height: imageHeight })
						else reject(t("validations.imageRatio"))
					}
				}
				// Reject promise on error
				image.onerror = reject
			}
		})
		return promise
	}

	const validateVideoWeight = (file: RcFile): Promise<void> => {
		if (!videoSizeLimit) return Promise.resolve()
		const promise = new Promise<void>((resolve, reject) => {
			if (file.size > videoSizeLimit * 1024 * 1024) {
				reject(t("validations.maxSize", { size: videoSizeLimit }))
				return
			}
			resolve()
		})
		return promise
	}
	return (
		<div>
			<Upload
				accept={`${type}/*`}
				disabled={disabled || Boolean(mediaUrl)}
				name="multimediaUploader"
				className={!containerStyle ? "avatar-uploader image-uploader" : ""}
				showUploadList={false}
				customRequest={options => {
					const fmData = new FormData()
					const { file, onSuccess, onError, onProgress } = options
					fmData.append("image", file)
					const fileData: RcFile = file as any
					customRequest &&
						customRequest({
							methods: { onSuccess, onError, onProgress },
							fileData: fmData,
							fileName: fileData.name
						})
				}}
				beforeUpload={async file => {
					try {
						if (type === Multimedia.Image) await validateImageDimensions(file)
						if (type === Multimedia.Video) await validateVideoWeight(file)
						if (manualUpload) return false
					} catch (error: any) {
						message.error(error)
					}
				}}
				onChange={({ file }) => {
					if (file?.status && (file.status === "uploading" || file.status === "error")) return
					handleProgress(file)
				}}
			>
				{mediaUrl && file ? (
					<>
						{type === Multimedia.Video ? (
							<FileContainer
								onHoverEnd={() => typeof mediaUrl === "string" && setDisplayActions(false)}
								onHoverStart={() => typeof mediaUrl === "string" && setDisplayActions(true)}
								displayActions={displayActions}
								videoMuted={videoMuted}
								videoPlaying={videoPlaying}
								type={Multimedia.Video}
								containerStyle={{
									...containerStyle,
									backgroundColor:
										typeof mediaUrl === "string" ? "rgb(0 0 0 / 0.4)" : "rgb(0 0 0 / 0.2)",
									cursor: typeof mediaUrl === "string" ? "pointer" : "default"
								}}
								disabled={disabled}
								onPreview={() => typeof mediaUrl === "string" && handlePreview(file)}
								onDelete={() => handleClean()}
								onMute={() => setVideoMuted(true)}
								onUnmute={() => setVideoMuted(false)}
								toolTipTitle={
									typeof mediaUrl === "string"
										? tCommon(`${videoPlaying ? "stop" : "preview"}`)
										: loading
										? tCommon("uploading")
										: tCommon("pendingUpload")
								}
							>
								{(mediaUrl as any) instanceof File ? (
									<div
										className="w-full h-full flex flex-col justify-center items-center"
										onClick={e => e.stopPropagation()}
									>
										{!loading ? (
											<Progress
												type="circle"
												percent={file ? 100 : 0}
												strokeColor={file?.status === "error" ? "#f5222d/60" : "#1890ff"}
												strokeWidth={2}
												format={() => (
													<>
														<VerticalAlignTopOutlined
															style={{ fontSize: 22 }}
															className="text-gray-700 relative top-2"
														/>
														<p className="text-xs">{tCommon("pending")}</p>
													</>
												)}
											/>
										) : (
											<LoadingSpinner className="h-16 w-16 text-blue-400" />
										)}
										<div className="flex px-4 py-2">
											<div className="bg-white/20 gap-2 rounded-lg flex px-2 h-10 items-center justify-center">
												<p className="text-gray-700 text-base line-clamp-1">{file?.name}</p>
												{!disabled && (
													<DeleteOutlined
														className=" cursor-pointer transition-all text-gray-500 hover:text-red-500 text-xl z-20"
														onClick={() => handleClean()}
													/>
												)}
											</div>
										</div>
									</div>
								) : mediaPreview && loadingVideo ? (
									<div className="grid">
										<LoadingSpinner className="h-14 w-14 text-gray-700 absolute self-center place-self-center" />
										<AntdImage
											src={mediaPreview}
											width={253.3}
											height={450}
											onLoad={() => setTimeout(() => setLoadingVideo(false), 800)}
											className={`rounded-xl transition-all bg-transparent opacity-60 blur-[12px] scale-[1.5] z-10`}
											alt="previewImg"
											style={{ width: "100%", height: "100%" }}
										/>
									</div>
								) : (
									<video
										key={mediaUrl}
										ref={videoRef}
										aria-disabled
										width={253.3}
										height={450}
										className={`rounded-xl transition-all bg-transparent ${
											displayActions ? "opacity-60 blur-[1px]" : ""
										}`}
										poster={mediaPreview || ""}
										muted={videoMuted}
										controls={false}
										onPlay={() => setVideoPlaying(true)}
										onPause={() => setVideoPlaying(false)}
									>
										<source src={mediaUrl} />
										<track src="captions_en.vtt" kind="captions" label="english_captions" />
										<track src="captions_es.vtt" kind="captions" label="spanish_captions" />
									</video>
								)}
							</FileContainer>
						) : (
							type === Multimedia.Image && (
								<>
									<Tooltip title={tCommon("preview")}>
										<FileContainer
											onHoverEnd={() => setDisplayActions(false)}
											onHoverStart={() => setDisplayActions(true)}
											onPreview={() => handlePreview(file)}
											onDelete={() => handleClean()}
											displayActions={displayActions}
											type={Multimedia.Image}
											containerStyle={{
												...containerStyle,
												backgroundColor:
													typeof mediaUrl === "string"
														? "rgb(0 0 0 / 0.4)"
														: "rgb(0 0 0 / 0.2)"
											}}
											disabled={disabled}
											toolTipTitle={tCommon("preview")}
										>
											<img
												onClick={() => handlePreview(file)}
												src={file?.response?.url || file?.preview}
												className={`rounded-xl ${
													displayActions ? "opacity-60 blur-[1px]" : ""
												}`}
												alt="previewImg"
												style={{ width: "100%", height: "100%" }}
											/>
										</FileContainer>
									</Tooltip>
								</>
							)
						)}
					</>
				) : (
					<Tooltip title={label || t("uploadNew")} open={disabled ? !disabled : undefined}>
						<div
							style={containerStyle}
							className={`image-src flex flex-col items-center justify-center ${
								disabled ? "!cursor-not-allowed" : "!cursor-pointer"
							}`}
						>
							<VerticalAlignTopOutlined style={{ color: "#000", fontSize: 22 }} />
							<p className="text-black">{label || t("uploadNew")}</p>
						</div>
					</Tooltip>
				)}
			</Upload>
			<Modal
				title={previewTitle}
				footer={null}
				onCancel={() => setPreviewOpen(false)}
				children={<img alt="previewImage" style={{ width: "100%" }} src={previewImage} />}
				width={previewWidth}
				open={previewOpen}
			/>
		</div>
	)
}

export default MultimediaUploader
