export enum Endpoint {
	// Auth
	LOGIN = "/auth/login",
	GOOGLE_LOGIN = "/event/auth-code",
	REGISTER = "/auth/register",

	// User
	USER = "/user",
	USER_SEARCH = "/user/search",
	USER_ADMIN = "/user/admin",
	USER_ADMIN_PROFILE_PICTURE_PRESIGN = "/user/:userId/uploadPictureUrl",
	USER_IMPORT_FILE = "/importer/users",

	// Sellers
	SELLERS = "/seller/admin",
	SELLER_CREATE = "/seller/admin",
	SELLER_UPDATE = "/seller/admin/{sellerId}",
	SELLER_UPDATE_LOGO = "/seller/{sellerId}/pictures",
	SELLERS_AUTOCOMPLETE = "user/autocomplete",

	// Services
	SELLER_SERVICES = "/service/admin",
	SELLER_SERVICES_CREATE = "/service/admin",
	SELLER_SERVICES_UPLOAD_IMAGE = "service/image/upload",
	SELLER_SERVICES_UPDATE = "/service/admin",
	SELLER_SERVICES_SEARCH = "/service/admin",
	SELLER_SERVICES_EXCEL = "/service/admin/download",

	// Service Sessions
	SERVICE_SESSION_UPDATE = "/service/session/admin",
	SERVICE_SESSION_CREATE = "/service/session/admin",

	// Vouchers
	VOUCHER_DETAIL = "/voucher/{voucherId}",
	VOUCHER = "/voucher",
	VOUCHER_UPLOAD_IMAGE = "/voucher/file/upload",
	VOUCHER_UPDATE = "/voucher/{voucherId}",
	VOUCHER_DELETE = "/voucher/delete",

	// Categories
	CATEGORIES = "/category",
	CATEGORIES_LIST = "/category/admin",
	CATEGORIES_UPDATE = "/category/:categoryId",
	CATEGORIES_DELETE = "/category/:categoryId",
	CATEGORIES_DETAIL = "/category/admin",
	CATEGORIES_ICONS = "/category/icon",

	//Microsites
	MICROSITE_ADMIN = "/microsite/admin",
	MICROSITE_SETTINGS = "/microsite/:micrositeId/settings",
	MICROSITES_ALL = "/microsite/admin/all",
	MICROSITES_DETAIL = "/microsites/find",
	MICROSITE_CREATE = "/microsites/create",
	MICROSITE_UPDATE = "/microsites/update",
	MICROSITE_DELETE = "/microsites/delete",
	MICROSITE_UPLOAD_IMAGE = "/microsites/upload",
	MICROSITE_DETAIL_UPLOAD_IMAGE = "/microsites/detail/upload",

	//Tips
	TIPS = "/tip/tips",
	TIP = "/tip",

	//Transactions
	TRANSACTION_UPLOAD_FILE = "/importer/transactions",
	TRANSACTIONS = "/service/request",
	TRANSACTIONS_DOWNLOAD = "/service/request/download",

	//Banners
	GET_ALL_BANNERS = "/home/admin/banners/:key",
	CREATE_BANNER = "/home/banner",
	UPDATE_BANNER = "/home/banner/:bannerId",
	DELETE_BANNER = "/home/banner/:bannerId",

	//Reports
	ADMIN_REPORT = "/report/admin"
}
