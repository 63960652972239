import { ErrorHandlerError } from "./ErrorHandlerError.interface"

export class MicrositeError extends Error implements ErrorHandlerError {
	public errorMessage: string
	public errorCode?: string
	public httpStatus: number
	static MICROSITES_FETCH_ERROR = "There was an error fetching microsites"
	static MICROSITE_FETCH_ERROR = "There was an error fetching the microsite"
	static MICROSITE_CREATE_ERROR = "There was an error creating the microsite"
	static MICROSITE_UPDATE_ERROR = "There was an error updating the microsite"
	static MICROSITE_SLUG_CONFLICT = "The microsite slug already exists"
	static MICROSITE_SERVICE_UPDATE_ERROR = "There was an error updating the microsite service"
	static MICROSITE_SERVICE_CREATE_ERROR = "There was an error creating the microsite service"
	static MICROSITE_SERVICE_DELETE_ERROR = "There was an error deleting the microsite service"
	static MICROSITE_DELETE_ERROR = "There was an error deleting the microsite"
	static MICROSITE_CLUSTER_FETCH_ERROR = "There was an error fetching microsite clusters"
	static MICROSITE_SERVICE_FETCH_ERROR = "There was an error fetching microsite services"
	static MICROSITE_UPLOAD_IMAGE_ERROR = "There was an error uploading the microsite image"
	static MICROSITE_DETAIL_UPLOAD_IMAGE_ERROR = "There was an error uploading the microsite detail image"

	constructor({ errorMessage, httpStatus, errorCode }: ErrorHandlerError) {
		super(errorMessage)
		this.errorMessage = errorMessage
		this.httpStatus = httpStatus
		this.errorCode = errorCode
		this.name = "MicrositeError"
	}
}
