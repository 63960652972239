import { CategoryRepository } from "./../../../repositories/categoryRepository"
import { UseCase } from "../../useCase"
import { GetCategoryIconsResponse } from "./response"
import { GetCategoryIconsRequest } from "./request"

export class GetCategoryIconsUseCase implements UseCase<GetCategoryIconsRequest, GetCategoryIconsResponse> {
	private _repository
	constructor({ CategoryRepository }: { CategoryRepository: CategoryRepository }) {
		this._repository = CategoryRepository
	}

	exec() {
		return this._repository.getCategoryIcons()
	}
}
