import { VoucherRepository } from "../../../repositories/voucherRepository"
import { UseCase } from "../../useCase"
import { DeleteVoucherRequest } from "./request"
import { DeleteVoucherResponse } from "./response"

export class DeleteVoucherUseCase implements UseCase<DeleteVoucherRequest, DeleteVoucherResponse> {
	private _repository
	constructor({ VoucherRepository }: { VoucherRepository: VoucherRepository }) {
		this._repository = VoucherRepository
	}

	exec(voucherId: DeleteVoucherRequest) {
		return this._repository.deleteVoucher(voucherId)
	}
}
