import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import SignIn from "../pages/signIn/SignInView"
import { RoutePaths } from "./RoutePaths.enum"
import { observer } from "mobx-react"
import { useContainerInjection } from "../hooks/useContainerInjection"
import { AuthStore } from "../stores/AuthStore"
import { useEffect } from "react"
import { Roles } from "../../domain/enum/roles.enum"

const PublicRoutes = () => {
	const { isAuthenticated } = useContainerInjection<AuthStore>("AuthStore")
	const location = useLocation()
	const navigate = useNavigate()
	const auth = useContainerInjection<AuthStore>("AuthStore")
	const hasAdminRole = auth.userData?.roles?.includes(Roles.ADMIN)

	useEffect(() => {
		//@ts-ignore
		if (isAuthenticated && Object.values([RoutePaths.SIGN_IN]).includes(location.pathname)) {
			console.warn(`Redirecting to home.`)
			navigate(hasAdminRole ? RoutePaths.CATEGORIES : RoutePaths.TRANSACTIONS, { replace: true })
		}
	}, [location.pathname])

	return (
		<Routes>
			<Route index={!isAuthenticated} element={<SignIn />} />
			{/* <Route path={RoutePaths.SIGN_UP} element={<SignUp/>} /> */}
			<Route path={RoutePaths.SIGN_IN} element={<SignIn />} />
		</Routes>
	)
}

export default observer(PublicRoutes)
