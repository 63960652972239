import { MicrositeCluster } from "../../domain/entities/Microsite"

interface ConstructorParams {
	id?: string
	status?: boolean
	clusterId: string
}

export class MicrositeDetailModel {
	public id?: string
	public status?: boolean
	public clusterId: string

	constructor(params: ConstructorParams) {
		this.id = params.id
		this.status = params.status
		this.clusterId = params.clusterId
	}

	static fromRawJson = (rawJson: any): MicrositeDetailModel => {
		return new MicrositeDetailModel(rawJson)
	}

	static fromDomain = (micrositeClusterDetail: MicrositeCluster): MicrositeDetailModel => {
		return new MicrositeDetailModel({
			...micrositeClusterDetail,
			clusterId: micrositeClusterDetail.clusterId
		})
	}
}

declare module "./MicrositeDetailModel" {
	interface MicrositeDetailModel {
		toDomain(): MicrositeCluster
	}
}

MicrositeDetailModel.prototype.toDomain = function (): MicrositeCluster {
	const data = this
	return {
		id: data.id,
		status: data.status,
		clusterId: data.clusterId
	}
}
