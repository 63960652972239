import { Button, Col, Form, Row, Typography } from "antd"
import { FormField } from "../../components/Form/FormField"
import { useTranslation } from "react-i18next"
import GoogleIcon from "../../assets/images/Google_G_Logo.png"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { Settings } from "../../stores/Settings"
import { useGoogleLogin } from "@react-oauth/google"
import { SettingsViewModel } from "./SettingsViewModel"
import { observer } from "mobx-react"
import Divider from "../../styledComponents/CustomDivider/Divider"
import { Card } from "../../styledComponents/CustomCard/Card"
import { useToastMessage } from "../../service/toastMessage"

const SettingsView = () => {
	const { t } = useTranslation("settings")
	const settingsStore = useContainerInjection<Settings>("Settings")
	const settingsViewModel = useContainerInjection<SettingsViewModel>("SettingsViewModel")
	const { show, context } = useToastMessage()

	const googleLogin = useGoogleLogin({
		scope: "https://www.googleapis.com/auth/calendar",
		onSuccess: async response => {
			try {
				localStorage.setItem("googleClientId", settingsStore.googleClientId)
				const loginResponse = await settingsViewModel.googleLogIn(response.code)
				//@ts-ignore
				if (loginResponse?.error) {
					//@ts-ignore
					throw loginResponse.error
				}
				show({
					type: "success",
					content: t("googleCalendarIntegration.successfullLogin").toString()
				})
			} catch (error) {
				console.error("@@GOOGLE_LOGIN_ERROR@@", error)
				show({
					type: "error",
					content: t("googleCalendarIntegration.errorLogin", { details: `: ${error}` }).toString()
				})
			}
		},
		onError(errorResponse) {
			console.error("@@GOOGLE_LOGIN_ERROR@@", errorResponse)
		},
		flow: "auth-code"
	})

	return (
		<div>
			{context}
			<Row gutter={[24, 0]}>
				<Col xs={24} xl={24}>
					<Card
						bordered={false}
						title={
							<div style={{ display: "flex", alignItems: "center" }}>
								<Typography.Title style={{ marginTop: 8 }} level={4}>
									{t("cardTitle")}
								</Typography.Title>
							</div>
						}
					>
						<Typography.Title level={5}>{t("googleCalendarIntegration.title").toString()}</Typography.Title>
						<Divider />
						<Row gutter={[24, 0]}>
							<Col>
								<Form
									key={"googleCalendar"}
									initialValues={{ googleClientId: localStorage.getItem("googleClientId") || "" }}
									onFinish={() =>
										settingsViewModel.loggedIn ? settingsViewModel.googleLogOut() : googleLogin()
									}
								>
									<FormField.Input
										label={t("googleCalendarIntegration.inputField")}
										name={"googleClientId"}
										onChange={settingsStore.setGoogleClientId}
										disabled={settingsViewModel.loggedIn}
										placeHolder={t(
											"googleCalendarIntegration.googleClientIdPlaceHolder"
										).toString()}
									/>
									<Button
										htmlType="submit"
										loading={settingsViewModel.isLoading}
										icon={
											<img
												style={{ position: "relative", top: 5, right: 2 }}
												src={GoogleIcon}
												width={20}
												height={20}
											/>
										}
										style={{ backgroundColor: "#FFFF", borderRadius: 6, width: "100%", height: 44 }}
									>
										<Typography.Text style={{ fontSize: 12.5 }}>
											{t(
												`googleCalendarIntegration.${
													settingsViewModel.loggedIn ? "googleSignOut" : "googleSignIn"
												}`
											)}
										</Typography.Text>
									</Button>
								</Form>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</div>
	)
}

export default observer(SettingsView)
