import { UserRepository } from "../../../repositories/userRepository"
import { UseCase } from "../../useCase"
import { GetUploadProfilePictureUrlResponse } from "./response"
import { GetUploadProfilePictureUrlRequest } from "./request"

export class GetUploadProfilePictureUrlUseCase
	implements UseCase<GetUploadProfilePictureUrlRequest, GetUploadProfilePictureUrlResponse>
{
	private _repository
	constructor({ UserRepository }: { UserRepository: UserRepository }) {
		this._repository = UserRepository
	}

	exec({ userId, fileName, mimeType }: GetUploadProfilePictureUrlRequest) {
		return this._repository.getUploadProfilePictureUrl(userId.toString(), fileName, mimeType)
	}
}
