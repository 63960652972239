import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { InputType } from "../../../components/TextInput/const/InputType.enum"

export default () => {
	const { t } = useTranslation("tips", { keyPrefix: "form.micrositesTips.dataTableHeaders" })

	return columns(t)
}

const columns = (t: TFunction) => [
	{
		key: "id",
		title: "ID",
		dataIndex: "id",
		sorteable: true,
		autoSort: false
	},
	{
		title: t("slug"),
		dataIndex: "slug",
		key: "slug",
		width: "32%"
		// responsive: ['xl'],
	},
	{
		title: t("weight"),
		dataIndex: "weight",
		key: "weight",
		inputType: InputType.NUMBER,
		editable: true,
		width: "10%"
	},
	{
		title: "",
		key: "actions",
		dataIndex: "actions"
	}
]
