import { Col, Row, Skeleton } from "antd"

const ServiceInformationSectionSkeleton = () => {
	return (
		<div style={{ display: "flex", flexDirection: "column", gap: 45 }}>
			<Row gutter={[24, 0]}>
				<Col xl={12} md={12} xs={24} style={{ marginBottom: 20 }}>
					<div style={{ display: "flex", flexDirection: "column", gap: 30 }}>
						<div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
							<Skeleton.Button active size="small" />
							<Skeleton.Input size="large" active style={{ width: "33vw" }} />
						</div>
						<div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
							<Skeleton.Button active size="small" />
							<Skeleton.Input size="large" active style={{ width: "33vw" }} />
						</div>
						<div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
							<Skeleton.Button active size="small" />
							<Skeleton.Input size="large" active style={{ width: "33vw" }} />
						</div>
						<div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
							<Skeleton.Button active size="small" />
							<Skeleton.Input size="large" active style={{ width: "33vw" }} />
						</div>
					</div>
				</Col>
				<Col xl={12} md={12} xs={24}>
					<Skeleton.Image active style={{ width: "32rem", height: "26rem", borderRadius: 10 }} />
				</Col>
			</Row>
			<div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
				<Skeleton.Button active size="small" />
				<Skeleton.Input size="large" active style={{ width: "70vw", height: "10vh" }} />
			</div>
			<div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
				<Skeleton.Button active size="small" />
				<Skeleton.Input size="large" active style={{ width: "70vw", height: "10vh" }} />
			</div>
			<div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
				<Skeleton.Button active size="small" />
				<Skeleton.Input size="large" active style={{ width: "70vw" }} />
			</div>
		</div>
	)
}

export default ServiceInformationSectionSkeleton
