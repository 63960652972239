import { UseCase } from "../../useCase"
import { SaveBannersResponse } from "./response"
import { SaveBannersRequest } from "./request"
import { BannersRepository } from "../../../repositories/bannersRepository"
import { Banner } from "../../../entities/Banner"
import { UploadFile } from "antd"

export class SaveBannersUseCase implements UseCase<SaveBannersRequest, SaveBannersResponse> {
	private _repository
	constructor({ BannersRepository }: { BannersRepository: BannersRepository }) {
		this._repository = BannersRepository
	}

	async exec(banners: SaveBannersRequest) {
		const changedBanners = banners.filter(b => b.changed || b.deleted)
		if (changedBanners.length === 0) return []
		const existingBanners = []
		const newBanners = []
		const deletedBanners = []
		for (const banner of changedBanners) {
			if (banner.deleted) deletedBanners.push(banner)
			else if (banner.id) existingBanners.push(banner)
			else newBanners.push(banner)
		}
		const createRequests = newBanners.map(banner =>
			this._repository.createBanner(
				banner,
				banner.desktopImage as unknown as File,
				banner.mobileImage as unknown as File
			)
		)
		const deleteRequests = deletedBanners.filter(b => b.id).map(banner => this._repository.deleteBanner(banner.id!))
		const updateRequests = []
		for (const banner of existingBanners) {
			// If desktop or mobile image was not updated, download current to send it to backend
			let desktopImage: File
			if (!(banner.desktopImage as UploadFile)?.name) {
				const imageBlob = await fetch(banner.image!).then(res => res.blob())
				desktopImage = new File([imageBlob], banner.getImageName() ?? banner.id!.toString())
			} else {
				desktopImage = (banner.desktopImage as UploadFile).originFileObj ?? (banner.desktopImage as any)
			}
			let mobileImage: File
			if (!(banner.mobileImage as UploadFile)?.name) {
				const imageBlob = await fetch(Banner.GetMobileImage(banner.image!)).then(res => res.blob())
				mobileImage = new File([imageBlob], banner.getImageName() ?? banner.id!.toString())
			} else {
				mobileImage = (banner.mobileImage as UploadFile).originFileObj ?? (banner.mobileImage as any)
			}
			updateRequests.push(this._repository.updateBanner(banner, desktopImage, mobileImage))
		}
		const [bannerIds, ,] = await Promise.all([
			Promise.all(createRequests),
			Promise.all(updateRequests),
			Promise.all(deleteRequests)
		])
		return bannerIds
	}
}
