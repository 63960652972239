import { makeObservable, observable, action } from "mobx"

export class Translation {
	static enUS = "en"
	static esES = "es"
	currentLang: string = Translation.defaultLanguage

	constructor() {
		makeObservable(this, {
			currentLang: observable,
			setCurrentLang: action
		})
		this.currentLang = localStorage.getItem("lang") || "en"
	}

	static get defaultLanguage() {
		return Translation.enUS
	}

	static get supported() {
		return [Translation.enUS, Translation.esES]
	}

	public transformToSupported(language: string) {
		if (Translation.supported.includes(language)) {
			return language
		} else {
			return Translation.defaultLanguage
		}
	}

	setCurrentLang = (lang: string) => {
		this.currentLang = this.transformToSupported(lang)
		localStorage.setItem("lang", this.currentLang)
	}
}
