import { Rule } from "antd/es/form"
import { Mandatory } from "../components/Form/FormField"

export const calcFormFieldValidationRule = (mandatory: Mandatory = false, validationMessage: string) => {
	let rule: Rule[] = [{ required: false }]
	switch (typeof mandatory) {
		case "object":
			rule = [
				{
					required: true,
					message: validationMessage,
					validator: mandatory.validator ? () => Promise.resolve() : () => Promise.reject()
				}
			]
			break
		case "boolean":
			rule = [
				{
					required: mandatory,
					message: validationMessage
				}
			]
			break
		default:
			rule = [{ required: false }]
			break
	}

	return rule
}
