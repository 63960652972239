import { AuthRepository } from "../../../repositories/AuthRepository"
import { UseCase } from "../../useCase"
import { GoogleLoginServiceRequest } from "./request"
import { GoogleLoginResponse } from "./response"

export class GoogleLoginUseCase implements UseCase<GoogleLoginServiceRequest, GoogleLoginResponse> {
	private _repository
	constructor({ AuthRepository }: { AuthRepository: AuthRepository }) {
		this._repository = AuthRepository
	}

	exec({ code }: GoogleLoginServiceRequest) {
		return this._repository.googleSignIn(code)
	}
}
