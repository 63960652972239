import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"

export default () => {
	const { t } = useTranslation("microsites", { keyPrefix: "form.dataTableHeaders" })

	return columns(t)
}

const columns = (t: TFunction) => [
	{
		key: "id",
		title: "ID",
		dataIndex: "id",
		sorteable: true
	},
	{
		key: "sellerName",
		title: t("partner"),
		dataIndex: "sellerName",
		width: "32%"
	},
	{
		key: "slug",
		title: t("slug"),
		dataIndex: "slug"
	},
	{
		key: "micrositeType",
		title: t("partnerType"),
		dataIndex: "micrositeType"
	},
	{
		key: "status",
		title: t("status"),
		dataIndex: "status"
	},
	{
		title: "",
		key: "actions",
		dataIndex: "actions"
	}
]
