import { Radio, RadioGroupProps } from "antd"

interface MultiSwitchProps extends RadioGroupProps {
	label?: string
	options: { label: string; value: any; disabled?: boolean }[]
	buttonsStyles?: React.CSSProperties
	containerStyles?: React.CSSProperties
	onChange?: (value: any) => void
}

const MultiSwitch = ({ label, options, onChange, buttonsStyles, containerStyles, ...props }: MultiSwitchProps) => {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				...containerStyles
			}}
		>
			{label && <label>{label}</label>}
			<Radio.Group
				style={style.container}
				buttonStyle="solid"
				onChange={e => onChange && onChange(e.target.value)}
				{...props}
			>
				{options.map((option, idx) => {
					return (
						<Radio.Button
							key={idx}
							style={{ ...style.buttons, ...buttonsStyles }}
							value={option.value}
							disabled={option.disabled ?? false}
						>
							{option.label}
						</Radio.Button>
					)
				})}
			</Radio.Group>
		</div>
	)
}

const style: { container: React.CSSProperties; buttons: React.CSSProperties } = {
	container: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "center"
	},
	buttons: {
		height: 30,
		alignItems: "center",
		justifyContent: "center",
		display: "flex"
	}
}

export default MultiSwitch
