import { Country } from "../../domain/entities/Country"

interface ConstructorParams {
	id: string
	name: string
	flag?: string
}

export class CountryModel {
	public id: string
	public name: string

	constructor(params: ConstructorParams) {
		this.id = params.id
		this.name = params.name
	}

	static fromRawJson = (rawJson: any): CountryModel => {
		const { id, name, flag } = rawJson

		return new CountryModel({
			id,
			name,
			flag
		})
	}
}

declare module "./CountryModel" {
	interface CountryModel {
		toDomain(): Country
	}
}

CountryModel.prototype.toDomain = function (): Country {
	const data = this
	return new Country({
		id: data.id,
		name: data.name
	})
}
