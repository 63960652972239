import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"

export default () => {
	const { t } = useTranslation("users", { keyPrefix: "form.dataTableHeaders" })

	return columns(t)
}

const columns = (t: TFunction) => [
	{
		key: "id",
		title: "ID",
		dataIndex: "id",
		sorteable: true
	},
	{
		key: "email",
		title: t("email"),
		dataIndex: "email"
	},
	{
		key: "name",
		title: t("name"),
		dataIndex: "name"
	},
	{
		key: "userName",
		title: t("userName"),
		dataIndex: "userName",
		width: 200
	},
	{
		key: "status",
		title: t("status"),
		dataIndex: "status",
		width: 200
	},
	{
		key: "city",
		title: t("city"),
		dataIndex: "city"
	},
	{
		title: "",
		key: "editButton",
		dataIndex: "editButton"
	}
]
