import { CheckCircleFilled, CheckCircleOutlined, UploadOutlined } from "@ant-design/icons"
import { Modal, Tooltip, Typography, Upload, message } from "antd"
import type { RcFile, UploadFile } from "antd/es/upload/interface"
import React, { useState } from "react"
import { MultipleImageUploaderProps } from "./interface/MultipleImageUploaderProps"
import { useTranslation } from "react-i18next"
import { colors } from "../../assets/styles/appStyles"

const MultipleImageUploader = ({
	disabled,
	src: images,
	onChange,
	onFinish,
	onClean,
	action,
	customRequest,
	manualUpload,
	containerStyle,
	maxCount,
	sizeLimit
}: MultipleImageUploaderProps) => {
	const { t } = useTranslation("components", { keyPrefix: "imageUploader" })
	const { t: tToolTips } = useTranslation("toolTips")
	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewImage, setPreviewImage] = useState("")
	const [previewTitle, setPreviewTitle] = useState("")
	const [fileList, setFileList] = useState<any[]>(
		images ? images.map(image => ({ url: image.url, uid: image.id, name: "Preview", status: "done" })) : []
	)

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await convertBase64(file.originFileObj as RcFile)
		}
		setPreviewImage(file.url || (file.preview as string))
		setPreviewOpen(true)
		setPreviewTitle(file.name || file.url!.substring(file?.url!.lastIndexOf("/") + 1))
	}

	const handleClean = (file: any) => {
		onClean && onClean()
		const index = fileList.indexOf(file)
		const newFileList: any = fileList.slice()
		newFileList.splice(index, 1)

		return setFileList(newFileList)
	}

	const convertBase64 = (file: File): Promise<string> => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader()
			fileReader.readAsDataURL(file)
			fileReader.onload = () => resolve(fileReader?.result as string)
			fileReader.onerror = error => reject(error)
		})
	}

	function validateImageDimensions(file: RcFile): Promise<{ width: number; height: number }> {
		if (!sizeLimit) return Promise.resolve({ width: 0, height: 0 })
		const promise = new Promise<{ width: number; height: number }>((resolve, reject) => {
			const fileReader = new FileReader()
			fileReader.readAsDataURL(file)
			fileReader.onload = () => {
				const image = new Image()
				image.src = fileReader.result as string
				image.onload = () => {
					const imageHeight = image.height
					const imageWidth = image.width
					const heightLimit = {
						min: sizeLimit.height - (sizeLimit.height * 10) / 100,
						max: sizeLimit.height + (sizeLimit.height * 10) / 100
					}
					const widthLimit = {
						min: sizeLimit.width - (sizeLimit.width * 10) / 100,
						max: sizeLimit.width + (sizeLimit.width * 10) / 100
					}
					// image sizes must be at least 677x257 plus 10% margin
					if (
						imageHeight < heightLimit.min ||
						imageWidth < widthLimit.min ||
						imageWidth > widthLimit.max ||
						imageHeight > heightLimit.max
					) {
						reject(t("validations.resolutionLimit", { width: sizeLimit.width, height: sizeLimit.height }))
						return
					}
					// Resolve promise with the width and height
					resolve({ width: imageWidth, height: imageHeight })
				}
				// Reject promise on error
				image.onerror = reject
			}
		})
		return promise
	}

	return (
		<div>
			<Upload
				accept="image/*"
				disabled={disabled}
				name="avatar"
				listType="picture-card"
				onPreview={handlePreview}
				onRemove={handleClean}
				className={!containerStyle ? "avatar-uploader image-uploader" : ""}
				customRequest={options => {
					const fmData = new FormData()
					const { file, onSuccess, onError, onProgress } = options
					fmData.append("image", file)
					const fileData: RcFile = file as any
					customRequest &&
						customRequest({
							methods: { onSuccess, onError, onProgress },
							fileData: fmData,
							fileName: fileData.name
						})
				}}
				maxCount={maxCount}
				multiple
				beforeUpload={async file => {
					try {
						// Validate image dimensions
						await validateImageDimensions(file)
						// Validate max count
						if (fileList.length >= maxCount) {
							message.error(t("validations.maxImages", { qty: maxCount }))
							return false
						}
						setFileList([...fileList, file])
						if (manualUpload) return false
					} catch (error: any) {
						message.error(error)
					}
				}}
				onChange={info => {
					// setLoading(true);
					if (info.fileList.length > maxCount) {
						message.error(t("maxImages"))
						return
					}
					if (info.file.status === "uploading" || info.file.status === "error") return
					const listFiles = info.fileList
					setFileList(listFiles)
					onChange && onChange(listFiles)
				}}
				fileList={fileList}
			>
				<div style={{ position: "relative", top: 10 }}>
					{
						<UploadOutlined
							style={{ color: "#000", fontSize: 16, position: "absolute", top: -18, left: 25 }}
						/>
					}
					<Typography.Text style={{ fontSize: 14 }}>
						{t("uploadNew")} (Max: {maxCount})
					</Typography.Text>
				</div>
			</Upload>
			<Modal
				title={previewTitle}
				footer={null}
				onCancel={() => setPreviewOpen(false)}
				children={<img alt="example" style={{ width: "100%" }} src={previewImage} />}
				open={previewOpen}
			/>
		</div>
	)
}

export default MultipleImageUploader
