interface ContructorParams {
	calendarId: string | null
	duration: string
	timeZone: string
}

export class CalendarData {
	public calendarId: string | null
	public duration: string
	public timeZone: string

	constructor(params: ContructorParams) {
		this.calendarId = params.calendarId
		this.duration = params.duration
		this.timeZone = params.timeZone
	}
}
