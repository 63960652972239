import { Endpoint } from "../network/endpoint"
import type { HttpManager } from "../network/interface/HttpManager"
import { VoucherModel } from "../models/VoucherModel"
import { UploadVoucherPictureRequest } from "../../domain/useCases/vouchers/uploadVoucherPicture/request"
import { VoucherError } from "../../ui/error/VoucherError"

export class VoucherService {
	private _http: HttpManager

	constructor({ HttpManager }: { HttpManager: HttpManager }) {
		this._http = HttpManager
	}

	public async getVoucher(id: string): Promise<VoucherModel> {
		try {
			const response = await this._http.requestNewApi({
				method: "GET",
				url: Endpoint.VOUCHER_DETAIL.replace("{voucherId}", id)
			})
			return VoucherModel.fromRawJson(response.data)
		} catch (error: any) {
			throw new VoucherError({
				errorMessage: VoucherError.VOUCHER_DETAIL_FETCH_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async getVouchers(offset: number = 0): Promise<VoucherModel[]> {
		try {
			const response = await this._http.requestNewApi({
				method: "GET",
				url: Endpoint.VOUCHER
			})
			return response.data.map((voucher: VoucherModel) => VoucherModel.fromRawJson(voucher))
		} catch (error: any) {
			throw new VoucherError({
				errorMessage: VoucherError.VOUCHERS_FETCH_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async createVoucher(voucher: VoucherModel): Promise<VoucherModel> {
		try {
			const response = await this._http.requestNewApi({
				method: "POST",
				url: Endpoint.VOUCHER,
				data: voucher
			})
			return VoucherModel.fromRawJson(response.data)
		} catch (error: any) {
			throw new VoucherError({
				errorMessage: VoucherError.VOUCHER_CREATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async updateVoucher(voucher: VoucherModel): Promise<VoucherModel> {
		try {
			const data = await this._http.requestNewApi({
				method: "PATCH",
				url: Endpoint.VOUCHER_UPDATE.replace("{voucherId}", voucher.id!),
				data: voucher
			})
			return VoucherModel.fromRawJson(data)
		} catch (error: any) {
			throw new VoucherError({
				errorMessage: VoucherError.VOUCHER_UPDATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async deleteVoucher(id: string): Promise<VoucherModel> {
		try {
			const response = await this._http.post(Endpoint.VOUCHER_DELETE, { voucher_id: id })
			return VoucherModel.fromRawJson(response.data)
		} catch (error: any) {
			throw new VoucherError({
				errorMessage: VoucherError.VOUCHER_DELETE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async uploadVoucherImage(uploadData: UploadVoucherPictureRequest): Promise<{ file_path: string }> {
		const { methods } = uploadData
		const onUploadProgress = (event: any) => {
			const percent = Math.round((event.loaded / event?.total!) * 100)
			methods.onProgress && methods.onProgress({ ...event, percent })
		}

		const response = await this._http.post(
			`${Endpoint.VOUCHER_UPLOAD_IMAGE}/${uploadData.fileName}`,
			uploadData.fileData,
			{
				headers: { "Content-Type": "multipart/form-data" },
				onUploadProgress
			}
		)
		return response.data
	}
}
