import { Tip } from "../../domain/entities/Tip"
import { User } from "../../domain/entities/User"
import { MicrositeTip } from "../../domain/entities/MicrositeTip"
import { CountryTip } from "../../domain/entities/CountryTip"

export class TipModel {
	public id: number
	public publication_date?: Date
	public mediaType?: string
	public mediaUrl?: string
	public mediaText?: string
	public description?: string
	public published: boolean
	public createdAt?: Date
	public updatedAt?: Date
	public mediaPreview?: string
	public user: Partial<User>
	public microsites?: MicrositeTip[]
	public countries?: CountryTip[]

	constructor(params: Tip) {
		this.id = params.id
		this.publication_date = params.publication_date
		this.mediaType = params.mediaType
		this.mediaUrl = params.mediaUrl
		this.mediaText = params.mediaText
		this.description = params.description
		this.published = params.published
		this.createdAt = params.createdAt
		this.updatedAt = params.updatedAt
		this.mediaPreview = params.mediaPreview
		this.countries = params.countries
		this.microsites = params.microsites
		this.user = params.user
	}

	static fromRawJson = (rawJson: any): TipModel => {
		return new TipModel(rawJson)
	}

	static fromDomain = (tip: Tip): TipModel => {
		return new TipModel(tip)
	}

	toDomain(): Tip {
		return {
			id: this.id,
			mediaType: this.mediaType,
			mediaText: this.mediaText,
			description: this.description,
			mediaUrl: this.mediaUrl || undefined,
			mediaPreview: this.mediaPreview || undefined,
			published: Boolean(this.published),
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
			publication_date: this.publication_date,
			countries: this.countries || [],
			microsites: this.microsites || [],
			user: this.user
		}
	}
}
