import { Language } from "../../locale/interfaces/Translation"
import { MicrositeError } from "../../ui/error/MicrositeError"
import { MicrositeModel } from "../models/MicrositeModel"
import { Endpoint } from "../network/endpoint"
import { HttpManager } from "./../network/interface/HttpManager"

export class MicrositeService {
	private _http: HttpManager

	constructor({ HttpManager }: { HttpManager: HttpManager }) {
		this._http = HttpManager
	}

	public async getMicrosites(): Promise<MicrositeModel[]> {
		try {
			const response = await this._http.requestNewApi({
				method: "GET",
				url: Endpoint.MICROSITES_ALL
			})
			return response.data.map((microsite: any) => MicrositeModel.fromRawJson(microsite))
		} catch (error: any) {
			throw new MicrositeError({
				errorMessage: MicrositeError.MICROSITES_FETCH_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async createMicrosite(microsite: MicrositeModel): Promise<MicrositeModel> {
		try {
			const micrositeData = {
				...microsite,
				sellerId: null,
				slug: microsite.slug,
				status: 1,
				partnerType: microsite.partnerType ? Number(microsite.partnerType) : undefined,
				sporttipsLogo: typeof microsite.sporttipsLogo === "string" ? microsite.sporttipsLogo : null,
				partnerLogo: typeof microsite.partnerLogo === "string" ? microsite.partnerLogo : null,
				infoIcon: typeof microsite.infoIcon === "string" ? microsite.infoIcon : null,
				color: microsite.color,
				font: microsite.font,
				lang: microsite.lang,
				currency: microsite.currency ? Number(microsite.currency) : undefined,
				feePercent: microsite.feePercent,
				microSitesDetail: microsite.microSitesDetail?.map(detail => ({
					partNumber: 0,
					partName: null,
					partLink: null,
					partImage: null,
					partTitle: null,
					partSubtitle: null,
					partFontColor: null,
					partBgColor: null,
					partSide: null,
					status: 1,
					clusterId: Number(detail.clusterId)
				}))
			}
			const settingsData = {
				currency: microsite.currency ? Number(microsite.currency) : undefined,
				font: microsite.font,
				partner_type: microsite.partnerType ? Number(microsite.partnerType) : undefined,
				lang: microsite.lang,
				sporttips_logo: typeof microsite.sporttipsLogo === "string" ? microsite.sporttipsLogo : null,
				show_logo: new Boolean(microsite.showLogo),
				has_user_auth: microsite.settings?.hasUserAuth,
				business_type: microsite.settings?.businessType ? Number(microsite.settings.businessType) : undefined
			}
			const micrositeResponse = await this._http.requestNewApi({
				method: "POST",
				url: Endpoint.MICROSITE_ADMIN,
				data: micrositeData
			})
			const settingsResponse = await this._http.requestNewApi({
				method: "POST",
				url: Endpoint.MICROSITE_SETTINGS.replace(":micrositeId", micrositeResponse.data.id),
				data: { settings: settingsData }
			})
			const micrositeModel = MicrositeModel.fromRawJson({
				...microsite,
				id: micrositeResponse.data.id,
				settings: settingsResponse.data
			})
			await this.createMicrositeServices(Number(micrositeModel.id), microsite.services)

			return micrositeModel
		} catch (error: any) {
			throw new MicrositeError({
				errorMessage: MicrositeError.MICROSITE_CREATE_ERROR,
				errorCode: error?.message,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async updateMicrosite(microsite: MicrositeModel): Promise<MicrositeModel> {
		try {
			const micrositeData = {
				...microsite,
				seller_id: null,
				slug: microsite.slug,
				status: microsite.status,
				partnerType: microsite.partnerType ? Number(microsite.partnerType) : undefined,
				sporttipsLogo: typeof microsite.sporttipsLogo === "string" ? microsite.sporttipsLogo : null,
				partnerLogo: typeof microsite.partnerLogo === "string" ? microsite.partnerLogo : null,
				infoIcon: typeof microsite.infoIcon === "string" ? microsite.infoIcon : null,
				color: microsite.color,
				font: microsite.font,
				lang: microsite.lang,
				currency: microsite.currency ? Number(microsite.currency) : undefined,
				feePercent: microsite.feePercent,
				microSitesDetail: microsite.microSitesDetail?.map(detail => ({
					partNumber: 0,
					partName: null,
					partLink: null,
					partImage: null,
					partTitle: null,
					partSubtitle: null,
					partFontColor: null,
					partBgColor: null,
					partSide: null,
					status: 1,
					clusterId: Number(detail.clusterId)
				}))
			}
			const settingsData = {
				currency: microsite.currency ? Number(microsite.currency) : undefined,
				font: microsite.font,
				partner_type: microsite.partnerType ? Number(microsite.partnerType) : undefined,
				lang: microsite.lang,
				sporttips_logo: typeof microsite.sporttipsLogo === "string" ? microsite.sporttipsLogo : null,
				show_logo: new Boolean(microsite.showLogo),
				has_user_auth: microsite.settings?.hasUserAuth,
				business_type: microsite.settings?.businessType ? Number(microsite.settings.businessType) : undefined,
				show_whatsapp: microsite.settings?.showWhatsapp
			}
			if (microsite.id) {
				await this.updateMicrositeServices(Number(microsite.id), microsite.services)
				await this.createMicrositeServices(Number(microsite.id), microsite.services)
				const [micrositeResponse, settingsResponse] = await Promise.all([
					this._http.requestNewApi({
						method: "PATCH",
						url: `${Endpoint.MICROSITE_ADMIN}/${microsite.id}`,
						data: micrositeData
					}),
					this._http.requestNewApi({
						method: "POST",
						url: Endpoint.MICROSITE_SETTINGS.replace(":micrositeId", microsite.id),
						data: { settings: settingsData }
					})
				])
				return MicrositeModel.fromRawJson({ ...micrositeResponse.data, settings: settingsResponse.data })
			} else {
				throw new MicrositeError({
					errorMessage: MicrositeError.MICROSITE_UPDATE_ERROR,
					httpStatus: 400
				})
			}
		} catch (error: any) {
			throw new MicrositeError({
				errorMessage: MicrositeError.MICROSITE_UPDATE_ERROR,
				errorCode: error?.message,
				httpStatus: error?.httpStatus
			})
		}
	}

	private async updateMicrositeServices(micrositeId: number, services: MicrositeModel["services"]): Promise<void> {
		try {
			for (let i = 0; i < services.length; i++) {
				const element = services[i]
				if (!element?.updated || element?.new) continue
				const data = {
					weight: element.weight,
					highlighted: element.highlighted
				}
				await this._http.requestNewApi({
					method: "PATCH",
					url: `${Endpoint.MICROSITE_ADMIN}/${micrositeId}/service/${element.id}`,
					data
				})
			}
		} catch (error: any) {
			throw new MicrositeError({
				errorMessage: MicrositeError.MICROSITE_SERVICE_UPDATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	private async createMicrositeServices(micrositeId: number, services: MicrositeModel["services"]): Promise<void> {
		try {
			for (let i = 0; i < services.length; i++) {
				const element = services[i]
				if (!element?.new) continue
				const data = {
					micrositeId,
					serviceId: element.id,
					weight: element.weight,
					highlighted: element.highlighted
				}
				await this._http.requestNewApi({
					method: "POST",
					url: `${Endpoint.MICROSITE_ADMIN}/service`,
					data
				})
			}
		} catch (error: any) {
			throw new MicrositeError({
				errorMessage: MicrositeError.MICROSITE_SERVICE_CREATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async deleteMicrosite(microsite_id: string): Promise<void> {
		try {
			await this._http.requestNewApi({
				method: "DELETE",
				url: `${Endpoint.MICROSITE_ADMIN}/${microsite_id}`
			})
		} catch (error: any) {
			throw new MicrositeError({
				errorMessage: MicrositeError.MICROSITE_DELETE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async deleteMicrositeService(micrositeId: string, serviceId: string): Promise<void> {
		try {
			await this._http.requestNewApi({
				method: "DELETE",
				url: `${Endpoint.MICROSITE_ADMIN}/${micrositeId}/service/${serviceId}`
			})
		} catch (error: any) {
			throw new MicrositeError({
				errorMessage: MicrositeError.MICROSITE_SERVICE_DELETE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async getMicrositeById(id: string, translation?: Language): Promise<MicrositeModel> {
		try {
			const response = await this._http.requestNewApi({
				method: "GET",
				url: `${Endpoint.MICROSITE_ADMIN}/${id}`,
				headers: translation ? { locale: translation } : undefined
			})
			return MicrositeModel.fromRawJson(response.data)
		} catch (error: any) {
			throw new MicrositeError({
				errorMessage: MicrositeError.MICROSITE_FETCH_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async getMicrositeServices(id: string, page: number = 0, limit: number = 200): Promise<MicrositeModel> {
		try {
			const queryParams = `page=${page}&limit=${limit}`
			const response = await this._http.requestNewApi({
				method: "GET",
				url: `${Endpoint.MICROSITE_ADMIN}/${id}/service?${queryParams}`
			})
			return MicrositeModel.fromRawJson({ services: response.data })
		} catch (error: any) {
			throw new MicrositeError({
				errorMessage: MicrositeError.MICROSITE_SERVICE_FETCH_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async uploadMicrositeImage(data: {
		micrositeId: string
		fileName: string
		fileType: string
		fileData: any
	}): Promise<{ file_path: string }> {
		try {
			const response = await this._http.post(
				`${Endpoint.MICROSITE_UPLOAD_IMAGE}/${data.micrositeId}/${data.fileType}/${data.fileName}`,
				data.fileData,
				{
					headers: { "Content-Type": "multipart/form-data" }
				}
			)
			return response.data
		} catch (error: any) {
			throw new MicrositeError({
				errorMessage: MicrositeError.MICROSITE_UPLOAD_IMAGE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async uploadMicrositeDetailImage(data: {
		micrositeDetailId: string
		fileName: string
		fileData: any
	}): Promise<{ file_path: string }> {
		try {
			const response = await this._http.post(
				`${Endpoint.MICROSITE_DETAIL_UPLOAD_IMAGE}/${data.micrositeDetailId}/${data.fileName}`,
				data.fileData,
				{
					headers: { "Content-Type": "multipart/form-data" }
				}
			)
			return response.data
		} catch (error: any) {
			throw new MicrositeError({
				errorMessage: MicrositeError.MICROSITE_DETAIL_UPLOAD_IMAGE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}
}
