import type { SellerServicesRepository } from "../../../repositories/sellerServicesRepository"
import { UseCase } from "../../useCase"
import { UpdateSellerServiceResponse } from "./response"
import { UpdateSellerServiceRequest } from "./request"

export class UpdateSellerServiceUseCase implements UseCase<UpdateSellerServiceRequest, UpdateSellerServiceResponse> {
	private _repository
	constructor({ SellerServicesRepository }: { SellerServicesRepository: SellerServicesRepository }) {
		this._repository = SellerServicesRepository
	}

	exec(service: UpdateSellerServiceRequest) {
		return this._repository.updateSellerService(service)
	}
}
