import { UseCase } from "../../useCase"
import { CreateTipResponse } from "./response"
import { CreateTipRequest } from "./request"
import { TipRepository } from "../../../repositories/tipRepository"

export class CreateTipUseCase implements UseCase<CreateTipRequest, CreateTipResponse> {
	private _repository
	constructor({ TipRepository }: { TipRepository: TipRepository }) {
		this._repository = TipRepository
	}

	exec(tip: CreateTipRequest) {
		return this._repository.createTip(tip)
	}
}
