import { action, makeObservable, observable, toJS } from "mobx"
import { Language } from "../../../locale/interfaces/Translation"
import { GetBannersUseCase } from "../../../domain/useCases/banners/getBanners"
import { ErrorHandler } from "../../error/ErrorHandler"
import { SaveBannersUseCase } from "../../../domain/useCases/banners/saveBanners"
import { BannerForm } from "../../components/BannersFormView/entities/BannerForm"

interface Props {
	SaveBannersUseCase: SaveBannersUseCase
	GetBannersUseCase: GetBannersUseCase
	ErrorHandler: ErrorHandler
}

export class BannersViewModel {
	private _getBannersUseCase
	private _saveBannersUseCase
	private _errorHandler
	selectedLanguage = "ES"
	isLoading = false
	bannersEs: BannerForm[] = []
	bannersMx: BannerForm[] = []

	constructor({ GetBannersUseCase, SaveBannersUseCase, ErrorHandler }: Props) {
		makeObservable(this, {
			selectedLanguage: observable,
			isLoading: observable,
			bannersEs: observable,
			bannersMx: observable,
			setBanners: action,
			setLoading: action,
			setSelectedLanguage: action
		})
		this._getBannersUseCase = GetBannersUseCase
		this._saveBannersUseCase = SaveBannersUseCase
		this._errorHandler = ErrorHandler
		this.loadBanners()
	}

	setLoading(value: boolean) {
		this.isLoading = value
	}

	setSelectedLanguage(language: Language) {
		this.selectedLanguage = language
	}

	setBanners(banners: BannerForm[], region: string) {
		if (region === "ES") this.bannersEs = banners
		else if (region === "MX") this.bannersMx = banners
	}

	hasIncompleteBanners() {
		return this.bannersEs.concat(this.bannersMx).some(b => !b.deleted && (!b.desktopImage || !b.mobileImage))
	}

	async loadBanners() {
		try {
			this.setLoading(true)
			const [bannersEs, bannersMx] = await Promise.all([
				this._getBannersUseCase.exec("ES"),
				this._getBannersUseCase.exec("MX")
			])
			this.bannersEs = bannersEs.map(b => new BannerForm(b))
			this.bannersMx = bannersMx.map(b => new BannerForm(b))
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	async saveBanners() {
		try {
			this.setLoading(true)
			await this._saveBannersUseCase.exec(this.bannersEs.concat(this.bannersMx))
			await this.loadBanners()
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}
}
