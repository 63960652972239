interface ContructorParams {
	id: number
	key: string
	type: string
	picture: string
}

export class CategoryIcon {
	public id: number
	public key: string
	public type: string
	public picture: string

	constructor(params: ContructorParams) {
		this.id = params.id
		this.key = params.key
		this.type = params.type
		this.picture = params.picture
	}
}
