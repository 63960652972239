import type { SellerServicesRepository } from "../../../repositories/sellerServicesRepository"
import { UseCase } from "../../useCase"
import { GetSellerServiceDetailResponse } from "./response"
import { GetSellerServiceDetailRequest } from "./request"

export class GetSellerServiceDetailUseCase
	implements UseCase<GetSellerServiceDetailRequest, GetSellerServiceDetailResponse>
{
	private _repository
	constructor({ SellerServicesRepository }: { SellerServicesRepository: SellerServicesRepository }) {
		this._repository = SellerServicesRepository
	}

	exec(serviceId: GetSellerServiceDetailRequest) {
		return this._repository.getServiceDetail(serviceId)
	}
}
