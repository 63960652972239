import { action, makeObservable, observable } from "mobx"

export enum ApiErrorCodes {
	USER_NOT_ADMIN = "user.not.admin",
	USER_NOT_EXISTS = "user.not.exists",
	SELLER_EXISTS = "seller.exists",
	SERVICE_NOT_EXISTS = "service.not.exists",
	NULL_VARIABLE_SENDED = "variables.missing.null",
	MICROSTE_SLUG_EXISTS = "microsites.errors.existingSlug"
}

export class ErrorHandler {
	private static USER_NOT_ADMIN_MESSAGE = "User is not admin"
	private static USER_NOT_EXISTS_MESSAGE = "User does not exists"
	private static SELLER_EXISTS_MESSAGE = "Seller already exists for that user"
	private static UNHANDLER_ERROR = "Unhandled error"
	private static NULL_VARIABLE_SENDED = "Not null variable sended:"
	private static MICROSTE_SLUG_EXISTS = "Microsite slug already exists"
	errorMessage: string = ""

	constructor() {
		makeObservable(this, {
			errorMessage: observable,
			setErrorMessage: action
		})
	}

	public handleError(error: any) {
		console.warn("ERROR_CODE:", error?.errorCode)
		console.error(error)
		this.setErrorMessage(this.getMessageError(error))
		this.setErrorMessage("")

		// Return somethingelse to catch at MainForm component on "onFormFinishFailed" function
		// return message ?? (error?.message || ErrorHandler.UNHANDLER_ERROR);
	}

	private getMessageError(error: any) {
		if (error?.errorCode) {
			const errorCode: ApiErrorCodes = error.errorCode
			const errorCodeMessages: { [key: string]: string } = {
				[ApiErrorCodes.USER_NOT_ADMIN]: ErrorHandler.USER_NOT_ADMIN_MESSAGE,
				[ApiErrorCodes.USER_NOT_EXISTS]: ErrorHandler.USER_NOT_EXISTS_MESSAGE,
				[ApiErrorCodes.SELLER_EXISTS]: ErrorHandler.SELLER_EXISTS_MESSAGE,
				[ApiErrorCodes.NULL_VARIABLE_SENDED]: ErrorHandler.NULL_VARIABLE_SENDED,
				[ApiErrorCodes.MICROSTE_SLUG_EXISTS]: ErrorHandler.MICROSTE_SLUG_EXISTS
			}

			if (errorCode.startsWith(ApiErrorCodes.NULL_VARIABLE_SENDED)) {
				const arrToEvaluate = errorCode.split(".")
				const variableName = arrToEvaluate.splice(arrToEvaluate.length - 1, 1)
				return `${errorCodeMessages[arrToEvaluate.join(".")]} ${variableName}`
			}

			return errorCodeMessages[errorCode] ?? (error?.message || ErrorHandler.UNHANDLER_ERROR)
		}
		return error?.message || ErrorHandler.UNHANDLER_ERROR
	}

	setErrorMessage(message: any) {
		this.errorMessage = message
	}
}
