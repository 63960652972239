import { action, makeObservable, observable } from "mobx"

export class Settings {
	googleClientId: string = localStorage.getItem("googleClientId") || ""

	constructor() {
		makeObservable(this, {
			googleClientId: observable,
			setGoogleClientId: action
		})
	}

	setGoogleClientId = (id: string) => {
		this.googleClientId = id
	}
}
