import { MicrositeRepository } from "./../../../repositories/micrositeRepository"
import { UseCase } from "../../useCase"
import { DeleteMicrositeResponse } from "./response"
import { DeleteMicrositeRequest } from "./request"

export class DeleteMicrositeUseCase implements UseCase<DeleteMicrositeRequest, DeleteMicrositeResponse> {
	private _repository
	constructor({ MicrositeRepository }: { MicrositeRepository: MicrositeRepository }) {
		this._repository = MicrositeRepository
	}

	exec(microsite_id: DeleteMicrositeRequest) {
		return this._repository.deleteMicrosite(microsite_id)
	}
}
