import { Navigate, useLocation } from "react-router-dom"
import { ReactElement } from "react"
import { observer } from "mobx-react"
import { AuthStore } from "../stores/AuthStore"
import { useContainerInjection } from "../hooks/useContainerInjection"

const ProtectedRoute = ({ children }: { children: ReactElement }) => {
	const { isAuthenticated } = useContainerInjection<AuthStore>("AuthStore")
	const location = useLocation()
	if (!isAuthenticated) {
		return <Navigate to="/" replace state={{ from: location }} />
	}

	return children
}

export default observer(ProtectedRoute)
