import { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Layout, Drawer, Affix } from "antd"
import Sidenav from "./Sidenav"
import Header from "./Header"
import { observer } from "mobx-react"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { AuthStore } from "../../stores/AuthStore"
import { RoutePaths } from "../../navigation/RoutePaths.enum"
import { colors } from "../../assets/styles/appStyles"
import { toJS } from "mobx"

const { Header: AntHeader, Content, Sider } = Layout

const Main = ({ children }: { children: React.ReactNode }) => {
	const navigate = useNavigate()
	const { isAuthenticated, userData } = useContainerInjection<AuthStore>("AuthStore")
	const [visible, setVisible] = useState(false)
	const [placement, setPlacement] = useState("right")
	const [sidenavColor, setSidenavColor] = useState(colors.primary)
	const [sidenavType, setSidenavType] = useState("transparent")
	const [fixed, setFixed] = useState(false)

	function openDrawer() {
		return setVisible(!visible)
	}
	// const handleSidenavType = (type) => setSidenavType(type);
	// const handleSidenavColor = (color) => setSidenavColor(color);
	// const handleFixedNavbar = (type) => setFixed(type);

	let { pathname } = useLocation()
	pathname = pathname.replace("/", "")

	useEffect(() => {
		if (pathname === "rtl") {
			setPlacement("left")
		} else {
			setPlacement("right")
		}
	}, [pathname, isAuthenticated])

	useEffect(() => {
		if (!isAuthenticated) {
			navigate(RoutePaths.SIGN_IN, { replace: true })
		}
	}, [navigate, isAuthenticated])

	return (
		<Layout
			className={`layout-dashboard ${pathname === "profile" ? "layout-profile" : ""} ${
				pathname === "rtl" ? "layout-dashboard-rtl" : ""
			}`}
		>
			<Drawer
				title={false}
				placement={placement === "right" ? "left" : "right"}
				closable={false}
				onClose={() => setVisible(false)}
				open={visible}
				key={placement === "right" ? "left" : "right"}
				width={250}
				className={`drawer-sidebar ${pathname === "rtl" ? "drawer-sidebar-rtl" : ""} `}
			>
				<Layout className={`layout-dashboard ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}>
					<Sider
						trigger={null}
						width={250}
						theme="light"
						className={`sider-primary ant-layout-sider-primary ${
							sidenavType === "#fff" ? "active-route" : ""
						}`}
						style={{ background: sidenavType }}
					>
						<Sidenav color={sidenavColor} userData={toJS(userData)} isAuthenticated={isAuthenticated} />
					</Sider>
				</Layout>
			</Drawer>
			<Sider
				hidden={!Boolean(isAuthenticated)}
				breakpoint="lg"
				collapsedWidth="0"
				onCollapse={(collapsed, type) => {
					// console.log(collapsed, type)
				}}
				trigger={null}
				width={250}
				theme="light"
				className={`sider-primary ant-layout-sider-primary ${sidenavType === "#fff" ? "active-route" : ""}`}
				style={{ background: sidenavType }}
			>
				<Sidenav color={sidenavColor} userData={toJS(userData)} isAuthenticated={isAuthenticated} />
			</Sider>
			{Boolean(isAuthenticated) && (
				<Layout>
					{fixed ? (
						<Affix>
							<AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
								<Header
									onPress={openDrawer}
									name={pathname}
									subName={pathname}
									// handleSidenavColor={handleSidenavColor}
									// handleSidenavType={handleSidenavType}
									// handleFixedNavbar={handleFixedNavbar}
								/>
							</AntHeader>
						</Affix>
					) : (
						<AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
							<Header
								onPress={openDrawer}
								name={pathname}
								subName={pathname}
								// handleSidenavColor={handleSidenavColor}
								// handleSidenavType={handleSidenavType}
								// handleFixedNavbar={handleFixedNavbar}
							/>
						</AntHeader>
					)}
					<Content className="content-ant">{children}</Content>
					{/* <Footer /> */}
				</Layout>
			)}
		</Layout>
	)
}

export default observer(Main)
