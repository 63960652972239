import { ErrorHandlerError } from "./ErrorHandlerError.interface"

export class ServiceError extends Error implements ErrorHandlerError {
	public errorMessage: string
	public errorCode?: string
	public httpStatus: number
	static SERVICES_FETCH_ERROR = "There was an error fetching the services"
	static SERVICE_DETAIL_FETCH_ERROR = "There was an error fetching the service detail"
	static SERVICE_CREATE_ERROR = "There was an error creating the service"
	static SERVICE_UPDATE_ERROR = "There was an error updating the service"
	static SERVICE_DELETE_ERROR = "There was an error deleting the service"
	static SERVICE_UPLOAD_IMAGE_ERROR = "There was an error uploading the service image"
	static SERVICE_PICTURES_FETCH_ERROR = "There was an error fetching the service pictures"
	static SERVICE_SEARCH_ERROR = "There was an error searching the service"
	static SERVICE_CALENDAR_ERROR = "There was an error fetching the service schedule"
	static SERVICE_EXCEL_ERROR = "There was an error exporting table data"
	static SERVICE_CONTENT_CREATE_ERROR = "There was an error creating the service content"

	constructor(error: ErrorHandlerError) {
		super(error.errorMessage)
		this.errorMessage = error.errorMessage
		this.httpStatus = error.httpStatus
		this.errorCode = error.errorCode
		this.name = "ServiceError"
	}
}
