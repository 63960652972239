import { File } from "../types/File.type"

export enum FileType {
	SPORTTIPS_LOGO = "sporttips_logo",
	PARTNER_LOGO = "partner_logo",
	INFO_ICON = "info_icon",
	PARTNER_IMAGE = "part_image"
}

export enum BusinessTypeEnum {
	"B2B CORPORATE",
	"B2B PARTNERS",
	"B2C"
}

export interface MicrositeCluster {
	id?: string
	key?: string
	clusterId: string
	status?: boolean
	updated?: boolean
}

export interface MicrositeService {
	id: string
	title: string
	weight: string
	seller: {
		id?: number
		name: string
	}
	cluster?: {
		clusterId: number
		clusterName: string
	}[]
	highlighted: string
	updated?: boolean
	new?: boolean
}

export interface MicrositeSetting {
	hasUserAuth: boolean
	businessType?: BusinessTypeEnum
	showWhatsapp: boolean
}

interface ContructorParams {
	id?: string
	slug: string
	status: boolean
	sellerId: string
	sellerName: string
	popupText?: string
	popupTextEn?: string
	externalLink?: string
	micrositeType: string
	showLogo: boolean
	sporttipsLogo?: string
	partnerLogo?: string
	infoIcon?: string
	color: string
	contrastColor?: string
	fontType?: string
	currency?: string
	lang: string
	availableLanguages?: string[]
	feePercentage?: number
	clusters: MicrositeCluster[]
	services: MicrositeService[]
	settings: MicrositeSetting
	whatsappNumber?: string
	pointsEnabled?: boolean
}

export class Microsite {
	static DEFAULT_COLOR = "#1677FF"
	static DEFAULT_INFO_ICON = "https://sporttips-dev.s3.eu-west-1.amazonaws.com/www/microsites/comun/Ideal.png"

	public id?: string

	public slug: string
	public status: boolean
	public sellerId: string
	public sellerName: string
	public popupText?: string
	public popupTextEn?: string
	public externalLink?: string
	public micrositeType: string
	public showLogo: boolean
	public sporttipsLogo?: string & File
	public partnerLogo?: string & File
	public infoIcon?: string & File
	public color: string
	public contrastColor?: string
	public fontType?: string
	public currency?: string
	public clusters: MicrositeCluster[]
	public services: MicrositeService[]
	public settings?: MicrositeSetting
	public lang: string
	public availableLanguages: string[]
	public feePercentage?: number
	public whatsappNumber?: string
	public pointsEnabled: boolean

	static businessTypes = [
		{ id: 1, label: "B2B CORPORATE" },
		{ id: 2, label: "B2B PARTNERS" },
		{ id: 3, label: "B2C" }
	]

	constructor(params: ContructorParams) {
		this.id = params.id
		this.sellerId = params.sellerId
		this.slug = params.slug
		this.status = params.status
		this.popupText = params.popupText
		this.popupTextEn = params.popupTextEn
		this.sporttipsLogo = params.sporttipsLogo
		this.partnerLogo = params.partnerLogo
		this.infoIcon = params.infoIcon
		this.externalLink = params.externalLink
		this.sellerName = params.sellerName
		this.micrositeType = params.micrositeType
		this.showLogo = params.showLogo
		this.currency = params.currency
		this.color = params.color
		this.contrastColor = params.contrastColor
		this.fontType = params.fontType
		this.lang = params.lang
		this.availableLanguages = params.availableLanguages ?? [params.lang]
		this.clusters = params.clusters
		this.services = params.services
		this.feePercentage = params.feePercentage
		this.settings = params.settings
		this.whatsappNumber = params.whatsappNumber
		this.pointsEnabled = params.pointsEnabled ?? false
	}
}
