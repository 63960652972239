import { Skeleton, Table } from "antd"

const DataTableRowsSkeleton = ({ size = "md" }: { size?: "sm" | "md" | "lg" }) => {
	const arrayLength = { ["sm"]: 8, ["md"]: 14, ["lg"]: 20 }
	return (
		<Table
			components={{
				body: {
					row: () => (
						<div
							style={{
								display: "flex",
								marginBottom: 10,
								marginTop: 10,
								position: "relative",
								left: -20
							}}
						>
							<Skeleton.Input active style={{ width: "100vw" }} />
						</div>
					)
				}
			}}
			dataSource={Array(arrayLength[size])
				.fill(0)
				.map((_, idx) => ({ key: idx }))}
			className="ant-border-space"
			size="large"
		/>
	)
}

export default DataTableRowsSkeleton
