import { useLocation } from "react-router-dom"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { createRef, useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react"
import { toJS } from "mobx"
import MainForm, { MainFormRef } from "../../components/Form/MainForm"
import { Button, Col, Row } from "antd"
import useUpdateEffect from "../../hooks/useUpdateEffect"
import { useTranslation } from "react-i18next"
import { Card } from "../../styledComponents/CustomCard/Card"
import { TipsViewModel } from "./TipsViewModel"
import { Tip } from "../../../domain/entities/Tip"
import TipInformationFormSection from "./components/TipInformationFormSection"
import TipConfigFormSection from "./components/TipConfigFormSection"
import TipsMicrositesFormSection from "./components/TipsMicrositesFormSection"
import TipMicrositesModal from "./components/TipMicrositesModal"
import { MicrositeTip } from "../../../domain/entities/MicrositeTip"

const TipsFormView = () => {
	const { t } = useTranslation("tips", { keyPrefix: "form" })
	const locationState: { tip: Tip } = useLocation().state
	const formRef = createRef<MainFormRef>()
	const viewModel = useContainerInjection<TipsViewModel>("TipsViewModel")
	const [modalVisible, setTipMicrositeModalVisible] = useState(false)

	useEffect(() => {
		viewModel.setEditMode(locationState?.tip?.id !== undefined)
		viewModel.setInitialFormData(locationState?.tip)
		viewModel.setFormData(locationState?.tip)
		viewModel.setOriginalFormData(locationState?.tip)
		viewModel.setError("", undefined)
	}, [locationState?.tip])

	useUpdateEffect(() => {
		const selectedTip = viewModel.selectedTip
		viewModel.setFormData({ ...viewModel.formData, ...selectedTip })
		viewModel.setOriginalFormData({ ...viewModel.formData, ...selectedTip })
		viewModel.setEditMode(selectedTip.id !== undefined)
	}, [viewModel.selectedTip])
	const formData = toJS(viewModel.formData)
	const originalFormData = useMemo(() => toJS(viewModel.originalFormData), [viewModel.originalFormData])
	const formModified = JSON.stringify(formData) !== JSON.stringify(originalFormData)

	const onSubmitForm = async () => {
		await viewModel.sendFormData()
	}

	return (
		<div>
			<MainForm onSubmitForm={onSubmitForm} initialValues={formData} formModified={formModified} ref={formRef}>
				<Row gutter={[24, 0]}>
					<Col xl={24} md={24} xs={24}>
						<Row gutter={[24, 0]}>
							<Col md={24} xl={18} xs={24}>
								<Card
									bordered={false}
									className="criclebox tablespace mb-24"
									title={<h6 className="font-semibold m-0">{t("tipInfo.cardTitle")}</h6>}
									bodyStyle={{ padding: "24px", paddingLeft: "2.5rem" }}
								>
									<TipInformationFormSection />
								</Card>
								<Card
									bordered={false}
									className="criclebox tablespace mb-24"
									title={
										<div style={{ display: "flex", alignItems: "center" }}>
											<h6 className="font-semibold m-0">{t("micrositesTips.cardTitle")}</h6>
											<Button
												onClick={() => setTipMicrositeModalVisible(true)}
												type="primary"
												style={{
													marginLeft: "1.5vw",
													height: "34px",
													display: "flex",
													alignItems: "center"
												}}
											>
												{t("tipInfo.addMicrosite")}
											</Button>
										</div>
									}
									bodyStyle={{ padding: "24px", paddingLeft: "2.5rem" }}
								>
									<TipsMicrositesFormSection />
								</Card>
							</Col>
							<Col md={12} xl={6} xs={24}>
								<Card
									bordered={false}
									title={<h6 className="font-semibold m-0">{t("configInfo.cardTitle")}</h6>}
									bodyStyle={{ padding: "24px", paddingBottom: "48px" }}
								>
									<TipConfigFormSection />
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</MainForm>

			<TipMicrositesModal
				visible={modalVisible}
				onCancel={() => setTipMicrositeModalVisible(false)}
				onConfirm={microsite => {
					if (!microsite.id) return
					const newMicrosite: MicrositeTip = {
						micrositeId: microsite.id,
						slug: microsite.slug,
						position: Number(microsite.position),
						new: true
					}
					viewModel.formData.microsites?.push({ ...newMicrosite })
					setTipMicrositeModalVisible(false)
				}}
			/>
		</div>
	)
}

export default observer(TipsFormView)
