import { action, makeObservable, observable } from "mobx"
import { User } from "../../../domain/entities/User"
import { GetUserDetailUseCase } from "../../../domain/useCases/users/getUserDetailUseCase"
import { CreateUserUseCase } from "../../../domain/useCases/users/createUserUseCase"
import { GetUsersUseCase } from "../../../domain/useCases/users/getUsersUseCase"
import { SearchUsersUseCase } from "../../../domain/useCases/users/searchUsers"
import { ApiErrorCodes, ErrorHandler } from "../../error/ErrorHandler"
import { TableConfig } from "../../components/DataTable/types/TableConfig"
import config from "../../../config/config"
import { UpdateUserUseCase } from "../../../domain/useCases/users/updateUser/createUserUseCase"
import { ViewModel } from "../ViewModel"
import { GetUploadProfilePictureUrlUseCase } from "../../../domain/useCases/users/getUploadPictureUrl"
import { ClientFileUploadUseCase } from "../../../domain/useCases/upload/clientFileUpload"
import { UploadProgressStatus } from "../../../domain/enum/uploadProgressStatus.enum"
import { ImportUsersUseCase } from "../../../domain/useCases/users/importUsers"
import { GetMicrositesUseCase } from "../../../domain/useCases/microsites/getMicrosites"
import { Microsite } from "../../../domain/entities/Microsite"
import { ReportDetails } from "../../../domain/entities/ReportEmbedInfo"
import { GetAdminReportUseCase } from "../../../domain/useCases/reports/getAdminReport"

interface Props {
	GetUserDetailUseCase: GetUserDetailUseCase
	GetMicrositesUseCase: GetMicrositesUseCase
	CreateUserUseCase: CreateUserUseCase
	UpdateUserUseCase: UpdateUserUseCase
	GetUsersUseCase: GetUsersUseCase
	SearchUsersUseCase: SearchUsersUseCase
	GetUploadProfilePictureUrlUseCase: GetUploadProfilePictureUrlUseCase
	ClientFileUploadUseCase: ClientFileUploadUseCase
	GetAdminReportUseCase: GetAdminReportUseCase
	ImportUsersUseCase: ImportUsersUseCase
	ErrorHandler: ErrorHandler
}

export class UsersViewModel extends ViewModel {
	private _getUsersUseCase
	private _getUploadProfilePictureUrlUseCase
	private _searchUsersUseCase
	private _getUserDetailUseCase
	private _createUserUseCase
	private _updateUserUseCase
	private _errorHandler
	private _clientFileUploadUseCase
	private _importUsersUseCase
	private _getMicrositesUseCase
	private _getAdminReportUseCase
	isLoading: boolean = false
	isFetching: boolean = false
	searchedUsers: Partial<User>[] = []
	isLoadingPartnerData: boolean = false
	error: { message: string; show: boolean } = { message: "", show: false }
	editMode: boolean = false
	users: Partial<User>[] = []
	selectedUser: Partial<User> = {}
	initialFormData: Partial<User> = {}
	formData = this.initialFormData
	originalFormData: Partial<User> = {}
	searchValue: string = ""
	importingUsers: boolean = false
	progressData: { active: boolean; file?: File; message: string; status?: UploadProgressStatus } = {
		active: false,
		message: "",
		status: UploadProgressStatus.LOADING
	}
	tableConfig: TableConfig = {
		pageSize: config.ui.componentsConfig.dataTablePageSize,
		sort: { order: "descend", field: "id" }
	}
	roles: { id: string; name: string }[] = [
		{
			id: "1",
			name: "admin"
		},
		{
			id: "2",
			name: "user"
		},
		{
			id: "3",
			name: "partner"
		},
		{
			id: "4",
			name: "seller"
		}
	]
	microsites: Microsite[] = []
	reports: ReportDetails[] = []

	constructor({
		UpdateUserUseCase,
		GetUserDetailUseCase,
		GetUploadProfilePictureUrlUseCase,
		ClientFileUploadUseCase,
		CreateUserUseCase,
		GetUsersUseCase,
		SearchUsersUseCase,
		ImportUsersUseCase,
		GetMicrositesUseCase,
		GetAdminReportUseCase,
		ErrorHandler
	}: Props) {
		super()
		makeObservable(this, {
			importingUsers: observable,
			setImportingUsers: action,
			searchValue: observable,
			isLoading: observable,
			isLoadingPartnerData: observable,
			error: observable,
			editMode: observable,
			users: observable,
			searchedUsers: observable,
			selectedUser: observable,
			setSelectedUser: action,
			formData: observable,
			originalFormData: observable,
			setOriginalFormData: action,
			setFormData: action,
			initialFormData: observable,
			setInitialFormData: action,
			setUsers: action,
			setLoading: action,
			setLoadingPartnerData: action,
			setEditMode: action,
			setError: action,
			tableConfig: observable,
			setTableConfig: action,
			isFetching: observable,
			setIsFetching: action,
			progressData: observable,
			setProgressData: action,
			microsites: observable,
			reports: observable,
			setReports: action
		})
		this._getUserDetailUseCase = GetUserDetailUseCase
		this._getUploadProfilePictureUrlUseCase = GetUploadProfilePictureUrlUseCase
		this._createUserUseCase = CreateUserUseCase
		this._getUsersUseCase = GetUsersUseCase
		this._searchUsersUseCase = SearchUsersUseCase
		this._updateUserUseCase = UpdateUserUseCase
		this._errorHandler = ErrorHandler
		this._clientFileUploadUseCase = ClientFileUploadUseCase
		this._importUsersUseCase = ImportUsersUseCase
		this._getMicrositesUseCase = GetMicrositesUseCase
		this._getAdminReportUseCase = GetAdminReportUseCase
		this.fetchUsers()
	}

	public async fetchUsers() {
		try {
			this.setLoading(true)
			const users = await this._getUsersUseCase.exec({
				pagination: 0,
				limit: this.limit
			})
			this.setUsers(users)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async fetchPaginatedUsers() {
		this.setIsFetching(true)
		try {
			const users = await this._getUsersUseCase.exec({
				pagination: this.pagination,
				limit: this.limit
			})
			if (users.length > 0) {
				const combinedSellerServices = [...users, ...this.users]
				this.setUsers(combinedSellerServices)
				// this.setSearchedServices(combinedSellerServices)
			}
			this.setHasNextPage(users.length > 0)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setIsFetching(false)
		}
	}

	public async fetchPartnerData() {
		try {
			this.setLoadingPartnerData(true)
			const [microsites, reports] = await Promise.all([
				this._getMicrositesUseCase.exec(),
				this._getAdminReportUseCase.exec()
			])
			this.setMicrosites(microsites)
			this.setReports(reports.embedUrl)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoadingPartnerData(false)
		}
	}

	private async uploadProfilePicture(userId: string, uploadData: any): Promise<string> {
		let fileUrl: string = ""
		try {
			if (uploadData instanceof File) {
				const { presignUrl, key } = await this._getUploadProfilePictureUrlUseCase.exec({
					userId,
					fileName: uploadData.name,
					mimeType: uploadData.type
				})
				await this._clientFileUploadUseCase.exec({ file: uploadData, url: presignUrl })
				fileUrl = `${config.assets}/${key}`
			}
			return fileUrl
		} catch (error) {
			throw this._errorHandler.handleError(error)
		}
	}

	public async searchUsers(expression: string) {
		if (expression === "") {
			this.setSearchedUsers(this.users)
			return
		}
		this.setLoading(true)
		try {
			const searchedUsers = await this._searchUsersUseCase.exec({ expression })
			this.setSearchedUsers(searchedUsers)
		} catch (error: any) {
			if (error?.errorCode === ApiErrorCodes.SERVICE_NOT_EXISTS) {
				this.setSearchedUsers([])
				return
			}
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async filterUsers(expression: string) {
		if (expression === "") {
			this.setSearchedUsers(this.users)
			return
		}
		const searchedUsers = await this._searchUsersUseCase.exec({ expression })

		this.setSearchedUsers(searchedUsers)
	}

	public async sendFormData() {
		if (this.formData.id) {
			await this.updateUser()
		} else {
			await this.createUser()
		}
	}

	public async createUser() {
		try {
			this.setLoading(true)
			const user = await this._createUserUseCase.exec({ ...this.formData, picture: undefined })
			let picture = this.formData.picture as any
			if (picture instanceof File) {
				picture = await this.uploadProfilePicture(user.id, this.formData.picture)
				await this._updateUserUseCase.exec({ ...this.formData, id: user.id, picture })
			}
			this.fetchUsers()
			this.setSelectedUser({ ...this.formData, id: user.id, picture, password: User.DEFAULT_PASSWORD })
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async updateUser() {
		try {
			this.setLoading(true)
			const newPicture =
				(await this.uploadProfilePicture(this.formData.id!, this.formData.picture)) || this.formData.picture
			await this._updateUserUseCase.exec({ ...this.formData, picture: newPicture })
			this.fetchUsers()
			this.setSelectedUser({ ...this.formData, picture: newPicture })
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async importUsers(file: File) {
		this.setImportingUsers(true)
		try {
			const response = await this._importUsersUseCase.exec(file)
			if (response.code === "ok") this.fetchUsers()
			return response
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setImportingUsers(false)
		}
	}

	public closeProgress() {
		this.setProgressData({ active: false, message: "", status: UploadProgressStatus.LOADING })
	}

	// ACTIONS
	setUsers(users: Partial<User>[]) {
		this.users = users
		this.setSearchedUsers(users)
	}

	setLoading(isLoading: boolean) {
		this.isLoading = isLoading
	}

	setEditMode(editMode: boolean) {
		this.editMode = editMode
	}

	setInitialFormData(formData: Partial<User>) {
		this.initialFormData = formData
	}

	setFormData(formData: Partial<User>) {
		this.formData = formData
	}

	setOriginalFormData(formData: Partial<User>) {
		this.originalFormData = formData
	}

	setSelectedUser(user: Partial<User>) {
		this.selectedUser = user
	}

	setSearchedUsers(users: Partial<User>[]) {
		this.searchedUsers = users
	}

	setLoadingPartnerData(isLoading: boolean) {
		this.isLoadingPartnerData = isLoading
	}

	setIsFetching(isFetching: boolean) {
		this.isFetching = isFetching
	}

	setError(message: string, show: boolean) {
		this.error = { message, show }
	}

	setTableConfig(config: Partial<typeof this.tableConfig>) {
		this.tableConfig = { ...this.tableConfig, ...config }
	}

	setProgressData(progressData: typeof this.progressData) {
		this.progressData = progressData
	}

	setImportingUsers(importingUsers: boolean) {
		this.importingUsers = importingUsers
	}

	setMicrosites(microsites: Microsite[]) {
		this.microsites = microsites
	}

	setReports(reports: ReportDetails[]) {
		this.reports = reports
	}
}
