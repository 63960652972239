import { Col, Row } from "antd"
import { createRef, useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import MainForm, { MainFormRef } from "../../components/Form/MainForm"
import { VoucherViewModel } from "./VoucherViewModel"
import { observer } from "mobx-react"
import { Voucher } from "../../../domain/entities/Voucher"
import { useConfirmModal } from "../../service/confirmModal"
import { useTranslation } from "react-i18next"
import VoucherRule from "./components/VoucherRule"
import { toJS } from "mobx"
import { FormField } from "../../components/Form/FormField"
import { Card } from "../../styledComponents/CustomCard/Card"

// TODO: SPLIT FORM INTO COMPONENTS
const VoucherFormView = () => {
	const { t } = useTranslation("vouchers", { keyPrefix: "form" })
	const selectedVoucher: { voucher: Voucher } = useLocation().state
	const formRef = createRef<MainFormRef>()
	const viewModel = useContainerInjection<VoucherViewModel>("VoucherViewModel")
	useEffect(() => {
		viewModel.setInitialFormData(selectedVoucher?.voucher)
		viewModel.setEditMode(selectedVoucher?.voucher.id !== undefined)
		viewModel.setFormData(selectedVoucher?.voucher)
		viewModel.setOriginalFormData(selectedVoucher?.voucher)
		selectedVoucher?.voucher?.id && viewModel.fetchVoucherDetail(selectedVoucher?.voucher?.id)
	}, [selectedVoucher?.voucher])
	const formData = viewModel.formData
	const formDataVoucherRule = viewModel.formData?.voucherRules || {}
	const editMode = viewModel.editMode
	const originalFormData = useMemo(() => toJS(viewModel.originalFormData), [viewModel.originalFormData])
	const formModified = JSON.stringify(formData) !== JSON.stringify(originalFormData)

	// const resetForm = () => {
	//   viewModel.setFormData(originalFormData);
	//   formRef.current?.setFormValues({...originalFormData, ...originalFormData.voucher_rules![0]});
	// }

	const onSubmitForm = async () => {
		try {
			if (editMode) {
				await viewModel.updateVoucher(formData)
			} else {
				await viewModel.createVoucher(formData)
			}
		} catch (error) {
			throw error
		}
	}

	return (
		<MainForm
			onSubmitForm={onSubmitForm}
			formModified={formModified}
			initialValues={{ ...formData, ...formDataVoucherRule }}
			ref={formRef}
		>
			<Row gutter={[24, 0]}>
				<Col xl={24} md={24} xs={24}>
					<Card
						loading={viewModel.isLoadingDetail}
						bordered={false}
						className="criclebox tablespace mb-24"
						title={
							<div>
								<h6 className="font-semibold m-0">{t("voucherInfo.cardTitle")}</h6>
							</div>
						}
						bodyStyle={{ padding: "24px" }}
					>
						<Row gutter={[24, 0]}>
							<Col span={24} xl={12} md={12} xs={24}>
								<FormField.Input
									key={"voucherCode"}
									label={t("voucherInfo.voucherCode")}
									name={"voucherCode"}
									mandatory
									onChange={(value: string) => {
										viewModel.setFormData({ ...formData, voucherCode: value })
									}}
								/>
							</Col>
							<Col span={24} xl={12} md={12} xs={24}>
								<FormField.SelectSearch
									key={"status"}
									name={"status"}
									label={t("status.title").toString()}
									value={String(viewModel.formData?.status)}
									options={[
										{ value: "1", label: t("status.active") },
										{ value: "0", label: t("status.inactive") }
									]}
									onChange={status => {
										//@ts-ignore
										viewModel.setFormData({ ...formData, status: status.value })
									}}
								/>
							</Col>
						</Row>
						<Row gutter={[24, 0]}>
							<Col span={24} xl={12} md={12} xs={24}></Col>
						</Row>
					</Card>
				</Col>
				{formData.voucherRules && (
					<VoucherRule
						loading={viewModel.isLoadingDetail}
						data={formData?.voucherRules}
						disabled={editMode}
					/>
				)}
			</Row>
		</MainForm>
	)
}

export default observer(VoucherFormView)
