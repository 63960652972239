import { Link } from "react-router-dom"
import TABLE_COLUMNS from "../const/VOUCHERS_TABLE_COLUMNS"
import { Tooltip, Typography } from "antd"
import { EditFilled } from "@ant-design/icons"
import { observer } from "mobx-react"
import { RoutePaths } from "../../../navigation/RoutePaths.enum"
import DataTable from "../../../components/DataTable/DataTable"
import { Voucher } from "../../../../domain/entities/Voucher"
import { useTranslation } from "react-i18next"
import { colors } from "../../../assets/styles/appStyles"
import { VoucherViewModel } from "../VoucherViewModel"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"

interface VoucherTableProps {
	data: Partial<Voucher>[]
	loading?: boolean
}

const VouchersTable = ({ data, loading }: VoucherTableProps) => {
	const { t } = useTranslation("toolTips")
	const viewModel = useContainerInjection<VoucherViewModel>("VoucherViewModel")
	const { t: tDataTable } = useTranslation("dataTable")

	return (
		<div className="table-responsive">
			<DataTable
				loading={loading}
				tableConfig={{
					sort: viewModel.tableConfig.sort,
					pageSize: viewModel.tableConfig.pageSize
				}}
				renderCustomLoadingComponent={() => <DataTableRowsSkeleton size="lg" />}
				onChange={data => {
					data.sorter &&
						viewModel.setTableConfig({ sort: { order: data.sorter.order, field: data.sorter.field } })
				}}
				onChangePage={(page, pageSize: number, lastPageFetched) => viewModel.setTableConfig({ pageSize })}
				columns={TABLE_COLUMNS()}
				dataSource={data.map(voucher => ({
					key: voucher.id,
					id: voucher.id,
					voucherCode: voucher.voucherCode,
					status: (
						<Typography.Title
							style={{
								color: Number(voucher.status) ? colors.success : colors.error,
								textAlign: "center",
								width: "5.5vw",
								display: "flex",
								justifyContent: "center",
								fontSize: "0.9rem"
							}}
						>
							{Number(voucher.status) === 1 ? tDataTable("status.active") : tDataTable("status.inactive")}
						</Typography.Title>
					),
					editButton: (
						<div className="ant-employed">
							<Link to={RoutePaths.VOUCHERS_ADD_NEW} state={{ voucher }}>
								<Tooltip title={t("edit")}>
									<EditFilled />
								</Tooltip>
							</Link>
						</div>
					)
				}))}
			/>
		</div>
	)
}

export default observer(VouchersTable)
