import { observer } from "mobx-react"
import { TipsViewModel } from "../TipsViewModel"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { Col, Form, Row } from "antd"
import { FormField } from "../../../components/Form/FormField"
import { useTranslation } from "react-i18next"
import Switch from "../../../components/Switch/Switch"

const TipConfigFormSection = () => {
	const viewModel = useContainerInjection<TipsViewModel>("TipsViewModel")
	const { t } = useTranslation("tips", { keyPrefix: "form.configInfo" })

	return (
		<Row gutter={[24, 0]}>
			<Col xl={24} md={24} xs={24}>
				<Form.Item
					label={t("published")}
					name="published"
					valuePropName="published"
					labelCol={{ span: 13 }}
					labelAlign="left"
				>
					<Switch
						checked={viewModel.formData.published}
						defaultChecked={true}
						onChange={published =>
							viewModel.setFormData({ ...viewModel.formData, published: Boolean(published) })
						}
					/>
				</Form.Item>
				<FormField.Select
					labelCol={{ span: 13 }}
					labelAlign="left"
					mandatory
					// className="right-1 absolute"
					key={"mediaType"}
					label={t("mediaType")}
					name={"mediaType"}
					toolTipTitle={t("mediaTypeSelector")}
					onChange={mediaType =>
						viewModel.setFormData({
							...viewModel.formData,
							mediaType,
							mediaPreview: undefined,
							mediaUrl: undefined
						})
					}
					options={viewModel.mediaType.map(mediaType => ({
						value: mediaType.value,
						label: t(mediaType.key)
					}))}
				/>
			</Col>
		</Row>
	)
}

export default observer(TipConfigFormSection)
