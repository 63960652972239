import { ServiceSessionModel } from "./ServiceSessionModel"
import { SellerServiceDetail } from "../../domain/entities/SellerServiceDetail"
import { CategoryModel } from "./CategoryModel"
import { CalendarAvailabilityModel } from "./CalendarAvailabilityModel"
import { CalendarDataModel } from "./CalendarDataModel"
import { ServiceTranslationModel } from "./ServiceTranslationModel"
import { SellersModel } from "./SellersModel"
import { SellerServiceCalendar } from "../../domain/entities/SellerServiceCalendar"
import { ServicePicture } from "../../domain/entities/ServicePicture"
import { ServiceTranslation } from "../../domain/entities/ServiceTranslation"

interface ContructorParams {
	serviceId: string
	title: string
	hint?: string
	description?: string
	price?: number
	typeService?: string
	picture?: string
	isOnline?: boolean
	isPresential?: boolean
	isFoundation?: boolean
	city?: string
	address?: string
	serviceClusters: CategoryModel[]
	availability: CalendarAvailabilityModel[]
	serviceCalendar?: CalendarDataModel[]
	weight: number
	serviceSessions: ServiceSessionModel[]
	serviceTranslations?: ServiceTranslationModel[]
	serviceContent?: { pictures: ServicePicture[] }[]
	endorsedUser?: { id: string; name: string }
	seller?: SellersModel
	countryId?: string
	highlighted?: boolean
	showAgenda: boolean
}

export class SellerServicesDetailModel {
	public serviceId: string
	public title: string
	public hint?: string
	public description?: string
	public price?: number
	public typeService?: string
	public picture?: string
	public isOnline?: boolean
	public isPresential?: boolean
	public city?: string
	public address?: string
	public isFoundation?: boolean
	public serviceClusters: CategoryModel[]
	public availability: CalendarAvailabilityModel[]
	public serviceCalendar?: CalendarDataModel[]
	public serviceContent?: { pictures: ServicePicture[] }[]
	public weight: number
	public serviceSessions: ServiceSessionModel[]
	public endorsedUser?: { id: string; name: string }
	public countryId?: string
	public highlighted?: boolean
	public showAgenda: boolean
	public serviceTranslations: ServiceTranslationModel[]
	public seller?: SellersModel

	constructor(params: ContructorParams) {
		this.serviceId = params.serviceId
		this.title = params.title
		this.hint = params.hint
		this.description = params.description
		this.price = params.price
		this.typeService = params.typeService
		this.picture = params.picture
		this.isOnline = params.isOnline
		this.isPresential = params.isPresential
		this.isFoundation = params.isFoundation
		this.city = params.city
		this.address = params.address
		this.serviceClusters = params.serviceClusters
		this.availability = params.availability
		this.serviceCalendar = params.serviceCalendar
		this.weight = params.weight
		this.serviceSessions = params.serviceSessions
		this.endorsedUser = params.endorsedUser
		this.countryId = params.countryId
		this.highlighted = params.highlighted
		this.showAgenda = params.showAgenda
		this.serviceTranslations = params.serviceTranslations ?? []
		this.seller = params.seller
		this.serviceContent = params.serviceContent
	}

	public get title_en() {
		return this.serviceTranslations?.find(t => t.locale === "en-us")?.title
	}
	public get title_ca() {
		return this.serviceTranslations?.find(t => t.locale === "ca-es")?.title
	}
	public get title_es() {
		return this.serviceTranslations?.find(t => t.locale === "es-es")?.title
	}
	public get description_en() {
		return this.serviceTranslations?.find(t => t.locale === "en-us")?.description
	}
	public get description_ca() {
		return this.serviceTranslations?.find(t => t.locale === "ca-es")?.description
	}
	public get description_es() {
		return this.serviceTranslations?.find(t => t.locale === "es-es")?.description
	}
	public get hint_en() {
		return this.serviceTranslations?.find(t => t.locale === "en-us")?.hint
	}
	public get hint_ca() {
		return this.serviceTranslations?.find(t => t.locale === "ca-es")?.hint
	}
	public get hint_es() {
		return this.serviceTranslations?.find(t => t.locale === "es-es")?.hint
	}

	public static fromRawJson = (rawJson: any): SellerServicesDetailModel => {
		return new SellerServicesDetailModel({
			...rawJson,
			serviceId: rawJson.id,
			seller: SellersModel.fromRawJson(rawJson.seller),
			serviceClusters: rawJson.serviceClusters?.map((category: any) => CategoryModel.fromRawJson(category)),
			availability: rawJson.availability?.map((slot: any) => CalendarAvailabilityModel.fromRawJson(slot)),
			serviceSessions: rawJson.serviceSessions?.map((session: any) => ServiceSessionModel.fromRawJson(session)),
			serviceTranslations: rawJson.serviceTranslations?.map((t: any) => ServiceTranslationModel.fromRawJson(t)),
			serviceContent: rawJson.serviceContent?.map((content: any) => ({
				pictures: content.pictures?.filter((p: any) => !p.cover)
			}))
		})
	}

	public static fromDomain = (service: SellerServiceDetail): SellerServicesDetailModel => {
		const serviceTranslations = [
			new ServiceTranslation({
				locale: "es-es",
				title: service.title,
				description: service.description,
				serviceId: service.id
			})
		]
		if (service.title_en || service.description_en || service.hint_en) {
			serviceTranslations.push(SellerServicesDetailModel.getTranslation(service, "en-us", "en"))
		}
		if (service.title_ca || service.description_ca || service.hint_ca) {
			serviceTranslations.push(SellerServicesDetailModel.getTranslation(service, "ca-es", "ca"))
		}
		return new SellerServicesDetailModel({
			...service,
			serviceId: service.id,
			typeService: service.typeService,
			seller: service.seller ? SellersModel.fromDomain(service.seller) : undefined,
			price: service.price || 0,
			isFoundation: service.isFoundation || false,
			isOnline: service.isOnline || false,
			isPresential: service.isPresential || false,
			serviceClusters: service.serviceClusters?.map(category => CategoryModel.fromDomain(category)),
			availability: service.calendarAvailability?.map(availability =>
				CalendarAvailabilityModel.fromDomain(availability)
			),
			serviceSessions: service.serviceSessions?.map(session => ServiceSessionModel.fromDomain(session)),
			serviceCalendar: service?.serviceCalendar
				? Array(CalendarDataModel.fromDomain(service.serviceCalendar))
				: undefined,
			countryId: service.countryId,
			showAgenda: service.showAgenda,
			serviceTranslations: serviceTranslations.map(t => ServiceTranslationModel.fromDomain(t)),
			endorsedUser:
				service.endorsedName && service.endorsedUserId
					? { name: service.endorsedName, id: service.endorsedUserId }
					: undefined
		})
	}

	private static getTranslation(service: SellerServiceDetail, locale: string, lang: "en" | "ca"): ServiceTranslation {
		return new ServiceTranslation({
			locale,
			title: service[`title_${lang}`],
			description: service[`description_${lang}`],
			hint: service[`hint_${lang}`],
			serviceId: service.id
		})
	}
}

declare module "./SellerServicesDetailModel" {
	interface SellerServicesDetailModel {
		toDomain(): SellerServiceDetail
	}
}

SellerServicesDetailModel.prototype.toDomain = function (): SellerServiceDetail {
	const data = this
	return new SellerServiceDetail({
		...data,
		title_en: data.title_en,
		title_ca: data.title_ca,
		description_en: data.description_en,
		description_ca: data.description_ca,
		hint_en: data.hint_en,
		hint_ca: data.hint_ca,
		id: data.serviceId,
		seller: data.seller?.toDomain(),
		typeService: data.typeService || "",
		isFoundation: data.isFoundation,
		weight: data.weight,
		isOnline: data.isOnline,
		isPresential: data.isPresential,
		serviceClusters: data.serviceClusters?.map(category => category.toDomain()),
		calendarAvailability: data.availability?.map(slot => slot.toDomain()),
		serviceCalendar:
			(data.serviceCalendar?.length ?? 0) > 0
				? {
						calendarId: data.serviceCalendar![0].calendarId,
						duration: String(data.serviceCalendar![0].duration),
						timeZone: data.serviceCalendar![0].timeZone
				  }
				: undefined,
		serviceSessions: data.serviceSessions?.map(session => session.toDomain()),
		endorsedUser: data.endorsedUser,
		countryId: data.countryId,
		showAgenda: data.showAgenda,
		endorsedName: data.endorsedUser?.name,
		endorsedUserId: data.endorsedUser?.id,
		endorsedUsername: data.endorsedUser?.name
	})
}
