import { Col, Row } from "antd"
import DataTable from "../../../components/DataTable/DataTable"
import { observer } from "mobx-react"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { TipsViewModel } from "../TipsViewModel"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"
import TABLE_COLUMNS from "../const/ASSOCIATED_MICROSITES_TABLE_COLUMNS"
import { useTranslation } from "react-i18next"
import { DeleteFilled } from "@ant-design/icons"
import { colors } from "../../../assets/styles/appStyles"
import { useConfirmModal } from "../../../service/confirmModal"
import { MicrositeTip } from "../../../../domain/entities/MicrositeTip"

interface Props {
	onChange?: (id: number, value: MicrositeTip) => void
}

const TipsMicrositesFormSection = ({ onChange }: Props) => {
	const viewModel = useContainerInjection<TipsViewModel>("TipsViewModel")
	const { showConfirm, context: modalContext } = useConfirmModal()
	const { t: tToast } = useTranslation("toast")

	const handleSave = (record: any) => {
		viewModel.setFormData({
			...viewModel.formData,
			microsites: viewModel.formData.microsites?.map(microsite => {
				if (microsite.micrositeId === record.id) {
					return { ...microsite, position: Number(record.weight), updated: true }
				}
				return microsite
			})
		})
	}

	const handleDeleteService = (micrositeTip: MicrositeTip) => {
		showConfirm({
			title: tToast("deleteConfirmMessage", { value: "session" }),
			onConfirm: async () => {
				viewModel.deleteMicrositeTip(micrositeTip)
			},
			type: "danger"
		})
	}

	return (
		<Row gutter={[24, 0]}>
			{modalContext}
			<Col md={24} xl={24} xs={24}>
				<DataTable
					loading={viewModel.isLoadingDetail}
					renderCustomLoadingComponent={() => <DataTableRowsSkeleton />}
					editableCells={{ handleSave }}
					columns={TABLE_COLUMNS()}
					dataSource={
						viewModel.formData.microsites?.map((micrositeTip, idx) => ({
							key: micrositeTip.micrositeId,
							slug: micrositeTip.slug,
							id: micrositeTip.micrositeId,
							weight: micrositeTip.position,
							actions: (
								<div key={micrositeTip.micrositeId}>
									<DeleteFilled
										style={{ fontSize: "1.2rem", color: colors.error }}
										onClick={() => handleDeleteService(micrositeTip)}
									/>
								</div>
							)
						})) || []
					}
				/>
			</Col>
		</Row>
	)
}

export default observer(TipsMicrositesFormSection)
