import { useContainerInjection } from "../../hooks/useContainerInjection"
import { SellerServicesViewModel } from "./SellerServicesViewModel"
import { observer } from "mobx-react"
import SellerServicesTable from "./components/SellerServicesTable"
import { Button, Col, Row, Typography } from "antd"
import { useNavigate } from "react-router-dom"
import { RoutePaths } from "../../navigation/RoutePaths.enum"
import SearchFilterField from "../../components/SearchFilterField/SearchFilterField"
import { SellerService } from "../../../domain/entities/SellerService"
import useUpdateEffect from "../../hooks/useUpdateEffect"
import { useTranslation } from "react-i18next"
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator"
import { colors } from "../../assets/styles/appStyles"
import { DownloadOutlined } from "@ant-design/icons"
import { Card } from "../../styledComponents/CustomCard/Card"
import config from "../../../config/config"

const { Title } = Typography

const SellerServicesView = () => {
	const { t } = useTranslation("services")
	const navigate = useNavigate()
	const viewModel = useContainerInjection<SellerServicesViewModel>("SellerServicesViewModel")
	const filterFunction = (value: Partial<SellerService>, searchInput: string | undefined) => {
		return searchInput && isNaN(parseInt(searchInput))
			? searchInput
				? !(value?.title?.toLowerCase().indexOf(searchInput.toLowerCase()) === -1)
				: true
			: searchInput
			? !(value?.id?.toString().indexOf(searchInput) === -1)
			: true
	}

	useUpdateEffect(() => {
		viewModel.fetchPaginatedSellersServices()
	}, [viewModel.pagination])

	return (
		<div>
			<SearchFilterField<SellerService>
				value={viewModel.searchValue}
				placeholder={t("searchBar").toString()}
				searchData={viewModel.sellersServices}
				filterFunction={filterFunction}
				onChange={value => {
					viewModel.setSearchedServices(value)
				}}
				inputDelay={config.ui.componentsConfig.searchInputDelay}
				onChangeText={value => {
					viewModel.searchValue = value
					viewModel.searchServices(value)
				}}
				style={{ marginBottom: "10px" }}
			/>
			<div className="tabled">
				<Row gutter={[24, 0]}>
					<Col xs="24" xl={24}>
						<Card
							bordered={false}
							className="criclebox tablespace mb-24"
							title={
								<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
									<div style={{ display: "flex", alignItems: "center" }}>
										<Title style={{ marginTop: 8 }} level={4}>
											{t("title")}
										</Title>
										<Button
											style={{ marginLeft: "1.5vw" }}
											type="primary"
											onClick={() => navigate(RoutePaths.SELLERS_SERVICES_ADD_NEW)}
										>
											{t("addNew")}
										</Button>
									</div>
									<div
										style={{
											width: 130,
											height: 40,
											display: "flex",
											justifyContent: "center",
											alignItems: "center"
										}}
									>
										{viewModel.isFetchingExcel && (
											<ActivityIndicator size="xxs" color={colors.activity} />
										)}
										<Button
											type="link"
											disabled={viewModel.isFetchingExcel}
											style={viewModel.isFetchingExcel ? { color: colors.activity } : undefined}
											icon={!viewModel.isFetchingExcel && <DownloadOutlined />}
											onClick={() => viewModel.fetchSellersServicesExcel()}
										>
											{t("exportTable")}
										</Button>
									</div>
								</div>
							}
						>
							<SellerServicesTable
								onChangePage={(page, pageSize, lastPageFetched) => {
									if (viewModel.searchValue) return
									if (page >= lastPageFetched - 1 && viewModel.hasNextPage && !viewModel.isFetching) {
										if (viewModel.pagination > 1 && viewModel.limit !== 200) {
											viewModel.setLimit(200)
											viewModel.setPagination(0)
											return
										}
										viewModel.setPagination(viewModel.pagination + 1)
									}
								}}
								data={viewModel.searchedServices}
								loading={viewModel.isLoading}
								isFetching={viewModel.isFetching}
							/>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default observer(SellerServicesView)
