import { Card, Typography } from "antd"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import MultiSwitch from "../../components/Switch/MultiSwitch"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { BannersViewModel } from "./BannersViewModel"
import BannersFormView from "../../components/BannersFormView/BannersFormView"
import MainForm from "../../components/Form/MainForm"

const { Title } = Typography

const Banners = () => {
	const { t } = useTranslation("banners")
	const viewModel = useContainerInjection<BannersViewModel>("BannersViewModel")
	const submitForm = async () => {
		if (viewModel.hasIncompleteBanners()) {
			throw { message: t("validations.incompleteBanner") }
		}
		await viewModel.saveBanners()
	}
	return (
		<MainForm
			hideFormCancelButton={true}
			disabled={viewModel.isLoading}
			onSubmitForm={() => submitForm()}
			isUpdating={true}
		>
			<Card bordered={true} className="criclebox tablespace">
				<div style={{ display: "flex", flexDirection: "column", padding: 20 }}>
					<Title style={{ marginTop: 0 }} level={4}>
						{t("selectLanguage")}
					</Title>
					<MultiSwitch
						options={[
							{ label: "ES", value: "ES" },
							{ label: "EN", value: "EN" },
							{ label: "CA", value: "CA" }
						]}
						value={viewModel.selectedLanguage}
						onChange={lang => viewModel.setSelectedLanguage(lang)}
						buttonsStyles={{ width: 80 }}
					/>
				</div>
			</Card>
			<div style={{ marginTop: 30 }}>
				<BannersFormView
					banners={viewModel.bannersEs}
					title={t("marketplaceES")}
					language={viewModel.selectedLanguage}
					microsite="ES"
					onBannerChange={banners => viewModel.setBanners(banners, "ES")}
					isLoading={viewModel.isLoading}
				/>
			</div>
			<div style={{ marginTop: 30 }}>
				<BannersFormView
					banners={viewModel.bannersMx}
					title={t("marketplaceMX")}
					language={viewModel.selectedLanguage}
					microsite="MX"
					onBannerChange={banners => viewModel.setBanners(banners, "MX")}
					isLoading={viewModel.isLoading}
				/>
			</div>
		</MainForm>
	)
}
export default observer(Banners)
