import { ReportEmbedInfo } from "../../../entities/ReportEmbedInfo"
import { ReportRepository } from "../../../repositories/reportRepository"
import { UseCase } from "../../useCase"
import { GetAdminReportRequest } from "./request"
import { GetAdminReportResponse } from "./response"

export class GetAdminReportUseCase implements UseCase<GetAdminReportRequest, GetAdminReportResponse> {
	private _repository
	constructor({ ReportRepository }: { ReportRepository: ReportRepository }) {
		this._repository = ReportRepository
	}
	exec(): Promise<ReportEmbedInfo> {
		return this._repository.getAdminEmbeddedReport()
	}
}
