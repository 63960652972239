import { UseCase } from "../../useCase"
import { GetTipsResponse } from "./response"
import { GetTipsRequest } from "./request"
import { TipRepository } from "../../../repositories/tipRepository"

export class GetTipsUseCase implements UseCase<GetTipsRequest, GetTipsResponse> {
	private _repository
	constructor({ TipRepository }: { TipRepository: TipRepository }) {
		this._repository = TipRepository
	}

	exec({ pagination, limit, searchExpression, filters }: GetTipsRequest) {
		return this._repository.getTips(pagination, limit, searchExpression, filters)
	}
}
