import { CategoryIcon } from "../../domain/entities/CategoryIcon"

interface ConstructorParams {
	id: number
	key: string
	type: string
	picture: string
}

export class CategoryIconModel {
	public id: number
	public key: string
	public type: string
	public picture: string

	constructor(params: ConstructorParams) {
		this.id = params.id
		this.key = params.key
		this.type = params.type
		this.picture = params.picture
	}

	static fromRawJson = (rawJson: any): CategoryIconModel => {
		const { id, key, type, picture } = rawJson

		return new CategoryIconModel({
			id,
			key,
			type,
			picture
		})
	}
}

declare module "./CategoryIconModel" {
	interface CategoryIconModel {
		toDomain(): CategoryIcon
	}
}

CategoryIconModel.prototype.toDomain = function (): CategoryIcon {
	const data = this
	return new CategoryIcon({
		id: data.id,
		key: data.key,
		type: data.type,
		picture: data.picture
	})
}
