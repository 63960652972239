import { ServiceSessionTranslation } from "../../domain/entities/ServiceSessionTranslation"

interface ConstructorParams {
	id?: number
	locale: string
	title?: string
	description?: string
	subtitle?: string
	serviceSessionId: string
}

export class ServiceSessionTranslationModel {
	public id?: number
	public locale: string
	public title?: string
	public description?: string
	public subtitle?: string
	public serviceSessionId: string

	constructor(params: ConstructorParams) {
		this.id = params.id
		this.locale = params.locale
		this.title = params.title
		this.description = params.description
		this.subtitle = params.subtitle
		this.serviceSessionId = params.serviceSessionId
	}

	static fromDomain(serviceTranslation: ServiceSessionTranslation): ServiceSessionTranslationModel {
		return new ServiceSessionTranslationModel({ ...serviceTranslation })
	}
	static fromRawJson(serviceTranslation: ServiceSessionTranslation): ServiceSessionTranslationModel {
		return new ServiceSessionTranslationModel({ ...serviceTranslation })
	}
}

declare module "./ServiceSessionTranslationModel" {
	interface ServiceSessionTranslationModel {
		toDomain(): ServiceSessionTranslation
	}
}

ServiceSessionTranslationModel.prototype.toDomain = function (): ServiceSessionTranslation {
	const data = this
	return new ServiceSessionTranslation({ ...data })
}
