import { UseCase } from "../../useCase"
import { UploadMicrositeImageResponse } from "./response"
import { UploadMicrositeImageRequest } from "./request"
import { MicrositeRepository } from "../../../repositories/micrositeRepository"

export class UploadMicrositeImageUseCase implements UseCase<UploadMicrositeImageRequest, UploadMicrositeImageResponse> {
	private _repository
	constructor({ MicrositeRepository }: { MicrositeRepository: MicrositeRepository }) {
		this._repository = MicrositeRepository
	}

	exec(uploadData: UploadMicrositeImageRequest) {
		return this._repository.uploadMicrositeImage(uploadData)
	}
}
