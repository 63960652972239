import { ErrorHandlerError } from "./ErrorHandlerError.interface"

export class ClusterError extends Error implements ErrorHandlerError {
	public errorMessage: string
	public errorCode?: string
	public httpStatus: number
	static CLUSTERS_FETCH_ERROR = "There was an error fetching the clusters"
	static CLUSTER_CREATE_ERROR = "There was an error creating the cluster"
	static CLUSTER_UPDATE_ERROR = "There was an error updating the cluster"
	static CLUSTER_DELETE_ERROR = "There was an error deleting the cluster"
	static CLUSTER_FETCH_ERROR = "There was an error fetching the cluster"
	static CLUSTER_DETAIL_ERROR = "There was an error fetching the cluster detail"
	static CLUSTER_ICONS_ERROR = "There was an error fetching the cluster icons"

	constructor(error: ErrorHandlerError) {
		super(error.errorMessage)
		this.errorMessage = error.errorMessage
		this.httpStatus = error.httpStatus
		this.errorCode = error.errorCode
		this.name = "ClusterError"
	}
}
