import { Col, Form, Row, Space, Tag } from "antd"
import { FormField } from "../../../components/Form/FormField"
import { useTranslation } from "react-i18next"
import { UsersViewModel } from "../../users/UsersViewModel"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { TipsViewModel } from "../TipsViewModel"
import { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import { DinamicSelectorWeightForm } from "../../sellersServices/components/DinamicSelectorWeightForm"
import { MicrositeCluster } from "../../../../domain/entities/Microsite"
import MultimediaUploader from "../../../components/MultimediaUploader/MultimediaUploader"
import config from "../../../../config/config"
import { colors } from "../../../assets/styles/appStyles"

const TipInformationFormSection = () => {
	const { t } = useTranslation("tips", { keyPrefix: "form.tipInfo" })
	const { t: tCountry } = useTranslation("countries")
	const { t: tForm } = useTranslation("mainForm", { keyPrefix: "validations" })
	const userViewModel = useContainerInjection<UsersViewModel>("UsersViewModel")
	const viewModel = useContainerInjection<TipsViewModel>("TipsViewModel")
	const [showMultimediaContentError, setShowMultimediaContentError] = useState(false)
	useEffect(() => {
		if (userViewModel.users.length === 0) {
			userViewModel.fetchUsers()
		}
	}, [])

	return (
		<>
			<Row gutter={[24, 0]}>
				<Col
					span={24}
					xl={14}
					md={24}
					xs={24}
					style={{ display: "flex", flexDirection: "column" }}
					className="pb-4"
				>
					<FormField.SelectSearch
						asyncFetchSearchFunction={async expression => await userViewModel.searchUsers(expression)}
						key={"user"}
						label={t("user")}
						name={"user"}
						mandatory
						placeholder={t("userPlaceholder")}
						loading={userViewModel.isLoading}
						disabled={!userViewModel.users.length && userViewModel.isLoading}
						value={
							viewModel.formData.user?.id
								? `${viewModel.formData.user?.id} - ${viewModel.formData.user?.name} (${viewModel.formData.user?.email})`
								: undefined
						}
						options={userViewModel.searchedUsers.map(user => ({
							value: user.id,
							label: `${user.id} - ${user.name} (${user.email})`,
							title: `${user.name}%${user.email}`
						}))}
						onChange={user => {
							//@ts-ignore
							const [name, email] = user.title.split("%")
							viewModel.setFormData({
								...viewModel.formData,
								// @ts-ignore
								user: { ...viewModel.formData.user, id: user.value, name, email }
							})
						}}
					/>
					<FormField.Input
						key={"description"}
						label={t("description")}
						name={"description"}
						inputType={InputType.TEXTAREA}
						mandatory
						onChange={(description: string) =>
							viewModel.setFormData({ ...viewModel.formData, description })
						}
					/>

					<DinamicSelectorWeightForm<MicrositeCluster>
						name={"countryTip"}
						key={"countryTip"}
						label={t("countriesSelectorLabel")}
						placeholder={t("countriesSelectorPlaceholder")}
						onAdd={item => {
							const newCountriesFiltered =
								viewModel.formData.countries?.filter(
									country => country.countryId !== String(item.id)
								) || []

							const finded = viewModel.formData.countries?.find(
								cTip => cTip.countryId === String(item.id)
							)
							if (finded) {
								delete finded.deleted
								newCountriesFiltered?.push({
									...finded,
									updated: true,
									countryId: String(item.id),
									position: Number(item.weight)
								})
								viewModel.setFormData({ ...viewModel.formData, countries: newCountriesFiltered })
								return
							}

							newCountriesFiltered?.push({
								countryId: String(item.id),
								position: Number(item.weight),
								new: true
							})
							viewModel.setFormData({ ...viewModel.formData, countries: newCountriesFiltered })
						}}
						data={viewModel.countries
							.filter(
								country =>
									!viewModel.formData.countries?.find(
										cTip => cTip.countryId === country.id && !cTip.deleted
									)
							)
							.map(country => ({
								id: country.id,
								label: tCountry(country.id),
								value: country.value
							}))}
						// loading={categoriesViewModel.isLoading}
						// disabled={categoriesViewModel.isLoading}
					/>
					<Space size={[0, 8]} wrap>
						{viewModel?.formData.countries
							?.filter(country => !country?.deleted)
							.map((countryTip, idx) => {
								return (
									<Tag
										key={countryTip.countryId}
										closable
										style={{
											userSelect: "none",
											height: 30,
											display: "flex",
											alignItems: "center",
											borderRadius: 10
										}}
										onClose={() => viewModel.deleteCountryTip(countryTip)}
									>
										{`${countryTip?.countryId} - ${tCountry(countryTip?.countryId)} (${
											countryTip.position
										})`}
									</Tag>
								)
							})}
					</Space>
				</Col>
				<Col xl={10} md={24} xs={24}>
					<Form.Item
						label={t("multimediaContent")}
						name={"mediaUrl"}
						rules={[
							{
								required: true,
								message: tForm("mandatoryField"),
								validator: async (_, value) => {
									setShowMultimediaContentError(!!!value)
									if (!value) {
										return Promise.reject(tForm("mandatoryField"))
									}
									return Promise.resolve()
								}
							}
						]}
					>
						<MultimediaUploader
							containerStyle={{
								width: "253px",
								height: "450px",
								overflow: "hidden",
								border: `${showMultimediaContentError ? `1px solid ${colors.error}` : ""}`
							}}
							mediaUrl={viewModel.formData.mediaUrl}
							mediaPreview={viewModel.formData.mediaPreview}
							type={viewModel.formData.mediaType === "video" ? "video" : "image"}
							onClean={() =>
								viewModel.setFormData({
									...viewModel.formData,
									mediaUrl: undefined,
									mediaPreview: undefined
								})
							}
							videoSizeLimit={config.ui.tips.limits.videoSizeMB}
							manualUpload
							label={t(
								`${viewModel.formData.mediaType == "video" ? "uploadVideoTip" : "uploadImageTip"}`
							)}
							onChange={mediaUrl => {
								viewModel.setFormData({ ...viewModel.formData, mediaUrl })
							}}
							loading={viewModel.uploadingMedia}
							disabled={viewModel.uploadingMedia}
						/>
					</Form.Item>
				</Col>
			</Row>
		</>
	)
}

export default observer(TipInformationFormSection)
