import { VoucherRepository } from "../../../repositories/voucherRepository"
import { UseCase } from "../../useCase"
import { GetVouchersRequest } from "./request"
import { GetVouchersResponse } from "./response"

export class GetVouchersUseCase implements UseCase<GetVouchersRequest, GetVouchersResponse> {
	private _repository
	constructor({ VoucherRepository }: { VoucherRepository: VoucherRepository }) {
		this._repository = VoucherRepository
	}

	exec({ pagination }: GetVouchersRequest) {
		return this._repository.getVouchers(pagination)
	}
}
