import type { SellerServicesRepository } from "../../../repositories/sellerServicesRepository"
import { UseCase } from "../../useCase"
import { CreateServiceContentResponse } from "./response"
import { CreateServiceContentRequest } from "./request"

export class CreateServiceContentUseCase implements UseCase<CreateServiceContentRequest, CreateServiceContentResponse> {
	private _repository
	constructor({ SellerServicesRepository }: { SellerServicesRepository: SellerServicesRepository }) {
		this._repository = SellerServicesRepository
	}

	exec(service: CreateServiceContentRequest) {
		return this._repository.createServiceContent(service)
	}
}
