import { HttpManager } from "../network/interface/HttpManager"
import { TipModel } from "../models/TipModel"
import { TipError } from "../../ui/error/TipError"
import { Endpoint } from "../network/endpoint"

export class TipService {
	private _http: HttpManager

	constructor({ HttpManager }: { HttpManager: HttpManager }) {
		this._http = HttpManager
	}

	public async getTips(
		pagination: number = 0,
		limit: number = 200,
		search?: string,
		filters?: Record<string, string[]>
	): Promise<TipModel[]> {
		try {
			let url = `${Endpoint.TIPS}?page=${pagination}&limit=${limit}`
			if (search) {
				url += `&searchText=${search}`
			}
			if (filters && Object.keys(filters).length) {
				url += `&filter=${JSON.stringify(filters)}`
			}

			const response = await this._http.requestNewApi({
				method: "GET",
				url
			})
			return response.data.map((tip: any) => TipModel.fromRawJson(tip))
		} catch (error: any) {
			throw new TipError({
				errorMessage: TipError.TIPS_FETCH_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async createTip(tip: TipModel): Promise<TipModel> {
		try {
			const data = {
				userId: tip.user.id,
				description: tip.description,
				mediaUrl: tip.mediaUrl,
				mediaType: tip.mediaType,
				mediaText: tip.mediaText,
				mediaPreview: tip.mediaPreview,
				published: tip.published
			}
			const response = await this._http.requestNewApi({
				method: "POST",
				url: Endpoint.TIP,
				data
			})

			await this.createCountryTip(response.data.tip.id, tip.countries)
			await this.createMicrositeTip(response.data.tip.id, tip.microsites)

			return TipModel.fromRawJson(response.data.tip)
		} catch (error: any) {
			throw new TipError({
				errorMessage: TipError.TIP_CREATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async updateTip(tip: TipModel): Promise<TipModel> {
		try {
			const data = {
				userId: tip.user.id,
				description: tip.description,
				mediaUrl: tip.mediaUrl,
				mediaType: tip.mediaType,
				mediaText: tip.mediaText,
				mediaPreview: tip.mediaPreview,
				published: tip.published
			}

			await this.updateCountryTip(tip.id, tip.countries)
			await this.updateMicrositeTip(tip.id, tip.microsites)
			await this.createCountryTip(tip.id, tip.countries)
			await this.createMicrositeTip(tip.id, tip.microsites)
			await this.deleteCountryTip(tip.id, tip.countries)

			const response = await this._http.requestNewApi({
				method: "PATCH",
				url: `${Endpoint.TIP}/${tip.id}`,
				data
			})
			return TipModel.fromRawJson(response.data)
		} catch (error: any) {
			throw new TipError({
				errorMessage: TipError.TIP_UPDATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	private async updateMicrositeTip(tipId: number, microsites: TipModel["microsites"]): Promise<void> {
		try {
			if (!microsites) return
			for (let i = 0; i < microsites.length; i++) {
				const element = microsites[i]
				if (!element?.updated || element?.new) continue
				const data = {
					position: element.position
				}
				await this._http.requestNewApi({
					method: "PATCH",
					url: `${Endpoint.TIP}/${tipId}/microsite/${element.micrositeId}`,
					data
				})
			}
		} catch (error: any) {
			throw new TipError({
				errorMessage: TipError.TIP_MICROSITE_UPDATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	private async createMicrositeTip(tipId: number, microsites: TipModel["microsites"]): Promise<void> {
		try {
			if (!microsites) return
			for (let i = 0; i < microsites.length; i++) {
				const element = microsites[i]
				if (!element?.new || element.deleted) continue
				const data = {
					micrositeId: element.micrositeId,
					position: element.position
				}
				await this._http.requestNewApi({
					method: "POST",
					url: `${Endpoint.TIP}/${tipId}/microsite`,
					data
				})
			}
		} catch (error: any) {
			throw new TipError({
				errorMessage: TipError.TIP_MICROSITE_CREATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	private async updateCountryTip(tipId: number, countries: TipModel["countries"]): Promise<void> {
		try {
			if (!countries) return
			for (let i = 0; i < countries.length; i++) {
				const element = countries[i]
				if (!element?.updated || element?.new || element?.deleted) continue
				const data = {
					position: element.position
				}
				await this._http.requestNewApi({
					method: "PATCH",
					url: `${Endpoint.TIP}/${tipId}/country/${element.countryId}`,
					data
				})
			}
		} catch (error: any) {
			throw new TipError({
				errorMessage: TipError.TIP_COUNTRY_UPDATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	private async createCountryTip(tipId: number, countries: TipModel["countries"]): Promise<void> {
		try {
			if (!countries) return
			for (let i = 0; i < countries.length; i++) {
				const element = countries[i]
				if (!element?.new || element.deleted) continue
				const data = {
					countryId: element.countryId,
					position: element.position
				}
				await this._http.requestNewApi({
					method: "POST",
					url: `${Endpoint.TIP}/${tipId}/country`,
					data
				})
			}
		} catch (error: any) {
			throw new TipError({
				errorMessage: TipError.TIP_COUNTRY_CREATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	private async deleteCountryTip(tipId: number, countries: TipModel["countries"]): Promise<void> {
		try {
			if (!countries) return
			for (let i = 0; i < countries.length; i++) {
				const element = countries[i]
				if (!element?.deleted || element?.new) continue
				const data = {
					position: element.position
				}
				await this._http.requestNewApi({
					method: "DELETE",
					url: `${Endpoint.TIP}/${tipId}/country/${element.countryId}`,
					data
				})
			}
		} catch (error: any) {
			throw new TipError({
				errorMessage: TipError.TIP_COUNTRY_DELETE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async deleteMicrositeTip(tipId: number, micrositeId: number): Promise<void> {
		try {
			await this._http.requestNewApi({
				method: "DELETE",
				url: `${Endpoint.TIP}/${tipId}/microsite/${micrositeId}`
			})
		} catch (error: any) {
			throw new TipError({
				errorMessage: TipError.TIP_MICROSITE_DELETE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async uploadTipMedia(
		tipId: number,
		file: File
	): Promise<{ code: string; message: string; mediaUrl: string; mediaPreview: string }> {
		try {
			const mediaType = file.type.split("/")[0]
			if (mediaType !== "image" && mediaType !== "video") {
				throw new TipError({
					errorMessage: TipError.TIP_UPLOAD_MEDIA_ERROR,
					errorCode: "Invalid file type",
					httpStatus: 400
				})
			}
			const formData = new FormData()
			formData.append("file", file)
			const response = await this._http.requestNewApi({
				method: "POST",
				headers: { "Content-Type": "multipart/form-data" },
				url: `${Endpoint.TIP}/${tipId}/${mediaType}`,
				data: formData
			})
			return response.data
		} catch (error: any) {
			throw new TipError({
				errorMessage: TipError.TIP_UPLOAD_MEDIA_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}
}
