import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Translation } from "../../stores/TranslationStore"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { observer } from "mobx-react"

const TranslationListener = () => {
	const translation = useContainerInjection<Translation>("Translation")
	const { i18n } = useTranslation()
	const language = translation.currentLang || Translation.defaultLanguage
	useEffect(() => {
		i18n.changeLanguage(language).then()
	}, [language])

	return null
}

export default observer(TranslationListener)
