import { ExclamationCircleFilled } from "@ant-design/icons"
import { LegacyButtonType } from "antd/lib/button/button"
import useModal from "antd/lib/modal/useModal"

interface Props {
	title: string
	onConfirm: () => void
	onCancel?: () => void
	description?: string
	icon?: React.ReactNode
	type?: LegacyButtonType
	confirmButtonText?: string
	cancelButtonText?: string
}

export const useConfirmModal = () => {
	const [modalApi, context] = useModal()

	const showConfirm = ({
		onConfirm,
		onCancel,
		title,
		description,
		icon,
		type,
		confirmButtonText,
		cancelButtonText
	}: Props) => {
		modalApi.confirm({
			title,
			centered: true,
			icon: icon || <ExclamationCircleFilled />,
			content: description || "",
			okText: confirmButtonText || "Yes",
			okType: type || "primary",
			cancelText: cancelButtonText || "No",
			onOk() {
				onConfirm && onConfirm()
			},
			onCancel() {
				onCancel && onCancel()
			}
		})
	}

	return { showConfirm, context }
}
