import { observer } from "mobx-react"
import DataTable from "../../../components/DataTable/DataTable"
import TABLE_COLUMNS from "../const/TABLE_COLUMNS"
import { EditFilled } from "@ant-design/icons"
import { Tooltip } from "antd"
import { Link } from "react-router-dom"
import { RoutePaths } from "../../../navigation/RoutePaths.enum"
import { toJS } from "mobx"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { UsersViewModel } from "../UsersViewModel"
import { useTranslation } from "react-i18next"
import { User } from "../../../../domain/entities/User"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"

interface UsersTableProps {
	data: Partial<User>[]
	loading?: boolean
	isFetching?: boolean
	onChangePage?: (page: number, pageSize: number, lastPageFetched: number) => void
}

const UsersTable = ({ data, loading, isFetching, onChangePage }: UsersTableProps) => {
	const viewModel = useContainerInjection<UsersViewModel>("UsersViewModel")
	const { t } = useTranslation("toolTips")

	const handlePageChange = (page: number, pageSize: number, lastPageFetched: number) => {
		viewModel.setTableConfig({ pageSize })
		onChangePage && onChangePage(page, pageSize, lastPageFetched)
	}

	return (
		<DataTable
			loading={loading}
			tableConfig={{
				sort: viewModel.tableConfig.sort,
				pageSize: viewModel.tableConfig.pageSize
			}}
			renderCustomLoadingComponent={() => <DataTableRowsSkeleton size="lg" />}
			onChange={data => {
				data.sorter &&
					viewModel.setTableConfig({ sort: { order: data.sorter.order, field: data.sorter.field } })
			}}
			isFetching={isFetching}
			onChangePage={handlePageChange}
			columns={TABLE_COLUMNS()}
			// columnFilters={viewModel.extractParentCategories().map(category => {
			//   const label: any = category.label;
			//   return {
			//     id: category.id,
			//     text: label,
			//     value: label,
			//   }
			// })}
			dataSource={data.map((user, idx) => ({
				key: user.id,
				id: user.id,
				name: user.name,
				userName: user.userName,
				email: user.email,
				status: user.status,
				city: user.city,
				editButton: (
					<div className="ant-employed">
						<Link to={RoutePaths.USERS_ADD_NEW} state={{ user: toJS(user) }}>
							<Tooltip title={t("edit")}>
								<EditFilled />
							</Tooltip>
						</Link>
					</div>
				)
			}))}
		/>
	)
}

export default observer(UsersTable)
