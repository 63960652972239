import type { SellerServicesRepository } from "../../../repositories/sellerServicesRepository"
import { UseCase } from "../../useCase"
import { DeleteSellerServiceResponse } from "./response"
import { DeleteSellerServiceRequest } from "./request"

export class DeleteSellerServiceUseCase implements UseCase<DeleteSellerServiceRequest, DeleteSellerServiceResponse> {
	private _repository
	constructor({ SellerServicesRepository }: { SellerServicesRepository: SellerServicesRepository }) {
		this._repository = SellerServicesRepository
	}

	exec(service: Partial<DeleteSellerServiceRequest>) {
		return this._repository.deleteSellerService(service)
	}
}
