import { Banner } from "../../domain/entities/Banner"
import { BannersRepository } from "../../domain/repositories/bannersRepository"
import { BannerService } from "../services/BannerService"

export class BannersRepositoryImpl implements BannersRepository {
	private _bannerService
	constructor({ BannerService }: { BannerService: BannerService }) {
		this._bannerService = BannerService
	}
	async createBanner(banner: Banner, desktopImage: File, mobileImage: File): Promise<number> {
		const createdId = await this._bannerService.createBanner({
			...banner,
			desktopImage,
			mobileImage
		})
		return createdId
	}
	async updateBanner(banner: Banner, desktopImage: File, mobileImage: File): Promise<void> {
		if (!banner.id) throw Error("Must provide ID to update banner")
		await this._bannerService.updateBanner(banner.id, {
			...banner,
			desktopImage,
			mobileImage
		})
	}
	async getBanners(region: string): Promise<Banner[]> {
		const banners = await this._bannerService.getBanners(region)
		return banners.map(b => b.toDomain())
	}
	async deleteBanner(bannerId: number): Promise<void> {
		return this._bannerService.deleteBanner(bannerId)
	}
}
