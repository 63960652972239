import { ReportEmbedInfo } from "../../domain/entities/ReportEmbedInfo"
import { ReportRepository } from "../../domain/repositories/reportRepository"
import { ReportService } from "../services/ReportService"

export class ReportRepositoryImpl implements ReportRepository {
	private _reportService: ReportService
	constructor({ ReportService }: { ReportService: ReportService }) {
		this._reportService = ReportService
	}
	getAdminEmbeddedReport(): Promise<ReportEmbedInfo> {
		return this._reportService.getAdminEmbeddedReport()
	}
}
