interface ReportDetailsConstructorParams {
	reportId: string
	reportName: string
	embedUrl: string
}
export class ReportDetailsModel {
	reportId: string
	reportName: string
	embedUrl: string

	constructor({ reportId, reportName, embedUrl }: ReportDetailsConstructorParams) {
		this.reportId = reportId
		this.reportName = reportName
		this.embedUrl = embedUrl
	}
}
interface ReportEmbedInfoConstructorParams {
	accessToken: string
	expiry: number
	embedUrl: ReportDetailsModel[]
}
export class ReportEmbedInfoModel {
	accessToken: string
	expiry: number
	embedUrl: ReportDetailsModel[]

	constructor({ accessToken, expiry, embedUrl }: ReportEmbedInfoConstructorParams) {
		this.accessToken = accessToken
		this.expiry = expiry
		this.embedUrl = embedUrl
	}
	static fromRawJson = (rawJson: any): ReportEmbedInfoModel => {
		return new ReportEmbedInfoModel({
			...rawJson,
			embedUrl: rawJson.embedUrl?.map((detail: any) => new ReportDetailsModel(detail))
		})
	}
}
