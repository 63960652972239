import type { SellerRepository } from "../../../repositories/sellerRepository"
import { UseCase } from "../../useCase"
import { AutoCompleteSellersRequest } from "./request"
import { AutoCompleteSellersResponse } from "./response"

export class GetAutoCompleteSellersUseCase implements UseCase<AutoCompleteSellersRequest, AutoCompleteSellersResponse> {
	private _repository
	constructor({ SellerRepository }: { SellerRepository: SellerRepository }) {
		this._repository = SellerRepository
	}

	public exec({ expression }: AutoCompleteSellersRequest) {
		return this._repository.getUsersByAutoComplete(expression)
	}
}
