import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"

export default () => {
	const { t } = useTranslation("services", { keyPrefix: "form.dataTableHeaders.sessions" })

	return columns(t)
}

const columns = (t: TFunction) => [
	{
		key: "title",
		title: t("title"),
		dataIndex: "title",
		width: "32%"
	},
	{
		key: "position",
		title: t("position"),
		dataIndex: "position"
	},
	{
		title: t("priceEUR"),
		dataIndex: "priceEUR",
		key: "priceEUR"
	},
	{
		title: t("priceMXN"),
		dataIndex: "priceMXN",
		key: "priceMXN"
	},
	{
		title: "",
		key: "actions",
		dataIndex: "actions"
	}
]
