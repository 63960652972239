/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import App from "./ui/App"
import "./ui/assets/fonts/AstoriaMedium.ttf"
import "./ui/assets/fonts/AstoriaLight.ttf"
import { ConfigProvider } from "antd"
import { DIProvider } from "./ui/context/DIProvider"
import { container } from "./config/di"

ReactDOM.render(
	<BrowserRouter>
		<ConfigProvider componentSize="large" theme={{ token: { colorPrimary: "#4BACD7", fontSizeLG: 14 } }}>
			<DIProvider container={container}>
				<App />
			</DIProvider>
		</ConfigProvider>
	</BrowserRouter>,
	document.getElementById("root")
)
