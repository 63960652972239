import { UserRepository } from "../../../repositories/userRepository"
import { UseCase } from "../../useCase"
import { GetUserProfileResponse } from "./response"
import { GetUserProfileRequest } from "./request"

export class GetUserDetailUseCase implements UseCase<GetUserProfileRequest, GetUserProfileResponse> {
	private _repository
	constructor({ UserRepository }: { UserRepository: UserRepository }) {
		this._repository = UserRepository
	}

	exec({ userId }: GetUserProfileRequest) {
		return this._repository.getUserDetail(userId)
	}
}
