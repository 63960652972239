import { ClientFileUploadRepository } from "../../domain/repositories/clientFileUploadRepository"
import { ClientFileUploadService } from "../services/ClientFileUploadService"

export class ClientFileUploadRepositoryImpl implements ClientFileUploadRepository {
	private _clientFileUploadService: ClientFileUploadService
	constructor({ ClientFileUploadService }: { ClientFileUploadService: ClientFileUploadService }) {
		this._clientFileUploadService = ClientFileUploadService
	}

	public async uploadUserProfilePicture(url: string, file: any): Promise<unknown> {
		const response = await this._clientFileUploadService.uploadUserProfilePicture(url, file)
		return response
	}
}
