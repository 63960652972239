import { UseCase } from "../../useCase"
import { GetBannersResponse } from "./response"
import { GetBannersRequest } from "./request"
import { BannersRepository } from "../../../repositories/bannersRepository"

export class GetBannersUseCase implements UseCase<GetBannersRequest, GetBannersResponse> {
	private _repository
	constructor({ BannersRepository }: { BannersRepository: BannersRepository }) {
		this._repository = BannersRepository
	}

	exec(region: string) {
		return this._repository.getBanners(region)
	}
}
