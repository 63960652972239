import { BannerError } from "../../ui/error/BannerError"
import { ClusterError } from "../../ui/error/ClusterError"
import { BannerCreationParams, BannerModel } from "../models/BannerModel"
import { Endpoint } from "../network/endpoint"
import { HttpManager } from "../network/interface/HttpManager"

export class BannerService {
	private _http: HttpManager

	constructor({ HttpManager }: { HttpManager: HttpManager }) {
		this._http = HttpManager
	}

	public async getBanners(region: string): Promise<BannerModel[]> {
		try {
			const response = await this._http.requestNewApi({
				method: "GET",
				url: Endpoint.GET_ALL_BANNERS.replace(":key", region.toUpperCase())
			})
			return response.data.map(BannerModel.fromRawJson)
		} catch (error: any) {
			throw new BannerError({
				errorMessage: BannerError.BANNERS_FETCH_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async createBanner(params: BannerCreationParams): Promise<number> {
		try {
			const formData = new FormData()
			formData.append("key", params.key)
			formData.append("type", params.type)
			params.href && formData.append("href", params.href)
			params.subType && formData.append("subType", params.subType)
			formData.append("weight", params.weight.toString())
			formData.append("language", params.language)
			formData.append("files", params.desktopImage)
			formData.append("files", params.mobileImage)
			const response = await this._http.requestNewApi({
				method: "POST",
				url: Endpoint.CREATE_BANNER,
				data: formData,
				headers: { "Content-Type": "multipart/form-data" }
			})
			return response.data.id
		} catch (error: any) {
			throw new BannerError({
				errorMessage: BannerError.BANNERS_CREATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async updateBanner(bannerId: number, params: BannerCreationParams): Promise<number> {
		try {
			const formData = new FormData()
			formData.append("key", params.key)
			formData.append("type", params.type)
			params.href && formData.append("href", params.href)
			params.subType && formData.append("subType", params.subType)
			formData.append("weight", params.weight.toString())
			formData.append("language", params.language)
			formData.append("files", params.desktopImage)
			formData.append("files", params.mobileImage)
			const response = await this._http.requestNewApi({
				method: "PATCH",
				url: Endpoint.UPDATE_BANNER.replace(":bannerId", bannerId.toString()),
				data: formData,
				headers: { "Content-Type": "multipart/form-data" }
			})
			return response.data.id
		} catch (error: any) {
			throw new BannerError({
				errorMessage: BannerError.BANNERS_UPDATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async deleteBanner(bannerId: number): Promise<void> {
		try {
			await this._http.requestNewApi({
				method: "DELETE",
				url: Endpoint.DELETE_BANNER.replace(":bannerId", bannerId.toString())
			})
		} catch (error: any) {
			throw new BannerError({
				errorMessage: BannerError.BANNERS_UPDATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}
}
