import { Link } from "react-router-dom"
import TABLE_COLUMNS from "../const/SERVICES_TABLE_COLUMNS"
import { Tooltip, Typography } from "antd"
import { EditFilled } from "@ant-design/icons"
import { observer } from "mobx-react"
import { RoutePaths } from "../../../navigation/RoutePaths.enum"
import { toJS } from "mobx"
import { SellerService } from "../../../../domain/entities/SellerService"
import DataTable from "../../../components/DataTable/DataTable"
import { SellerServicesViewModel } from "../SellerServicesViewModel"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"

interface SellerTableProps {
	data: Partial<SellerService>[]
	loading?: boolean
	isFetching?: boolean
	onChangePage?: (page: number, pageSize: number, lastPageFetched: number) => void
}

const SellerServicesTable = ({ data, loading, isFetching, onChangePage }: SellerTableProps) => {
	const viewModel = useContainerInjection<SellerServicesViewModel>("SellerServicesViewModel")
	return (
		<div className="table-responsive">
			<DataTable
				onChangePage={(page, pageSize, lastPageFetched) => {
					viewModel.setTableConfig({ pageSize })
					onChangePage && onChangePage(page, pageSize, lastPageFetched)
				}}
				renderCustomLoadingComponent={() => <DataTableRowsSkeleton size="lg" />}
				loading={loading}
				tableConfig={{
					sort: viewModel.tableConfig.sort,
					pageSize: viewModel.tableConfig.pageSize
				}}
				onChange={data => {
					data.sorter &&
						viewModel.setTableConfig({ sort: { order: data.sorter.order, field: data.sorter.field } })
				}}
				isFetching={isFetching}
				columns={TABLE_COLUMNS()}
				dataSource={data.map((service, idx) => ({
					key: service.id,
					id: service.id,
					weight: service.weight,
					user: service.email,
					service: (
						<>
							<div className="avatar-info">
								<Typography.Text>{service.title}</Typography.Text>
							</div>
						</>
					),
					contact: service.contact,
					email: service.email,
					city: service.city,
					editButton: (
						<div className="ant-employed">
							<Link to={RoutePaths.SELLERS_SERVICES_ADD_NEW} state={{ service: toJS(service) }}>
								<Tooltip title={"Edit"}>
									<EditFilled />
								</Tooltip>
							</Link>
						</div>
					)
				}))}
			/>
		</div>
	)
}

export default observer(SellerServicesTable)
