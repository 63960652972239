import { Voucher } from "../../domain/entities/Voucher"
import { VoucherRepository } from "../../domain/repositories/voucherRepository"
import { VoucherModel } from "../models/VoucherModel"
import { VoucherService } from "../services/VoucherService"
import { UploadVoucherPictureResponse } from "../../domain/useCases/vouchers/uploadVoucherPicture/response"

export class VoucherRepositoryImpl implements VoucherRepository {
	private _voucherService
	constructor({ VoucherService }: { VoucherService: VoucherService }) {
		this._voucherService = VoucherService
	}

	public async getVouchers(offset: number): Promise<Voucher[]> {
		const response = await this._voucherService.getVouchers(offset)
		return response.map(vouchers => vouchers.toDomain())
	}

	public async getVoucher(id: string): Promise<Voucher> {
		const response = await this._voucherService.getVoucher(id)
		return response.toDomain()
	}

	public async createVoucher(sellerService: Voucher): Promise<Partial<Voucher>> {
		const response = await this._voucherService.createVoucher(VoucherModel.fromDomain(sellerService))
		return response.toDomain()
	}

	public async updateVoucher(sellerService: Voucher): Promise<Partial<Voucher>> {
		const response = await this._voucherService.updateVoucher(VoucherModel.fromDomain(sellerService))
		return response.toDomain()
	}

	public async deleteVoucher(id: string): Promise<Partial<Voucher>> {
		const response = await this._voucherService.deleteVoucher(id)
		return response.toDomain()
	}

	public async uploadVoucherImage(uploadData: any): Promise<UploadVoucherPictureResponse> {
		const response = await this._voucherService.uploadVoucherImage(uploadData)
		return { file_path: response.file_path }
	}
}
