import { UpdateUserResponse } from "./response"
import { UpdateUserRequest } from "./request"
import { UseCase } from "../../../useCase"
import { UserRepository } from "../../../../repositories/userRepository"

export class UpdateUserUseCase implements UseCase<UpdateUserRequest, UpdateUserResponse> {
	private _repository
	constructor({ UserRepository }: { UserRepository: UserRepository }) {
		this._repository = UserRepository
	}

	exec(user: UpdateUserRequest) {
		return this._repository.updateUser(user)
	}
}
