import { SellersModel } from "./../models/SellersModel"
import { SellersService } from "../services/SellersService"
import { SellerRepository } from "../../domain/repositories/sellerRepository"
import { Seller } from "../../domain/entities/Seller"
import { User } from "../../domain/entities/User"
import { SellersNewModel } from "../models/SellersNewModel"

export class SellersRepositoryImpl implements SellerRepository {
	private _sellersService
	constructor({ SellersService }: { SellersService: SellersService }) {
		this._sellersService = SellersService
	}

	public async getSeller(id: string): Promise<Seller> {
		const sellerModel = await this._sellersService.getSeller(id)
		return sellerModel.toDomain()
	}

	public async getSellers(): Promise<Seller[]> {
		const sellers = await this._sellersService.getSellers()
		return sellers.map(seller => seller.toDomain())
	}

	public async createSeller(seller: Seller): Promise<Partial<Seller>> {
		const data = await this._sellersService.createSeller(SellersNewModel.fromDomain(seller))
		return data.toDomain()
	}

	public async updateSeller(seller: Seller): Promise<Partial<Seller>> {
		const data = await this._sellersService.updateSeller(SellersNewModel.fromDomain(seller))
		return data.toDomain()
	}

	public async getUsersByAutoComplete(searchText: string): Promise<Partial<User[]>> {
		const users = await this._sellersService.getUsersByAutoComplete(searchText)
		return users.map(users => users.toDomain())
	}
}
