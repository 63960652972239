import { ErrorHandlerError } from "./ErrorHandlerError.interface"

export class VoucherError extends Error implements ErrorHandlerError {
	public errorMessage: string
	public errorCode?: string
	public httpStatus: number
	static VOUCHERS_FETCH_ERROR = "There was an error fetching the vouchers"
	static VOUCHER_DETAIL_FETCH_ERROR = "There was an error fetching the voucher detail"
	static VOUCHER_CREATE_ERROR = "There was an error creating the voucher"
	static VOUCHER_UPDATE_ERROR = "There was an error updating the voucher"
	static VOUCHER_DELETE_ERROR = "There was an error disabling the voucher"

	constructor(error: ErrorHandlerError) {
		super(error.errorMessage)
		this.errorMessage = error.errorMessage
		this.httpStatus = error.httpStatus
		this.errorCode = error.errorCode
		this.name = "VoucherError"
	}
}
