import type { SellerServicesRepository } from "../../../repositories/sellerServicesRepository"
import { UseCase } from "../../useCase"
import { GetSellerServiceExcelRequest } from "./request"
import { GetSellerServiceExcelResponse } from "./response"

export class GetSellerServiceExcelUseCase
	implements UseCase<GetSellerServiceExcelRequest, GetSellerServiceExcelResponse>
{
	private _repository
	constructor({ SellerServicesRepository }: { SellerServicesRepository: SellerServicesRepository }) {
		this._repository = SellerServicesRepository
	}

	exec() {
		return this._repository.getSellerServiceExcel()
	}
}
