import { Country } from "../../domain/entities/Country"
import { CountryService } from "../services/CountryService"
import { CountryRepository } from "./../../domain/repositories/countryRepository"

export class CountryRepositoryImpl implements CountryRepository {
	private _countryService: CountryService
	constructor({ CountryService }: { CountryService: CountryService }) {
		this._countryService = CountryService
	}

	public async getCountries(): Promise<Country[]> {
		const countries = await this._countryService.getCountries()
		return countries.map(country => country.toDomain())
	}
}
