import { HttpManager } from "../network/interface/HttpManager"

export class ClientFileUploadService {
	private _http: HttpManager

	constructor({ HttpManager }: { HttpManager: HttpManager }) {
		this._http = HttpManager
	}

	public async uploadUserProfilePicture(url: string, file: any) {
		const response = await this._http.rawRequest({
			method: "PUT",
			url,
			data: file,
			headers: {
				"Content-Type": file.type
			}
		})
		return response
	}
}
