import { Col, Row, Select } from "antd"
import DataTable from "../../../components/DataTable/DataTable"
import { observer } from "mobx-react"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { MicrositesViewModel } from "../MicrositesViewModel"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"
import TABLE_COLUMNS from "../const/ASSOCIATED_SERVICES_TABLE_COLUMNS"
import { MicrositeService } from "../../../../domain/entities/Microsite"
import { useTranslation } from "react-i18next"
import { DeleteFilled } from "@ant-design/icons"
import { colors } from "../../../assets/styles/appStyles"
import { useConfirmModal } from "../../../service/confirmModal"

interface Props {
	onChange?: (id: number, value: MicrositeService) => void
}

const MicrositeServicesFormSection = ({ onChange }: Props) => {
	const viewModel = useContainerInjection<MicrositesViewModel>("MicrositesViewModel")
	const { t: tCommon } = useTranslation("common")
	const { showConfirm, context: modalContext } = useConfirmModal()
	const { t: tToast } = useTranslation("toast")

	const handleSave = (record: any) => {
		delete record.highlighted
		delete record.actions
		onChange && onChange(record.id, record)
	}

	const handleDeleteService = (service: MicrositeService) => {
		showConfirm({
			title: tToast("deleteConfirmMessage", { value: "service" }),
			onConfirm: async () => {
				viewModel.deleteMicrositeService(service)
			},
			type: "danger"
		})
	}

	return (
		<Row gutter={[24, 0]}>
			{modalContext}
			<Col md={24} xl={24} xs={24}>
				<DataTable
					loading={viewModel.isLoadingDetail}
					renderCustomLoadingComponent={() => <DataTableRowsSkeleton />}
					editableCells={{ handleSave }}
					columns={TABLE_COLUMNS()}
					dataSource={
						viewModel.formData.services?.map((service, idx) => ({
							key: service.id,
							title: service.title,
							id: service.id,
							weight: service.weight,
							seller: service.seller.name,
							highlighted: (
								<Select
									className="w-16"
									value={service.highlighted}
									options={[
										{ value: "1", label: tCommon("yes") },
										{ value: "0", label: tCommon("no") }
									]}
									onChange={(value: string) => {
										onChange && onChange(Number(service.id), { ...service, highlighted: value })
									}}
								/>
							),
							actions: !service.cluster?.length && (
								<div key={service.id}>
									<DeleteFilled
										style={{ fontSize: "1.2rem", color: colors.error }}
										onClick={() => handleDeleteService(service)}
									/>
								</div>
							)
						})) || []
					}
				/>
			</Col>
		</Row>
	)
}

export default observer(MicrositeServicesFormSection)
