import { CategoryRepository } from "./../../../repositories/categoryRepository"
import { UseCase } from "../../useCase"
import { GetEnglishFieldsResponse } from "./response"
import { GetEnglishFieldsRequest } from "./request"
import { Language } from "../../../../locale/interfaces/Translation"

export class GetEnglishFieldsUseCase implements UseCase<GetEnglishFieldsRequest, GetEnglishFieldsResponse> {
	private _repository
	constructor({ CategoryRepository }: { CategoryRepository: CategoryRepository }) {
		this._repository = CategoryRepository
	}

	exec() {
		return this._repository.getCategories(Language.enUS)
	}
}
