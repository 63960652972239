import type { UserRepository } from "../../../repositories/userRepository"
import { UseCase } from "../../useCase"
import { SearchUsersResponse } from "./response"
import { SearchUsersRequest } from "./request"

export class SearchUsersUseCase implements UseCase<SearchUsersRequest, SearchUsersResponse> {
	private _repository
	constructor({ UserRepository }: { UserRepository: UserRepository }) {
		this._repository = UserRepository
	}

	exec({ expression, offset, limit }: SearchUsersRequest) {
		return this._repository.searchUsers(expression, offset, limit)
	}
}
