import { UseCase } from "../../useCase"
import { VoucherRepository } from "../../../repositories/voucherRepository"
import { UploadVoucherPictureResponse } from "./response"
import { UploadVoucherPictureRequest } from "./request"

export class UploadVoucherPictureUseCase implements UseCase<UploadVoucherPictureRequest, UploadVoucherPictureResponse> {
	private _repository
	constructor({ VoucherRepository }: { VoucherRepository: VoucherRepository }) {
		this._repository = VoucherRepository
	}

	exec(uploadData: UploadVoucherPictureRequest) {
		return this._repository.uploadVoucherImage(uploadData)
	}
}
