import { observer } from "mobx-react"
import { createRef, useEffect, useMemo } from "react"
import useUpdateEffect from "../../hooks/useUpdateEffect"
import { toJS } from "mobx"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { UsersViewModel } from "./UsersViewModel"
import { User } from "../../../domain/entities/User"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import MainForm, { MainFormRef } from "../../components/Form/MainForm"
import { Col, Row } from "antd"
import { Card } from "../../styledComponents/CustomCard/Card"
import UserInformationFormSection from "./components/UserInformationFormSection"
import UserDetailFormSkeleton from "../../styledComponents/LoadingSkeletons/MicrositeDetailFormSkeleton copy"
import { Language } from "../../../locale/interfaces/Translation"

const UsersFormView = () => {
	const { t } = useTranslation("users", { keyPrefix: "form" })
	const locationState: { user: User } = useLocation().state
	const formRef = createRef<MainFormRef>()
	const viewModel = useContainerInjection<UsersViewModel>("UsersViewModel")
	const navigate = useNavigate()

	useEffect(() => {
		viewModel.setEditMode(locationState?.user?.id !== undefined)
		viewModel.setInitialFormData(locationState?.user)
		viewModel.setFormData(locationState?.user)
		viewModel.setOriginalFormData(locationState?.user)
		// locationState?.user?.id && viewModel.fetchUserDetail(locationState?.user?.id)
	}, [locationState?.user])

	useUpdateEffect(() => {
		const selectedUser = toJS(viewModel.selectedUser)
		viewModel.setFormData({ ...viewModel.formData, ...selectedUser })
		viewModel.setOriginalFormData({ ...viewModel.formData, ...selectedUser })
		viewModel.setEditMode(selectedUser.id !== undefined)
	}, [viewModel.selectedUser])
	const formData = toJS(viewModel.formData)
	const originalFormData = useMemo(() => toJS(viewModel.originalFormData), [viewModel.originalFormData])
	const formModified = JSON.stringify(formData) !== JSON.stringify(originalFormData)

	const onSubmitForm = async () => {
		await formRef.current?.customValidateFields(() => {
			if (!formData.role) {
				throw { message: t("validations.role") }
			}
			if (!formData.status) {
				throw { message: t("validations.status") }
			}
			if (!formData.birthdate) {
				throw { message: t("validations.birthdate") }
			}
		})
		await viewModel.sendFormData()
		navigate(".", {
			replace: true,
			state: {
				...locationState,
				user: {
					...toJS(viewModel.formData)
				}
			}
		})
	}

	return (
		<div>
			<MainForm
				onSubmitForm={onSubmitForm}
				initialValues={{
					...formData,
					birthdate: formData.birthdate ? User.sanitizeDate(formData.birthdate) : formData.birthdate
				}}
				formModified={formModified}
				ref={formRef}
			>
				<Row gutter={[24, 0]}>
					<Col xl={24} md={24} xs={24}>
						<Card
							bordered={false}
							className="criclebox tablespace mb-24"
							title={
								<div style={{ display: "flex", justifyContent: "space-between" }}>
									<h6 className="font-semibold m-0">{t("userInfo.cardTitle")}</h6>
								</div>
							}
							bodyStyle={{ padding: "24px" }}
						>
							<UserInformationFormSection
								userLanguage={formData?.language as Language}
								picture={formData?.picture}
								role={formData?.role}
								status={formData?.status}
								inputDisabled={!!formData?.id}
								sealKey={formData?.endorserTypeKey}
								onChange={value => {
									viewModel.setFormData({ ...formData, ...value })
								}}
							/>
						</Card>
					</Col>
				</Row>
			</MainForm>
		</div>
	)
}

export default observer(UsersFormView)
