import { SearchOutlined } from "@ant-design/icons"
import { DividerProps, Input } from "antd"
import Title from "antd/lib/typography/Title"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { colors } from "../../assets/styles/appStyles"
import { debounce } from "../../utils/debounce"

interface SearchFilterFieldProps<T> extends DividerProps {
	value?: string
	placeholder?: string
	label?: string
	onChange?: (value: Partial<T>[]) => void
	onChangeText?: (value: string) => void
	filterFunction: (value: Partial<T>, searchInput: string | undefined) => boolean
	searchData?: Partial<T>[]
	inputDelay?: number
}

const SearchFilterField = <T extends {}>({
	value = "",
	searchData,
	label,
	placeholder,
	inputDelay,
	filterFunction,
	onChange,
	onChangeText,
	...props
}: SearchFilterFieldProps<T>) => {
	const location = useLocation()
	useEffect(() => {
		setSearchValue("")
		onChangeText && onChangeText("")
		filterData("")
	}, [location.pathname])

	const [searchValue, setSearchValue] = useState<string | undefined>(value)
	const filterData = (searchInput: string | undefined) => {
		if (searchData && onChange) {
			onChange(searchData.filter(value => filterFunction(value, searchInput)) || "")
		}
	}

	const handleInputChange = (event: { target: { value: string | undefined } }) => {
		setSearchValue(event.target.value)
		if (inputDelay && event.target.value !== "") {
			debounce(() => {
				onChangeText && onChangeText(event.target.value ? event.target.value : "")
				filterData(event.target.value)
			}, inputDelay)
		} else {
			onChangeText && onChangeText(event.target.value ? event.target.value : "")
			filterData(event.target.value)
		}
	}

	return (
		<div {...props}>
			{label && <Title level={5}>{label}:</Title>}
			<Input
				value={searchValue}
				bordered={false}
				inputMode="search"
				allowClear
				onChange={handleInputChange}
				style={{
					width: "50%",
					backgroundColor: colors.overlapBackground,
					boxShadow: "-3px 5px 10px 1px rgba(0,0,0,0.1)"
				}}
				placeholder={placeholder}
				prefix={<SearchOutlined />}
			/>
		</div>
	)
}

export default SearchFilterField
