export interface BannerParams {
	id?: number
	key: string
	weight: number
	href?: string
	image?: string
	type: "mainBanner" | "secondaryBanner"
	subType?: string
	language: string
}

export class Banner {
	id?: number
	key: string
	weight: number
	href?: string
	image?: string
	type: "mainBanner" | "secondaryBanner"
	subType?: string
	language: string

	constructor(params: BannerParams) {
		this.id = params.id
		this.key = params.key
		this.weight = params.weight
		this.href = params.href
		this.image = params.image
		this.type = params.type
		this.subType = params.subType
		this.language = params.language
	}

	setId(id: number) {
		this.id = id
	}

	setWeight(weight: number) {
		this.weight = weight
	}

	setHref(href: string) {
		this.href = href
	}

	setImage(image: string) {
		this.image = image
	}

	getImageName() {
		if (!this.image) return null
		const urlParts: string[] = this.image.split("/")
		return urlParts[urlParts.length - 1]
	}

	static GetMobileImage(image: string) {
		const urlParts: string[] = image.split("/")
		const url = urlParts.slice(0, urlParts.length - 1).join("/")
		const fileParts = urlParts[urlParts.length - 1].split(".")
		const fileName = fileParts.slice(0, fileParts.length - 1).join(".")
		return `${url}/${fileName}_mobile.${fileParts[fileParts.length - 1]}`
	}
}
