import { Typography } from "antd"
import { colors } from "../../assets/styles/appStyles"

interface DividerProps {
	style?: React.CSSProperties
	disabled?: boolean
	title?: string
}

const Divider = ({ title, style, disabled }: DividerProps) => {
	return (
		<div style={{ margin: "20px 0px", ...style }}>
			{title && (
				<div
					style={{
						position: "relative",
						top: 11,
						left: 14,
						maxWidth: title.length >= 7 ? "5.5rem" : "4.5rem",
						minWidth: "2vw",
						display: "flex",
						zIndex: 1,
						backgroundColor: colors.shadowBackground,
						padding: "0px 10px",
						textAlign: "center"
					}}
				>
					<Typography.Text
						style={{ fontWeight: "500", fontSize: 15, color: disabled ? colors.disabled : "black" }}
					>
						{title}
					</Typography.Text>
				</div>
			)}
			<div>
				<div style={{ backgroundColor: "#D9D9D9", width: "100%", height: 0.5, borderRadius: 100 }} />
				<div
					style={{
						backgroundColor: "#FFFF",
						width: "100%",
						height: 2,
						position: "relative",
						top: 0.5,
						borderRadius: 100
					}}
				/>
			</div>
		</div>
	)
}

export default Divider
