import { Empty, Form, FormItemProps, Select, SelectProps, Spin, Typography } from "antd"
import { SelectValue } from "antd/lib/select"
import { colors } from "../../assets/styles/appStyles"
import { useState } from "react"
import { debounce } from "../../utils/debounce"
import config from "../../../config/config"
import { useTranslation } from "react-i18next"
import { calcFormFieldValidationRule } from "../../utils/calFormFieldValidationRule"

export interface SelectSearchFieldProps extends FormItemProps {
	placeholder?: string
	label?: string
	mandatory?: boolean | { validator?: boolean }
	name: string
	asyncFetchSearchFunction?: (value: string) => Promise<any>
	errorMessage?: string
	validationMessage?: string
	containerStyles?: React.CSSProperties
	loading?: SelectProps["loading"]
	value?: SelectProps["value"]
	disabled?: SelectProps["disabled"]
	options?: SelectProps["options"]
	allowClear?: SelectProps["allowClear"]
	mode?: SelectProps["mode"]
	onChange?: (value: SelectValue | string[], option: any) => void
	maxTagCount?: number
}

const SelectSearchField = ({
	placeholder,
	mandatory,
	asyncFetchSearchFunction,
	containerStyles,
	validationMessage,
	errorMessage = "",
	loading,
	disabled,
	onChange,
	options,
	mode,
	maxTagCount,
	allowClear = false,
	...props
}: SelectSearchFieldProps) => {
	const [fetching, setFetching] = useState(false)
	const { t: tCommon } = useTranslation("common")
	const { t } = useTranslation("mainForm", { keyPrefix: "validations" })

	const onSearch = (value: string) => {
		if (asyncFetchSearchFunction) {
			debounce(async () => {
				setFetching(true)
				try {
					await asyncFetchSearchFunction(value)
				} catch (error) {
					console.error(error)
				} finally {
					setFetching(false)
				}
			}, config.ui.componentsConfig.searchInputDelay)
		}
	}

	return (
		<Form.Item
			labelCol={{ span: 24 }}
			rules={calcFormFieldValidationRule(mandatory, validationMessage ?? t("mandatoryField"))}
			{...props}
		>
			<div style={{ ...containerStyles }}>
				<Select
					maxTagCount={maxTagCount}
					status={mandatory && errorMessage ? "error" : undefined}
					notFoundContent={
						<div className="flex justify-center items-center flex-col">
							<Empty
								imageStyle={{ width: 100, height: 100, margin: "auto" }}
								description={
									<Typography.Text className="text-gray-400 ">{tCommon("noData")}</Typography.Text>
								}
							/>
						</div>
					}
					loading={loading ?? fetching}
					showSearch
					onDropdownVisibleChange={open => {
						if (open) {
							onSearch("")
						}
					}}
					dropdownRender={menu =>
						fetching ? (
							<>
								{menu}
								<div className="flex">
									<Spin className="m-auto pt-4 pb-2" size="small" />
								</div>
							</>
						) : (
							menu
						)
					}
					popupMatchSelectWidth={false}
					labelInValue={true}
					placeholder={
						(
							<Typography.Text style={{ color: colors.disabled, fontWeight: "500" }}>
								{placeholder}
							</Typography.Text>
						) || "Select an option"
					}
					optionFilterProp="children"
					onSearch={onSearch}
					filterOption={(input, option) =>
						option?.label && typeof option.label === "string"
							? option?.label.toLowerCase().includes(input.toLowerCase())
							: false
					}
					onChange={onChange}
					disabled={disabled}
					options={options}
					allowClear={allowClear}
					mode={mode}
					value={props.value}
				/>
			</div>
		</Form.Item>
	)
}

export default SelectSearchField
