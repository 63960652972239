import { Col, Row, Space, Tag } from "antd"
import { Microsite, MicrositeCluster } from "../../../../domain/entities/Microsite"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import { FormField } from "../../../components/Form/FormField"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import { DinamicSelectorWeightForm } from "../../sellersServices/components/DinamicSelectorWeightForm"
import { MicrositesViewModel } from "../MicrositesViewModel"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { CategoriesViewModel } from "../../categories/CategoriesViewModel"
import { useEffect } from "react"

interface Props {
	inputDisabled?: boolean
	onChange?: (value: Partial<Microsite>) => void
}

const MicrositeInformationFormSection = ({ inputDisabled, onChange }: Props) => {
	const { t } = useTranslation("microsites", { keyPrefix: "form.micrositeInfo" })
	const viewModel = useContainerInjection<MicrositesViewModel>("MicrositesViewModel")
	const categoriesViewModel = useContainerInjection<CategoriesViewModel>("CategoriesViewModel")

	useEffect(() => {
		if (categoriesViewModel.categories.length === 0) {
			categoriesViewModel.fetchCategoriesData()
		}
	}, [])

	return (
		<>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"sellerName"}
						label={t("partner")}
						name={"sellerName"}
						mandatory
						onChange={(sellerName: string) => onChange && onChange({ sellerName })}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"slug"}
						label={t("slug")}
						name={"slug"}
						mandatory
						onChange={(slug: string) => onChange && onChange({ slug })}
					/>
				</Col>
			</Row>

			<Row gutter={[24, 0]}>
				<Col xl={24} md={24} xs={24}>
					<FormField.Input
						key={"popupText"}
						label={t("popUpText")}
						name={"popupText"}
						inputType={InputType.TEXTAREA}
						mandatory
						onChange={(popupText: string) => onChange && onChange({ popupText })}
					/>
				</Col>
			</Row>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"fontType"}
						label={t("fontType")}
						name={"fontType"}
						onChange={(fontType: string) => onChange && onChange({ fontType })}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"externalLink"}
						label={t("externalLink")}
						name={"externalLink"}
						onChange={(externalLink: string) => onChange && onChange({ externalLink })}
					/>
				</Col>
				<Col xl={24} md={24} xs={24} style={{ display: "flex", flexDirection: "column", gap: 2 }}>
					<DinamicSelectorWeightForm<MicrositeCluster>
						label={t("clusterSelectorLabel")}
						placeholder={t("clusterSelectorPlaceholder")}
						hideOrder
						name="micrositeCluster"
						onAdd={msiteCluster => {
							if (!msiteCluster.id) return
							const newDetail: MicrositeCluster = {
								clusterId: String(msiteCluster.id),
								status: true
							}
							onChange &&
								onChange({
									clusters: viewModel.formData?.clusters?.length
										? [...viewModel.formData.clusters, newDetail]
										: [newDetail]
								})
						}}
						data={categoriesViewModel.categories.filter(
							category =>
								!viewModel?.formData.clusters?.find(cluster => cluster.clusterId === category.id)
						)}
						loading={categoriesViewModel.isLoading}
						disabled={categoriesViewModel.isLoading}
					/>
					<Space size={[0, 8]} wrap>
						{viewModel.formData?.clusters?.map((cat, idx) => {
							const clusterDetail = categoriesViewModel.categories.find(
								category => category.id == cat.clusterId
							)
							return clusterDetail ? (
								<Tag
									key={cat.clusterId}
									closable
									style={{
										userSelect: "none",
										height: 30,
										display: "flex",
										alignItems: "center",
										borderRadius: 10
									}}
									onClose={() => viewModel.removeCluster(idx)}
								>
									{`${clusterDetail.id} - ${clusterDetail?.label}`}
								</Tag>
							) : null
						})}
					</Space>
				</Col>
			</Row>
		</>
	)
}

export default observer(MicrositeInformationFormSection)
