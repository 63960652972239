import { UserRepository } from "../../../repositories/userRepository"
import { UseCase } from "../../useCase"
import { GetUsersResponse } from "./response"
import { GetUsersRequest } from "./request"

export class GetUsersUseCase implements UseCase<GetUsersRequest, GetUsersResponse> {
	private _repository
	constructor({ UserRepository }: { UserRepository: UserRepository }) {
		this._repository = UserRepository
	}

	exec({ pagination, limit }: GetUsersRequest) {
		return this._repository.getUsersList(pagination, limit)
	}
}
