import { Button, Input, Typography } from "antd"
import { FormField } from "../../../components/Form/FormField"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { SelectSearchFieldProps } from "../../../components/SelectSearchField/SelectSearchField"
import { StarFilled } from "@ant-design/icons"

interface DinamicSelectorWeightFormProps<T> extends SelectSearchFieldProps {
	data: Partial<any>[]
	loading?: boolean
	disabled?: boolean
	onAdd?: (value: { id: number; label: string; weight: string }) => void
	hideOrder?: boolean
}

export const DinamicSelectorWeightForm = <T extends any>({
	data,
	label,
	mandatory,
	loading,
	disabled,
	hideOrder,
	onAdd,
	placeholder,
	...props
}: DinamicSelectorWeightFormProps<T>) => {
	const { t } = useTranslation("services", { keyPrefix: "form.serviceInfo" })
	const { t: tCommon } = useTranslation("common")
	const [item, setItem] = useState<any>({})

	return (
		<div>
			{label && (
				<div style={{ marginLeft: 4, marginBottom: 10 }}>
					{mandatory && (
						<span style={{ fontSize: 4.5, color: "red", position: "relative", top: -8, right: 4 }}>
							<StarFilled />
						</span>
					)}
					<Typography.Text>{label}</Typography.Text>
				</div>
			)}
			<div style={{ display: "flex", gap: 14, alignItems: "center" }}>
				<FormField.SelectSearch
					style={{ width: "200px" }}
					loading={loading}
					disabled={disabled}
					value={item?.id}
					placeholder={placeholder || tCommon("search")}
					options={data.map(data => ({
						value: data.id,
						label: `${data.id} - ${data.label}`,
						key: data.id
					}))}
					onChange={selectedOption => {
						// @ts-ignore
						setItem({ ...item, id: selectedOption.value, label: selectedOption.label })
					}}
					{...props}
				/>
				{!hideOrder && (
					<Input
						type="number"
						placeholder={t("weight").toString()}
						onChange={event => setItem({ ...item, weight: event.target.value.trim() })}
						value={item?.weight}
						style={{ width: "15%", marginTop: -24 }}
					/>
				)}
				<Button
					style={{ marginTop: -24 }}
					onClick={() => {
						if ((!hideOrder && (!item?.weight || item?.weight?.trim() == "")) || !item?.id) return
						onAdd && onAdd(item)
						setItem({})
					}}
				>
					<Typography.Text style={{ fontSize: 12, display: "flex", fontWeight: "normal" }}>{`+ ${t(
						"add"
					)}`}</Typography.Text>
				</Button>
			</div>
		</div>
	)
}
