import { UseCase } from "../../useCase"
import { DeleteServiceSessionResponse } from "./response"
import { DeleteServiceSessionRequest } from "./request"
import { ServiceSessionRepository } from "../../../repositories/serviceSessionRepository"

export class DeleteServiceSessionUseCase implements UseCase<DeleteServiceSessionRequest, DeleteServiceSessionResponse> {
	private _repository
	constructor({ ServiceSessionRepository }: { ServiceSessionRepository: ServiceSessionRepository }) {
		this._repository = ServiceSessionRepository
	}

	exec(request: DeleteServiceSessionRequest) {
		return this._repository.deleteServiceSession(request.session, request.serviceId)
	}
}
