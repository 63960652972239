import { ErrorHandlerError } from "./ErrorHandlerError.interface"

export class SellerError extends Error implements ErrorHandlerError {
	public errorMessage: string
	public errorCode?: string
	public httpStatus: number
	static SELLERS_FETCH_ERROR = "There was an error fetching the sellers"
	static SELLER_CREATE_ERROR = "There was an error creating the seller"
	static SELLER_UPDATE_ERROR = "There was an error updating the seller"
	static SELLERS_FETCH_USERS_ERROR = "There was an error fetching users by autocomplete"

	constructor({ errorMessage, errorCode, httpStatus }: ErrorHandlerError) {
		super(errorMessage)
		this.errorMessage = errorMessage
		this.errorCode = errorCode
		this.httpStatus = httpStatus
		this.name = "SellerError"
	}
}
