import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"

export default () => {
	const { t } = useTranslation("vouchers", { keyPrefix: "form.dataTableHeaders" })

	return columns(t)
}

const columns = (t: TFunction) => [
	{
		key: "id",
		title: "ID",
		dataIndex: "id",
		sorteable: true
	},
	{
		key: "voucherCode",
		title: t("voucherCode"),
		dataIndex: "voucherCode",
		width: "32%"
	},
	{
		key: "status",
		title: t("status"),
		dataIndex: "status"
	},
	{
		title: "",
		key: "editButton",
		dataIndex: "editButton"
	}
]
