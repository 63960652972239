import { AuthRepository } from "../../../repositories/AuthRepository"
import { UseCase } from "../../useCase"
import { LoginServiceRequest } from "./request"
import { LoginResponse } from "./response"

export class LoginUseCase implements UseCase<LoginServiceRequest, LoginResponse> {
	private _repository
	constructor({ AuthRepository }: { AuthRepository: AuthRepository }) {
		this._repository = AuthRepository
	}

	exec(loginData: LoginServiceRequest) {
		return this._repository.emailSignIn(loginData)
	}
}
