import { ErrorHandlerError } from "./ErrorHandlerError.interface"

export class TipError extends Error implements ErrorHandlerError {
	public errorMessage: string
	public errorCode?: string
	public httpStatus: number
	static TIPS_FETCH_ERROR = "There was an error fetching tips"
	static TIP_CREATE_ERROR = "There was an error creating the tip"
	static TIP_MICROSITE_CREATE_ERROR = "There was an error creating the tip-microsite relationship"
	static TIP_COUNTRY_CREATE_ERROR = "There was an error creating the tip-country relationship"
	static TIP_MICROSITE_UPDATE_ERROR = "There was an error updating the tip-microsite relationship"
	static TIP_MICROSITE_DELETE_ERROR = "There was an error deleting the tip-microsite relationship"
	static TIP_COUNTRY_DELETE_ERROR = "There was an error deleting the tip-country relationship"
	static TIP_COUNTRY_UPDATE_ERROR = "There was an error updating the tip-country relationship"
	static TIP_UPDATE_ERROR = "There was an error updating the tip"
	static TIP_DELETE_ERROR = "There was an error deleting the tip"
	static TIP_UPLOAD_MEDIA_ERROR = "There was an error uploading the tip media"

	constructor({ errorMessage, httpStatus, errorCode }: ErrorHandlerError) {
		super(errorMessage)
		this.errorMessage = errorMessage
		this.httpStatus = httpStatus
		this.errorCode = errorCode
		this.name = "TipError"
	}
}
