import { action, makeObservable, observable, toJS } from "mobx"
import { ApiErrorCodes, ErrorHandler } from "../../error/ErrorHandler"
import { TableConfig } from "../../components/DataTable/types/TableConfig"
import { Tip } from "../../../domain/entities/Tip"
import { GetTipsUseCase } from "../../../domain/useCases/tips/getTips"
import { CreateTipUseCase } from "../../../domain/useCases/tips/createTip"
import { UpdateTipUseCase } from "../../../domain/useCases/tips/updateTip"
import { ViewModel } from "../ViewModel"
import { DeleteMicrositeTipUseCase } from "../../../domain/useCases/tips/deleteMicrositeTip"
import { CountryTip } from "../../../domain/entities/CountryTip"
import { UploadTipMediaUseCase } from "../../../domain/useCases/tips/uploadTipMedia"

interface Props {
	GetTipsUseCase: GetTipsUseCase
	CreateTipUseCase: CreateTipUseCase
	UpdateTipUseCase: UpdateTipUseCase
	UploadTipMediaUseCase: UploadTipMediaUseCase
	DeleteMicrositeTipUseCase: DeleteMicrositeTipUseCase
	ErrorHandler: ErrorHandler
}
type ErrorType = "header" | "detail" | undefined
type Error = { message: string; type: ErrorType }

export class TipsViewModel extends ViewModel {
	private _getTipsUseCase
	private _createTipUsecase
	private _updateTipUseCase
	private _deleteMicrositeTipUseCase
	private _errorHandler
	private _uploadTipMediaUseCase
	isFetching: boolean = false
	isLoading: boolean = false
	uploadingMedia: boolean = false
	isLoadingDetail: boolean = false
	searchedTips: Partial<Tip>[] = []
	error: Error = { message: "", type: undefined }
	editMode: boolean = false
	tips: Partial<Tip>[] = []
	selectedTip: Partial<Tip> = {}
	initialFormData: Partial<Tip> = {
		microsites: [],
		countries: []
	}
	formData = this.initialFormData
	originalFormData: Partial<Tip> = {}
	searchValue: string = ""
	tableConfig: TableConfig = {
		pageSize: 20,
		sort: { order: "descend", field: "id" }
	}
	mediaType = [
		{ value: "image", key: "image" },
		{ value: "video", key: "video" }
	]
	countries = [
		{ id: "MX", value: "MX" },
		{ id: "ES", value: "ES" }
	]
	tipTableFilters = {}

	constructor({
		GetTipsUseCase,
		UpdateTipUseCase,
		CreateTipUseCase,
		DeleteMicrositeTipUseCase,
		UploadTipMediaUseCase,
		ErrorHandler
	}: Props) {
		super()
		makeObservable(this, {
			searchValue: observable,
			isLoading: observable,
			uploadingMedia: observable,
			error: observable,
			editMode: observable,
			tips: observable,
			searchedTips: observable,
			setSearchedTips: action,
			selectedTip: observable,
			setSelectedTip: action,
			formData: observable,
			originalFormData: observable,
			setOriginalFormData: action,
			setFormData: action,
			initialFormData: observable,
			setInitialFormData: action,
			setTips: action,
			setLoading: action,
			setUploadingMedia: action,
			setEditMode: action,
			setError: action,
			tableConfig: observable,
			setTableConfig: action,
			isFetching: observable,
			setIsFetching: action,
			tipTableFilters: observable,
			setTipTableFilters: action
		})
		this._getTipsUseCase = GetTipsUseCase
		this._updateTipUseCase = UpdateTipUseCase
		this._createTipUsecase = CreateTipUseCase
		this._uploadTipMediaUseCase = UploadTipMediaUseCase
		this._deleteMicrositeTipUseCase = DeleteMicrositeTipUseCase
		this._errorHandler = ErrorHandler
		this.fetchTips()
	}

	public async fetchTips() {
		try {
			this.setLoading(true)
			const tips = await this._getTipsUseCase.exec({
				pagination: 0,
				limit: this.limit
			})
			this.setTips(tips)
			this.setHasNextPage(true)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async searchTips(expression: string) {
		if (expression === "") {
			this.setSearchedTips(this.tips)
			return
		}
		this.setLoading(true)
		try {
			const searchedUsers = await this._getTipsUseCase.exec({
				searchExpression: expression,
				filters: this.tipTableFilters
			})
			this.setSearchedTips(searchedUsers)
		} catch (error: any) {
			if (error?.errorCode === ApiErrorCodes.SERVICE_NOT_EXISTS) {
				this.setSearchedTips([])
				return
			}
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public extractAssociatedMicrosites() {
		const microsites = this.tips.filter(tip => tip.microsites?.length).map(tip => tip.microsites)
		const uniques = new Set<string>()
		// REMOVE DUPLICATED SLUGS
		microsites.forEach((micrositeArr, index) => {
			micrositeArr?.forEach(microsite => {
				uniques.add(microsite.slug)
			})
		})
		return Array.from(uniques)
	}

	public deleteCountryTip(countryTip: CountryTip) {
		this.setFormData({
			...this.formData,
			countries: this.formData.countries?.map(country => {
				if (country.countryId === countryTip.countryId) {
					country.deleted = true
				}
				return country
			})
		})
	}

	public async deleteMicrositeTip(micrositeTip: any) {
		try {
			this.setFormData({
				...this.formData,
				microsites: this.formData.microsites?.filter(
					microsite => microsite.micrositeId !== micrositeTip.micrositeId
				)
			})

			await this._deleteMicrositeTipUseCase.exec({
				tipId: this.formData.id!,
				micrositeId: micrositeTip.micrositeId
			})

			this.fetchTips()
			this.setOriginalFormData(this.formData)
		} catch (error) {
			this.setFormData(this.originalFormData)
			throw this._errorHandler.handleError(error)
		} finally {
			// this.setLoading(false)
		}
	}

	public extractAssociatedCountries() {
		const countries = this.tips.filter(tip => tip.countries?.length).map(tip => tip.countries)
		const uniques = new Set<string>()
		// REMOVE DUPLICATED COUNTRIES
		countries.forEach((countryArr, index) => {
			countryArr?.forEach(country => {
				uniques.add(country.countryId)
			})
		})
		return Array.from(uniques)
	}

	public async sendFormData() {
		if (this.formData.id) {
			await this.updateTip()
		} else {
			await this.createTip()
		}
	}

	public async createTip() {
		try {
			this.setLoading(true)
			const createdTip = await this._createTipUsecase.exec({ ...this.formData, mediaUrl: undefined })
			let mediaUrl = this.formData.mediaUrl as any
			let mediaPreview = this.formData.mediaPreview
			if (mediaUrl instanceof File) {
				const mediaUrls = await this.uploadTipMedia(createdTip.tipId, mediaUrl)
				this.sanitizeTips()
				await this._updateTipUseCase.exec({
					...this.formData,
					id: createdTip.tipId,
					mediaUrl: mediaUrls.mediaUrl || this.formData.mediaUrl,
					mediaPreview: mediaUrls.mediaPreviewUrl || this.formData.mediaPreview
				})
				mediaUrl = mediaUrls.mediaUrl
				mediaPreview = mediaUrls.mediaPreviewUrl
			}
			this.fetchTips()
			this.sanitizeTips()
			this.setSelectedTip({ ...this.formData, id: createdTip.tipId, mediaUrl, mediaPreview })
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async updateTip() {
		try {
			this.setLoading(true)
			const mediaUrls = await this.uploadTipMedia(this.formData.id!, this.formData.mediaUrl)
			const mediaUrl = mediaUrls.mediaUrl || this.formData.mediaUrl
			const mediaPreview = mediaUrls.mediaPreviewUrl || this.formData.mediaPreview
			this.setSelectedTip({ ...this.formData, mediaUrl, mediaPreview })
			await this._updateTipUseCase.exec({ ...this.formData, mediaUrl, mediaPreview })
			await this.fetchTips()
			this.sanitizeTips()
			// this.setOriginalFormData(this.formData)
			this.setSelectedTip({ ...this.formData })
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	private async uploadTipMedia(
		tipId: number,
		uploadData: any
	): Promise<{ mediaUrl: string; mediaPreviewUrl: string }> {
		this.setUploadingMedia(true)
		let mediaUrl: string = ""
		let mediaPreviewUrl: string = ""
		try {
			if (uploadData instanceof File) {
				const uploadResponse = await this._uploadTipMediaUseCase.exec({
					tipId,
					file: uploadData
				})
				mediaUrl = uploadResponse.mediaUrl
				mediaPreviewUrl = uploadResponse.mediaPreview
			}
			return { mediaUrl, mediaPreviewUrl }
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setUploadingMedia(false)
		}
	}

	public async fetchPaginatedTips() {
		this.setIsFetching(true)
		try {
			const tips = await this._getTipsUseCase.exec({
				pagination: this.pagination,
				limit: this.limit,
				filters: this.tipTableFilters
			})
			if (tips.length > 0) {
				const combinedTips = [...tips, ...this.tips]
				this.setTips(combinedTips)
				// this.setSearchedServices(combinedSellerServices)
			}
			this.setHasNextPage(tips.length > 0)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setIsFetching(false)
		}
	}

	private sanitizeTips(): void {
		const microsites = this.formData.microsites?.map(msite => {
			delete msite.new
			delete msite.updated
			return msite
		})
		const countries = this.formData.countries
			?.filter(country => !country?.deleted)
			.map(country => {
				delete country.new
				delete country.updated
				return country
			})
		this.setFormData({ ...this.formData, microsites, countries })
	}

	public async fetchTipsWithFilters(filters: Record<string, string[]>) {
		try {
			this.setLoading(true)
			const tips = await this._getTipsUseCase.exec({
				pagination: 0,
				limit: this.limit,
				filters
			})
			this.setHasNextPage(true)
			this.setTipTableFilters(filters)
			this.setTips(tips)
		} catch (error) {
		} finally {
			this.setLoading(false)
		}
	}

	// ACTIONS
	setTips(tips: Partial<Tip>[]) {
		this.tips = tips
		this.setSearchedTips(tips)
	}

	setLoading(isLoading: boolean) {
		this.isLoading = isLoading
	}

	setUploadingMedia(isLoading: boolean) {
		this.uploadingMedia = isLoading
	}

	setIsFetching(isFetching: boolean) {
		this.isFetching = isFetching
	}

	setEditMode(editMode: boolean) {
		this.editMode = editMode
	}

	setInitialFormData(formData: Partial<Tip>) {
		this.initialFormData = formData
	}

	setFormData(formData: Partial<Tip> | any) {
		this.formData = formData
	}

	setOriginalFormData(formData: Partial<Tip>) {
		this.originalFormData = formData
	}

	setSelectedTip(tip: Partial<Tip>) {
		this.selectedTip = tip
	}

	setSearchedTips(tips: Partial<Tip>[]) {
		this.searchedTips = tips
	}

	setError(message: string, type: ErrorType) {
		this.error = { message, type }
	}

	setTableConfig(config: Partial<typeof this.tableConfig>) {
		this.tableConfig = { ...this.tableConfig, ...config }
	}

	setTipTableFilters(filters: Record<string, string[]>) {
		this.tipTableFilters = { ...this.tipTableFilters, ...filters }
	}
}
