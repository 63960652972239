import { Col, Row } from "antd"
import { createRef, useEffect, useState } from "react"
import MainForm, { MainFormRef } from "../../../components/Form/MainForm"
import { observer } from "mobx-react"
import { Currency } from "../../../../domain/entities/Currency"
import Switch from "../../../components/Switch/Switch"
import { useTranslation } from "react-i18next"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import { FormField } from "../../../components/Form/FormField"
import { Multicurrency } from "../../../../data/dto/MultiCurrency.dto"

interface ServiceSessionFormProps {
	data: Multicurrency
	currency: Currency
	key: number
	mandatory?: boolean
	onChangeText?: (value: any) => void
}

export const ServiceSessionForm = observer(
	({ data, key, mandatory, currency, onChangeText }: ServiceSessionFormProps) => {
		const { t } = useTranslation("services", { keyPrefix: "form.sessionsInfo.sessionModal" })
		const initialFormData = {
			currencyId: Number(currency.id),
			price: data ? data.price : undefined,
			outprice: data ? data.outprice : undefined,
			sellerPrice: data ? data.sellerPrice : undefined
		}
		const [formData, setFormData] = useState<any>(initialFormData)
		const [switchStatus, setSwitchStatus] = useState<boolean>(
			Boolean(data) && (data.price !== undefined || data.outprice !== undefined)
		)
		const formRef = createRef<MainFormRef>()
		useEffect(() => {
			setFormData({ ...formData, ...initialFormData })
			setSwitchStatus(Boolean(data) && (data.price !== undefined || data.outprice !== undefined))
		}, [data])

		return (
			<MainForm
				initialValues={formData}
				hideFormButtons
				onChangeFormValues={data => setFormData({ ...formData, ...data })}
				ref={formRef}
			>
				<Row gutter={[24, 0]} style={{ alignItems: "center" }}>
					<Col xl={8} md={12} xs={24}>
						<FormField.Input
							disabled={!switchStatus}
							addonBefore={currency.symbol}
							mandatory={mandatory}
							key={"price" + key}
							label={`${t("price")} ` + currency.isoCode}
							name={"price"}
							inputType={InputType.NUMBER}
							onChange={(price: string) =>
								onChangeText && onChangeText({ ...formData, price: Number(price) })
							}
						/>
					</Col>
					<Col xl={8} md={12} xs={24}>
						<FormField.Input
							disabled={!switchStatus}
							addonBefore={currency.symbol}
							key={"outprice" + key}
							label={t("outPrice")}
							name={"outprice"}
							inputType={InputType.NUMBER}
							onChange={(outprice: string) =>
								onChangeText && onChangeText({ ...formData, outprice: Number(outprice) })
							}
						/>
					</Col>
					<Switch
						style={{ marginTop: "25px" }}
						checked={switchStatus}
						onChange={(value: boolean) => {
							if (!value) {
								const data = { ...formData, price: undefined, outprice: undefined }
								setFormData({ ...formData, ...data })
								onChangeText && onChangeText({ ...formData, ...data })
							} else {
								const data = { ...formData, price: 0, outprice: 0 }
								setFormData({ ...formData, ...data })
								onChangeText && onChangeText({ ...formData, ...data })
							}
							setSwitchStatus(value)
						}}
					/>
				</Row>
				<Row gutter={[24, 0]} style={{ alignItems: "center" }}>
					<Col xl={8} md={12} xs={24}>
						<FormField.Input
							disabled={!switchStatus}
							addonBefore={currency.symbol}
							mandatory={mandatory}
							key={"sellerPrice" + key}
							label={`${t("sellerPrice")} ` + currency.isoCode}
							name={"sellerPrice"}
							inputType={InputType.NUMBER}
							onChange={(sellerPrice: string) =>
								onChangeText &&
								onChangeText({
									...formData,
									sellerPrice: sellerPrice ? Number(sellerPrice) : undefined
								})
							}
						/>
					</Col>
				</Row>
			</MainForm>
		)
	}
)
