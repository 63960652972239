import { Skeleton } from "antd"

const ServiceConfigSectionSkeleton = () => (
	<div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
		<div style={{ display: "flex", gap: 55, justifyContent: "space-between" }}>
			<Skeleton.Button active size="small" style={{ width: "8vw" }} />
			<Skeleton.Button active size="small" style={{ borderRadius: 100 }} />
		</div>
		<div style={{ display: "flex", gap: 55, justifyContent: "space-between" }}>
			<Skeleton.Button active size="small" style={{ width: "8vw" }} />
			<Skeleton.Button active size="small" style={{ borderRadius: 100 }} />
		</div>
		<div style={{ display: "flex", gap: 55, justifyContent: "space-between" }}>
			<Skeleton.Button active size="small" style={{ width: "8vw" }} />
			<Skeleton.Button active size="small" style={{ borderRadius: 100 }} />
		</div>
		<div style={{ display: "flex", gap: 55, justifyContent: "space-between" }}>
			<Skeleton.Button active size="small" style={{ width: "8vw" }} />
			<Skeleton.Button active size="small" style={{ borderRadius: 100 }} />
		</div>
		<div style={{ display: "flex", gap: 55, justifyContent: "space-between" }}>
			<Skeleton.Button active size="small" style={{ width: "8vw" }} />
			<Skeleton.Button active size="small" style={{ borderRadius: 100 }} />
		</div>
	</div>
)

export default ServiceConfigSectionSkeleton
