import { Login } from "../../domain/entities/login"
import { AuthRepository } from "../../domain/repositories/AuthRepository"
import { AuthService } from "../services/AuthService"

export class AuthRepositoryImpl implements AuthRepository {
	private _authService
	constructor({ AuthService }: { AuthService: AuthService }) {
		this._authService = AuthService
	}

	public async emailSignIn(loginData: Login): Promise<any> {
		const response = await this._authService.emailSignIn(loginData)
		return response.data
	}

	public async logout(): Promise<any> {
		await this._authService.logout()
	}

	public async googleSignIn(code: string): Promise<any> {
		const response = await this._authService.googleSignIn(code)
		return response.data
	}
}
