interface ContructorParams {
	id: string
	name: string
	symbol: string
	isoCode: string
	symbolPosition: string
}

export class Currency {
	public id: string
	public name: string
	public symbol: string
	public isoCode: string
	public symbolPosition: string

	constructor(params: ContructorParams) {
		this.id = params.id
		this.name = params.name
		this.symbol = params.symbol
		this.isoCode = params.isoCode
		this.symbolPosition = params.symbolPosition
	}
}

export enum MicrositeCurrency {
	ES = 1,
	MX = 4
}
