import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"

export default () => {
	const { t } = useTranslation("transactions", { keyPrefix: "form.dataTableHeaders" })

	return columns(t)
}

const columns = (t: TFunction) => [
	{
		key: "id",
		title: "ID",
		dataIndex: "id",
		sorteable: true
	},
	{
		key: "userEmail",
		title: t("userEmail"),
		dataIndex: "userEmail"
	},
	{
		key: "marketplace",
		title: t("marketplace"),
		dataIndex: "marketplace",
		width: "10rem",
		align: "center"
	},
	{
		key: "micrositeType",
		title: t("micrositeType"),
		dataIndex: "micrositeType",
		width: "10rem",
		align: "center"
	},
	{
		key: "micrositeSlug",
		title: t("micrositeSlug"),
		dataIndex: "micrositeSlug",
		width: 200,
		align: "center"
	},
	{
		key: "serviceName",
		title: t("serviceName"),
		dataIndex: "serviceName"
	},
	{
		key: "sessionName",
		title: t("sessionName"),
		dataIndex: "sessionName"
	},
	{
		key: "date",
		title: t("date"),
		dataIndex: "date"
	},
	{
		key: "finalPrice",
		title: t("finalPrice"),
		dataIndex: "finalPrice",
		width: "10rem",
		align: "center"
	},
	{
		key: "cancelledAt",
		title: t("cancellationDate"),
		dataIndex: "cancelledAt"
	}
]
