import { CountryModel } from "../models/CountryModel"
import { HttpManager } from "../network/interface/HttpManager"

const COUNTRY_MOCK = [
	{
		id: "MX",
		name: "Mexico"
	},
	{
		id: "ES",
		name: "España"
	}
]

export class CountryService {
	// private _http: HttpManager

	// constructor({ HttpManager }: { HttpManager: HttpManager }) {
	// this._http = HttpManager
	// }

	public async getCountries(): Promise<CountryModel[]> {
		// const response = await this._http.get();
		// TODO: Implementar llamada a API
		return COUNTRY_MOCK.map((country: any) => CountryModel.fromRawJson(country))
	}
}
