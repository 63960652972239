import { DeleteCategoryUseCase } from "./../../../domain/useCases/categories/deleteCategory/index"
import { UpdateCategoryUseCase } from "./../../../domain/useCases/categories/updateCategory/index"
import { CreateCategoryUseCase } from "./../../../domain/useCases/categories/createCategory/index"
import { GetCategoriesUseCase } from "./../../../domain/useCases/categories/getCategories/index"
import { GetCountriesUseCase } from "../../../domain/useCases/countries/getCountries/index"
import { action, makeObservable, observable, toJS } from "mobx"
import { Category, CategoryChildren } from "../../../domain/entities/Category"
import { Country } from "../../../domain/entities/Country"
import { ErrorHandler } from "../../error/ErrorHandler"
import { TableConfig } from "../../components/DataTable/types/TableConfig"
import { GetCategoryDetailUseCase } from "../../../domain/useCases/categories/getCategoryDetail"
import { GetCategoryIconsUseCase } from "../../../domain/useCases/categories/getCategoryIcons"
import { CategoryIcon } from "../../../domain/entities/CategoryIcon"

interface Props {
	GetCategoriesUseCase: GetCategoriesUseCase
	GetCategoryDetailUseCase: GetCategoryDetailUseCase
	CreateCategoryUseCase: CreateCategoryUseCase
	UpdateCategoryUseCase: UpdateCategoryUseCase
	DeleteCategoryUseCase: DeleteCategoryUseCase
	GetCountriesUseCase: GetCountriesUseCase
	GetCategoryIconsUseCase: GetCategoryIconsUseCase
	ErrorHandler: ErrorHandler
}

export class CategoriesViewModel {
	private nonTranslatedLabel = "clusters.not.translations"
	private _getCategoriesUseCase
	private _getCategoryDetailUseCase
	private _createCategoryUseCase
	private _updateCategoryUseCase
	private _deleteCategoryUseCase
	private _getCountriesUseCase
	private _getCategoryIconsUseCase
	private _errorHandler
	isLoading: boolean = false
	isLoadingParents: boolean = false
	isLoadingENFields: boolean = false
	isLoadingDetail: boolean = false
	categories: Partial<Category>[] = []
	searchedCategories: Partial<Category>[] = []
	selectedCategory: Partial<Category> = {}
	countries: Country[] = []
	icons: CategoryIcon[] = []
	searchValue: string = ""
	tableConfig: TableConfig = {
		pageSize: 20,
		sort: { order: "descend", field: "id" }
	}

	constructor({
		GetCategoriesUseCase,
		GetCategoryDetailUseCase,
		CreateCategoryUseCase,
		UpdateCategoryUseCase,
		DeleteCategoryUseCase,
		GetCountriesUseCase,
		GetCategoryIconsUseCase,
		ErrorHandler
	}: Props) {
		makeObservable(this, {
			isLoading: observable,
			isLoadingParents: observable,
			isLoadingENFields: observable,
			isLoadingDetail: observable,
			categories: observable,
			countries: observable,
			icons: observable,
			selectedCategory: observable,
			searchedCategories: observable,
			setSelectedCategory: action,
			setCategories: action,
			setCountries: action,
			setIcons: action,
			setLoading: action,
			setLoadingParents: action,
			setIsLoadingENFields: action,
			setLoadingDetail: action,
			tableConfig: observable,
			setTableConfig: action,
			searchValue: observable
		})
		this._getCategoriesUseCase = GetCategoriesUseCase
		this._getCategoryDetailUseCase = GetCategoryDetailUseCase
		this._createCategoryUseCase = CreateCategoryUseCase
		this._updateCategoryUseCase = UpdateCategoryUseCase
		this._deleteCategoryUseCase = DeleteCategoryUseCase
		this._getCountriesUseCase = GetCountriesUseCase
		this._getCategoryIconsUseCase = GetCategoryIconsUseCase
		this._errorHandler = ErrorHandler
		this.fetchCategoriesData()
	}

	public async fetchCategoriesData() {
		this.setLoading(true)
		try {
			const categories = await this._getCategoriesUseCase.exec()
			const countries = await this._getCountriesUseCase.exec()
			const icons = await this._getCategoryIconsUseCase.exec()
			this.setCountries(countries)
			this.setIcons(icons)
			this.setCategories(categories)
		} catch (error) {
			this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async fetchCategoryDetail(categoryId: string) {
		this.setLoadingDetail(true)
		try {
			const category = await this._getCategoryDetailUseCase.exec(categoryId)
			this.setSelectedCategory(category)
		} catch (error) {
			this._errorHandler.handleError(error)
		} finally {
			this.setLoadingDetail(false)
		}
	}

	public extractParentCategories(): Category[] {
		const parentsIds = this.categories
			.filter(category => category.parentId !== null)
			.map(category => ({ id: category.parentId }))
		// REMOVE DUPLICATED PARENTS
		const uniqueParents = parentsIds
			.filter((parent, index) => {
				const _parent = JSON.stringify(parent)
				return (
					index ===
					parentsIds.findIndex(obj => {
						return JSON.stringify(obj) === _parent
					})
				)
			})
			.map(parent => this.categories.find(category => category.id === parent.id))
		//@ts-ignore
		return uniqueParents
	}

	public extractMicrosites() {
		const categoriesWithMicrositesSlugs = this.categories
			.filter(category => category.micrositesAssociated?.length)
			.map(category => category.micrositesAssociated)
		const uniques = new Set<string>()
		// REMOVE DUPLICATED SLUGS
		categoriesWithMicrositesSlugs.forEach((micrositeArr, index) => {
			micrositeArr?.forEach(microsite => {
				uniques.add(microsite.slug)
			})
		})

		return Array.from(uniques)
	}

	public async createCategory(newCategory: Partial<Category>) {
		try {
			this.setLoading(true)
			const category = await this._createCategoryUseCase.exec(newCategory)
			this.setCategories([{ ...newCategory, id: category.id }, ...this.categories])
			this.setSelectedCategory({ ...newCategory, id: category.id })
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
			this.fetchCategoriesData()
		}
	}

	public async updateCategory(category: Partial<Category>) {
		try {
			this.setLoading(true)
			if (category.children?.some(subCategory => !subCategory.id)) {
				const subCategoriesToCreate = category.children?.filter(subCategory => !subCategory.id)
				const subCategoriesCreated = await this.createSubCategories(subCategoriesToCreate, category.id!)
				const updateStateCategory: Partial<Category> = {
					...category,
					//@ts-ignore
					children: [...subCategoriesCreated, ...category.children!.filter(subCategory => subCategory.id)]
				}
				category = updateStateCategory
			}

			await this._updateCategoryUseCase.exec(category)
			this.updateCategoryState(category)
			this.setSelectedCategory(category)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
			this.fetchCategoriesData()
		}
	}

	private async createSubCategories(
		subCategories: Partial<CategoryChildren>[],
		parentId: string
	): Promise<Partial<Category>[]> {
		const subCategoriesCreated: Partial<Category>[] = []
		try {
			this.setLoading(true)
			for (let i = 0; i < subCategories.length; i++) {
				const subCategory = {
					...subCategories![i],
					id: parentId,
					parentId
				}
				const category = await this._createCategoryUseCase.exec(subCategory)
				subCategoriesCreated.push({ ...category, label: subCategory.label })
			}
		} catch (error) {
			console.warn("@@ERROR_ON_CREATE_SUBCATEGORIES@@", error)
		} finally {
			this.setLoading(false)
		}
		return subCategoriesCreated
	}

	public async deleteCategory(category: Partial<Category>) {
		try {
			await this._deleteCategoryUseCase.exec(category)
			this.setCategories(this.categories.filter(({ id }) => id !== category.id))
		} catch (error) {
			throw this._errorHandler.handleError(error)
		}
	}

	public getParentCategoryLabel = (parentId?: string) => {
		const parentCategory = this.categories.find(category => category.id === parentId)
		return parentCategory?.label || parentCategory?.picture || ""
	}

	setCategories(categories: Partial<Category>[]) {
		this.categories = categories
		this.setSearchedCategories(categories)
	}

	setSelectedCategory(category: Partial<Category>) {
		this.selectedCategory = category
	}

	setLoading(isLoading: boolean) {
		this.isLoading = isLoading
	}

	setLoadingParents(isLoading: boolean) {
		this.isLoadingParents = isLoading
	}

	setLoadingDetail(isLoading: boolean) {
		this.isLoadingDetail = isLoading
	}

	setIsLoadingENFields(isLoading: boolean) {
		this.isLoadingENFields = isLoading
	}

	updateCategoryState(category: Partial<Category>) {
		const categories = this.categories.filter(cat => cat.id !== category.id)
		this.setCategories([category, ...categories])
	}

	setSearchedCategories(categories: Partial<Category>[]) {
		this.searchedCategories = categories
	}

	setTableConfig(config: Partial<typeof this.tableConfig>) {
		this.tableConfig = { ...this.tableConfig, ...config }
	}

	setCountries(countries: Country[]) {
		this.countries = countries
	}

	setIcons(icons: CategoryIcon[]) {
		this.icons = icons
	}
}
