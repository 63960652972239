import axios, {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	HttpStatusCode,
	InternalAxiosRequestConfig
} from "axios"
import config from "../../config/config"
import { Language } from "../../locale/interfaces/Translation"
import { HttpManager } from "./interface/HttpManager"

export class AxioshHttpManager implements HttpManager {
	private http: AxiosInstance
	private httpNewApi: AxiosInstance
	private legacyAuthToken: string = localStorage.getItem("legacyToken") || ""
	private authToken: string = localStorage.getItem("token") || ""

	constructor() {
		this.httpNewApi = axios.create({
			baseURL: config.api.baseEndpoint
		})
		this.http = axios.create({
			baseURL: config.api.legacyBaseEndpoint
		})

		// New API Interceptors
		this.httpNewApi.interceptors.request.use(
			(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
				if (this.authToken) {
					config.headers["Authorization"] = `Bearer ${this.authToken}`
				}
				config.headers["locale"] = !config.headers["locale"] ? Language.esES : config.headers["locale"]
				config.headers["Content-Type"] = !config.headers["Content-Type"]
					? "application/json"
					: config.headers["Content-Type"]

				return config
			},
			(error: AxiosError) => {
				console.log(error)
				return Promise.reject(error)
			}
		)
		this.httpNewApi.interceptors.response.use(
			(response: AxiosResponse) => {
				return response
			},
			(error: AxiosError) => {
				console.error("@@AXIOS ERROR@@", error)
				console.warn("@@AXIOS ERROR DETAIL@@", error.response?.data)
				if (error.response?.status === HttpStatusCode.Unauthorized && window.location.pathname !== "/sign-in") {
					localStorage.removeItem("token")
					window.location.href = "/sign-in"
				}
				const errorResponseData = error.response?.data
				return Promise.reject({ ...Object(errorResponseData), httpStatus: error.response?.status })
			}
		)

		// Legacy API Interceptors
		this.http.interceptors.request.use(
			(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
				config.headers["SPORTTIPS-AUTH-TOKEN"] = this.legacyAuthToken
				config.headers["locale"] = !config.headers["locale"] ? Language.esES : config.headers["locale"]
				config.headers["Content-Type"] = !config.headers["Content-Type"]
					? "application/json"
					: config.headers["Content-Type"]
				return config
			},
			(error: AxiosError) => {
				console.log(error)
				return Promise.reject(error)
			}
		)
		this.http.interceptors.response.use(
			(response: AxiosResponse) => {
				return response.data
			},
			(error: AxiosError) => {
				console.error("@@AXIOS ERROR@@", error)
				console.warn("@@AXIOS ERROR DETAIL@@", error.response?.data)
				const errorResponseData = error.response?.data
				return Promise.reject({ ...Object(errorResponseData), httpStatus: error.response?.status })
			}
		)
	}

	public requestNewApi(config: AxiosRequestConfig) {
		return this.httpNewApi.request(config)
	}

	public rawRequest(config: AxiosRequestConfig) {
		return axios.request(config)
	}

	public get(url: string, config?: AxiosRequestConfig): any {
		// console.log("@@GET@@", url, config?.params);
		const response = this.http.get(url, config)
		return response
	}

	public post(url: string, data: any, config?: AxiosRequestConfig): any {
		// console.log("@@POST@@", url, data);
		const response = this.http.post(url, data, config)
		return response
	}

	public put(url: string, data: any, config?: AxiosRequestConfig): any {
		// console.log("@@PUT@@", url);
		const response = this.http.put(url, data, config)
		return response
	}

	public delete(url: string): any {
		// console.log("@@DELETE@@", url);
		const response = this.http.delete(url)
		return response
	}

	public setLegacyAuthToken(token: string): void {
		this.legacyAuthToken = token
	}

	public setAuthToken(token: string): void {
		this.authToken = token
	}
}
