import { action, makeObservable, observable } from "mobx"
import config from "../../config/config"

export class ViewModel {
	public pagination: number = 0
	public limit: number = config.ui.componentsConfig.dataTableRecordsLimit
	public hasNextPage: boolean = true

	constructor() {
		makeObservable(this, {
			pagination: observable,
			setPagination: action,
			hasNextPage: observable,
			setHasNextPage: action
		})
	}

	public setPagination(pagination: number) {
		this.pagination = pagination
	}

	public setHasNextPage(hasNextPage: boolean) {
		this.hasNextPage = hasNextPage
	}

	public setLimit(limit: number) {
		this.limit = limit
	}
}
