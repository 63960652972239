import { Voucher } from "../../domain/entities/Voucher"
import { VoucherRules } from "../dto/VoucherRules.dto"

interface ContructorParams {
	id?: string
	status?: number
	voucherCode: string
	voucherRules?: Partial<VoucherRules>
}

export class VoucherModel {
	public id?: string
	public status?: number
	public voucherCode: string
	public voucherRules?: Partial<VoucherRules>

	constructor(params: ContructorParams) {
		this.id = params.id
		this.status = params.status
		this.voucherCode = params.voucherCode
		this.voucherRules = params.voucherRules
	}

	static fromRawJson = (rawJson: any): VoucherModel => {
		const { id, status, voucherCode, voucherRules } = rawJson

		return new VoucherModel({
			id,
			status,
			voucherCode,
			voucherRules
		})
	}

	static fromDomain = (voucher: Voucher): VoucherModel => {
		return new VoucherModel({
			...voucher,
			id: voucher.id,
			status: Number(voucher.status),
			voucherCode: voucher.voucherCode,
			voucherRules: { ...voucher.voucherRules, status: Number(voucher.voucherRules?.status) }
		})
	}
}

declare module "./VoucherModel" {
	interface VoucherModel {
		toDomain(): Voucher
	}
}

VoucherModel.prototype.toDomain = function (): Voucher {
	const data = this
	return new Voucher({
		...data,
		id: data.id || "",
		voucherCode: data.voucherCode,
		status: data.status ?? 0,
		voucherRules: data.voucherRules
	})
}
