import { User } from "../../domain/entities/User"
import { GetUploadProfilePictureUrlResponse } from "../../domain/useCases/users/getUploadPictureUrl/response"
import { ImportUsersResponse } from "../../domain/useCases/users/importUsers/response"
import { UserModel } from "../models/UserModel"
import { UserService } from "../services/UserService"
import { UserRepository } from "./../../domain/repositories/userRepository"

export class UserRepositoryImpl implements UserRepository {
	private _userService: UserService
	constructor({ UserService }: { UserService: UserService }) {
		this._userService = UserService
	}

	public async getUserDetail(userId: string): Promise<User> {
		const userModel = await this._userService.getUserDetail(userId)
		return userModel.toDomain()
	}

	public async createUser(user: User): Promise<User> {
		const userModel = await this._userService.createUser(UserModel.fromDomain(user))
		return userModel.toDomain()
	}

	public async updateUser(user: User): Promise<User> {
		const userModel = await this._userService.updateUser(UserModel.fromDomain(user))
		return userModel.toDomain()
	}

	public async getUsersList(pagination: number, limit: number): Promise<User[]> {
		const response = await this._userService.getUsersList(pagination, limit)
		return response.map((userModel: UserModel) => userModel.toDomain())
	}

	public async searchUsers(expression: string, offset: number = 0, limit = 100): Promise<User[]> {
		const response = await this._userService.searchUsers(expression, offset, limit)
		return response.map((userModel: UserModel) => userModel.toDomain())
	}

	public async getUploadProfilePictureUrl(
		userId: string,
		fileName: string,
		mimeType: string
	): Promise<GetUploadProfilePictureUrlResponse> {
		const response = await this._userService.getUploadProfilePictureUrl(userId, fileName, mimeType)
		return response
	}

	public async importUsers(file: File): Promise<ImportUsersResponse> {
		return await this._userService.importUsers(file)
	}
}
