import { Col, Form, Row, Slider } from "antd"
import { useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Seller } from "../../../domain/entities/Seller"
import { SellersViewModel } from "./SellersViewModel"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import MainForm from "../../components/Form/MainForm"
import SellerInformationFormSection from "./components/SellerInformationFormSection"
import UserInformationFormSection from "./components/UserInformationFormSection"
import { User } from "../../../domain/entities/User"
import { useTranslation } from "react-i18next"
import { toJS } from "mobx"
import useUpdateEffect from "../../hooks/useUpdateEffect"
import { observer } from "mobx-react"
import { InputAddons, InputType } from "../../components/TextInput/const/InputType.enum"
import { FormField } from "../../components/Form/FormField"
import { Card } from "../../styledComponents/CustomCard/Card"
import AdministrativeInformationFormSection from "./components/AdministrativeInformationFormSection"

const SellerFormView = () => {
	const { t } = useTranslation("sellers", { keyPrefix: "form" })
	const locationState = useLocation().state
	const viewModel = useContainerInjection<SellersViewModel>("SellersViewModel")
	const navigate = useNavigate()

	useEffect(() => {
		const userSeller = {
			user: {
				email: locationState?.seller?.userEmail
			},
			seller: { ...locationState?.seller, web: locationState?.seller?.web?.replace(InputAddons.HTTPS, "") }
		}
		viewModel.setEditMode(locationState?.seller?.id !== undefined)
		viewModel.setInitialFormData(userSeller)
		viewModel.setFormData(userSeller)
		viewModel.setOriginalFormData(userSeller)
	}, [locationState?.seller])

	useUpdateEffect(() => {
		const selectedSeller = toJS(viewModel.selectedSeller)
		const seller = { ...selectedSeller, web: selectedSeller?.web?.replace(InputAddons.HTTPS, "") }
		viewModel.setFormData({ ...viewModel.formData, seller })
		viewModel.setOriginalFormData({ ...viewModel.formData, seller })
		viewModel.setEditMode(selectedSeller.id !== undefined)
	}, [viewModel.selectedSeller])
	const formData = toJS(viewModel.formData)
	const originalFormData = useMemo(() => toJS(viewModel.originalFormData), [viewModel.originalFormData])
	const formModified = JSON.stringify(formData) === JSON.stringify(originalFormData)
	const onSubmitForm = async () => {
		try {
			if (Number(formData.seller.feePercentage) < 0 || Number(formData.seller.feePercentage) > 100) {
				throw new Error(t("validations.feePercentage"))
			}
			const web = formData.seller.web && `${InputAddons.HTTPS}${formData.seller?.web}`
			let updatedData: Partial<Seller>
			if (viewModel.editMode) {
				// @ts-ignore
				updatedData = await viewModel.updateSeller({ ...formData.seller, web })
			} else {
				updatedData = await viewModel.createSeller({
					...formData.seller,
					userEmail: formData.user?.email,
					userId: formData.user?.id,
					web
				})
			}
			navigate(".", {
				replace: true,
				state: {
					...locationState,
					seller: {
						...toJS(viewModel.formData.seller),
						logo: updatedData.logo
					}
				}
			})
		} catch (error) {
			throw error
		}
	}

	return (
		<MainForm
			initialValues={{
				phonePrefix: "34",
				...formData.seller,
				email: formData.user?.email
			}}
			onSubmitForm={onSubmitForm}
			formModified={!formModified}
		>
			<Row gutter={[24, 0]}>
				<Col xl={24} md={24} xs={24}>
					<Card
						bordered={false}
						className="criclebox tablespace mb-24"
						title={
							<div>
								<h6 className="font-semibold m-0">{t("userInfoTitle")}</h6>
							</div>
						}
						bodyStyle={{ padding: "24px" }}
					>
						<UserInformationFormSection
							disabled={viewModel.editMode}
							//@ts-ignore
							userData={formData.user}
							onChangeText={(userValue: Partial<User>) =>
								viewModel.setFormData({
									...formData,
									user: {
										...formData.user,
										...userValue
									}
								})
							}
						/>
					</Card>
					<Card
						bordered={false}
						className="criclebox tablespace mb-24"
						title={
							<div>
								<h6 className="font-semibold m-0">{t("sellerInfoTitle")}</h6>
							</div>
						}
						bodyStyle={{ padding: "24px" }}
					>
						<SellerInformationFormSection
							seller={formData.seller}
							onChangeText={(sellerValue: Partial<Seller>) =>
								viewModel.setFormData({
									...formData,
									seller: {
										...formData.seller,
										...sellerValue
									}
								})
							}
							onChangeLogo={logo =>
								viewModel.setFormData({
									...formData,
									seller: { ...formData.seller, logo: logo ?? undefined }
								})
							}
						/>
					</Card>
					<Card
						bordered={false}
						className="criclebox tablespace mb-24"
						title={
							<div>
								<h6 className="font-semibold m-0">{t("sellerAdministrativeInfoTitle")}</h6>
							</div>
						}
						bodyStyle={{ padding: "24px" }}
					>
						<AdministrativeInformationFormSection
							onChangeText={(sellerValue: Partial<Seller>) =>
								viewModel.setFormData({
									...formData,
									seller: {
										...formData.seller,
										...sellerValue
									}
								})
							}
						/>
					</Card>
				</Col>
			</Row>
			<Row gutter={[24, 0]}>
				<Col xl={24} md={24} xs={24}>
					<Card
						bordered={false}
						className="criclebox tablespace mb-24"
						title={<h6 className="font-semibold m-0">{t("experienceInfoTitle")}</h6>}
						style={{ paddingLeft: "1rem" }}
						bodyStyle={{ padding: "24px" }}
					>
						<FormField.Input
							inputType={InputType.NUMBER}
							key={"expYears"}
							label={t("fields.expYears")}
							name={"expYears"}
							onChange={expYears =>
								viewModel.setFormData({
									...formData,
									seller: { ...formData.seller, expYears: Number(expYears) }
								})
							}
						/>
						<FormField.Input
							key={"degrees"}
							label={t("fields.degrees")}
							name={"degrees"}
							onChange={(degrees: string) =>
								viewModel.setFormData({
									...formData,
									seller: { ...formData.seller, degrees }
								})
							}
						/>
					</Card>
				</Col>
			</Row>
		</MainForm>
	)
}

export default observer(SellerFormView)
