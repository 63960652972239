export enum Language {
	enUS = "en-us",
	esES = "es-es",
	caES = "ca-es",
	esMX = "es-mx"
}
export enum LanguageTranslation {
	"en-us" = "EN",
	"es-es" = "ES",
	"ca-es" = "CA",
	"es-mx" = "MX"
}
