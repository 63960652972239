import { useNavigate } from "react-router-dom"
import TABLE_COLUMNS from "../const/MICROSTES_TABLE_COLUMNS"
import { Button, Divider, Dropdown, Tooltip, Typography } from "antd"
import { CopyFilled, EditFilled, MoreOutlined } from "@ant-design/icons"
import { observer } from "mobx-react"
import { RoutePaths } from "../../../navigation/RoutePaths.enum"
import DataTable from "../../../components/DataTable/DataTable"
import { toJS } from "mobx"
import { Microsite } from "../../../../domain/entities/Microsite"
import { MicrositesViewModel } from "../MicrositesViewModel"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { useTranslation } from "react-i18next"
import { colors } from "../../../assets/styles/appStyles"
import { useState } from "react"
import { MicrositeOption } from "../const/MicrositeOption"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"

interface MicrositesTableProps {
	data: Partial<Microsite>[]
	loading?: boolean
}

const MicrositesTable = ({ data, loading }: MicrositesTableProps) => {
	const navigate = useNavigate()
	const viewModel = useContainerInjection<MicrositesViewModel>("MicrositesViewModel")
	const { t } = useTranslation("toolTips")
	const [open, setOpen] = useState<{ [key: string]: boolean }>({})
	const { t: tDataTable } = useTranslation("dataTable")

	return (
		<div className="table-responsive">
			<DataTable
				loading={loading}
				columns={TABLE_COLUMNS()}
				renderCustomLoadingComponent={() => <DataTableRowsSkeleton size="lg" />}
				tableConfig={{
					sort: viewModel.tableConfig.sort,
					pageSize: viewModel.tableConfig.pageSize
				}}
				onChange={data => {
					data.sorter &&
						viewModel.setTableConfig({ sort: { order: data.sorter.order, field: data.sorter.field } })
				}}
				onChangePage={(page, pageSize: number, lastPageFetched) => viewModel.setTableConfig({ pageSize })}
				dataSource={data.map(microsite => {
					const micrositeTypeData = viewModel.parthnersTypes?.find(
						type => type.id === microsite.micrositeType
					)
					return {
						key: microsite.id,
						id: microsite.id,
						sellerName: microsite.sellerName,
						slug: microsite.slug,
						micrositeType: (
							<div>
								<Tooltip title={micrositeTypeData?.description}>{micrositeTypeData?.typeName}</Tooltip>
							</div>
						),
						status: (
							<Typography.Title
								style={{
									color: Number(microsite.status) ? colors.success : colors.error,
									textAlign: "center",
									width: "5.5vw",
									display: "flex",
									justifyContent: "center",
									fontSize: "0.95vw"
								}}
							>
								{Number(microsite.status) === 1
									? tDataTable("status.active")
									: tDataTable("status.inactive")}
							</Typography.Title>
						),
						actions: (
							<div key={microsite.id}>
								<Dropdown
									key={microsite.id}
									dropdownRender={() => (
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												height: "100%",
												justifyContent: "space-around",
												cursor: "pointer"
											}}
										>
											<Tooltip className="row-actions-dropdown" title={t("edit")} placement="top">
												<EditFilled
													onClick={() =>
														navigate(RoutePaths.MICROSITES_ADD_NEW, {
															state: { microsite: toJS(microsite) }
														})
													}
													style={{ padding: 5 }}
												/>
											</Tooltip>
											<Divider style={{ margin: -10 }} />
											<Tooltip
												className="row-actions-dropdown"
												title={t("duplicate")}
												placement="right"
											>
												<CopyFilled
													style={{ padding: 5 }}
													onClick={() =>
														navigate(RoutePaths.MICROSITES_ADD_NEW, {
															state: {
																microsite: { ...toJS(microsite) },
																option: MicrositeOption.DUPLICATE
															}
														})
													}
												/>
											</Tooltip>
										</div>
									)}
									open={open[microsite.id!]}
									trigger={["click"]}
									//@ts-ignore
									placement="leftTop"
									onOpenChange={open => {
										setOpen({ [microsite.id!]: open })
									}}
									overlayStyle={{
										width: 50,
										height: 100,
										backgroundColor: colors.shadowBackground,
										borderRadius: 14
									}}
								>
									<Tooltip title={t("options")}>
										<Button
											type="default"
											ghost
											style={{
												backgroundColor: colors.pressable,
												borderRadius: 100,
												display: "flex",
												flex: 1,
												justifyContent: "center",
												alignItems: "center",
												width: 43
											}}
										>
											<MoreOutlined
												rotate={90}
												style={{
													fontSize: 22,
													display: "flex",
													transform: `
                          rotate(${open[microsite.id!] ? 90 : 0}deg) 
                          translateX(${open[microsite.id!] ? 2 : 2}px)
                          translateY(${open[microsite.id!] ? 0 : 0}px)
                        `,
													transition: "transform 0.3s ease"
												}}
											/>
										</Button>
									</Tooltip>
								</Dropdown>
							</div>
						)
					}
				})}
			/>
		</div>
	)
}

export default observer(MicrositesTable)
