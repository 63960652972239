import { Multicurrency } from "../../data/dto/MultiCurrency.dto"
import { ServiceSessionTranslation } from "./ServiceSessionTranslation"

export type SERVICE_STATUS = "blocked" | "available"
interface ConstructorParams {
	id?: string
	title: string
	title_en?: string
	title_ca?: string
	description?: string
	description_en?: string
	description_ca?: string
	subtitle?: string
	subtitle_en?: string
	subtitle_ca?: string
	multicurrency?: Multicurrency[]
	price: number
	outprice: number
	position?: string
}

export class ServiceSession {
	public id?: string
	public key?: string
	public title: string
	public title_en?: string
	public title_ca?: string
	public multicurrency?: Multicurrency[]
	public description?: string
	public description_en?: string
	public description_ca?: string
	public subtitle?: string
	public subtitle_en?: string
	public subtitle_ca?: string
	public price: number
	public outprice: number
	public updated?: boolean
	public position?: string

	constructor(params: ConstructorParams) {
		this.id = params.id
		this.title = params.title
		this.title_en = params.title_en
		this.title_ca = params.title_ca
		this.multicurrency = params.multicurrency
		this.description = params.description
		this.description_en = params.description_en
		this.description_en = params.description_ca
		this.subtitle = params.subtitle
		this.subtitle_en = params.subtitle_en
		this.subtitle_ca = params.subtitle_ca
		this.price = params.price
		this.outprice = params.outprice
		this.position = params.position
	}
}
