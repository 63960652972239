import { Input, InputProps, Tooltip, Typography } from "antd"
import ActivityIndicator from "../ActivityIndicator/ActivityIndicator"
import { InputType } from "./const/InputType.enum"

interface TextInputProps extends InputProps {
	Icon?: any
	toolTipText?: string
	placeHolder?: string
	title?: string
	inputValue?: string | number
	inputType?: InputType
	disabled?: boolean
	onClickIcon?: () => void
	loading?: boolean
}

const InputContainer = ({ textArea, ...props }: any) => {
	switch (props.type) {
		case InputType.PASSWORD:
			return <Input.Password {...props} />
		case InputType.TEXTAREA:
			return <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} {...props} />
		default:
			return <Input {...props} />
	}
}

const TextInput = ({
	Icon,
	toolTipText,
	placeHolder,
	title,
	inputValue,
	inputType,
	disabled,
	onClickIcon,
	loading,
	...props
}: TextInputProps) => {
	return (
		<>
			{title && <Typography.Text>{title}</Typography.Text>}
			<InputContainer
				type={inputType || InputType.TEXT}
				value={inputValue}
				placeholder={placeHolder}
				{...props}
				disabled={disabled}
			/>
			{Icon ? (
				<Tooltip className="hover:cursor-pointer" title={toolTipText}>
					<Icon onClick={() => onClickIcon && onClickIcon()} className="absolute top-2 right-4" />
				</Tooltip>
			) : (
				loading && <ActivityIndicator size="xs" />
			)}
		</>
	)
}

export default TextInput
