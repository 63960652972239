import { Form, Input, InputRef } from "antd"
import { EditableContext, Item } from "./EditableRow"
import { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { InputType } from "../../TextInput/const/InputType.enum"

interface EditableCellProps {
	title: React.ReactNode
	editable: boolean
	inputType: InputType
	children: React.ReactNode
	dataIndex: keyof Item
	record: Item
	handleSave: (record: Item) => void
	onAddRow: () => void
}

const EditableCell: React.FC<EditableCellProps> = ({
	title,
	editable,
	inputType,
	children,
	dataIndex,
	record,
	handleSave,
	onAddRow,
	...restProps
}) => {
	const { t } = useTranslation("common")
	const [editing, setEditing] = useState(false)
	const inputRef = useRef<InputRef>(null)
	const form = useContext(EditableContext)!

	useEffect(() => {
		if (editing) {
			inputRef.current?.focus()
		}
	}, [editing])

	const toggleEdit = () => {
		setEditing(!editing)
		form.setFieldsValue({ [dataIndex]: record[dataIndex] })
	}

	const save = async () => {
		try {
			const values = await form.validateFields()
			toggleEdit()
			handleSave({ ...record, ...values })
		} catch (errInfo) {
			console.log("Save failed:", errInfo)
		}
	}

	let childNode = children

	if (editable) {
		childNode = editing ? (
			<Form.Item
				className="m-0"
				name={dataIndex}
				rules={[
					{
						required: true,
						message: t("requiredField")
					}
				]}
			>
				<Input
					className="w-[5.5rem] absolute bottom-[0rem] right-5"
					placeholder={typeof title === "string" ? t("inputPlaceholder") : undefined}
					ref={inputRef}
					onPressEnter={save}
					onBlur={save}
					// inputMode="numeric"
					type={inputType}
				/>
			</Form.Item>
		) : (
			<div className="editable-cell-value-wrap" onClick={toggleEdit} style={{ paddingRight: 24 }}>
				{children}
			</div>
		)
	}

	return <td {...restProps}>{childNode}</td>
}

export default EditableCell
