import { VoucherRepository } from "../../../repositories/voucherRepository"
import { UseCase } from "../../useCase"
import { VoucherServiceRequest } from "./request"
import { VoucherResponse } from "./response"

export class CreateVoucherUseCase implements UseCase<VoucherServiceRequest, VoucherResponse> {
	private _repository
	constructor({ VoucherRepository }: { VoucherRepository: VoucherRepository }) {
		this._repository = VoucherRepository
	}

	exec(voucher: VoucherServiceRequest) {
		return this._repository.createVoucher(voucher)
	}
}
