import { Microsite, MicrositeService as MicrositeServiceEntity } from "../../domain/entities/Microsite"
import { MicrositeRepository } from "../../domain/repositories/micrositeRepository"
import { Language } from "../../locale/interfaces/Translation"
import { MicrositeModel } from "../models/MicrositeModel"
import { MicrositeService } from "../services/MicrositeService"

export class MicrositeRepositoryImpl implements MicrositeRepository {
	private _micrositeService
	constructor({ MicrositeService }: { MicrositeService: MicrositeService }) {
		this._micrositeService = MicrositeService
	}

	public async getMicrosites(): Promise<Microsite[]> {
		const micrositeModel = await this._micrositeService.getMicrosites()
		return micrositeModel.map(microsite => microsite.toDomain())
	}

	public async createMicrosite(microsite: Microsite): Promise<Microsite> {
		const micrositeModel = await this._micrositeService.createMicrosite(MicrositeModel.fromDomain(microsite))
		return micrositeModel.toDomain()
	}

	public async updateMicrosite(microsite: Microsite): Promise<Microsite> {
		const micrositeModel = await this._micrositeService.updateMicrosite(MicrositeModel.fromDomain(microsite))
		return micrositeModel.toDomain()
	}

	public async deleteMicrosite(id: string): Promise<void> {
		return this._micrositeService.deleteMicrosite(id)
	}

	public async deleteMicrositeService(micrositeId: string, serviceId: string): Promise<void> {
		await this._micrositeService.deleteMicrositeService(micrositeId, serviceId)
	}

	public async getMicrositeById(id: string, translation?: Language): Promise<Microsite> {
		const microsite = await this._micrositeService.getMicrositeById(id, translation)
		return microsite.toDomain()
	}

	public async getMicrositeServices(id: string): Promise<MicrositeServiceEntity[]> {
		const micrositeServices = await this._micrositeService.getMicrositeServices(id)
		return micrositeServices.toDomain().services
	}

	public async uploadMicrositeImage(uploadData: any): Promise<{ file_path: string }> {
		const response = await this._micrositeService.uploadMicrositeImage(uploadData)
		return { file_path: response.file_path }
	}

	public async uploadMicrositeDetailImage(uploadData: any): Promise<{ file_path: string }> {
		const response = await this._micrositeService.uploadMicrositeDetailImage(uploadData)
		return { file_path: response.file_path }
	}
}
