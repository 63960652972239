import { CategoryService } from "../services/CategoryService"
import { CategoryRepository } from "../../domain/repositories/categoryRepository"
import { Category } from "../../domain/entities/Category"
import { CategoryModel } from "../models/CategoryModel"
import { CategoryIcon } from "../../domain/entities/CategoryIcon"

export class CategoryRepositoryImpl implements CategoryRepository {
	private _categoryService
	constructor({ CategoryService }: { CategoryService: CategoryService }) {
		this._categoryService = CategoryService
	}

	public async getCategories(): Promise<Category[]> {
		const categoryModel = await this._categoryService.getCategoriesList()
		return categoryModel.map(category => category.toDomain())
	}

	public async createCategory(category: Category): Promise<Category> {
		const categoryModel = await this._categoryService.createCategory(CategoryModel.fromDomain(category))
		return categoryModel.toDomain()
	}

	public async updateCategory(category: Category): Promise<Category> {
		const categoryModel = await this._categoryService.updateCategory(CategoryModel.fromDomain(category))
		return categoryModel.toDomain()
	}

	public async deleteCategory(category: Category): Promise<Category> {
		const categoryModel = await this._categoryService.deleteCategory(CategoryModel.fromDomain(category))
		return categoryModel.toDomain()
	}

	public async getCategoryDetail(categoryId: string): Promise<Category> {
		const categoryModel = await this._categoryService.getCategoryDetail(categoryId)
		return categoryModel.toDomain()
	}

	public async getCategoryIcons(): Promise<CategoryIcon[]> {
		const categoryIconModel = await this._categoryService.getCategoryIcons()
		return categoryIconModel.map(categoryIcon => categoryIcon.toDomain())
	}
}
