import { Button, Typography } from "antd"
import React from "react"
import { colors } from "../../../assets/styles/appStyles"

interface CustomMenuButtonProps {
	title: string
	leftIcon?: React.ReactElement
	containerStyle?: React.CSSProperties
	textStyle?: React.CSSProperties
	fullWidth?: boolean
	disabled?: boolean
	onClick?: () => void
}

const CustomMenuButton = ({
	title,
	leftIcon,
	fullWidth,
	disabled,
	containerStyle,
	textStyle,
	onClick
}: CustomMenuButtonProps) => {
	const disabledStyle = disabled ? { color: colors.disabled, cursor: "not-allowed" } : {}

	//Cloned leftIcon ReactNode and add style
	const LeftIcon =
		leftIcon &&
		React.cloneElement(leftIcon, {
			style: { fontSize: 16, ...leftIcon.props.style, ...disabledStyle }
		})

	return (
		<Button
			onClick={() => onClick && onClick()}
			disabled={disabled}
			type="default"
			ghost
			style={{ boxShadow: "none", ...(fullWidth && { width: "100%" }), ...disabledStyle, ...containerStyle }}
		>
			<div style={{ display: "flex", position: "relative", left: 10 }}>
				{LeftIcon ? LeftIcon : null}
				<Typography.Text
					style={{ fontSize: 14, position: "relative", left: 18, ...textStyle, ...disabledStyle }}
				>
					{title}
				</Typography.Text>
			</div>
		</Button>
	)
}

export default CustomMenuButton
