import MainForm, { MainFormRef } from "../../components/Form/MainForm"
import { createRef, useEffect, useMemo, useState } from "react"
import { Button, Col, Row, Switch } from "antd"
import { Seller } from "../../../domain/entities/Seller"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { SellerServicesViewModel } from "./SellerServicesViewModel"
import { observer } from "mobx-react"
import { useLocation, useNavigate } from "react-router-dom"
import ServiceInformationFormSection from "./components/ServiceInformationFormSection"
import DataTable from "../../components/DataTable/DataTable"
import TABLE_COLUMNS from "./const/SESSIONS_TABLE_COLUMNS"
import { ServiceSession } from "../../../domain/entities/ServiceSesssion"
import { DeleteFilled, EditFilled } from "@ant-design/icons"
import { useConfirmModal } from "../../service/confirmModal"
import { toJS } from "mobx"
import useUpdateEffect from "../../hooks/useUpdateEffect"
import { RoutePaths } from "../../navigation/RoutePaths.enum"
import { ServiceConfigurationFormSection } from "./components/ServiceConfigurationFormSection"
import ServiceSessionModal from "./components/ServiceSessionModal"
import { useTranslation } from "react-i18next"
import { SellerServiceDetail } from "../../../domain/entities/SellerServiceDetail"
import { useToastMessage } from "../../service/toastMessage"
import ServiceAvailabilityFormSection, { ServiceAvailabilityFormRef } from "./components/ServiceAvailabilityFormSection"
import DataTableRowsSkeleton from "../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"
import { FormField } from "../../components/Form/FormField"
import { Card } from "../../styledComponents/CustomCard/Card"

export type EditableServiceSession = ServiceSession & { editting?: boolean }

const SellerServicesFormView = () => {
	const { t } = useTranslation("services", { keyPrefix: "form" })
	const { t: tToast } = useTranslation("toast")
	const navigate = useNavigate()
	const { show, context: toastContext } = useToastMessage()
	const { showConfirm, context: modalContext } = useConfirmModal()
	const locationState: { service: SellerServiceDetail } = useLocation().state
	const formRef = createRef<MainFormRef>()
	const serviceAvailavitityFormRef = createRef<ServiceAvailabilityFormRef>()
	const viewModel = useContainerInjection<SellerServicesViewModel>("SellerServicesViewModel")

	useUpdateEffect(() => {
		const selectedService = toJS(viewModel.selectedService)
		const newFormData = {
			...structuredClone(selectedService),
			calendarAvailability: selectedService.calendarAvailability
		}
		setFormData(newFormData)
		setOriginalFormData(newFormData)
		setEditMode(selectedService.id !== undefined)
	}, [viewModel.selectedService])

	const initialFormData: Partial<SellerServiceDetail> = {
		...locationState?.service,
		countryId: locationState?.service?.countryId || "ES",
		highlighted: locationState?.service?.highlighted || false,
		serviceSessions: locationState?.service?.serviceSessions || []
	}

	const [formData, setFormData] = useState(initialFormData)
	const [originalFormData, setOriginalFormData] = useState(initialFormData)
	const [editMode, setEditMode] = useState(locationState?.service?.id !== undefined)
	const [modalVisible, setSessionModalVisible] = useState(false)
	const [sessionData, setSessionData] = useState<EditableServiceSession | undefined>(undefined)

	const formModified = () => {
		const formDataSorted = {
			...formData,
			serviceSession: formData?.serviceSessions?.sort((a, b) => (a.id! > b.id! ? 1 : -1))
		}
		const originalFormDataSorted = {
			...originalFormData,
			serviceSession: originalFormData?.serviceSessions?.sort((a, b) => (a.id! > b.id! ? 1 : -1))
		}
		return JSON.stringify(formDataSorted) === JSON.stringify(originalFormDataSorted)
	}

	const onSubmitForm = async () => {
		await formRef.current?.customValidateFields(() => {
			if (!formData.isOnline && !formData.isPresential) {
				throw { message: t("validations.onlineOrFaceToFace") }
			}
			if (formData?.serviceClusters?.length === 0 || !formData?.serviceClusters) {
				throw { message: t("validations.cluster") }
			}
			if (formData?.calendarAvailability?.some(slot => slot.error)) {
				throw { message: t("validations.calendarSlots") }
			}
			if (formData?.serviceCalendar !== undefined && formData?.serviceCalendar?.calendarId !== "") {
				if (!formData?.serviceCalendar?.timeZone || formData?.serviceCalendar?.timeZone === "") {
					throw { message: t("validations.timeZone") }
				} else if (formData.calendarAvailability?.length === 0 || !formData.calendarAvailability) {
					throw { message: t("validations.calendar") }
				}
			}
			if (!formData.picture || !formData.pictures || formData.pictures.length === 0) {
				throw { message: t("validations.serviceImages") }
			}
		})
		if (editMode) {
			const deletedSessions = originalFormData.serviceSessions?.filter(
				session => !formData.serviceSessions?.find(sessionFound => sessionFound.id === session.id)
			)
			await viewModel.updateService(formData, deletedSessions)
		} else {
			await viewModel.createService(formData)
		}
	}

	// const onCancelForm = () => {
	//   setFormData(structuredClone(originalFormData))
	// }

	const style = !viewModel.isLoadingDetail
		? {
				card: { backgroundColor: formData.seller ? undefined : "rgba(0,0,0,0.2)" }
		  }
		: undefined

	useEffect(() => {
		if (editMode) {
			viewModel.fetchServiceDetail(formData.id!)
		}
		if (viewModel.sellers.length === 0) {
			viewModel.fetchSellers()
		}
	}, [])

	const memoizedDataSource =
		useMemo(() => {
			return (
				formData.serviceSessions?.length &&
				formData.serviceSessions.map((session, index) => {
					const priceEUR = session?.multicurrency?.find(
						value => value.currencyId === Number(viewModel.currencies[0].id)
					)?.price
					const priceMXN = session?.multicurrency?.find(
						value => value.currencyId === Number(viewModel.currencies[1].id)
					)?.price
					return {
						key: session.id,
						...session,
						priceEUR: priceEUR !== undefined ? priceEUR : "-",
						priceMXN: priceMXN !== undefined ? priceMXN : "-",
						actions: (
							<div style={{ columnGap: 10 }}>
								<DeleteFilled
									style={{ fontSize: "1.5rem", color: "red" }}
									onClick={() => handleDeleteSession(session)}
								/>
								<EditFilled
									style={{ fontSize: "1.5rem", color: "gray" }}
									onClick={() => handleEditSession(session)}
								/>
							</div>
						)
					}
				})
			)
		}, [formData?.serviceSessions]) || []

	const dataSource = memoizedDataSource?.length ? memoizedDataSource : []

	const handleEditSession = (session: ServiceSession) => {
		setSessionData({ ...session, updated: true })
		setSessionModalVisible(true)
	}

	const handleDeleteSession = (session: ServiceSession & any) => {
		const index = session.id ? "id" : "key"
		showConfirm({
			title: tToast("deleteConfirmMessage", { value: "session" }),
			onConfirm: async () => {
				setFormData({
					...formData,
					serviceSessions: formData.serviceSessions?.filter((item: ServiceSession & any) => {
						return item[index] !== session[index]
					})
				})
			},
			type: "danger"
		})
	}

	const handleConfirm = (data: EditableServiceSession & any) => {
		const key = data.id ? "id" : "key"
		const index = formData.serviceSessions?.findIndex(
			(session: ServiceSession & any) => data[key] && session[key] === data[key]
		)
		if (index !== undefined && index !== -1) {
			const newServiceSession = {
				...formData.serviceSessions![index],
				...data,
				updated: !!data?.id
			}
			const newFormData = [...formData.serviceSessions!]
			newFormData.splice(index, 1, newServiceSession)
			setFormData({
				...formData,
				serviceSessions: newFormData
			})
			viewModel.updateServiceSessionState(data)
		} else {
			const lastKey =
				formData.serviceSessions?.sort((a, b) => (a.key! > b.key! ? 1 : -1))?.[
					formData.serviceSessions?.length - 1
				]?.key || 0
			const newServiceSession: ServiceSession = {
				...data,
				key: Number(lastKey) + 1
			}
			if (formData?.serviceSessions?.length) {
				setFormData({
					...formData,
					serviceSessions: [...formData.serviceSessions, newServiceSession]
				})
			} else {
				setFormData({ ...formData, serviceSessions: [newServiceSession] })
			}
		}
		setSessionData(undefined)
		setSessionModalVisible(false)
	}

	const handleDeleteService = (sellerServiceId: string) => {
		showConfirm({
			title: tToast("deleteConfirmMessage", { value: "service" }),
			onConfirm: async () => {
				show({
					key: "serviceDeleting",
					type: "loading",
					content: tToast("deleting")
				})
				await viewModel.deleteSelectedService()
				navigate(RoutePaths.SELLER_SERVICES)
				show({
					key: "serviceDeleting",
					type: "success",
					content: tToast("deleteSuccess")
				})
			},
			type: "danger"
		})
	}

	return (
		<div>
			<MainForm
				onSubmitForm={onSubmitForm}
				formModified={!formModified()}
				initialValues={{
					...formData,
					...formData.serviceCalendar
				}}
				ref={formRef}
			>
				{modalContext}
				{toastContext}
				<Row gutter={[24, 0]}>
					<Col xl={24} md={24} xs={24}>
						<Card
							bordered={false}
							className="criclebox tablespace mb-24"
							title={
								<div>
									<h6 className="font-semibold m-0">{t("sellerInfo.cardTitle")}</h6>
								</div>
							}
							bodyStyle={{ padding: "24px" }}
						>
							<FormField.SelectSearch
								label={t("sellerInfo.seller").toString()}
								loading={viewModel.isLoadingSellers}
								key={"seller"}
								name={"seller"}
								disabled={viewModel.isLoading || !!editMode}
								placeholder={"Select a seller"}
								style={{ display: "flex" }}
								//@ts-ignore
								value={{ value: formData?.seller?.sellerName, label: formData?.seller?.sellerName }}
								options={viewModel.sellers.map((seller: Seller) => ({
									value: seller.id,
									label: seller.sellerName,
									key: seller.id
								}))}
								onChange={seller => {
									setFormData({
										...formData,
										//@ts-ignore
										seller: { ...formData.seller, id: seller.value, sellerName: seller?.label }
									})
								}}
							/>
						</Card>

						{/* SERVICE */}
						<Card
							bordered={false}
							className="criclebox tablespace mb-24"
							title={
								<div>
									<h6 className="font-semibold m-0">{t("serviceInfo.cardTitle")}</h6>
									{editMode ? (
										<Button
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												position: "absolute",
												right: "2.5rem",
												top: "1rem"
											}}
											type="link"
											danger
											onClick={() => handleDeleteService(formData.id!)}
										>
											<DeleteFilled style={{ fontSize: "1.5rem" }} />
										</Button>
									) : null}
								</div>
							}
							style={style && style.card}
							bodyStyle={{ padding: "24px" }}
						>
							<ServiceInformationFormSection
								editMode={editMode}
								onChangeText={value => {
									setFormData({ ...formData, ...value })
								}}
								data={{
									serviceClusters: formData?.serviceClusters,
									endorsedUser: { id: formData?.endorsedUserId, name: formData?.endorsedName }
								}}
								picture={formData?.picture}
								pictures={formData?.pictures}
								formTextInputProps={{
									isPresential: formData.isPresential,
									hideEditIcon: true,
									disabled: editMode ? false : formData.seller ? false : true
								}}
							/>
						</Card>
						<Row gutter={[24, 0]}>
							<Col md={12} xl={18} xs={24} className="flex">
								<Card
									loading={viewModel.isLoadingDetail}
									bordered={false}
									className="criclebox tablespace mb-24 flex-1"
									title={
										<div className="flex gap-4 items-center">
											<h6 className="font-semibold m-0">{t("availability.cardTitle")}</h6>
											<Switch
												checked={formData.showAgenda}
												onChange={value => {
													setFormData({ ...formData, showAgenda: value })
													!value
														? serviceAvailavitityFormRef.current?.closeCollapseMenu()
														: serviceAvailavitityFormRef.current?.enableCollapseMenu()
												}}
											/>
										</div>
									}
									bodyStyle={{
										paddingRight: "24px",
										paddingBottom: "24px",
										paddingLeft: "24px"
									}}
									style={style && { ...style.card }}
								>
									<ServiceAvailabilityFormSection
										ref={serviceAvailavitityFormRef}
										calendarSchedule={formData.calendarAvailability}
										calendarData={formData.serviceCalendar}
										disabled={formData.seller === undefined || !formData.showAgenda}
										onChange={values => setFormData({ ...formData, ...values })}
									/>
								</Card>
							</Col>
							<Col md={12} xl={6} xs={24}>
								<Card
									bordered={false}
									className="criclebox tablespace mb-24"
									title={
										<div>
											<h6 className="font-semibold m-0">{t("configInfo.cardTitle")}</h6>
										</div>
									}
									bodyStyle={{ padding: "24px", paddingBottom: "64px" }}
									style={style?.card}
								>
									<ServiceConfigurationFormSection
										values={formData}
										onChange={value => setFormData({ ...formData, ...value })}
										disabled={!formData.seller}
									/>
								</Card>
							</Col>
						</Row>
						<Row gutter={[24, 0]}>
							<Col xl={24} md={24} xs={24}>
								<Card
									bordered={false}
									className="criclebox tablespace mb-24"
									title={
										<div style={{ display: "flex", alignItems: "center" }}>
											<h6 className="font-semibold m-0">{t("sessionsInfo.cardTitle")}</h6>
											<Button
												disabled={!formData.seller}
												onClick={() => {
													setSessionData(undefined)
													setSessionModalVisible(true)
												}}
												type="primary"
												style={{
													marginLeft: "1.5vw",
													height: "34px",
													display: "flex",
													alignItems: "center"
												}}
											>
												{t("sessionsInfo.addNew")}
											</Button>
										</div>
									}
									style={style && style.card}
								>
									<DataTable
										loading={viewModel.isLoadingDetail}
										renderCustomLoadingComponent={() => <DataTableRowsSkeleton />}
										disabled={!formData.seller}
										columns={TABLE_COLUMNS()}
										dataSource={dataSource}
									/>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</MainForm>

			<ServiceSessionModal
				data={sessionData}
				currencies={viewModel.currencies}
				visible={modalVisible}
				onConfirm={handleConfirm}
				onCancel={() => {
					setSessionData(undefined)
					setSessionModalVisible(false)
				}}
			/>
		</div>
	)
}

export default observer(SellerServicesFormView)
