import { UseCase } from "../../useCase"
import { UpdateServiceSessionResponse } from "./response"
import { UpdateServiceSessionRequest } from "./request"
import { ServiceSessionRepository } from "../../../repositories/serviceSessionRepository"

export class UpdateServiceSessionUseCase implements UseCase<UpdateServiceSessionRequest, UpdateServiceSessionResponse> {
	private _repository
	constructor({ ServiceSessionRepository }: { ServiceSessionRepository: ServiceSessionRepository }) {
		this._repository = ServiceSessionRepository
	}

	exec({ session, serviceId }: UpdateServiceSessionRequest) {
		return this._repository.updateServiceSession(session, serviceId)
	}
}
