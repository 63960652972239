import { CategoryRepository } from "./../../../repositories/categoryRepository"
import { UseCase } from "../../useCase"
import { GetCategoriesResponse } from "./response"
import { GetCategoriesRequest } from "./request"

export class GetCategoriesUseCase implements UseCase<GetCategoriesRequest, GetCategoriesResponse> {
	private _repository
	constructor({ CategoryRepository }: { CategoryRepository: CategoryRepository }) {
		this._repository = CategoryRepository
	}

	exec() {
		return this._repository.getCategories()
	}
}
