import type { SellerServicesRepository } from "../../../repositories/sellerServicesRepository"
import { UseCase } from "../../useCase"
import { SearchSellersServicesResponse } from "./response"
import { SearchSellersServicesRequest } from "./request"

export class SearchSellersServicesUseCase
	implements UseCase<SearchSellersServicesRequest, SearchSellersServicesResponse>
{
	private _repository
	constructor({ SellerServicesRepository }: { SellerServicesRepository: SellerServicesRepository }) {
		this._repository = SellerServicesRepository
	}

	exec({ expression, offset, limit }: SearchSellersServicesRequest) {
		return this._repository.searchSellerServices(expression, offset, limit)
	}
}
