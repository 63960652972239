import { CategoryRepository } from "./../../../repositories/categoryRepository"
import { UseCase } from "../../useCase"
import { DeleteCategoryResponse } from "./response"
import { DeleteCategoryRequest } from "./request"

export class DeleteCategoryUseCase implements UseCase<DeleteCategoryRequest, DeleteCategoryResponse> {
	private _repository
	constructor({ CategoryRepository }: { CategoryRepository: CategoryRepository }) {
		this._repository = CategoryRepository
	}

	async exec(category: DeleteCategoryRequest) {
		return await this._repository.deleteCategory(category)
	}
}
