import { Button, Col, Row, Typography } from "antd"
import MicrositesTable from "./components/MicrositesTable"
import { useNavigate } from "react-router-dom"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { MicrositesViewModel } from "./MicrositesViewModel"
import { RoutePaths } from "../../navigation/RoutePaths.enum"
import { observer } from "mobx-react"
import SearchFilterField from "../../components/SearchFilterField/SearchFilterField"
import { Microsite } from "../../../domain/entities/Microsite"
import { Language } from "../../../locale/interfaces/Translation"
import { useTranslation } from "react-i18next"
import { Card } from "../../styledComponents/CustomCard/Card"

const { Title } = Typography

const MicrositesView = () => {
	const { t } = useTranslation("microsites")
	const viewModel = useContainerInjection<MicrositesViewModel>("MicrositesViewModel")
	const navigate = useNavigate()
	const filterFunction = (value: Partial<Microsite>, searchInput: string | undefined) => {
		return searchInput && isNaN(parseInt(searchInput))
			? searchInput
				? !(
						value?.sellerName?.toLowerCase().indexOf(searchInput.toLowerCase()) === -1 &&
						value?.slug?.toLowerCase().indexOf(searchInput.toLowerCase()) === -1
				  )
				: true
			: searchInput
			? !(value?.id?.toString().indexOf(searchInput) === -1)
			: true
	}

	const initialMicrosite: Partial<Microsite> = {
		sellerName: "",
		slug: "",
		popupText: "",
		fontType: "",
		externalLink: "",
		lang: Language.esES,
		color: Microsite.DEFAULT_COLOR,
		contrastColor: Microsite.DEFAULT_COLOR,
		infoIcon: Microsite.DEFAULT_INFO_ICON,
		showLogo: true,
		clusters: [],
		services: []
	}

	return (
		<>
			<SearchFilterField<Microsite>
				value={viewModel.searchValue}
				placeholder={t("searchBar").toString()}
				searchData={viewModel.microsites}
				filterFunction={filterFunction}
				onChange={value => {
					viewModel.setSearchedMicroSite(value)
				}}
				onChangeText={value => (viewModel.searchValue = value)}
				style={{ marginBottom: "10px" }}
			/>
			<div className="tabled">
				<Row gutter={[24, 0]}>
					<Col xs="24" xl={24}>
						<Card
							bordered={false}
							className="criclebox tablespace mb-24"
							title={
								<div style={{ display: "flex", alignItems: "center" }}>
									<Title style={{ marginTop: 8 }} level={4}>
										{t("title")}
									</Title>
									<Button
										style={{ marginLeft: "1.5vw" }}
										type="primary"
										onClick={() =>
											navigate(RoutePaths.MICROSITES_ADD_NEW, {
												state: { microsite: initialMicrosite }
											})
										}
									>
										{t("addNew")}
									</Button>
								</div>
							}
						>
							<MicrositesTable data={viewModel.searchedMicroSite} loading={viewModel.isLoading} />
						</Card>
					</Col>
				</Row>
			</div>
		</>
	)
}

export default observer(MicrositesView)
