import { observer } from "mobx-react"
import { Microsite } from "../../../../domain/entities/Microsite"
import { Col, Row } from "antd"
import Switch from "../../../components/Switch/Switch"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { MicrositesViewModel } from "../MicrositesViewModel"
import { Currency } from "../../../../domain/entities/Currency"
import { CustomBasicSelector as Selector } from "../../../components/CustomSelectors/CustomBasicSelector"
import { PartnerType } from "../../../../domain/entities/PartnerType"
import { useTranslation } from "react-i18next"
import MultiSwitch from "../../../components/Switch/MultiSwitch"
import { Language, LanguageTranslation } from "../../../../locale/interfaces/Translation"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import { FormField } from "../../../components/Form/FormField"

interface MicrositeConfigurationFormSectionProps {
	data?: Partial<Microsite>
	onChange: (value: any) => void
	disabled?: boolean
}

const MicrositeConfigurationFormSection = ({ data, onChange, disabled }: MicrositeConfigurationFormSectionProps) => {
	const viewModel = useContainerInjection<MicrositesViewModel>("MicrositesViewModel")
	const { t } = useTranslation("microsites", { keyPrefix: "form.configInfo" })
	const { t: tToolTips } = useTranslation("toolTips")
	const { t: tCommon } = useTranslation("common")
	return (
		<Row gutter={[24, 0]}>
			<Col xl={24} md={24} xs={24} className="gap-2 flex flex-col">
				<Switch
					title={t("showLogo").toString()}
					checked={data?.showLogo}
					onChange={showLogo => onChange && onChange({ showLogo })}
					disabled={disabled}
				/>
				<div>
					<FormField.Input
						inputType={InputType.NUMBER}
						key={"feePercentage"}
						label={t("feePercentage")}
						name={"feePercentage"}
						preFix={"%"}
						onChange={(feePercentage: string) =>
							onChange && onChange({ feePercentage: feePercentage ? Number(feePercentage) : undefined })
						}
						style={{ marginBottom: "9px" }}
						customFieldValidation={
							data?.feePercentage && (Number(data.feePercentage) < 0 || Number(data.feePercentage) > 100)
								? {
										status: "error",
										message: tCommon("invalidField").toString()
								  }
								: { status: "", message: "" }
						}
						customFieldPosition={"relative"}
					/>
				</div>
				<Selector
					mandatory
					toolTipTitle={tToolTips("currencySelector").toString()}
					label={t("currency").toString()}
					onChange={currency => onChange && onChange({ currency })}
					options={viewModel?.currencies.map((currency: Currency) => {
						const label =
							currency.symbolPosition === "before"
								? `${currency.symbol}${currency.isoCode}`
								: `${currency.isoCode}${currency.symbol}`
						return {
							value: currency.id,
							label,
							key: currency.id
						}
					})}
					disabled={disabled}
					value={data?.currency?.toString() || undefined}
				/>
				<Selector
					style={{
						maxWidth: "99px"
					}}
					mandatory
					toolTipTitle={tToolTips("partnerTypeSelector").toString()}
					label={t("partnerType").toString()}
					onChange={micrositeType => onChange && onChange({ micrositeType })}
					options={viewModel?.parthnersTypes.map((partnerType: PartnerType) => {
						return {
							value: partnerType.id,
							label: `${partnerType.typeName} - ${partnerType.description}`,
							key: partnerType.id
						}
					})}
					disabled={disabled}
					popupMatchSelectWidth={false}
					value={data?.micrositeType || undefined}
				/>
				<Selector
					style={{
						maxWidth: "99px"
					}}
					mandatory
					toolTipTitle={tToolTips("businessType").toString()}
					label={t("businessType").toString()}
					onChange={businessType => onChange && onChange({ settings: { ...data?.settings, businessType } })}
					options={viewModel?.businessTypes.map(businessType => ({
						value: businessType.id.toString(),
						label: businessType.label,
						key: businessType.id
					}))}
					disabled={disabled}
					popupMatchSelectWidth={false}
					// value={viewModel?.businessTypes.find(businessType => businessType.id === data?.setting?.businessType)?.label}
					value={data?.settings?.businessType?.toString()}
				/>
				<Selector
					style={{
						width: "150px"
					}}
					mandatory
					mode="multiple"
					toolTipTitle={tToolTips("availableLanguages").toString()}
					label={t("availableLanguages").toString()}
					disabled={disabled}
					popupMatchSelectWidth={false}
					options={
						Object.values(Language).map(lang => ({
							label: LanguageTranslation[lang as keyof typeof LanguageTranslation] ?? lang,
							value: lang,
							disabled: data?.lang === lang
						})) ?? []
					}
					maxTagCount="responsive"
					onChange={availableLanguages => onChange && onChange({ availableLanguages })}
					value={data?.availableLanguages ?? [Language.esES]}
				/>
				<MultiSwitch
					label={t("locale").toString()}
					options={
						Object.values(Language).map(lang => ({
							label: LanguageTranslation[lang as keyof typeof LanguageTranslation] ?? lang,
							value: lang,
							disabled: !data?.availableLanguages?.includes(lang)
						})) ?? []
					}
					value={data?.lang || Language.esES}
					onChange={lang => onChange && onChange({ lang })}
					buttonsStyles={{ width: 50 }}
				/>
				<div style={{ paddingTop: "10px" }}>
					<Switch
						title={t("hasUserAuth").toString()}
						checked={data?.settings?.hasUserAuth}
						onChange={hasUserAuth => onChange && onChange({ settings: { ...data?.settings, hasUserAuth } })}
						disabled={disabled}
					/>
				</div>
				<Switch
					title={t("creditsEnabled").toString()}
					checked={data?.pointsEnabled}
					onChange={pointsEnabled => onChange && onChange({ pointsEnabled })}
					disabled={disabled}
				/>
				<Switch
					title={t("showWhatsapp").toString()}
					checked={data?.settings?.showWhatsapp}
					onChange={showWhatsapp => onChange && onChange({ settings: { ...data?.settings, showWhatsapp } })}
					disabled={disabled}
					containerStyles={{ marginBottom: 0 }}
				/>
				{data?.settings?.showWhatsapp && (
					<FormField.Input
						inputType={InputType.TEL}
						key={"whatsappNumber"}
						label={t("whatsappNumber")}
						name={"whatsappNumber"}
						preFix={"+"}
						onChange={(whatsappNumber: string) => onChange && onChange({ whatsappNumber })}
						customFieldPosition={"relative"}
					/>
				)}
			</Col>
		</Row>
	)
}

export default observer(MicrositeConfigurationFormSection)
