import { googleLogout } from "@react-oauth/google"
import { Settings } from "../../stores/Settings"
import { action, makeObservable, observable } from "mobx"
import { GoogleLoginUseCase } from "../../../domain/useCases/auth/googleSignIn"

interface ConstructorProps {
	Settings: Settings
	GoogleLoginUseCase: GoogleLoginUseCase
}

export class SettingsViewModel {
	private _settingsStore: Settings
	private _googleLoginUseCase: GoogleLoginUseCase
	isLoading: boolean = false
	loggedIn: boolean = false

	constructor({ Settings, GoogleLoginUseCase }: ConstructorProps) {
		makeObservable(this, {
			loggedIn: observable,
			isLoading: observable,
			setIsLoading: action,
			setLoggedIn: action
		})
		this._settingsStore = Settings
		this._googleLoginUseCase = GoogleLoginUseCase
	}

	public async googleLogIn(code: string) {
		this.setIsLoading(true)
		try {
			const response = await this._googleLoginUseCase.exec({ code })
			return response
		} catch (error) {
			console.error(error)
		} finally {
			this.setIsLoading(false)
		}
	}

	public googleLogOut() {
		googleLogout()
		this.setLoggedIn(false)
	}

	setIsLoading(loading: boolean) {
		this.isLoading = loading
	}

	setLoggedIn(loggedIn: boolean) {
		this.loggedIn = loggedIn
	}
}
