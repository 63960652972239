import dayjs from "dayjs"
import redSeal from "../../ui/assets/icons/redSeal"
import blackSeal from "../../ui/assets/icons/blackSeal"
import { File } from "../types/File.type"

interface ContructorParams {
	id: string
	name: string
	userName: string
	webSite: string
	birthdate: string
	picture: string & File
	email: string
	password?: string
	language: string
	phoneNumber: string
	city: string
	goal: string
	bio: string
	status: string
	role: string
	endorserTypeKey?: string
	roleAttributes?: { microsites?: number[]; reports?: string[] }
}
export class User {
	public id: string
	public name: string
	public userName: string
	public webSite: string
	public birthdate: string
	public picture: string & File
	public email: string
	public password?: string
	public language: string
	public phoneNumber: string
	public city: string
	public goal: string
	public bio: string
	public status: string
	public role: string
	public roleAttributes?: { microsites?: number[]; reports?: string[] }
	public endorserTypeKey?: string
	static readonly birthdateFormat = "YYYY-MM-DD"
	static readonly BACKEND_POSSIBLE_DATE_FORMATS = [
		"DD-MM-YYYY",
		"DD/MM/YYYY",
		"YYYY-MM-DD",
		"MM-DD-YYYY",
		"MM/DD/YYYY",
		"DD-MM-YY",
		"DD/MM/YY",
		"MM-DD-YY",
		"MM/DD/YY",
		"MM.DD.YYYY",
		"D-M-YYYY",
		"D/M/YYYY",
		"D/M/YY",
		"D-M-YY",
		"M-D-YYYY",
		"M/D/YYYY",
		"M/D/YY",
		"M.D.YYYY"
	]
	public static endorserSeals: { id: number; value: string; key: string; icon: string }[] = [
		{ id: 1, value: "red", key: "red", icon: redSeal },
		{ id: 2, value: "black", key: "black", icon: blackSeal }
	]
	public static roles: { value: string; key: string }[] = [
		{ value: "ROLE_USER", key: "user" },
		{ value: "ROLE_ADMIN", key: "admin" },
		{ value: "ROLE_PARTNER", key: "partner" },
		{ value: "ROLE_SELLER", key: "seller" }
	]
	public static status: { value: string; key: string }[] = [
		{ value: "active", key: "active" },
		{ value: "code_validated", key: "code_validated" },
		{ value: "cancel-account", key: "cancel_account" },
		{ value: "validation_code", key: "validation_code" }
	]
	public static DEFAULT_PASSWORD = "********"

	constructor(params: ContructorParams) {
		this.id = params.id
		this.name = params.name
		this.userName = params.userName
		this.webSite = params.webSite
		this.birthdate = params.birthdate
		this.picture = params.picture
		this.email = params.email
		this.password = params.password || User.DEFAULT_PASSWORD
		this.language = params.language
		this.phoneNumber = params.phoneNumber
		this.city = params.city
		this.goal = params.goal
		this.bio = params.bio
		this.status = params.status || User.status[0].value
		this.role = params.role || User.roles[0].value
		this.endorserTypeKey = params.endorserTypeKey
		this.roleAttributes = params.roleAttributes
	}

	public static sanitizeDate = (date?: string) => {
		return dayjs(date, User.BACKEND_POSSIBLE_DATE_FORMATS)
	}
}
