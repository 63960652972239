import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"

export default () => {
	const { t } = useTranslation("clusters", { keyPrefix: "form.dataTableHeaders" })

	return columns(t)
}

const columns = (t: TFunction) => [
	{
		key: "id",
		title: "ID",
		dataIndex: "id",
		sorteable: true
	},
	{
		key: "category",
		title: t("category"),
		dataIndex: "category"
	},
	{
		key: "country",
		title: t("country"),
		dataIndex: "country",
		filter: true
	},
	{
		key: "highlighted",
		title: t("highlighted"),
		dataIndex: "highlighted",
		filter: true
	},
	{
		key: "position",
		title: t("position"),
		dataIndex: "position",
		sorteable: true,
		autoSort: false,
		width: "10%",
		align: "center"
	},
	{
		key: "parent",
		title: t("parent"),
		dataIndex: "parent",
		filter: true
	},
	{
		key: "micrositesAssociated",
		title: t("micrositesAssociated"),
		dataIndex: "micrositesAssociated",
		filter: true
	},
	{
		title: "",
		key: "editButton",
		dataIndex: "editButton"
	}
]
