import { Card, Row, Typography } from "antd"
import { observer } from "mobx-react"
import BannerImagesForm from "./components/BannerImagesForm"
import { useEffect, useState } from "react"
import { BannersFormViewModel } from "./BannersFormViewModel"
import { BannerForm } from "./entities/BannerForm"
import ActivityIndicator from "../ActivityIndicator/ActivityIndicator"
import { colors } from "../../assets/styles/appStyles"

interface BannersFormViewProps {
	title: string
	microsite: string
	language: string
	banners: BannerForm[]
	notCollapse?: boolean
	isLoading?: boolean
	onBannerChange?: (banners: BannerForm[]) => void
}

const { Title } = Typography

const BannersFormView = ({
	title,
	microsite,
	banners,
	language,
	notCollapse,
	isLoading,
	onBannerChange
}: BannersFormViewProps) => {
	const [formViewModel] = useState(() => new BannersFormViewModel({ microsite, banners }))
	const [isCollapsed, setIsCollapsed] = useState(notCollapse ? false : true)
	useEffect(() => formViewModel.setBanners(banners), [banners])
	useEffect(() => formViewModel.setSelectedLanguage(language), [language])
	useEffect(() => formViewModel.setMicrosite(microsite), [microsite])
	useEffect(() => onBannerChange && onBannerChange(formViewModel.banners), [formViewModel.banners])
	return (
		<Card
			bordered={false}
			className="criclebox tablespace"
			title={
				<Row justify="space-between" align="middle">
					<Title style={{ marginTop: 8 }} level={4}>
						{title}
					</Title>
					{!notCollapse && (
						<div
							style={{
								border: "1px solid #8f8f8f",
								paddingLeft: 4,
								paddingRight: 4,
								color: "#8f8f8f",
								cursor: "pointer"
							}}
							onClick={() => setIsCollapsed(value => !value)}
						>
							{isCollapsed ? "+" : "-"}
						</div>
					)}
				</Row>
			}
		>
			{!isCollapsed && (
				<>
					<BannerImagesForm main={true} viewModel={formViewModel}></BannerImagesForm>
					<BannerImagesForm main={false} viewModel={formViewModel}></BannerImagesForm>
				</>
			)}
			{isLoading && <ActivityIndicator styles={{ margin: 10 }} size="xl" color={colors.activity} />}
		</Card>
	)
}
export default observer(BannersFormView)
