import { UseCase } from "../../useCase"
import { ImportUsersResponse } from "./response"
import { ImportUsersRequest } from "./request"
import { UserRepository } from "../../../repositories/userRepository"

export class ImportUsersUseCase implements UseCase<ImportUsersRequest, ImportUsersResponse> {
	private _repository
	constructor({ UserRepository }: { UserRepository: UserRepository }) {
		this._repository = UserRepository
	}

	exec(file: ImportUsersRequest) {
		return this._repository.importUsers(file)
	}
}
