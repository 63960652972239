import { SellerService } from "../../domain/entities/SellerService"

interface ContructorParams {
	city: string
	address?: string
	contact: string
	contact_telf: string
	email: string
	id: number
	name: string
	title: string
	weight: number
}

export class SellerServicesModel {
	public city: string
	public address?: string
	public contact: string
	public contact_telf: string
	public email: string
	public id: number
	public weight: number
	public name: string
	public title: string

	constructor(params: ContructorParams) {
		this.city = params.city
		this.address = params.address
		this.contact = params.contact
		this.contact_telf = params.contact_telf
		this.email = params.email
		this.id = params.id
		this.name = params.name
		this.title = params.title
		this.weight = params.weight
	}

	static fromRawJson = (rawJson: any): SellerServicesModel => {
		const { id, email, contact_telf, name, title, city, address, contact, weight } = rawJson
		return new SellerServicesModel({
			id,
			email,
			contact_telf,
			name,
			title,
			city,
			address,
			weight,
			contact
		})
	}

	static fromDomain = (service: SellerService): SellerServicesModel => {
		return new SellerServicesModel({
			...service,
			city: service.city,
			contact: service.contact,
			contact_telf: service.contact_telf,
			email: service.email,
			id: service.id,
			name: service.name,
			title: service.title
		})
	}
}

declare module "./SellerServicesModel" {
	interface SellerServicesModel {
		toDomain(): SellerService
	}
}

SellerServicesModel.prototype.toDomain = function (): SellerService {
	const data = this
	return new SellerService({
		...data,
		city: data.city,
		address: data.address,
		contact: data.contact,
		contact_telf: data.contact_telf,
		email: data.email,
		id: data.id,
		name: data.name,
		weight: data.weight,
		title: data.title
	})
}
