import { Row, Col, Button, Typography } from "antd"
import { useNavigate } from "react-router-dom"
import { SellersViewModel } from "./SellersViewModel"
import { observer } from "mobx-react"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import SellerTable from "./components/SellerTable"
import { RoutePaths } from "../../navigation/RoutePaths.enum"
import SearchFilterField from "../../components/SearchFilterField/SearchFilterField"
import { Seller } from "../../../domain/entities/Seller"
import { useTranslation } from "react-i18next"
import { Card } from "../../styledComponents/CustomCard/Card"

const { Title } = Typography

const Sellers = () => {
	const { t } = useTranslation("sellers")
	const navigate = useNavigate()
	const viewModel = useContainerInjection<SellersViewModel>("SellersViewModel")
	const filterFunction = (value: Partial<Seller>, searchInput: string | undefined) => {
		return searchInput && isNaN(parseInt(searchInput))
			? searchInput
				? !(
						value?.sellerName?.toLowerCase().indexOf(searchInput.toLowerCase()) === -1 &&
						value?.userEmail?.toLowerCase().indexOf(searchInput.toLowerCase()) === -1 &&
						value?.contact?.toLowerCase().indexOf(searchInput.toLowerCase()) === -1 &&
						value?.web?.toLowerCase().indexOf(searchInput.toLowerCase()) === -1
				  )
				: true
			: searchInput
			? !(value?.id?.toString().indexOf(searchInput) === -1)
			: true
	}

	const initialSeller: Partial<Seller> = {
		userEmail: "",
		id: undefined,
		sellerName: "",
		nif: "",
		iban: "",
		contact: "",
		phone: "",
		organization: "",
		web: "",
		description: "",
		weekdays: "",
		expYears: 0,
		degrees: ""
	}

	return (
		<>
			<SearchFilterField<Seller>
				value={viewModel.searchValue}
				placeholder={t("searchBar").toString()}
				searchData={viewModel.sellers}
				filterFunction={filterFunction}
				onChange={value => {
					viewModel.setSearchedSellers(value)
				}}
				onChangeText={value => (viewModel.searchValue = value)}
				style={{ marginBottom: "10px" }}
			/>
			<div className="tabled">
				<Row gutter={[24, 0]}>
					<Col xs="24" xl={24}>
						<Card
							bordered={false}
							className="criclebox tablespace mb-24"
							title={
								<div style={{ display: "flex", alignItems: "center" }}>
									<Title style={{ marginTop: 8 }} level={4}>
										{t("title")}
									</Title>
									<Button
										style={{ marginLeft: "1.5vw" }}
										type="primary"
										onClick={() =>
											navigate(RoutePaths.SELLERS_ADD_NEW, { state: { seller: initialSeller } })
										}
									>
										{t("addNew")}
									</Button>
								</div>
							}
						>
							<SellerTable data={viewModel.searchedSellers} loading={viewModel.isLoading} />
						</Card>
					</Col>
				</Row>
			</div>
		</>
	)
}

export default observer(Sellers)
