import { useEffect } from "react"
import { observer } from "mobx-react"
import { ErrorHandler } from "./ErrorHandler"
import { useContainerInjection } from "../hooks/useContainerInjection"
import { useToastMessage } from "../service/toastMessage"

const ErrorListener = () => {
	const errorHandler = useContainerInjection<ErrorHandler>("ErrorHandler")
	const { show, context } = useToastMessage()
	const message = errorHandler.errorMessage

	useEffect(() => {
		if (!message) return
		show({
			content: message,
			type: "error"
		})
	}, [message])

	return <>{context}</>
}

export default observer(ErrorListener)
