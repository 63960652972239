import SelectSearchField, { SelectSearchFieldProps } from "../SelectSearchField/SelectSearchField"
import { FormDatePicker, FormDatePickerProps } from "./FormDatePicker"
import FormSelector, { FormSelectorProps } from "./FormSelector"
import FormTextInput, { FormTextInputProps } from "./FormTextInput"

enum FormFieldType {
	Input,
	SearchSelect,
	Select,
	DatePicker
}

interface FormFieldProps {
	type: FormFieldType
}
export type Mandatory = boolean | { validator?: boolean }

const FormFieldWrapper = ({ type, ...props }: FormFieldProps) => {
	if (type === FormFieldType.Input) {
		return <FormTextInput {...props} />
	}

	if (type === FormFieldType.SearchSelect) {
		return <SelectSearchField {...(props as SelectSearchFieldProps)} />
	}

	if (type === FormFieldType.Select) {
		return <FormSelector {...props} />
	}

	if (type === FormFieldType.DatePicker) {
		return <FormDatePicker {...props} />
	}

	return null
}

export const FormField = {
	Select: (props: FormSelectorProps) => <FormFieldWrapper type={FormFieldType.Select} {...props} />,
	Input: (props: FormTextInputProps) => <FormFieldWrapper type={FormFieldType.Input} {...props} />,
	DatePicker: (props: FormDatePickerProps) => <FormFieldWrapper type={FormFieldType.DatePicker} {...props} />,
	SelectSearch: (props: SelectSearchFieldProps) => <FormFieldWrapper type={FormFieldType.SearchSelect} {...props} />
}
