import { UseCase } from "../../useCase"
import { GetTransactionsResponse } from "./response"
import { GetTransactionsRequest } from "./request"
import { TransactionsRepository } from "../../../repositories/TransactionsRepository"

export class GetTransactionsUseCase implements UseCase<GetTransactionsRequest, GetTransactionsResponse> {
	private _repository
	constructor({ TransactionsRepository }: { TransactionsRepository: TransactionsRepository }) {
		this._repository = TransactionsRepository
	}

	exec({ pagination, limit, filter }: GetTransactionsRequest) {
		return this._repository.getTransactions(pagination, limit, filter)
	}
}
