import { observer } from "mobx-react"
import { Category } from "../../../../domain/entities/Category"
import DataTable from "../../../components/DataTable/DataTable"
import TABLE_COLUMNS from "../const/TABLE_COLUMNS"
import { EditFilled } from "@ant-design/icons"
import { Tag, Tooltip, Typography } from "antd"
import { Link } from "react-router-dom"
import { RoutePaths } from "../../../navigation/RoutePaths.enum"
import { toJS } from "mobx"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { CategoriesViewModel } from "../CategoriesViewModel"
import { useTranslation } from "react-i18next"
import { colors } from "../../../assets/styles/appStyles"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"

interface CategoryTableProps {
	data: Partial<Category>[]
}

const CategoryTable = ({ data }: CategoryTableProps) => {
	const viewModel = useContainerInjection<CategoriesViewModel>("CategoriesViewModel")
	const { t: tTooltips } = useTranslation("toolTips")
	const { t: tCountries } = useTranslation("countries")
	const { t: tCommon } = useTranslation("common")

	return (
		<DataTable
			loading={viewModel.isLoading}
			tableConfig={{
				sort: viewModel.tableConfig.sort,
				pageSize: viewModel.tableConfig.pageSize
			}}
			renderCustomLoadingComponent={() => <DataTableRowsSkeleton size="lg" />}
			onChange={data => {
				data.sorter &&
					viewModel.setTableConfig({ sort: { order: data.sorter.order, field: data.sorter.field } })
			}}
			onChangePage={(page, pageSize: number, lastPageFetched) => viewModel.setTableConfig({ pageSize })}
			columns={TABLE_COLUMNS()}
			columnFilters={{
				parent: viewModel.extractParentCategories().map(category => {
					const label: string = category?.label || ""
					return {
						id: category?.id,
						text: label,
						value: label
					}
				}),
				micrositesAssociated: viewModel.extractMicrosites().map(slug => {
					const label: string = slug
					return {
						text: label,
						value: label
					}
				}),
				country: viewModel.countries.map(country => {
					const label: string = tCountries(`${country.id}`)
					return {
						text: label,
						value: label
					}
				}),
				highlighted: [
					{
						text: tCommon("yes"),
						value: tCommon("yes")
					},
					{
						text: tCommon("no"),
						value: tCommon("no")
					}
				]
			}}
			dataSource={data.map((category, idx) => {
				const getColor = (labelLength: number) =>
					labelLength > 18 ? "geekblue" : labelLength > 12 ? "cyan" : "blue"
				return {
					key: category.id,
					id: category.id,
					category: category.label || category.picture,
					country: (
						<div style={{ width: "4.5rem", display: "flex", justifyContent: "center" }}>
							{tCountries(`${category.countryId}`)}
						</div>
					),
					micrositesAssociated: (() => {
						const microsites = category.micrositesAssociated || []
						return (
							<div
								style={{
									minWidth: "11rem",
									maxWidth: "20rem",
									flexWrap: "wrap",
									display: "flex"
								}}
							>
								{microsites?.length
									? microsites?.map((microsite, idx) => (
											<Tag
												style={{ marginBottom: 3 }}
												key={idx}
												color={getColor(microsite.slug.length)}
											>
												{`${microsite.id} - ${microsite.slug}`}
											</Tag>
									  ))
									: null}
							</div>
						)
					})(),
					highlighted: (
						<Typography.Text
							style={{
								color: category.highlighted ? colors.success : colors.error,
								fontWeight: "500",
								width: "5rem",
								display: "flex",
								justifyContent: "center"
							}}
						>
							{category.highlighted ? tCommon("yes") : tCommon("no")}
						</Typography.Text>
					),
					position: category.position,
					parent: (() => {
						const parentLabel = viewModel.getParentCategoryLabel(category.parentId)
						const labelLength = parentLabel?.length || 0
						return (
							<div style={{ width: "12rem" }}>
								{parentLabel.length > 0 ? (
									<Tag color={getColor(labelLength)} key={category.id}>
										{parentLabel.toUpperCase()}
									</Tag>
								) : null}
							</div>
						)
					})(),
					editButton: (
						<div className="ant-employed">
							<Link to={RoutePaths.CATEGORIES_ADD_NEW} state={{ category: toJS(category) }}>
								<Tooltip title={tTooltips("edit")}>
									<EditFilled />
								</Tooltip>
							</Link>
						</div>
					)
				}
			})}
		/>
	)
}

export default observer(CategoryTable)
