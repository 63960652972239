import { observer } from "mobx-react"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { PowerBIEmbed } from "powerbi-client-react"
import { models, Report } from "powerbi-client"
import { ReportsViewModel } from "./ReportsViewModel"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import ActivityIndicator from "../../components/ActivityIndicator/ActivityIndicator"
import { colors } from "../../assets/styles/appStyles"

const ReportsView = () => {
	const viewModel = useContainerInjection<ReportsViewModel>("ReportsViewModel")
	const location = useLocation()
	const navigate = useNavigate()
	useEffect(() => {
		if (!location.state?.menuItem) {
			loadReport()
		}
	}, [location.state])
	const loadReport = async () => {
		const embedInfo = await viewModel.getReports()
		navigate(".", {
			replace: true,
			state: {
				menuItem: {
					...embedInfo.embedUrl[0],
					id: embedInfo.embedUrl[0].reportId,
					label: embedInfo.embedUrl[0].reportName,
					accessToken: embedInfo.accessToken,
					expiry: embedInfo.expiry
				}
			}
		})
	}
	return (
		<>
			{location.state?.menuItem ? (
				<PowerBIEmbed
					embedConfig={{
						type: "report",
						id: location.state.menuItem.reportId,
						embedUrl: location.state.menuItem.embedUrl,
						accessToken: location.state.menuItem.accessToken,
						tokenType: models.TokenType.Embed,
						settings: {
							panes: {
								filters: {
									expanded: false,
									visible: false
								}
							},
							background: models.BackgroundType.Transparent
						}
					}}
					eventHandlers={
						new Map([
							[
								"loaded",
								function () {
									console.log("Report loaded")
								}
							],
							[
								"rendered",
								function () {
									console.log("Report rendered")
								}
							],
							[
								"error",
								function (event) {
									console.log(event?.detail)
								}
							],
							["visualClicked", () => console.log("visual clicked")],
							["pageChanged", event => console.log(event)]
						])
					}
					cssClassName={"embedded-admin-report"}
					getEmbeddedComponent={embeddedReport => {
						viewModel.setEmbeddedAdminReport(embeddedReport as Report)
					}}
				/>
			) : (
				<ActivityIndicator size="xl" color={colors.activity} />
			)}
		</>
	)
}

export default observer(ReportsView)
