import { Col, ColorPicker, Row, Space } from "antd"
import type { Color, ColorPickerProps } from "antd/es/color-picker"
import { useMemo, useState } from "react"
import useUpdateEffect from "../../hooks/useUpdateEffect"

interface CustomColorPickerProps {
	type?: "hex" | "hsb" | "rgb"
	label?: string
	value?: Color | string
	onChange?: (value: string) => void
}

const defaultLabel = { hex: "HEX", hsb: "HSB", rgb: "RGB" }
const defaultHexValue = "#1677ff"
const defaultHsbValue = "hsb(215, 91%, 100%)"
const defaultRgbValue = "rgb(22, 119, 255)"

const CustomColorPicker = ({ label, type = "hex", value, onChange }: CustomColorPickerProps) => {
	useUpdateEffect(() => {
		if (value) {
			if (typeof value === "string") {
				if (value.startsWith("rgb")) {
					setColorRgb(value)
				} else if (value.startsWith("hsb")) {
					setColorHsb(value)
				} else {
					setColorHex(value)
				}
			} else {
				setColorHex(value.toHexString())
				setColorHsb(value.toHsbString())
				setColorRgb(value.toRgbString())
			}
		} else {
			setColorHex(defaultHexValue)
			setColorHsb(defaultHsbValue)
			setColorRgb(defaultRgbValue)
		}
	}, [value])

	const [colorHex, setColorHex] = useState<Color | string>(value || defaultHexValue)
	const [colorHsb, setColorHsb] = useState<Color | string>(value || defaultHsbValue)
	const [colorRgb, setColorRgb] = useState<Color | string>(value || defaultRgbValue)
	const [formatHex, setFormatHex] = useState<ColorPickerProps["format"]>("hex")
	const [formatHsb, setFormatHsb] = useState<ColorPickerProps["format"]>("hsb")
	const [formatRgb, setFormatRgb] = useState<ColorPickerProps["format"]>("rgb")

	const hexString = useMemo(() => (typeof colorHex === "string" ? colorHex : colorHex.toHexString()), [colorHex])

	const hsbString = useMemo(() => (typeof colorHsb === "string" ? colorHsb : colorHsb.toHsbString()), [colorHsb])

	const rgbString = useMemo(() => (typeof colorRgb === "string" ? colorRgb : colorRgb.toRgbString()), [colorRgb])

	return (
		<Space direction="vertical" size="middle" style={{ display: "flex" }}>
			{label && <span>{label}</span>}
			{type === "hex" && (
				<Row align="middle">
					<Space>
						<Col>
							<ColorPicker
								format={formatHex}
								value={colorHex}
								onChange={(value, hex) => {
									onChange && onChange(hex)
									setColorHex(value)
								}}
								onFormatChange={setFormatHex}
							/>
						</Col>
						<Col>
							{defaultLabel[type]}:{" "}
							<span>
								{hexString?.toUpperCase()} {!value && "(Default color)"}
							</span>
						</Col>
					</Space>
				</Row>
			)}
			{type === "hsb" && (
				<Row align="middle">
					<Space>
						<Col>
							<ColorPicker
								format={formatHsb}
								value={colorHsb}
								onChange={(value, hex) => {
									setColorHsb(value)
									onChange && onChange(hex)
								}}
								onFormatChange={setFormatHsb}
							/>
						</Col>
						<Col>
							{defaultLabel[type]}: <span>{hsbString?.toUpperCase()}</span>
						</Col>
					</Space>
				</Row>
			)}
			{type === "rgb" && (
				<Row align="middle">
					<Space>
						<Col>
							<ColorPicker
								format={formatRgb}
								value={colorRgb}
								onChange={(value, hex) => {
									setColorRgb(value)
									onChange && onChange(hex)
								}}
								onFormatChange={setFormatRgb}
							/>
						</Col>
						<Col>
							{defaultLabel[type]}: <span>{rgbString?.toUpperCase()}</span>
						</Col>
					</Space>
				</Row>
			)}
		</Space>
	)
}

export default CustomColorPicker
