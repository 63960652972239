import { CategoryRepository } from "./../../../repositories/categoryRepository"
import { UseCase } from "../../useCase"
import { GetCategoryDetailResponse } from "./response"
import { GetCategoryDetailRequest } from "./request"

export class GetCategoryDetailUseCase implements UseCase<GetCategoryDetailRequest, GetCategoryDetailResponse> {
	private _repository
	constructor({ CategoryRepository }: { CategoryRepository: CategoryRepository }) {
		this._repository = CategoryRepository
	}

	exec(categoryId: GetCategoryDetailRequest) {
		return this._repository.getCategoryDetail(categoryId)
	}
}
