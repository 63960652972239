import { VoucherRepository } from "../../../repositories/voucherRepository"
import { UseCase } from "../../useCase"
import { UpdateVoucherRequest } from "./request"
import { UpdateVoucherResponse } from "./response"

export class UpdateVoucherUseCase implements UseCase<UpdateVoucherRequest, UpdateVoucherResponse> {
	private _repository
	constructor({ VoucherRepository }: { VoucherRepository: VoucherRepository }) {
		this._repository = VoucherRepository
	}

	exec(voucher: UpdateVoucherRequest) {
		return this._repository.updateVoucher(voucher)
	}
}
