import { ErrorHandlerError } from "./ErrorHandlerError.interface"

export class BannerError extends Error implements ErrorHandlerError {
	public errorMessage: string
	public errorCode?: string
	public httpStatus: number
	static BANNERS_FETCH_ERROR = "There was an error fetching the banners"
	static BANNERS_CREATE_ERROR = "There was an error creating the banners"
	static BANNERS_UPDATE_ERROR = "There was an error updating the banners"

	constructor(error: ErrorHandlerError) {
		super(error.errorMessage)
		this.errorMessage = error.errorMessage
		this.httpStatus = error.httpStatus
		this.errorCode = error.errorCode
		this.name = "BannerError"
	}
}
