import { action, makeObservable, observable } from "mobx"
import { ErrorHandler } from "../../error/ErrorHandler"
import { TableConfig } from "../../components/DataTable/types/TableConfig"
import { ViewModel } from "../ViewModel"
import { UploadTransactionsUseCase } from "../../../domain/useCases/transactions/uploadTransactions"
import { UploadProgressStatus } from "../../../domain/enum/uploadProgressStatus.enum"
import { Filter } from "../../../domain/types/transactions/Filter"
import { Transaction } from "../../../domain/entities/Transaction"
import { GetTransactionsUseCase } from "../../../domain/useCases/transactions/getTransactions"
import { DownloadTransactionsUseCase } from "../../../domain/useCases/transactions/downloadTransactions"
import { FileService } from "../../../domain/services/FileService"

interface Props {
	ErrorHandler: ErrorHandler
	UploadTransactionsUseCase: UploadTransactionsUseCase
	GetTransactionsUseCase: GetTransactionsUseCase
	DownloadTransactionsUseCase: DownloadTransactionsUseCase
}
type ErrorType = "header" | "detail" | undefined
type Error = { message: string; type: ErrorType }

export class TransactionsViewModel extends ViewModel {
	private _errorHandler
	private _uploadTransactionsUseCase: UploadTransactionsUseCase
	private _getTransactionsUseCase: GetTransactionsUseCase
	private _downloadTransactionsUseCase: DownloadTransactionsUseCase
	isFetching: boolean = false
	isLoading: boolean = false
	isLoadingDetail: boolean = false
	progressData: { active: boolean; file?: File; message: string; status?: UploadProgressStatus } = {
		active: false,
		message: "",
		status: UploadProgressStatus.LOADING
	}
	uploadingFile: boolean = false
	searchedTransactions: Transaction[] = []
	error: Error = { message: "", type: undefined }
	editMode: boolean = false
	transactions: Transaction[] = []
	searchValue: string = ""
	tableConfig: TableConfig = {
		pageSize: 20,
		sort: { order: "descend", field: "id" }
	}
	tipTableFilters = {}

	constructor({
		ErrorHandler,
		UploadTransactionsUseCase,
		GetTransactionsUseCase,
		DownloadTransactionsUseCase
	}: Props) {
		super()
		makeObservable(this, {
			uploadingFile: observable,
			setUploadingFile: action,
			progressData: observable,
			setProgressData: action,
			isFetching: observable,
			isLoading: observable,
			isLoadingDetail: observable,
			transactions: observable,
			setTransactions: action,
			searchedTransactions: observable,
			setSearchedTransactions: action
		})
		this._errorHandler = ErrorHandler
		this._uploadTransactionsUseCase = UploadTransactionsUseCase
		this._getTransactionsUseCase = GetTransactionsUseCase
		this._downloadTransactionsUseCase = DownloadTransactionsUseCase
		this.fetchTransactions()
	}

	public async uploadTransactions(file: File) {
		try {
			this.setUploadingFile(true)
			const frmData = new FormData()
			frmData.append("file", file)
			const response = await this._uploadTransactionsUseCase.exec(frmData)
			return response
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setUploadingFile(false)
		}
	}

	public closeProgress() {
		this.setProgressData({ active: false, message: "", status: UploadProgressStatus.LOADING })
	}

	public async fetchTransactions(filter?: Filter) {
		try {
			this.setLoading(true)
			const transactions = await this._getTransactionsUseCase.exec({
				pagination: 0,
				limit: this.limit,
				filter
			})
			this.setTransactions(transactions)
			this.cleanPagination()
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async downloadTransactions(filter?: Filter) {
		try {
			this.setLoading(true)
			const file = await this._downloadTransactionsUseCase.exec({
				pagination: 0,
				limit: this.limit,
				filter
			})
			const fileService = new FileService()
			const dateString = new Date().toISOString().split("T")[0]
			fileService.dowloadFile(file, `Transactions_${dateString}.csv`)
		} catch (error: any) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	private cleanPagination() {
		this.pagination = 0
		this.setHasNextPage(true)
	}

	public async fetchPaginatedTransactions(filter?: Filter) {
		this.setIsFetching(true)
		try {
			const transactions = await this._getTransactionsUseCase.exec({
				pagination: this.pagination,
				limit: this.limit,
				filter
			})
			if (transactions.length > 0) {
				const combinedTransactions = [...transactions, ...this.transactions]
				this.setTransactions(combinedTransactions)
			}
			this.setHasNextPage(transactions.length > 0)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setIsFetching(false)
		}
	}

	// ACTIONS
	setTransactions(transactions: Transaction[]) {
		this.transactions = transactions
		this.setSearchedTransactions(transactions)
	}

	setLoading(isLoading: boolean) {
		this.isLoading = isLoading
	}

	setUploadingFile(uploadingFile: boolean) {
		this.uploadingFile = uploadingFile
	}

	setIsFetching(isFetching: boolean) {
		this.isFetching = isFetching
	}

	setProgressData(progressData: typeof this.progressData) {
		this.progressData = progressData
	}

	setEditMode(editMode: boolean) {
		this.editMode = editMode
	}

	setSearchedTransactions(transactions: Transaction[]) {
		this.searchedTransactions = transactions
	}

	setError(message: string, type: ErrorType) {
		this.error = { message, type }
	}

	setTableConfig(config: Partial<typeof this.tableConfig>) {
		this.tableConfig = { ...this.tableConfig, ...config }
	}
}
