import { UseCase } from "../../useCase"
import { UploadTransactionsResponse } from "./response"
import { UploadTransactionsRequest } from "./request"
import { TransactionsRepository } from "../../../repositories/TransactionsRepository"

export class UploadTransactionsUseCase implements UseCase<UploadTransactionsRequest, UploadTransactionsResponse> {
	private _repository
	constructor({ TransactionsRepository }: { TransactionsRepository: TransactionsRepository }) {
		this._repository = TransactionsRepository
	}

	exec(file: UploadTransactionsRequest) {
		return this._repository.uploadTransactions(file)
	}
}
