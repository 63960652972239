import { Col, Row } from "antd"
import { VoucherRules } from "../../../../data/dto/VoucherRules.dto"
import { CategoriesViewModel } from "../../categories/CategoriesViewModel"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { useTranslation } from "react-i18next"
import { Category } from "../../../../domain/entities/Category"
import { observer } from "mobx-react"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import { VoucherViewModel } from "../VoucherViewModel"
import { FormField } from "../../../components/Form/FormField"
import { MicrositesViewModel } from "../../microSites/MicrositesViewModel"

interface Props {
	inputDisabled?: boolean
	data: Partial<VoucherRules>
	onChangeText: (value: Partial<VoucherRules>) => void
}

const VoucherRulesFormSection = ({ data, onChangeText }: Props) => {
	const categoriesViewModel = useContainerInjection<CategoriesViewModel>("CategoriesViewModel")
	const voucherViewModel = useContainerInjection<VoucherViewModel>("VoucherViewModel")
	const micrositesViewModel = useContainerInjection<MicrositesViewModel>("MicrositesViewModel")
	const { t } = useTranslation("vouchers", { keyPrefix: "form" })
	const { t: tCountries } = useTranslation("countries")
	const getCategoriesDataLabels = (categories: string[] | undefined) => {
		return categoriesViewModel.categories
			.filter(category => categories?.includes(String(category.id)))
			.map(category => ({
				label: `${category.id} - ${category.label}` || "",
				value: category.id || ""
			}))
	}
	const getServicesDataLabels = (services?: string[]) => {
		return voucherViewModel.sellersServices
			.filter(service => services?.includes(String(service.id)))
			.map(service => {
				return {
					label: `${service.id} - ${service.title}` || "",
					value: service.id || ""
				}
			})
	}

	return (
		<>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"discount"}
						name={"discount"}
						inputType={InputType.NUMBER}
						mandatory
						label={t("voucherRuleInfo.discount")}
						initialValue={data.discount}
						onChange={discount => {
							onChangeText && onChangeText({ discount: Number(discount) })
						}}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.SelectSearch
						key={"discountType"}
						name={"discountType"}
						label={t("voucherRuleInfo.discountType.title").toString()}
						mandatory
						placeholder={t("voucherRuleInfo.discountTypeSelectorPlaceHolder").toString()}
						value={data.discountType?.toString()}
						options={voucherViewModel.discountTypes.map(discountType => ({
							value: discountType.value,
							label: t(`voucherRuleInfo.discountType.${discountType.label}`)
						}))}
						onChange={discountType =>
							//@ts-ignore
							onChangeText && onChangeText({ discountType: Number(discountType.value) })
						}
					/>
				</Col>
			</Row>

			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"usesPerUser"}
						name={"usesPerUser"}
						label={t("voucherRuleInfo.usesPerUser")}
						inputType={InputType.NUMBER}
						mandatory
						initialValue={data.usesPerUser}
						onChange={usesPerUser => {
							onChangeText && onChangeText({ usesPerUser: Number(usesPerUser) })
						}}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"totalUses"}
						name={"totalUses"}
						label={t("voucherRuleInfo.totalUses")}
						inputType={InputType.NUMBER}
						mandatory
						initialValue={data.totalUses}
						onChange={totalUses => {
							onChangeText && onChangeText({ totalUses: Number(totalUses) })
						}}
					/>
				</Col>
			</Row>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.SelectSearch
						key={"clusters"}
						name="clusters"
						label={t("voucherRuleInfo.clusters").toString()}
						mode="multiple"
						allowClear
						loading={categoriesViewModel.isLoading}
						disabled={categoriesViewModel.isLoading}
						placeholder={t("voucherRuleInfo.clusterSelectorPlaceHolder").toString()}
						value={getCategoriesDataLabels(data?.clusters?.split(","))}
						options={categoriesViewModel.categories.map((category: Category) => ({
							value: category.id,
							label: `${category.id} - ${category.label}`,
							key: category.id
						}))}
						onChange={clusterSelected => {
							if (Array.isArray(clusterSelected)) {
								onChangeText &&
									onChangeText({
										clusters: clusterSelected.length //@ts-ignore
											? clusterSelected?.map(cluster => cluster.value).join(",")
											: null,
										services: clusterSelected.length ? null : data.services
									})
							}
						}}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.SelectSearch
						key={"services"}
						name="services"
						label={t("voucherRuleInfo.services").toString()}
						mode="multiple"
						allowClear
						loading={voucherViewModel.isLoadingFields}
						disabled={voucherViewModel.isLoadingFields}
						value={getServicesDataLabels(data?.services?.split(","))}
						placeholder={t("voucherRuleInfo.serviceSelectorPlaceHolder").toString()}
						onChange={servicesSelected => {
							if (Array.isArray(servicesSelected)) {
								onChangeText &&
									onChangeText({
										services: servicesSelected.length //@ts-ignore
											? servicesSelected?.map(service => service.value).join(",")
											: null,
										clusters: servicesSelected.length ? null : data.clusters
									})
							}
						}}
						options={voucherViewModel.sellersServices.map(service => ({
							label: `${service.id} - ${service.title}` || "",
							value: service.id || ""
						}))}
					/>
				</Col>
			</Row>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.SelectSearch
						key={"microsite"}
						name="microsite"
						label={t("voucherRuleInfo.microsite").toString()}
						mode="multiple"
						allowClear
						loading={micrositesViewModel.isLoading}
						disabled={micrositesViewModel.isLoading}
						placeholder={t("voucherRuleInfo.micrositeSelectorPlaceHolder").toString()}
						value={
							data.microsites?.length
								? data.microsites?.split(",").map(microsite => Number(microsite))
								: []
						}
						options={micrositesViewModel.microsites.map(microsite => ({
							value: microsite.id,
							label: `${microsite.id} - ${microsite.slug}`
						}))}
						onChange={micrositeSelected => {
							if (Array.isArray(micrositeSelected)) {
								onChangeText &&
									onChangeText({
										microsites: micrositeSelected.length //@ts-ignore
											? micrositeSelected?.map(microsite => microsite.value).join(",")
											: null,
										countries: micrositeSelected.length ? null : data.countries
									})
							}
						}}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.SelectSearch
						key="countries"
						name="countries"
						allowClear
						mode="multiple"
						label={t("voucherRuleInfo.country").toString()}
						placeholder={t("voucherRuleInfo.countrySelectorPlaceHolder")}
						options={voucherViewModel.countries.map(country => ({
							value: country.id,
							label: tCountries(country.id),
							key: country.id
						}))}
						value={
							voucherViewModel.countries?.length ? data.countries?.split(",").map(country => country) : []
						}
						onChange={countriesSelected => {
							if (Array.isArray(countriesSelected)) {
								onChangeText &&
									onChangeText({
										countries: countriesSelected.length //@ts-ignore
											? countriesSelected?.map(country => country.value).join(",")
											: null,
										microsites: countriesSelected.length ? null : data.microsites
									})
							}
						}}
					/>
				</Col>
			</Row>
		</>
	)
}

export default observer(VoucherRulesFormSection)
