import { Banner } from "../../domain/entities/Banner"

interface ConstructorParams {
	id: number
	key: string
	image: string
	type: "mainBanner" | "secondaryBanner"
	subType?: string
	href: string
	weight: number
	language: string
}
export interface BannerCreationParams {
	key: string
	desktopImage: File
	mobileImage: File
	type: "mainBanner" | "secondaryBanner"
	subType?: string
	href?: string
	weight: number
	language: string
}
export class BannerModel {
	public id: number
	public key: string
	public image: string
	public type: "mainBanner" | "secondaryBanner"
	public subType?: string
	public href?: string
	public weight: number
	public language: string

	constructor(params: ConstructorParams) {
		this.id = params.id
		this.key = params.key
		this.image = params.image
		this.type = params.type
		this.subType = params.subType
		this.href = params.href
		this.weight = params.weight
		this.language = params.language
	}

	static fromRawJson = (rawJson: any): BannerModel => {
		const { id, image, key, type, subType, href, weight, language } = rawJson

		return new BannerModel({
			id,
			image,
			key,
			type,
			subType,
			href,
			weight,
			language
		})
	}
}

declare module "./BannerModel" {
	interface BannerModel {
		toDomain(): Banner
	}
}

BannerModel.prototype.toDomain = function (): Banner {
	const data = this
	return new Banner({
		id: data.id,
		key: data.key,
		image: data.image,
		type: data.type,
		subType: data.subType,
		href: data.href,
		weight: data.weight,
		language: data.language
	})
}
