import { UseCase } from "../../useCase"
import { CreateServiceSessionResponse } from "./response"
import { CreateServiceSessionRequest } from "./request"
import { ServiceSessionRepository } from "../../../repositories/serviceSessionRepository"

export class CreateServiceSessionUseCase implements UseCase<CreateServiceSessionRequest, CreateServiceSessionResponse> {
	private _repository
	constructor({ ServiceSessionRepository }: { ServiceSessionRepository: ServiceSessionRepository }) {
		this._repository = ServiceSessionRepository
	}

	exec({ serviceId, session }: CreateServiceSessionRequest) {
		return this._repository.createServiceSession(session, serviceId)
	}
}
