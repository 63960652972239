import { useEffect, useState } from "react"
import { Row, Col, Breadcrumb, Dropdown, Button, Avatar, Divider } from "antd"
import { NavLink, useNavigate } from "react-router-dom"
import { colors } from "../../assets/styles/appStyles"
import { useTranslation } from "react-i18next"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { AuthStore } from "../../stores/AuthStore"
import { TFunction } from "i18next"
import { HomeOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons"
import CustomMenuButton from "../Menu/Button/CustomMenuButton"
import { RoutePaths } from "../../navigation/RoutePaths.enum"
import { Roles } from "../../../domain/enum/roles.enum"

const Menu = ({ t, togglerFunc }: { t: TFunction; togglerFunc: (open: boolean) => void }) => {
	const navigate = useNavigate()
	const { onLogOut } = useContainerInjection<AuthStore>("AuthStore")
	const auth = useContainerInjection<AuthStore>("AuthStore")
	const hasAdminRole = auth.userData?.roles?.includes(Roles.ADMIN)

	return (
		<div
			className="ant-dropdown-menu"
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				width: 210,
				height: "100%",
				backgroundColor: colors.shadowBackground,
				justifyContent: "space-around"
			}}
		>
			<div>
				<CustomMenuButton
					title={t("home")}
					fullWidth
					leftIcon={<HomeOutlined style={{ color: "#000000" }} />}
					onClick={() => {
						navigate(hasAdminRole ? RoutePaths.CATEGORIES : RoutePaths.TRANSACTIONS)
						setTimeout(() => togglerFunc(false), 100)
					}}
				/>
				<CustomMenuButton
					title={t("settings")}
					leftIcon={<SettingOutlined style={{ color: "#000000" }} />}
					onClick={() => {
						navigate(RoutePaths.SETTINGS)
						setTimeout(() => togglerFunc(false), 100)
					}}
					fullWidth
				/>
			</div>
			<Divider style={{ margin: "6px 0px" }} />
			<CustomMenuButton
				title={t("signOut")}
				leftIcon={<LogoutOutlined style={{ color: "#FFFF" }} />}
				fullWidth
				containerStyle={{ backgroundColor: colors.primary }}
				textStyle={{ color: "#FFFF" }}
				onClick={() => onLogOut()}
			/>
		</div>
	)
}

const toggler = [
	<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" key={0}>
		<path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
	</svg>
]

interface HeaderProps {
	name: string
	subName: string
	onPress: () => void
}

const Header = ({
	// placement,
	name,
	subName,
	onPress // handleSidenavColor, handleSidenavType, handleFixedNavbar
}: HeaderProps) => {
	const { t } = useTranslation("menu")
	const { t: tProfile } = useTranslation("profile")
	const [open, setOpen] = useState(false)

	// const [visible, setVisible] = useState(false);
	// const [sidenavType, setSidenavType] = useState("transparent");

	useEffect(() => window.scrollTo(0, 0))

	// const showDrawer = () => setVisible(true);
	// const hideDrawer = () => setVisible(false);
	const subNameShowed = subName.substring(0).split("/").pop()
	return (
		<>
			<Row gutter={[24, 0]}>
				<Col span={24} md={6}>
					<Breadcrumb>
						{name
							.substring(0)
							.split("/")
							.map((item, index) => {
								if (index === name.substring(0).split("/").length - 1) {
									return null
								}
								return (
									<Breadcrumb.Item key={index}>
										<NavLink style={{ textTransform: "capitalize" }} to={item}>
											{t(item)}
										</NavLink>
									</Breadcrumb.Item>
								)
							})}
					</Breadcrumb>
					<div className="ant-page-header-heading">
						<span className="ant-page-header-heading-title" style={{ textTransform: "capitalize" }}>
							{subNameShowed && t(subNameShowed?.toString())}
						</span>
					</div>
				</Col>
				<Col span={24} md={18} className="header-control">
					<Dropdown
						dropdownRender={() => <Menu t={tProfile} togglerFunc={setOpen} />}
						trigger={["click"]}
						overlayStyle={{ height: 160 }}
						open={open}
						onOpenChange={open => setOpen(open)}
					>
						<a href="#profile" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
							<Avatar
								shape="circle"
								src={<UserOutlined style={{ position: "relative", top: 2 }} />}
								size={"large"}
								style={{ backgroundColor: colors.pressable }}
							/>
						</a>
					</Dropdown>
					<Button type="link" className="sidebar-toggler" onClick={() => onPress()}>
						{toggler}
					</Button>
				</Col>
			</Row>
		</>
	)
}

export default Header
