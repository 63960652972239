import { UseCase } from "../../useCase"
import { GetSellerServicesResponse } from "./response"
import { GetSellerServicesRequest } from "./request"
import { SellerServicesRepository } from "../../../repositories/sellerServicesRepository"

export class GetSellerServicesUseCase implements UseCase<GetSellerServicesRequest, GetSellerServicesResponse> {
	private _repository
	constructor({ SellerServicesRepository }: { SellerServicesRepository: SellerServicesRepository }) {
		this._repository = SellerServicesRepository
	}

	exec({ pagination, limit }: GetSellerServicesRequest) {
		return this._repository.getSellerServices(pagination, limit)
	}
}
