import { TipRepository } from "../../domain/repositories/tipRepository"
import { TipService } from "../services/TipService"
import { Tip } from "../../domain/entities/Tip"
import { TipModel } from "../models/TipModel"

export class TipRepositoryImpl implements TipRepository {
	private _tipService
	constructor({ TipService }: { TipService: TipService }) {
		this._tipService = TipService
	}

	public async getTips(
		pagination: number,
		limit: number,
		searchExpression?: string,
		filters?: Record<string, string[]>
	): Promise<Tip[]> {
		const tipModel = await this._tipService.getTips(pagination, limit, searchExpression, filters)
		return tipModel.map(tip => tip.toDomain())
	}

	public async createTip(tip: Tip): Promise<{ tipId: number }> {
		const tipModel = await this._tipService.createTip(TipModel.fromDomain(tip))
		return { tipId: tipModel.toDomain().id }
	}

	public async updateTip(tip: Tip): Promise<Tip> {
		const tipModel = await this._tipService.updateTip(TipModel.fromDomain(tip))
		return tipModel.toDomain()
	}

	public async deleteMicrositeTip(tipId: number, micrositeId: number): Promise<void> {
		await this._tipService.deleteMicrositeTip(tipId, micrositeId)
	}

	public async uploadTipMedia(
		tipId: number,
		file: File
	): Promise<{ code: string; message: string; mediaUrl: string; mediaPreview: string }> {
		return await this._tipService.uploadTipMedia(tipId, file)
	}
}
