interface ConstructorParams {
	id?: number
	locale: string
	title?: string
	description?: string
	hint?: string
	serviceId: string
}

export class ServiceTranslation {
	public id?: number
	public locale: string
	public title?: string
	public description?: string
	public hint?: string
	public serviceId: string

	constructor(params: ConstructorParams) {
		this.id = params.id
		this.locale = params.locale
		this.title = params.title
		this.description = params.description
		this.hint = params.hint
		this.serviceId = params.serviceId
	}
}
