import { Button, Col, Row, Switch, Typography } from "antd"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd"
import { BannerForm } from "../entities/BannerForm"
import { BannersFormViewModel } from "../BannersFormViewModel"
import BannerSelection from "./BannerSelection"
import { toJS } from "mobx"

const { Title } = Typography

interface BannerImagesFormProps {
	main: boolean
	viewModel: BannersFormViewModel
}

const BannerImagesForm = ({ main, viewModel }: BannerImagesFormProps) => {
	const { t } = useTranslation("banners")
	const addBanner = () => {
		const position = viewModel.getBanners(main ? "mainBanner" : "secondaryBanner").length + 1
		viewModel.addBanner(
			new BannerForm({
				weight: position,
				type: main ? "mainBanner" : "secondaryBanner",
				language: viewModel.selectedLanguage,
				key: viewModel.microsite
			})
		)
	}
	const dragBanner = (result: DropResult) => {
		if (!result.destination) return
		viewModel.reorderBanners(
			main ? "mainBanner" : "secondaryBanner",
			result.source.index,
			result.destination?.index
		)
	}
	return (
		<Row style={{ margin: 0, padding: 20 }}>
			<Col xl={24} md={24} xs={24}>
				<Row>
					<Title
						style={{ marginTop: 0, textDecoration: "underline", marginRight: 10, marginLeft: 10 }}
						level={5}
					>
						{main ? t("mainBanners") : t("secondaryBanners")}
					</Title>
					{main ? (
						<Button
							type="primary"
							style={{ paddingRight: 8, paddingLeft: 8, paddingTop: 0, paddingBottom: 0, height: 24 }}
							onClick={addBanner}
						>
							+
						</Button>
					) : (
						<Switch
							title={t("on")}
							checked={viewModel.showSecondaryBanners}
							onChange={value => viewModel.setShowSecondaryBanners(value)}
						/>
					)}
				</Row>
				{viewModel.banners && viewModel.selectedLanguage && (
					<DragDropContext onDragEnd={dragBanner}>
						<Droppable droppableId={`${viewModel.microsite}-${main ? "main" : "secondary"}`}>
							{(provided, snapshot) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									{(main || viewModel.showSecondaryBanners) &&
										viewModel
											.getBanners(main ? "mainBanner" : "secondaryBanner")
											.map((banner, index) => (
												<Draggable
													key={banner.viewId}
													draggableId={banner.viewId}
													index={index}
												>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
														>
															<BannerSelection
																banner={banner}
																canDelete={main && banner.weight > 1}
																onDelete={() => viewModel.removeBanner(banner)}
																viewModel={viewModel}
															/>
														</div>
													)}
												</Draggable>
											))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				)}
			</Col>
		</Row>
	)
}
export default observer(BannerImagesForm)
