import { ServiceSession } from "../../domain/entities/ServiceSesssion"
import { ServiceSessionRepository } from "../../domain/repositories/serviceSessionRepository"
import { ServiceSessionModel } from "../models/ServiceSessionModel"
import { ServiceSessionService } from "../services/ServiceSessionService"

export class ServiceSessionRepositoryImpl implements ServiceSessionRepository {
	private _serviceSessionService
	constructor({ ServiceSessionService }: { ServiceSessionService: ServiceSessionService }) {
		this._serviceSessionService = ServiceSessionService
	}

	public async deleteServiceSession(session: ServiceSession, serviceId: string): Promise<Partial<ServiceSession>> {
		const response = await this._serviceSessionService.deleteServiceSession(
			ServiceSessionModel.fromDomain(session),
			serviceId
		)
		return response.toDomain()
	}

	public async updateServiceSession(session: ServiceSession, serviceId: string): Promise<Partial<ServiceSession>> {
		const response = await this._serviceSessionService.updateServiceSession(
			ServiceSessionModel.fromDomain(session),
			serviceId
		)
		return response.toDomain()
	}

	public async createServiceSession(session: ServiceSession, serviceId: string): Promise<Partial<ServiceSession>> {
		const response = await this._serviceSessionService.createServiceSession(
			ServiceSessionModel.fromDomain(session),
			serviceId
		)
		return response.toDomain()
	}
}
