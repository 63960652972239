import { CalendarAvailability } from "../../domain/entities/CalendarAvailability"

export interface ContructorParams {
	id: string
	day: number
	startTime: number
	endTime: number
}

export class CalendarAvailabilityModel {
	public id: string
	public day: number
	public startTime: number
	public endTime: number

	constructor(params: ContructorParams) {
		this.id = params.id
		this.day = params.day
		this.startTime = params.startTime
		this.endTime = params.endTime
	}

	public static fromRawJson = (rawJson: any): CalendarAvailabilityModel => {
		return new CalendarAvailabilityModel({
			...rawJson
		})
	}

	public static fromDomain = (calendarAvailability: CalendarAvailability): CalendarAvailabilityModel => {
		return new CalendarAvailabilityModel({
			id: calendarAvailability.id,
			day: calendarAvailability.day,
			startTime: CalendarAvailabilityModel.parseDayJsToSeconds(calendarAvailability.start.toString()),
			endTime: CalendarAvailabilityModel.parseDayJsToSeconds(calendarAvailability.end.toString())
		})
	}

	public static parseSecondsToDate(seconds: number) {
		const hours = Math.floor(seconds / 3600)
		const minutes = Math.floor((seconds % 3600) / 60)
		const date = new Date()
		date.setHours(hours)
		date.setMinutes(minutes)
		date.setSeconds(0)
		return date
	}

	public static parseDayJsToSeconds(dateTime: string) {
		const date = new Date(dateTime)
		const hours = date.getHours()
		const minutes = date.getMinutes()
		return hours * 3600 + minutes * 60
	}
}

declare module "./CalendarAvailabilityModel" {
	interface CalendarAvailabilityModel {
		toDomain(): CalendarAvailability
	}
}

CalendarAvailabilityModel.prototype.toDomain = function (): CalendarAvailability {
	const data = this
	return new CalendarAvailability({
		id: data.id,
		day: data.day,
		start: CalendarAvailabilityModel.parseSecondsToDate(data.startTime),
		end: CalendarAvailabilityModel.parseSecondsToDate(data.endTime)
	})
}
