import { CalendarData } from "../../domain/entities/CalendarData"

export interface ContructorParams {
	calendarId: string | null
	duration: number
	timeZone: string
}

export class CalendarDataModel {
	public calendarId: string | null
	public duration: number
	public timeZone: string

	constructor(params: ContructorParams) {
		this.calendarId = params.calendarId
		this.duration = params.duration
		this.timeZone = params.timeZone
	}

	public static fromRawJson = (rawJson: any): CalendarDataModel => {
		return new CalendarDataModel({
			...rawJson
		})
	}

	public static fromDomain = (calendarData: CalendarData): CalendarDataModel => {
		return new CalendarDataModel({
			calendarId: calendarData.calendarId || null,
			duration: Number(calendarData.duration),
			timeZone: calendarData.timeZone
		})
	}
}

declare module "./CalendarDataModel" {
	interface CalendarDataModel {
		toDomain(): CalendarData
	}
}

CalendarDataModel.prototype.toDomain = function (): CalendarData {
	const data = this
	return new CalendarData({
		calendarId: data.calendarId,
		duration: String(data.duration),
		timeZone: data.timeZone
	})
}
