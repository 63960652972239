import { UseCase } from "../../useCase"
import { DeleteMicrositeTipResponse } from "./response"
import { DeleteMicrositeTipRequest } from "./request"
import { TipRepository } from "../../../repositories/tipRepository"

export class DeleteMicrositeTipUseCase implements UseCase<DeleteMicrositeTipRequest, DeleteMicrositeTipResponse> {
	private _repository
	constructor({ TipRepository }: { TipRepository: TipRepository }) {
		this._repository = TipRepository
	}

	exec(tip: DeleteMicrositeTipRequest) {
		return this._repository.deleteMicrositeTip(tip.tipId, tip.micrositeId)
	}
}
