import { DatePicker, Form, FormItemProps } from "antd"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { Mandatory } from "./FormField"
import { calcFormFieldValidationRule } from "../../utils/calFormFieldValidationRule"

export interface FormDatePickerProps extends FormItemProps {
	onChange?: (date: dayjs.Dayjs | null) => void
	mandatory?: Mandatory
	validationMessage?: string
}

export const FormDatePicker = ({ onChange, mandatory, validationMessage, ...props }: FormDatePickerProps) => {
	const { t } = useTranslation("mainForm", { keyPrefix: "validations" })

	if (validationMessage && !mandatory)
		console.warn(
			"FormTextInput: 'mandatory' prop is not set to true, but 'validationMessage' is set. Validation message will not be shown."
		)
	return (
		<Form.Item {...props} rules={calcFormFieldValidationRule(mandatory, validationMessage ?? t("mandatoryField"))}>
			<DatePicker allowClear={false} className="w-full" onChange={date => onChange && onChange(date)} />
		</Form.Item>
	)
}
