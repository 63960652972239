import { UserRepository } from "./../../../repositories/userRepository"
import { UseCase } from "../../useCase"
import { CreateUserResponse } from "./response"
import { CreateUserRequest } from "./request"

export class CreateUserUseCase implements UseCase<CreateUserRequest, CreateUserResponse> {
	private _repository
	constructor({ UserRepository }: { UserRepository: UserRepository }) {
		this._repository = UserRepository
	}

	exec(user: CreateUserRequest) {
		return this._repository.createUser(user)
	}
}
