import { ReportEmbedInfoModel } from "../models/ReportEmbedInfoModel"
import { Endpoint } from "../network/endpoint"
import { HttpManager } from "../network/interface/HttpManager"

export class ReportService {
	private _http: HttpManager

	constructor({ HttpManager }: { HttpManager: HttpManager }) {
		this._http = HttpManager
	}

	async getAdminEmbeddedReport() {
		const response = await this._http.requestNewApi({
			method: "GET",
			url: Endpoint.ADMIN_REPORT
		})
		return ReportEmbedInfoModel.fromRawJson(response.data)
	}
}
