import { UseCase } from "../../useCase"
import { UploadMicrositeDetailImageResponse } from "./response"
import { UploadMicrositeDetailImageRequest } from "./request"
import { MicrositeRepository } from "../../../repositories/micrositeRepository"

export class UploadMicrositeDetailImageUseCase
	implements UseCase<UploadMicrositeDetailImageRequest, UploadMicrositeDetailImageResponse>
{
	private _repository
	constructor({ MicrositeRepository }: { MicrositeRepository: MicrositeRepository }) {
		this._repository = MicrositeRepository
	}

	exec(uploadData: UploadMicrositeDetailImageRequest) {
		return this._repository.uploadMicrositeDetailImage(uploadData)
	}
}
