import { StarFilled } from "@ant-design/icons"
import { Select, SelectProps, Tooltip } from "antd"
import { TooltipProps } from "antd/lib"
import { useState } from "react"
import { useTranslation } from "react-i18next"

export interface CustomBasicSelectorProps extends SelectProps {
	value?: any
	onChange?: (value: any) => void
	disabled?: boolean
	loading?: boolean
	placeholder?: string
	label?: string
	toolTipTitle?: string
	labelStyle?: React.CSSProperties
	mandatory?: boolean
	toolTipPlacement?: TooltipProps["placement"]
}

const Selector = (props: CustomBasicSelectorProps) => <Select {...props} />

export const CustomBasicSelector = ({
	toolTipTitle,
	label,
	placeholder,
	mandatory,
	toolTipPlacement = "right",
	labelStyle,
	...props
}: CustomBasicSelectorProps) => {
	const { t } = useTranslation("selectors")
	const [toolTipVisible, setToolTipVisible] = useState(false)
	const customTooltip = props.options?.find(option => option.value === props.value)?.label ?? toolTipTitle

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between"
			}}
		>
			{label && (
				<label style={{ position: "relative", right: 10, ...labelStyle }}>
					{mandatory && (
						<span style={{ fontSize: 4.5, color: "red", position: "relative", bottom: 8 }}>
							<StarFilled />
						</span>
					)}{" "}
					{label} :
				</label>
			)}
			{toolTipTitle ? (
				<Tooltip title={customTooltip} placement={toolTipPlacement} open={toolTipVisible}>
					<Selector
						placeholder={placeholder || t("defaultPlaceHolder")}
						onMouseEnter={() => setToolTipVisible(true)}
						onMouseLeave={() => setToolTipVisible(false)}
						onDropdownVisibleChange={visible => setToolTipVisible(!visible)}
						{...props}
					/>
				</Tooltip>
			) : (
				<Selector placeholder={placeholder || t("defaultPlaceHolder")} {...props} />
			)}
		</div>
	)
}
