import { CategoryRepository } from "./../../../repositories/categoryRepository"
import { UseCase } from "../../useCase"
import { UpdateCategoryResponse } from "./response"
import { UpdateCategoryRequest } from "./request"

export class UpdateCategoryUseCase implements UseCase<UpdateCategoryRequest, UpdateCategoryResponse> {
	private _repository
	constructor({ CategoryRepository }: { CategoryRepository: CategoryRepository }) {
		this._repository = CategoryRepository
	}

	async exec(category: UpdateCategoryRequest) {
		return await this._repository.updateCategory(category)
	}
}
