import { MicrositeRepository } from "./../../../repositories/micrositeRepository"
import { UseCase } from "../../useCase"
import { GetMicrositeDetailResponse } from "./response"
import { GetMicrositeDetailRequest } from "./request"

export class GetMicrositeDetailUseCase implements UseCase<GetMicrositeDetailRequest, GetMicrositeDetailResponse> {
	private _repository
	constructor({ MicrositeRepository }: { MicrositeRepository: MicrositeRepository }) {
		this._repository = MicrositeRepository
	}

	exec(micrositeId: GetMicrositeDetailRequest) {
		const microsite = this._repository.getMicrositeById(micrositeId)
		const services = this._repository.getMicrositeServices(micrositeId)

		return new Promise<GetMicrositeDetailResponse>((resolve, reject) => {
			Promise.all([microsite, services])
				.then(([microsite, services]) => {
					resolve({ clusters: microsite.clusters, services, settings: microsite.settings })
				})
				.catch(reject)
		})
	}
}
