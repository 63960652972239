import { Divider, Skeleton } from "antd"

const CategoryDetailFormSkeleton = () => {
	return (
		<>
			<Skeleton.Button active size="small" />
			<Divider />
			<div className="flex space-x-14">
				<div className="space-y-8">
					<div style={{ display: "flex", gap: 80 }}>
						<div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
							<Skeleton.Button active size="small" />
							<Skeleton.Input size="large" active style={{ width: "33vw" }} />
						</div>
					</div>
					<div style={{ display: "flex", gap: 80 }}>
						<div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
							<Skeleton.Button active size="small" />
							<Skeleton.Input size="large" active style={{ width: "33vw" }} />
						</div>
					</div>
					<div style={{ display: "flex", gap: 80 }}>
						<div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
							<Skeleton.Button active size="small" />
							<Skeleton.Input size="large" active style={{ width: "33vw" }} />
						</div>
					</div>
					<div style={{ display: "flex", gap: 80 }}>
						<div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
							<Skeleton.Button active size="small" />
							<Skeleton.Input size="large" active style={{ width: "33vw" }} />
						</div>
					</div>
				</div>
				<div className="space-y-8">
					<div style={{ display: "flex", gap: 80 }}>
						<div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
							<Skeleton.Button active size="small" />
							<Skeleton.Input size="large" active style={{ width: "33vw" }} />
						</div>
					</div>
					<div style={{ display: "flex", gap: 80 }}>
						<div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
							<Skeleton.Button active size="small" />
							<Skeleton.Input size="large" active style={{ width: "33vw" }} />
						</div>
					</div>
					<div style={{ display: "flex", gap: 80 }}>
						<div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
							<Skeleton.Button active size="small" />
							<Skeleton.Input size="large" active style={{ width: "33vw" }} />
						</div>
					</div>
					<div style={{ display: "flex", gap: 80 }}>
						<div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
							<Skeleton.Button active size="default" />
							<Skeleton active style={{ width: "33vw" }} />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default CategoryDetailFormSkeleton
