import { ErrorHandlerError } from "./ErrorHandlerError.interface"

export class SessionError extends Error implements ErrorHandlerError {
	public errorMessage: string
	public errorCode?: string
	public httpStatus: number
	static SESSION_CREATE_ERROR = "There was an error creating service's session"
	static SESSION_UPDATE_ERROR = "There was an error updating service's session"
	static SESSION_DELETE_ERROR = "There was an error deleting service's session"

	constructor(error: ErrorHandlerError) {
		super(error.errorMessage)
		this.errorMessage = error.errorMessage
		this.httpStatus = error.httpStatus
		this.errorCode = error.errorCode
		this.name = "SessionError"
	}
}
