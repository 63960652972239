import type { SellerRepository } from "../../../repositories/sellerRepository"
import { UseCase } from "../../useCase"
import { GetSellersResponse } from "./response"
import { GetSellersRequest } from "./request"

export class GetSellersUseCase implements UseCase<GetSellersRequest, GetSellersResponse> {
	private _repository
	constructor({ SellerRepository }: { SellerRepository: SellerRepository }) {
		this._repository = SellerRepository
	}

	public async exec() {
		return await this._repository.getSellers()
	}
}
