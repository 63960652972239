import { CountryRepository } from "../../../repositories/countryRepository"
import { UseCase } from "../../useCase"
import { GetCountriesResponse } from "./response"
import { GetCountriesRequest } from "./request"

export class GetCountriesUseCase implements UseCase<GetCountriesRequest, GetCountriesResponse> {
	private _repository
	constructor({ CountryRepository }: { CountryRepository: CountryRepository }) {
		this._repository = CountryRepository
	}

	exec() {
		return this._repository.getCountries()
	}
}
