import { MicrositeRepository } from "./../../../repositories/micrositeRepository"
import { UseCase } from "../../useCase"
import { GetMicrositesResponse } from "./response"
import { GetMicrositesRequest } from "./request"

export class GetMicrositesUseCase implements UseCase<GetMicrositesRequest, GetMicrositesResponse> {
	private _repository
	constructor({ MicrositeRepository }: { MicrositeRepository: MicrositeRepository }) {
		this._repository = MicrositeRepository
	}

	exec() {
		return this._repository.getMicrosites()
	}
}
