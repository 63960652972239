export class WeekDays {
	static Monday = 1
	static Tuesday = 2
	static Wednesday = 3
	static Thursday = 4
	static Friday = 5
	static Saturday = 6
	static Sunday = 7

	static toString(dayIndex: number) {
		const weekDaysString: { [key: number]: string } = {
			[WeekDays.Monday]: "Monday",
			[WeekDays.Tuesday]: "Tuesday",
			[WeekDays.Wednesday]: "Wednesday",
			[WeekDays.Thursday]: "Thursday",
			[WeekDays.Friday]: "Friday",
			[WeekDays.Saturday]: "Saturday",
			[WeekDays.Sunday]: "Sunday"
		}
		return weekDaysString[dayIndex]
	}

	static toNumber(day: string) {
		const weekDaysNumbers: { [key: string]: number } = {
			Monday: WeekDays.Monday,
			Tuesday: WeekDays.Tuesday,
			Wednesday: WeekDays.Wednesday,
			Thursday: WeekDays.Thursday,
			Friday: WeekDays.Friday,
			Saturday: WeekDays.Saturday,
			Sunday: WeekDays.Sunday
		}
		return weekDaysNumbers[day]
	}

	static toObject() {}
}
