import { UploadTipMediaResponse } from "./response"
import { UploadTipMediaRequest } from "./request"
import { UseCase } from "../../useCase"
import { TipRepository } from "../../../repositories/tipRepository"

export class UploadTipMediaUseCase implements UseCase<UploadTipMediaRequest, UploadTipMediaResponse> {
	private _repository
	constructor({ TipRepository }: { TipRepository: TipRepository }) {
		this._repository = TipRepository
	}

	exec({ tipId, file }: UploadTipMediaRequest) {
		return this._repository.uploadTipMedia(tipId, file)
	}
}
