export const colors = {
	primary: "#c83854",
	error: "#FF6363",
	success: "#4CAF50",
	disabled: "#BDBDBD",
	activity: "#5B8CD0",
	pressable: "#EDEDED",
	text: "#000000",
	secondaryText: "#777B7E",
	actionButton: "#5F6368",
	shadowBackground: "#FCFCFC",
	overlapBackground: "#FFFFFF",
	socialMedia: {
		facebook: "#3B5998",
		google: "#DD4B39"
	}
}
