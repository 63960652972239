import { Form, FormItemProps, InputProps, Typography } from "antd"
import { useState } from "react"
import TextInput from "../TextInput/TextInput"
import { ReactComponent as Pencil } from "../../assets/icons/pencil.svg"
import useUpdateEffect from "../../hooks/useUpdateEffect"
import { useTranslation } from "react-i18next"
import { InputType } from "../TextInput/const/InputType.enum"
import { colors } from "../../assets/styles/appStyles"
import { calcFormFieldValidationRule } from "../../utils/calFormFieldValidationRule"

export interface FormTextInputProps extends FormItemProps {
	disabled?: boolean
	placeHolder?: string
	onChange?: (value: string) => void
	inputType?: InputType
	addonBefore?: InputProps["addonBefore"]
	hideEditIcon?: boolean
	preFix?: string
	fetchingData?: boolean
	mandatory?: boolean | { validator?: boolean }
	validationMessage?: string
	isPresential?: boolean
	defaultValue?: string
	customFieldValidation?: { status: FormItemProps["validateStatus"]; message: string }
	customFieldPosition?: "absolute" | "relative"
}

const FormTextInput = ({
	onChange,
	disabled,
	placeHolder,
	inputType,
	preFix,
	hideEditIcon = true,
	fetchingData,
	mandatory,
	validationMessage,
	addonBefore,
	customFieldValidation,
	defaultValue,
	customFieldPosition = "relative",
	...formItemProps
}: FormTextInputProps) => {
	useUpdateEffect(() => setIsEditable(disabled), [disabled])

	const [isEditable, setIsEditable] = useState(disabled)
	const { t } = useTranslation("mainForm", { keyPrefix: "validations" })
	const { t: tToolTips } = useTranslation("toolTips")

	if (validationMessage && !mandatory)
		console.warn(
			"FormTextInput: 'mandatory' prop is not set to true, but 'validationMessage' is set. Validation message will not be shown."
		)

	return (
		<>
			<Form.Item
				labelCol={{ span: 24 }}
				validateStatus={customFieldValidation?.message ? customFieldValidation?.status : undefined}
				rules={calcFormFieldValidationRule(mandatory, validationMessage ?? t("mandatoryField"))}
				{...formItemProps}
			>
				<TextInput
					defaultValue={defaultValue ?? undefined}
					loading={fetchingData}
					inputType={inputType || InputType.TEXT}
					onChange={({ target: { value } }) => onChange && onChange(value)}
					placeHolder={placeHolder || formItemProps?.label?.toString()}
					toolTipText={tToolTips("edit").toString()}
					prefix={preFix}
					Icon={hideEditIcon ? undefined : Pencil}
					disabled={isEditable || fetchingData}
					onClickIcon={() => {
						setIsEditable(!isEditable)
						onChange && onChange("")
					}}
					addonBefore={addonBefore}
				/>
			</Form.Item>
			{customFieldValidation?.message && (
				<Typography.Text
					className={`${customFieldPosition}  bottom-[1.4rem] bg-white`}
					style={{ color: colors.error }}
				>
					{customFieldValidation?.message}
				</Typography.Text>
			)}
		</>
	)
}

export default FormTextInput
