import { ErrorHandlerError } from "./ErrorHandlerError.interface"

export class TransactionsError extends Error implements ErrorHandlerError {
	public errorMessage: string
	public errorCode?: string
	public httpStatus: number
	static TRANSACTIONS_UPLOAD_FILE_ERROR = "There was an error uploading the transactions file"
	static TRANSACTIONS_GET_ERROR = "There was an error getting the transactions"

	constructor(error: ErrorHandlerError) {
		super(error.errorMessage)
		this.errorMessage = error.errorMessage
		this.httpStatus = error.httpStatus
		this.errorCode = error.errorCode
		this.name = "TransactionsError"
	}
}
