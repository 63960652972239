import { Button, Col, Form, Row, Typography } from "antd"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import MultimediaUploader from "../../../components/MultimediaUploader/MultimediaUploader"
import { FormField } from "../../../components/Form/FormField"
import { Banner } from "../../../../domain/entities/Banner"
import { BannerForm } from "../entities/BannerForm"
import { BannersFormViewModel } from "../BannersFormViewModel"
import config from "../../../../config/config"

interface BannerSelectionProps {
	banner: BannerForm
	canDelete?: boolean
	onDelete?: (banner: Banner) => void
	viewModel: BannersFormViewModel
}

const { Title } = Typography

const BannerSelection = ({ banner, canDelete = false, onDelete, viewModel }: BannerSelectionProps) => {
	const { t } = useTranslation("banners")
	return (
		<Row
			style={{
				border: "1px solid #eaeaea",
				padding: 20,
				margin: 10,
				borderRadius: 10,
				boxShadow: "0px 4px 6px #0000001f"
			}}
			align="middle"
		>
			<Col xl={4} lg={24} md={24} xs={24}>
				<Title style={{ marginBottom: 10, marginTop: 0, fontWeight: "bold" }} level={5}>
					{t("position")} {banner.weight}
				</Title>
				<FormField.Input
					initialValue={banner.href}
					key={`${banner.viewId}-redirectUrl`}
					name={`${banner.viewId}-redirectUrl`}
					label={t("redirectUrl")}
					onChange={(href: string) => viewModel.setBannerHref(banner.viewId, href)}
				></FormField.Input>
			</Col>
			<Col xl={9} lg={12} md={24} xs={24}>
				<div style={{ display: "flex", justifyContent: "center" }}>
					<Form.Item>
						<MultimediaUploader
							containerStyle={{
								width: banner.type === "mainBanner" || banner.subType === "large" ? "450px" : "350px",
								height: "140px"
							}}
							mediaUrl={banner.desktopImage as (string & File) | undefined}
							previewWidth={banner.type === "mainBanner" || banner.subType === "large" ? 1900 : 1000}
							onClean={() => viewModel.removeNewBannerImage(banner.viewId, "desktop")}
							manualUpload
							label={t("desktop")}
							onChange={image => viewModel.loadNewBannerImage(banner.viewId, image, "desktop")}
							type="image"
							imageRatio={
								banner.type === "mainBanner"
									? config.ui.banners.main.desktop.imageRatio
									: config.ui.banners.secondary[banner.subType === "large" ? "large" : "short"]
											.desktop.imageRatio
							}
						/>
					</Form.Item>
				</div>
			</Col>
			<Col xl={8} lg={12} md={24} xs={24}>
				<div style={{ display: "flex", justifyContent: "center" }}>
					<Form.Item>
						<MultimediaUploader
							containerStyle={{ width: "280px", height: "140px" }}
							mediaUrl={banner.mobileImage as (string & File) | undefined}
							previewWidth={800}
							onClean={() => viewModel.removeNewBannerImage(banner.viewId, "mobile")}
							manualUpload
							label={t("mobile")}
							onChange={image => viewModel.loadNewBannerImage(banner.viewId, image, "mobile")}
							type="image"
							imageRatio={
								banner.type === "mainBanner"
									? config.ui.banners.main.mobile.imageRatio
									: config.ui.banners.secondary[banner.subType === "large" ? "large" : "short"].mobile
											.imageRatio
							}
						/>
					</Form.Item>
				</div>
			</Col>
			<Col xl={3} lg={24} md={24} xs={24}>
				{canDelete && (
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							type="primary"
							danger={true}
							style={{ width: "10rem" }}
							onClick={() => onDelete && onDelete(banner)}
						>
							{t("delete")}
						</Button>
					</div>
				)}
			</Col>
		</Row>
	)
}
export default observer(BannerSelection)
