import { ErrorHandlerError } from "./ErrorHandlerError.interface"

export class UserError extends Error implements ErrorHandlerError {
	public errorMessage: string
	public errorCode?: string
	public httpStatus: number
	static USERS_FETCH_ERROR = "There was an error fetching users"
	static USER_CREATE_ERROR = "There was an error creating the user"
	static USER_UPDATE_ERROR = "There was an error updating the user"
	static USER_DELETE_ERROR = "There was an error deleting the user"
	static USER_DETAIL_FETCH_ERROR = "There was an error fetching the user detail"
	static USER_UPLOAD_IMAGE_ERROR = "There was an error uploading the user image"
	static USER_SEARCH_ERROR = "There was an error searching users"
	static USER_GET_IMAGE_URL_ERROR = "There was an error getting the image upload url"
	static USER_UPLOAD_FILE_ERROR = "There was an error uploading the file"

	constructor({ errorMessage, httpStatus, errorCode }: ErrorHandlerError) {
		super(errorMessage)
		this.errorMessage = errorMessage
		this.httpStatus = httpStatus
		this.errorCode = errorCode
		this.name = "UserError"
	}
}
