import { Col } from "antd"
import { VoucherRules } from "../../../../data/dto/VoucherRules.dto"
import { useTranslation } from "react-i18next"
import VoucherRulesFormSection from "./VoucherRulesFormSection"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { VoucherViewModel } from "../VoucherViewModel"
import { Card } from "../../../styledComponents/CustomCard/Card"

interface Props {
	data: Partial<VoucherRules>
	disabled: boolean
	loading?: boolean
}

const VoucherRule = ({ data, disabled, loading }: Props) => {
	const viewModel = useContainerInjection<VoucherViewModel>("VoucherViewModel")
	const { t } = useTranslation("vouchers", { keyPrefix: "form" })

	return (
		<>
			<Col xl={24} md={24} xs={24}>
				<Card
					loading={loading}
					bordered={false}
					className="criclebox tablespace mb-24"
					title={
						<div>
							<h6 className="font-semibold m-0">{t("voucherRuleInfo.cardTitle")}</h6>
						</div>
					}
					bodyStyle={{ padding: "24px" }}
				>
					<VoucherRulesFormSection
						inputDisabled={!disabled}
						data={data}
						onChangeText={value => viewModel.handleRulesFormChange(value)}
					/>
				</Card>
			</Col>
		</>
	)
}

export default VoucherRule
