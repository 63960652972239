import { CategoryRepository } from "./../../../repositories/categoryRepository"
import { UseCase } from "../../useCase"
import { CreateCategoryResponse } from "./response"
import { CreateCategoryRequest } from "./request"

export class CreateCategoryUseCase implements UseCase<CreateCategoryRequest, CreateCategoryResponse> {
	private _repository
	constructor({ CategoryRepository }: { CategoryRepository: CategoryRepository }) {
		this._repository = CategoryRepository
	}

	async exec(category: CreateCategoryRequest) {
		return await this._repository.createCategory(category)
	}
}
