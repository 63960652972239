import { MicrositeRepository } from "./../../../repositories/micrositeRepository"
import { UseCase } from "../../useCase"
import { UpdateMicrositeResponse } from "./response"
import { UpdateMicrositeRequest } from "./request"

export class UpdateMicrositeUseCase implements UseCase<UpdateMicrositeRequest, UpdateMicrositeResponse> {
	private _repository
	constructor({ MicrositeRepository }: { MicrositeRepository: MicrositeRepository }) {
		this._repository = MicrositeRepository
	}

	exec(microsite: UpdateMicrositeRequest) {
		return this._repository.updateMicrosite(microsite)
	}
}
