import { Form, FormProps } from "antd"
import React, { Ref, forwardRef, useEffect, useImperativeHandle, useState } from "react"
import FormButtons from "./FormButtons"
import { useTranslation } from "react-i18next"
import { useToastMessage } from "../../service/toastMessage"
import { useConfirmModal } from "../../service/confirmModal"
import { useLocation, useNavigate } from "react-router-dom"

interface MainFormProps extends FormProps {
	children?: React.ReactNode
	onSubmitForm?: () => Promise<void>
	onCancelForm?: () => void
	onDeleteForm?: () => Promise<void>
	onChangeFormValues?: (values: any) => void
	initialValues?: any
	buttonsDisabled?: boolean
	disabled?: boolean
	hideFormButtons?: boolean
	hideFormCancelButton?: boolean
	editting?: boolean
	formModified?: boolean
	isUpdating?: boolean
}

export interface MainFormRef {
	validateFields: () => Promise<void>
	customValidateFields: (callback: () => void) => Promise<void>
	setFormValues: (values: any) => void
}

const MainForm = forwardRef(
	(
		{
			children,
			onChangeFormValues,
			onSubmitForm,
			onCancelForm,
			initialValues,
			formModified,
			buttonsDisabled,
			disabled,
			hideFormButtons = false,
			hideFormCancelButton = false,
			editting,
			isUpdating = false,
			...props
		}: MainFormProps,
		ref: Ref<MainFormRef>
	) => {
		const [form] = Form.useForm()
		const { show, context: toastContext, reset } = useToastMessage()
		const [sendingData, setSendingData] = useState(false)
		const { t } = useTranslation("mainForm")
		const { t: tToast } = useTranslation("toast")
		const key = "FORM_SUBMITTING"
		const { showConfirm, context: confirmModalContext } = useConfirmModal()
		const navigate = useNavigate()
		const routerLocation = useLocation()

		useEffect(() => {
			initialValues ? form.setFieldsValue(initialValues) : form.resetFields()
		}, [initialValues, form])

		// useEffect(() => {
		//   console.log("@@USE_EFFECT@@", formModified)
		//   const handleRouteChange = (e: any) => {
		//     console.log("@@HANDLE_ROUTE_CHANGE@@", formModified)
		//     e.preventDefault();
		//     if (formModified) {
		//       // Show a confirmation message to the user
		//       const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
		//       e.returnValue = confirmationMessage; // For some browsers
		//       return confirmationMessage; // For modern browsers
		//     }
		//   };

		//   window.addEventListener('beforeunload', handleRouteChange);

		//   return () => {
		//     window.removeEventListener('beforeunload', handleRouteChange);
		//   };
		// }, [formModified]);

		useImperativeHandle(ref, () => ({
			async validateFields() {
				try {
					await form.validateFields()
				} catch (error: any) {
					onFormFinishFailed(error)
					throw error
				}
			},
			async customValidateFields(callback) {
				return new Promise((resolve, reject) => {
					try {
						callback()
						resolve()
					} catch (error: any) {
						onFormFinishFailed(error)
						reject(error)
					}
				})
			},
			setFormValues(values) {
				form.setFieldsValue(values)
			}
		}))

		const handleOnFinish = async () => {
			if (!onSubmitForm) return
			setSendingData(true)
			try {
				show({
					key,
					type: "loading",
					content: initialValues?.id || isUpdating ? tToast("updating") : tToast("creating"),
					duration: 0
				})

				await onSubmitForm()

				show({
					key,
					type: "success",
					content: initialValues?.id || isUpdating ? tToast("updateSuccess") : tToast("createSuccess")
				})
			} catch (error: any) {
				reset(key)
				onFormFinishFailed(
					error?.response?.data?.error_msg
						? { message: `${t("submitFormError")}: ${error?.response?.data?.error_msg}` }
						: error
				)
			} finally {
				setSendingData(false)
			}
		}

		const onFormFinishFailed = (err?: any) => {
			if (typeof err === "string") {
				err !== "" && show({ key, type: "error", content: err })
				return
			}
			if (err?.message) {
				show({ key, type: "error", content: err.message })
				return
			}
			if (err?.errorFields?.length > 0) {
				show({ key, type: "error", content: t("validations.mandatoryFields") })
			}
		}

		const handleReset = (routePath?: string) => {
			if (onCancelForm) {
				onCancelForm()
				return
			}

			if (formModified) {
				showConfirm({
					title: t("onBackConfirmTitle"),
					description: t("onBackConfirmMessage").toString(),
					onConfirm: () => {
						navigate(-1)
					}
				})
			} else {
				navigate(-1)
			}
		}

		return (
			<Form
				wrapperCol={{ span: 22 }}
				labelCol={{ span: 24 }}
				form={form}
				style={{ flex: 1 }}
				name="main-form"
				onChange={() => onChangeFormValues && onChangeFormValues(form.getFieldsValue())}
				// autoComplete="off"
				initialValues={initialValues}
				onFinish={handleOnFinish}
				onReset={() => handleReset()}
				onError={error => {
					// console.error("@@ONFORM_ERROR@@", error)
					// toastMessage.error(JSON.stringify({ error, type: error.type }))
				}}
				onFinishFailed={onFormFinishFailed}
				{...props}
			>
				{toastContext}
				{confirmModalContext}
				{children}
				{!hideFormButtons && (
					<FormButtons
						form={form}
						disabled={buttonsDisabled}
						hideFormCancelButton={hideFormCancelButton}
						loading={sendingData}
						t={t}
					/>
				)}
			</Form>
		)
	}
)

export default MainForm
