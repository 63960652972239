import { UserError } from "../../ui/error/UserError"
import { UserModel } from "./../models/UserModel"
import { Endpoint } from "./../network/endpoint"
import { HttpManager } from "./../network/interface/HttpManager"

export class UserService {
	private _http: HttpManager

	constructor({ HttpManager }: { HttpManager: HttpManager }) {
		this._http = HttpManager
	}

	public async getUserDetail(userId: string) {
		try {
			const response = await this._http.requestNewApi({
				method: "GET",
				url: `${Endpoint.USER}/${userId}`
			})
			return UserModel.fromRawJson(response.data)
		} catch (error: any) {
			throw new UserError({
				errorMessage: UserError.USER_DETAIL_FETCH_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async createUser(user: UserModel) {
		try {
			const response = await this._http.requestNewApi({
				method: "POST",
				url: Endpoint.USER,
				data: user
			})
			return UserModel.fromRawJson(response.data.user)
		} catch (error: any) {
			throw new UserError({
				errorMessage: UserError.USER_CREATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async getUploadProfilePictureUrl(userId: string, fileName: string, mimeType: string) {
		try {
			const response = await this._http.requestNewApi({
				method: "GET",
				url: `${Endpoint.USER_ADMIN_PROFILE_PICTURE_PRESIGN.replace(
					":userId",
					userId
				)}?fileName=${fileName}&contentType=${mimeType}`
			})
			return response.data
		} catch (error: any) {
			throw new UserError({
				errorMessage: UserError.USER_GET_IMAGE_URL_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async updateUser(user: UserModel) {
		try {
			const response = await this._http.requestNewApi({
				method: "PATCH",
				url: `${Endpoint.USER_ADMIN}/${user.id}`,
				data: user
			})
			return UserModel.fromRawJson(response.data.body)
		} catch (error: any) {
			throw new UserError({
				errorMessage: UserError.USER_UPDATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async getUsersList(pagination: number, limit: number, orderBy: {} = { id: "desc" }) {
		try {
			const queryParams = `page=${pagination}&limit=${limit}&orderBy=${JSON.stringify(orderBy)}`
			const response = await this._http.requestNewApi({
				method: "GET",
				url: `${Endpoint.USER}?${queryParams}`
			})
			const data = response.data.map((user: any) => UserModel.fromRawJson(user))
			return data
		} catch (error: any) {
			throw new UserError({
				errorMessage: UserError.USERS_FETCH_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async searchUsers(
		expression: string,
		offset: number = 0,
		limit: number = 200,
		orderBy: {} = { id: "desc" }
	) {
		try {
			const queryParams = `page=${offset}&limit=${limit}&orderBy=${JSON.stringify(orderBy)}&filter=${expression}`
			const response = await this._http.requestNewApi({
				method: "GET",
				url: `${Endpoint.USER}?${queryParams}`
			})
			const data = response.data
			return data.map((user: any) => UserModel.fromRawJson(user))
		} catch (error: any) {
			throw new UserError({
				errorMessage: UserError.USER_SEARCH_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async importUsers(file: File): Promise<{ code: string; message: string }> {
		try {
			const formData = new FormData()
			formData.append("file", file)
			const response = await this._http.requestNewApi({
				url: Endpoint.USER_IMPORT_FILE,
				method: "POST",
				data: formData,
				headers: { "Content-Type": "multipart/form-data" }
			})
			return response.data
		} catch (error: any) {
			throw new UserError({
				errorMessage: UserError.USER_UPLOAD_FILE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}
}
