import type { SellerServicesRepository } from "../../../repositories/sellerServicesRepository"
import { UseCase } from "../../useCase"
import { UploadMultipleSellerServicePictureResponse } from "./response"
import { UploadMultipleSellerServicePictureRequest } from "./request"

export class UploadMultipleSellerServicePictureUseCase
	implements UseCase<UploadMultipleSellerServicePictureRequest, UploadMultipleSellerServicePictureResponse>
{
	private _repository
	constructor({ SellerServicesRepository }: { SellerServicesRepository: SellerServicesRepository }) {
		this._repository = SellerServicesRepository
	}

	exec(uploadData: UploadMultipleSellerServicePictureRequest) {
		return this._repository.uploadServiceImages(uploadData.id, uploadData.files)
	}
}
