import { MicrositeRepository } from "./../../../repositories/micrositeRepository"
import { UseCase } from "../../useCase"
import { CreateMicrositeResponse } from "./response"
import { CreateMicrositeRequest } from "./request"

export class CreateMicrositeUseCase implements UseCase<CreateMicrositeRequest, CreateMicrositeResponse> {
	private _repository
	constructor({ MicrositeRepository }: { MicrositeRepository: MicrositeRepository }) {
		this._repository = MicrositeRepository
	}

	exec(microsite: CreateMicrositeRequest) {
		return this._repository.createMicrosite(microsite)
	}
}
