import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { InputType } from "../../../components/TextInput/const/InputType.enum"

export default () => {
	const { t } = useTranslation("microsites", { keyPrefix: "form.dataTableHeaders.services" })

	return columns(t)
}

const columns = (t: TFunction) => [
	{
		key: "id",
		title: "ID",
		dataIndex: "id",
		sorteable: true,
		autoSort: false
	},
	{
		title: t("title"),
		dataIndex: "title",
		key: "title",
		width: "32%"
		// responsive: ['xl'],
	},
	{
		title: t("weight"),
		dataIndex: "weight",
		key: "weight",
		inputType: InputType.NUMBER,
		editable: true,
		width: "10%"
	},
	{
		title: t("seller"),
		dataIndex: "seller",
		key: "seller",
		width: "32%"
	},
	{
		title: t("highlighted"),
		dataIndex: "highlighted",
		key: "highlighted"
	},
	{
		title: "",
		key: "actions",
		dataIndex: "actions"
	}
]
