import { action, makeObservable, observable } from "mobx"
import { Report } from "powerbi-client"
import { GetAdminReportUseCase } from "../../../domain/useCases/reports/getAdminReport"
import { ErrorHandler } from "../../error/ErrorHandler"
import { ReportEmbedInfo } from "../../../domain/entities/ReportEmbedInfo"

interface ReportsViewModelConstructorParams {
	GetAdminReportUseCase: GetAdminReportUseCase
	ErrorHandler: ErrorHandler
}

export class ReportsViewModel {
	private _errorHandler
	private _getAdminReportUseCase
	embeddedAdminReport?: Report

	constructor({ GetAdminReportUseCase, ErrorHandler }: ReportsViewModelConstructorParams) {
		makeObservable(this, {
			embeddedAdminReport: observable,
			setEmbeddedAdminReport: action
		})
		this._getAdminReportUseCase = GetAdminReportUseCase
		this._errorHandler = ErrorHandler
	}

	async getReports() {
		try {
			const embedInfo = await this._getAdminReportUseCase.exec()
			return embedInfo
		} catch (err) {
			throw this._errorHandler.handleError(err)
		}
	}

	setEmbeddedAdminReport(value: Report) {
		this.embeddedAdminReport = value
	}
}
