export class FileService {
	private defaultFileName = "file"
	private defaultExtension = "txt"

	public dowloadFile(file: Blob, fileName: string = `${this.defaultFileName}.${this.defaultExtension}`) {
		const aTag = document.createElement("a")
		aTag.href = URL.createObjectURL(file)
		aTag.download = fileName
		aTag.click()
		aTag.remove()
	}
}
