interface ContructorParams {
	id: string
	name: string
	flagImage?: string
}

export class Country {
	public id: string
	public name: string

	constructor(params: ContructorParams) {
		this.id = params.id
		this.name = params.name
	}
}
