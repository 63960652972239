import { Button, Col, Dropdown, Form, Row, Space, Tag, Typography } from "antd"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Category } from "../../../domain/entities/Category"
import { DeleteFilled, DownOutlined } from "@ant-design/icons"
import MainForm from "../../components/Form/MainForm"
import { CategoriesViewModel } from "./CategoriesViewModel"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { observer } from "mobx-react"
import { toJS } from "mobx"
import useUpdateEffect from "../../hooks/useUpdateEffect"
import { RoutePaths } from "../../navigation/RoutePaths.enum"
import { useConfirmModal } from "../../service/confirmModal"
import Switch from "../../components/Switch/Switch"
import { useTranslation } from "react-i18next"
import { useToastMessage } from "../../service/toastMessage"
import { InputType } from "../../components/TextInput/const/InputType.enum"
import { FormField } from "../../components/Form/FormField"
import { Card } from "../../styledComponents/CustomCard/Card"
import { Country } from "../../../domain/entities/Country"
import CategoryDetailFormSkeleton from "../../styledComponents/LoadingSkeletons/CategoryDetailFormSkeleton"
import { CategoryIcon } from "../../../domain/entities/CategoryIcon"

const styles = {
	formField: { display: "block" }
}

const CategoryFormView = () => {
	const { t } = useTranslation("clusters", { keyPrefix: "form" })
	const { t: tToast } = useTranslation("toast")
	const { t: tCountries } = useTranslation("countries")
	const { show, context: toastMessageContext } = useToastMessage()
	const viewModel = useContainerInjection<CategoriesViewModel>("CategoriesViewModel")
	const locationState = useLocation().state
	const [iconMenuOpened, setIconMenuOpened] = useState(false)

	const initialFormData: Partial<Category> & { parentId?: string } = {
		id: locationState?.category?.id || "",
		label: locationState?.category?.label || locationState?.category?.picture || "",
		labelEn: locationState?.category?.labelEn || "",
		labelCa: locationState?.category?.labelCa || "",
		picture: locationState?.category?.picture || "",
		children: locationState?.category?.children || [],
		parentId: locationState?.category?.parentId || undefined,
		position: locationState?.category?.position || 1,
		highlighted: locationState?.category?.highlighted || false,
		countryId: locationState?.category?.countryId || "ES"
	}

	const navigate = useNavigate()
	const { showConfirm, context: modalContext } = useConfirmModal()
	const [formData, setFormData] = useState(initialFormData)
	const [editMode, setEditMode] = useState(locationState?.category?.id !== undefined)
	const enableSubCategories = formData.parentId !== undefined
	const [visibleSubCategories, setVisibleSubCategories] = useState(enableSubCategories)
	const [originalFormData, setOriginalFormData] = useState(initialFormData)

	useUpdateEffect(() => {
		const selectedCategory = toJS(viewModel.selectedCategory)
		setFormData(selectedCategory)
		setOriginalFormData(selectedCategory)
		setEditMode(viewModel.selectedCategory?.id !== undefined)
	}, [viewModel.selectedCategory])

	useEffect(() => {
		if (editMode) {
			viewModel.fetchCategoryDetail(formData.id!)
		}
	}, [])

	const formModified = () => {
		const formDataSorted = {
			...formData,
			children: formData.children?.sort((a, b) => (a.id! > b.id! ? 1 : -1))
		}
		const originalFormDataSorted = {
			...originalFormData,
			children: originalFormData.children?.sort((a, b) => (a.id! > b.id! ? 1 : -1))
		}
		return JSON.stringify(formDataSorted) === JSON.stringify(originalFormDataSorted)
	}

	const onSubmitForm = async () => {
		if (editMode) {
			await viewModel.updateCategory(formData)
		} else {
			await viewModel.createCategory(formData)
		}
		setTimeout(() => {
			navigate(-1)
		}, 800)
	}

	const onDeleteCategory = () => {
		showConfirm({
			title: tToast("deleteConfirmMessage", { value: "cluster" }),
			onConfirm: async () => {
				show({
					key: "categoryDeleting",
					type: "loading",
					content: tToast("deleting")
				})
				await viewModel.deleteCategory(formData)
				navigate(RoutePaths.CATEGORIES)
				show({
					key: "categoryDeleting",
					type: "success",
					content: tToast("deleteSuccess")
				})
			},
			type: "danger"
		})
	}

	if (viewModel.isLoadingDetail) {
		return <CategoryDetailFormSkeleton />
	}

	return (
		<MainForm onSubmitForm={onSubmitForm} initialValues={formData} formModified={!formModified()}>
			{modalContext}
			{toastMessageContext}
			<Row gutter={[24, 0]}>
				<Col xl={24} md={24} xs={24}>
					<Card
						bordered={false}
						className="criclebox tablespace mb-24"
						title={
							<div>
								<h6 className="font-semibold m-0">{t("title")}</h6>
								{editMode ? (
									<Button
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											position: "absolute",
											right: "2.5rem",
											top: "1rem"
										}}
										type="link"
										danger
										onClick={() => onDeleteCategory()}
									>
										<DeleteFilled style={{ fontSize: "1.5rem" }} />
									</Button>
								) : null}
							</div>
						}
						bodyStyle={{ padding: "24px" }}
					>
						<Row gutter={[24, 0]}>
							<Col xl={8} md={12} xs={24}>
								<FormField.Input
									key={"label"}
									label={t("fields.title")}
									name={"label"}
									rules={[{ required: true, message: t("fields.validations.title").toString() }]}
									onChange={(label: string) => setFormData({ ...formData, label })}
								/>
							</Col>
							<Col xl={8} md={12} xs={24}>
								<FormField.Input
									fetchingData={viewModel.isLoadingENFields}
									key={"labelEn"}
									label={`${t("fields.title")} (EN)`}
									name={"labelEn"}
									rules={[{ required: true, message: t("fields.validations.titleEn").toString() }]}
									onChange={(labelEn: string) => setFormData({ ...formData, labelEn })}
								/>
							</Col>
							<Col xl={8} md={12} xs={24}>
								<FormField.Input
									fetchingData={viewModel.isLoadingENFields}
									key={"labelCa"}
									label={`${t("fields.title")} (CA)`}
									name={"labelCa"}
									onChange={(labelCa: string) => setFormData({ ...formData, labelCa })}
								/>
							</Col>
						</Row>
						<Row gutter={[24, 0]}>
							<Col xl={12} md={12} xs={24}>
								<FormField.Input
									key={"position"}
									label={t("fields.position")}
									inputType={InputType.NUMBER}
									name={"position"}
									onChange={(position: string) => setFormData({ ...formData, position })}
								/>
							</Col>
							<Col xl={12} md={12} xs={24}>
								<FormField.SelectSearch
									mandatory
									key="countryId"
									name="countryId"
									label={t("fields.country")}
									placeholder={t("countryPlaceHolder").toString()}
									//@ts-ignore
									onChange={country => setFormData({ ...formData, countryId: country?.value })}
									value={formData.countryId}
									options={viewModel.countries.map((country: Country) => ({
										value: country.id,
										label: tCountries(country.id),
										key: country.id
									}))}
								/>
							</Col>
						</Row>
						<Row gutter={[24, 0]} style={{ marginTop: 12 }}>
							<Col xl={12} md={12} xs={24}>
								<Switch
									title={t("fields.highlighted")}
									containerStyles={{ justifyContent: "flex-start" }}
									checked={formData.highlighted}
									onChange={(value: boolean) => {
										setFormData({
											...formData,
											highlighted: value
										})
									}}
								/>
							</Col>

							<Col xl={12} md={12} xs={24}>
								<Form.Item>
									<div className="flex  items-center gap-4">
										<p>{t("fields.icon")}: </p>
										<Dropdown
											onOpenChange={setIconMenuOpened}
											trigger={["click"]}
											menu={{
												items: viewModel.icons.map((icon: CategoryIcon) => ({
													key: icon.id,
													label: (
														<div className="flex items-center gap-1">
															<div className="grid w-10 h-10 rounded-full m-2 p-2 shadow">
																<div
																	className="m-auto"
																	dangerouslySetInnerHTML={{ __html: icon.picture }}
																/>
															</div>
															<p>{t(`icons.${icon.key}`)}</p>
														</div>
													)
												})),
												selectable: true,
												onClick: item => {
													const iconId = Number(item.key)
													setFormData({
														...formData,
														iconId: iconId,
														iconKey: viewModel.icons.find(icon => icon.id === iconId)?.key,
														iconPicture: viewModel.icons.find(icon => icon.id === iconId)
															?.picture
													})
													setIconMenuOpened(false)
												},
												className: "max-h-60 overflow-y-auto"
											}}
											className="grid"
										>
											<div
												className={`flex shadow rounded-full hover:cursor-pointer hover:opacity-80 ${
													formData.iconId ? "h-14 w-14" : "px-2"
												}`}
											>
												{formData.iconPicture ? (
													<div className="grid relative">
														<div className="grid m-auto rounded-full">
															<div
																className="m-auto"
																dangerouslySetInnerHTML={{
																	__html: formData.iconPicture
																}}
															/>
														</div>
														<DownOutlined
															className={`absolute -right-[.15rem] -top-[.15rem]  w-4 h-4 bg-gray-200 text-gray-500 p-1 rounded-full transition-all ${
																iconMenuOpened ? "rotate-180" : ""
															}`}
														/>
													</div>
												) : (
													<Space>
														<p className="text-gray-400">{t("iconPlaceHolder")}</p>
														<DownOutlined
															className={`text-gray-400 w-4 h-4 transition-all ${
																iconMenuOpened ? "rotate-180" : ""
															}`}
														/>
													</Space>
												)}
											</div>
										</Dropdown>
									</div>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={[24, 0]}>
							<Col xl={12} md={12} xs={24}>
								<Form.Item
									key={"subCategory"}
									style={styles.formField}
									name="subCategory"
									rules={[
										{
											required: visibleSubCategories && formData?.parentId === undefined,
											message: t("fields.validations.parent").toString()
										}
									]}
								>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											marginBottom: "14px"
										}}
									>
										<div style={{ display: "flex", flexDirection: "row" }}>
											{visibleSubCategories && (
												<Typography.Text
													style={{ color: "red", fontSize: 9.5, marginRight: 4 }}
												>
													*
												</Typography.Text>
											)}
											<Typography.Text style={{ marginRight: "10px" }}>
												{t("fields.parent")}:
											</Typography.Text>
										</div>
										<Switch
											style={{ marginTop: "12px" }}
											checked={visibleSubCategories}
											onChange={(value: boolean) => {
												setFormData({
													...formData,
													parentId: !value ? undefined : originalFormData.parentId
												})
												setVisibleSubCategories(value)
											}}
										/>
									</div>

									{visibleSubCategories ? (
										<FormField.SelectSearch
											name="parentId"
											key="parentId"
											loading={viewModel.isLoadingParents}
											disabled={viewModel.isLoading}
											placeholder={t("parentPlaceHolder").toString()}
											value={formData?.parentId}
											options={viewModel.categories
												.filter(
													category =>
														category.position !== Category.MICROSITE &&
														category.id !== formData.id
												)
												.map((category: Category) => ({
													value: category.id,
													label: `${category.id} - ${category.label}`,
													key: category.id
												}))}
											onChange={parent => {
												//@ts-ignore
												setFormData({ ...formData, parentId: parent?.value })
											}}
										/>
									) : null}
								</Form.Item>
							</Col>
							{formData.micrositesAssociated?.length ? (
								<Col xl={12} md={12} xs={24}>
									<Form.Item
										key={"micrositesAssociated"}
										style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
										name="micrositesAssociated"
									>
										<Typography.Text style={{ marginRight: "10px" }}>
											{t("fields.micrositesAssociated")} :
										</Typography.Text>
										{formData.micrositesAssociated.map(microsite => {
											let color =
												microsite.slug.length > 18
													? "geekblue"
													: microsite.slug.length > 12
													? "cyan"
													: "blue"
											return (
												<Tag color={color} key={microsite.id} style={{ marginBottom: 4 }}>
													{`${microsite.id} - ${microsite.slug.toUpperCase()}`}
												</Tag>
											)
										})}
									</Form.Item>
								</Col>
							) : null}
						</Row>
					</Card>
				</Col>
			</Row>
		</MainForm>
	)
}

export default observer(CategoryFormView)
