import type { SellerRepository } from "../../../repositories/sellerRepository"
import { UseCase } from "../../useCase"
import { UpdateSellerResponse } from "./response"
import { UpdateSellerRequest } from "./request"

export class UpdateSellerUseCase implements UseCase<UpdateSellerRequest, UpdateSellerResponse> {
	private _repository
	constructor({ SellerRepository }: { SellerRepository: SellerRepository }) {
		this._repository = SellerRepository
	}

	public async exec(seller: UpdateSellerRequest) {
		return await this._repository.updateSeller(seller)
	}
}
