import { SVGProps } from "react"

const LoadingSpinner = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="18"
		height="18"
		viewBox="0 0 24 24"
		fill="none"
		{...props}
		className={`text-primary animate-spin align-middle ${props.className}`}
	>
		<path d="M12 3C16.9706 3 21 7.02944 21 12" stroke="currentColor" strokeWidth="1" strokeLinecap="round" />
	</svg>
)

export default LoadingSpinner
