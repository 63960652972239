import { Button, Form, FormInstance, Spin } from "antd"
import { TFunction } from "i18next"

interface FormButtonsProps {
	disabled?: boolean
	loading?: boolean
	hideFormCancelButton?: boolean
	form: FormInstance<any>
	t: TFunction
}

const FormButtons = ({ disabled, loading, form, hideFormCancelButton = false, t }: FormButtonsProps) => {
	return (
		<Form.Item key={"formbuttons"} wrapperCol={{ span: 24 }}>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					width: "100%",
					marginTop: 24
				}}
			>
				<Button
					type="primary"
					htmlType="submit"
					className="w-full gap-2 flex items-center justify-center"
					style={{ width: "10rem", marginInlineEnd: "5rem" }}
					disabled={loading || disabled}
					loading={loading}
				>
					{t("save")}
				</Button>
				{!hideFormCancelButton && (
					<Button disabled={loading || disabled} htmlType="reset" style={{ width: "10rem" }}>
						{t("cancel")}
					</Button>
				)}
			</div>
		</Form.Item>
	)
}

export default FormButtons
