import { ClientFileUploadResponse } from "./response"
import { ClientFileUploadRequest } from "./request"
import { ClientFileUploadRepository } from "../../../repositories/clientFileUploadRepository"
import { UseCase } from "../../useCase"

export class ClientFileUploadUseCase implements UseCase<ClientFileUploadRequest, ClientFileUploadResponse> {
	private _repository
	constructor({ ClientFileUploadRepository }: { ClientFileUploadRepository: ClientFileUploadRepository }) {
		this._repository = ClientFileUploadRepository
	}

	exec({ url, file }: ClientFileUploadRequest) {
		return this._repository.uploadUserProfilePicture(url, file)
	}
}
