import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"

export default () => {
	const { t } = useTranslation("sellers", { keyPrefix: "form.dataTableHeaders" })

	return columns(t)
}

const columns = (t: TFunction) => [
	{
		key: "id",
		title: "ID",
		dataIndex: "id",
		sorteable: true
	},
	{
		key: "userEmail",
		title: t("user"),
		dataIndex: "userEmail"
	},
	{
		key: "seller",
		title: t("seller"),
		dataIndex: "seller",
		width: "5%"
	},
	{
		key: "contact",
		title: t("contact"),
		dataIndex: "contact"
	},
	{
		title: t("nif"),
		dataIndex: "nif",
		key: "nif"
	},
	{
		title: t("iban"),
		dataIndex: "iban",
		key: "iban"
	},
	{
		title: t("web"),
		key: "web",
		dataIndex: "web"
	},
	{
		title: "",
		key: "editButton",
		dataIndex: "editButton"
		// "fixed": "right"
	}
]
