import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import en from "../../locale/en.json"
import es from "../../locale/es.json"
import { Translation } from "../stores/TranslationStore"

const localizations: any = {
	[Translation.enUS]: en,
	[Translation.esES]: es
}
const defaultLocalization = localStorage.getItem("lang") || Translation.defaultLanguage

// const languageDetector: LanguageDetectorModule = {
//     type: 'languageDetector',
//     detect: () => {
//         return defaultLocalization;
//     },
//     init: () => {},
//     cacheUserLanguage: () => {},
// };

export default i18next
	.use(initReactI18next)
	// .use(languageDetector)
	.init(
		{
			fallbackLng: defaultLocalization,
			defaultNS: defaultLocalization,
			// debug: __DEV__,
			resources: localizations
		},
		undefined
	)
	.then()
