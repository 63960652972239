import { VoucherRepository } from "../../../repositories/voucherRepository"
import { UseCase } from "../../useCase"
import { GetVoucherDetailRequest } from "./request"
import { GetVoucherDetailResponse } from "./response"

export class GetVoucherDetailUseCase implements UseCase<GetVoucherDetailRequest, GetVoucherDetailResponse> {
	private _repository
	constructor({ VoucherRepository }: { VoucherRepository: VoucherRepository }) {
		this._repository = VoucherRepository
	}

	exec(voucherId: GetVoucherDetailRequest) {
		return this._repository.getVoucher(voucherId)
	}
}
