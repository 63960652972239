import { UseCase } from "../../useCase"
import { DownloadTransactionsResponse } from "./response"
import { DownloadTransactionsRequest } from "./request"
import { TransactionsRepository } from "../../../repositories/TransactionsRepository"

export class DownloadTransactionsUseCase implements UseCase<DownloadTransactionsRequest, DownloadTransactionsResponse> {
	private _repository
	constructor({ TransactionsRepository }: { TransactionsRepository: TransactionsRepository }) {
		this._repository = TransactionsRepository
	}

	exec({ pagination, limit, filter }: DownloadTransactionsRequest) {
		return this._repository.downloadTransactions(pagination, limit, filter)
	}
}
