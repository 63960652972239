import { observer } from "mobx-react"
import { Button, Col, Row, Typography } from "antd"
import { useNavigate } from "react-router-dom"
import { RoutePaths } from "../../navigation/RoutePaths.enum"
import VouchersTable from "./components/VouchersTable"
import { VoucherViewModel } from "./VoucherViewModel"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import SearchFilterField from "../../components/SearchFilterField/SearchFilterField"
import { Voucher } from "../../../domain/entities/Voucher"
import { useTranslation } from "react-i18next"
import { Card } from "../../styledComponents/CustomCard/Card"

const { Title } = Typography

const VouchersView = () => {
	const { t } = useTranslation("vouchers")
	const navigate = useNavigate()
	const viewModel = useContainerInjection<VoucherViewModel>("VoucherViewModel")
	const filterFunction = (value: Partial<Voucher>, searchInput: string | undefined) => {
		return searchInput && isNaN(parseInt(searchInput))
			? searchInput
				? !(value?.voucherCode?.toLowerCase().indexOf(searchInput.toLowerCase()) === -1)
				: true
			: searchInput
			? !(value?.id?.toString().indexOf(searchInput) === -1)
			: true
	}

	const initialVoucher: Partial<Voucher> = {
		status: 1,
		voucherCode: "",
		voucherRules: {
			discount: undefined,
			clusters: "",
			discountType: undefined,
			totalUses: undefined,
			services: "",
			usesPerUser: undefined,
			status: 1
		}
	}

	return (
		<div>
			<SearchFilterField<Voucher>
				value={viewModel.searchValue}
				placeholder={t("searchBar").toString()}
				searchData={viewModel.vouchers}
				filterFunction={filterFunction}
				onChange={value => viewModel.setSearchedVouchers(value)}
				onChangeText={value => (viewModel.searchValue = value)}
				style={{ marginBottom: "10px" }}
			/>
			<div className="tabled">
				<Row gutter={[24, 0]}>
					<Col xs="24" xl={24}>
						<Card
							bordered={false}
							className="criclebox tablespace mb-24"
							title={
								<div style={{ display: "flex", alignItems: "center" }}>
									<Title style={{ marginTop: 8 }} level={4}>
										{t("title")}
									</Title>
									<Button
										style={{ marginLeft: "1.5vw" }}
										type="primary"
										onClick={() => {
											viewModel.setFormData(initialVoucher)
											viewModel.setOriginalFormData(initialVoucher)
											navigate(RoutePaths.VOUCHERS_ADD_NEW, {
												state: { voucher: initialVoucher }
											})
										}}
									>
										{t("addNew")}
									</Button>
								</div>
							}
						>
							<VouchersTable data={viewModel.searchedVouchers} loading={viewModel.isLoading} />
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default observer(VouchersView)
