import { UpdateSellerUseCase } from "./../../../domain/useCases/sellers/updateSeller/index"
import { CreateSellerUseCase } from "./../../../domain/useCases/sellers/createSeller/index"
import { GetAutoCompleteSellersUseCase } from "./../../../domain/useCases/sellers/autoCompleteSellers/index"
import { GetSellersUseCase } from "../../../domain/useCases/sellers/getSellers"
import { action, makeObservable, observable } from "mobx"
import { Seller } from "../../../domain/entities/Seller"
import { User } from "../../../domain/entities/User"
import { ErrorHandler } from "../../error/ErrorHandler"
import { UserSeller } from "./const/UserSeller"
import { TableConfig } from "../../components/DataTable/types/TableConfig"

interface SellerViewModelConstructorParams {
	GetSellersUseCase: GetSellersUseCase
	GetAutoCompleteSellersUseCase: GetAutoCompleteSellersUseCase
	CreateSellerUseCase: CreateSellerUseCase
	UpdateSellerUseCase: UpdateSellerUseCase
	ErrorHandler: ErrorHandler
}

export class SellersViewModel {
	private _getSellersUseCase
	private _createSellerUseCase
	private _updateSellerUseCase
	private _getAutoCompleteSellersUseCase
	private _errorHandler
	isLoading: boolean = false
	searchedSellers: Partial<Seller>[] = []
	users: Partial<User[]> = []
	sellers: Partial<Seller>[] = []
	selectedSeller: Partial<Seller> = {}
	searchValue: string = ""

	editMode: boolean = false
	initialFormData: UserSeller = { user: {}, seller: {} }
	formData = this.initialFormData
	originalFormData: UserSeller = { user: {}, seller: {} }
	tableConfig: TableConfig = {
		pageSize: 20,
		sort: { order: "descend", field: "id" }
	}

	constructor({
		GetSellersUseCase,
		GetAutoCompleteSellersUseCase,
		CreateSellerUseCase,
		UpdateSellerUseCase,
		ErrorHandler
	}: SellerViewModelConstructorParams) {
		makeObservable(this, {
			searchValue: observable,
			isLoading: observable,
			sellers: observable,
			searchedSellers: observable,
			selectedSeller: observable,
			users: observable,
			setUsers: action,
			setSellerState: action,
			setLoading: action,
			deleteSellerState: action,
			updateSellerState: action,
			setSelectedSeller: action,
			originalFormData: observable,
			setOriginalFormData: action,
			formData: observable,
			setFormData: action,
			initialFormData: observable,
			setInitialFormData: action,
			editMode: observable,
			setEditMode: action,
			tableConfig: observable,
			setTableConfig: action
		})
		this._getSellersUseCase = GetSellersUseCase
		this._getAutoCompleteSellersUseCase = GetAutoCompleteSellersUseCase
		this._createSellerUseCase = CreateSellerUseCase
		this._updateSellerUseCase = UpdateSellerUseCase
		this._errorHandler = ErrorHandler
		this.fetchSellers()
		this.fetchAutoCompleteSellers("")
	}

	public async fetchSellers() {
		try {
			this.setLoading(true)
			const sellers = await this._getSellersUseCase.exec()
			this.setSellerState(sellers)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async fetchAutoCompleteSellers(expression: string) {
		if (!expression || expression.length < 3) return
		this.setLoading(true)
		try {
			const users = await this._getAutoCompleteSellersUseCase.exec({ expression })
			this.setUsers(users)
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async createSeller(newSeller: Partial<Seller>) {
		this.setLoading(true)
		try {
			const sellerCreated = await this._createSellerUseCase.exec({ seller: newSeller })
			this.fetchSellers()
			this.setSelectedSeller({ ...newSeller, id: sellerCreated.id })
			return sellerCreated
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	public async updateSeller(seller: Partial<Seller>) {
		this.setLoading(true)
		try {
			const updatedData = await this._updateSellerUseCase.exec(seller)
			this.updateSellerState(seller.id!, seller)
			this.setOriginalFormData(this.formData)
			return updatedData
		} catch (error) {
			throw this._errorHandler.handleError(error)
		} finally {
			this.setLoading(false)
		}
	}

	setSellerState(sellers: Partial<Seller>[]) {
		this.sellers = sellers
		this.setSearchedSellers(sellers)
	}

	setLoading(isLoading: boolean) {
		this.isLoading = isLoading
	}

	deleteSellerState(sellerId: string) {
		this.setSellerState(this.sellers.filter(seller => seller.id !== sellerId))
	}

	updateSellerState(sellerId: string, updatedSeller: Partial<Seller>) {
		this.setSellerState(
			this.sellers.map(seller => (seller.id === sellerId ? { ...seller, ...updatedSeller } : seller))
		)
	}

	setSearchedSellers(sellers: Partial<Seller>[]) {
		this.searchedSellers = sellers
	}

	setSelectedSeller(seller: Partial<Seller>) {
		this.selectedSeller = seller
	}

	setUsers(users: Partial<User[]>) {
		this.users = users
	}

	public resetUsers() {
		this.users = []
	}

	setEditMode(editMode: boolean) {
		this.editMode = editMode
	}

	setInitialFormData(formData: UserSeller) {
		this.initialFormData = formData
	}

	setFormData(formData: UserSeller) {
		this.formData = formData
	}

	setOriginalFormData(formData: UserSeller) {
		this.originalFormData = formData
	}

	setTableConfig(config: Partial<typeof this.tableConfig>) {
		this.tableConfig = { ...this.tableConfig, ...config }
	}
}
