import { MicrositeRepository } from "./../../../repositories/micrositeRepository"
import { UseCase } from "../../useCase"
import { DeleteMicrositeServiceResponse } from "./response"
import { DeleteMicrositeServiceRequest } from "./request"

export class DeleteMicrositeServiceUseCase
	implements UseCase<DeleteMicrositeServiceRequest, DeleteMicrositeServiceResponse>
{
	private _repository
	constructor({ MicrositeRepository }: { MicrositeRepository: MicrositeRepository }) {
		this._repository = MicrositeRepository
	}

	exec({ micrositeId, serviceId }: DeleteMicrositeServiceRequest) {
		return this._repository.deleteMicrositeService(micrositeId, serviceId)
	}
}
