import { LoadingOutlined } from "@ant-design/icons"

interface ActivitiIndicatorProps {
	size?: "xxs" | "xs" | "md" | "xl" | "xxl"
	color?: string
	styles?: React.CSSProperties
}

const ActivityIndicator = ({ size = "md", color = "blue", styles }: ActivitiIndicatorProps) => {
	const sizeMap = {
		xxs: 12,
		xs: 16,
		md: 24,
		xl: 48,
		xxl: 64
	}

	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				...styles
			}}
		>
			<LoadingOutlined style={{ fontSize: sizeMap[size], color }} />
		</div>
	)
}

export default ActivityIndicator
