import { MessageArgsProps, message } from "antd"
import { useState } from "react"

// interface ToastProps {
//     type: MessageArgsProps['type'];
//     content: MessageArgsProps['content'];
//     key?: MessageArgsProps['key'];
//     duration?: MessageArgsProps['duration'];
// }

export const useToastMessage = () => {
	const [isToastShowing, setShowing] = useState(false)
	const [messageApi, context] = message.useMessage()
	const [key, setKey] = useState<string | number>("")

	const show = async ({ duration = 2.5, ...props }: MessageArgsProps) => {
		if (props.type === "loading") {
			if (!props.key) {
				throw new Error("key is mandatory for type loading")
			}
			setKey(props.key)
			duration = 0
		}
		setShowing(true)
		await messageApi.open({ duration, ...props })
		setShowing(false)
	}

	const reset = (keyParam?: string | number) => {
		messageApi.destroy(keyParam || key)
		setShowing(false)
	}

	return { show, context, isToastShowing, reset }
}
