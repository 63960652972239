import React from "react"
import { Switch as AntDesignSwitch, SwitchProps as AntDesignSwitchProps, Typography } from "antd"

interface SwitchProps extends AntDesignSwitchProps {
	title?: string
	checked?: boolean
	containerStyles?: React.CSSProperties
	titleStyles?: React.CSSProperties
}

const Switch = ({ checked, title, containerStyles, titleStyles, ...props }: SwitchProps) => {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				marginBottom: "14px",
				justifyContent: "space-between",
				...containerStyles
			}}
		>
			<div style={{ display: "flex", flexDirection: "row" }}>
				{title && <Typography.Text style={{ marginRight: "10px", ...titleStyles }}>{title} :</Typography.Text>}
			</div>
			<AntDesignSwitch defaultChecked={checked} checked={checked} {...props} />
		</div>
	)
}

export default Switch
