import { Seller } from "../../domain/entities/Seller"

// Seller Model used for Legacy Backend
// Will be deprecated

interface ContructorParams {
	id?: string
	userId?: string
	userEmail: string
	name: string
	nif: string
	iban: string
	contact: string
	contactTelf: string
	weekdays?: string | null
	logo?: string
	schedule?: string
	organization?: string
	description?: string
	expYears?: number
	web?: string
	degrees?: string
	fiscalName?: string
	fiscalCountry?: string
	feePercent?: number
	paymentDays?: number
}

export class SellersModel {
	public id?: string
	public userId?: string
	public userEmail: string
	public name: string
	public nif: string
	public iban: string
	public contact: string
	public contactTelf: string
	public weekdays?: string | null
	public logo?: string
	public schedule?: string
	public organization?: string
	public description?: string
	public expYears?: number
	public web?: string
	public degrees?: string
	public fiscalName?: string
	public fiscalCountry?: string
	public feePercent?: number
	public paymentDays?: number

	constructor(params: ContructorParams) {
		this.id = params.id
		this.userId = params.userId
		this.userEmail = params.userEmail
		this.name = params.name
		this.nif = params.nif
		this.iban = params.iban
		this.contact = params.contact
		this.contactTelf = params.contactTelf
		this.weekdays = params.weekdays
		this.logo = params.logo
		this.schedule = params.schedule
		this.organization = params.organization
		this.description = params.description
		this.expYears = params.expYears
		this.web = params.web
		this.degrees = params.degrees
		this.fiscalName = params.fiscalName
		this.fiscalCountry = params.fiscalCountry
		this.feePercent = params.feePercent
		this.paymentDays = params.paymentDays
	}

	static fromRawJson = (rawJson: any): SellersModel => {
		const {
			id,
			email,
			name,
			nif,
			iban,
			contact,
			contactTelf,
			weekdays,
			logo,
			schedule,
			organization,
			description,
			expYears,
			web,
			degrees,
			fiscalName,
			fiscalCountry,
			feePercent,
			paymentDays
		} = rawJson

		return new SellersModel({
			id,
			userEmail: email,
			name,
			nif,
			iban,
			contact,
			contactTelf,
			weekdays,
			logo,
			schedule,
			organization,
			description,
			expYears,
			web,
			degrees,
			fiscalName,
			fiscalCountry,
			feePercent,
			paymentDays
		})
	}

	static fromDomain = (seller: Seller): SellersModel => {
		return new SellersModel({
			...seller,
			id: seller.id,
			userId: seller.userId,
			userEmail: seller.userEmail,
			name: seller.sellerName,
			contactTelf: seller.phone,
			weekdays: seller.weekdays,
			schedule: seller.schedule ? seller.schedule.toISOString() : undefined,
			expYears: seller.expYears,
			degrees: seller.degrees,
			fiscalName: seller.fiscalName,
			fiscalCountry: seller.fiscalCountry,
			feePercent: Number(seller.feePercentage),
			paymentDays: Number(seller.paymentDays)
		})
	}
}

declare module "./SellersModel" {
	interface SellersModel {
		toDomain(): Seller
	}
}

SellersModel.prototype.toDomain = function (): Seller {
	const data = this
	return new Seller({
		...data,
		sellerName: data.name,
		userEmail: data.userEmail,
		schedule: data.schedule ? new Date() : undefined,
		phone: data.contactTelf?.trim().replace(/\s/g, ""),
		expYears: data.expYears || 0,
		degrees: data.degrees || "", //data.degrees?.split('%')
		web: data.web || "",
		organization: data.organization || "",
		description: data.description || "",
		fiscalName: data.fiscalName || undefined,
		fiscalCountry: data.fiscalCountry || undefined,
		feePercentage: data.feePercent?.toString() || undefined,
		paymentDays: data.paymentDays?.toString() || undefined
	})
}
