import type { SellerServicesRepository } from "../../../repositories/sellerServicesRepository"
import { UseCase } from "../../useCase"
import { CreateSellerServiceResponse } from "./response"
import { CreateSellerServiceRequest } from "./request"

export class CreateSellerServiceUseCase implements UseCase<CreateSellerServiceRequest, CreateSellerServiceResponse> {
	private _repository
	constructor({ SellerServicesRepository }: { SellerServicesRepository: SellerServicesRepository }) {
		this._repository = SellerServicesRepository
	}

	exec(service: CreateSellerServiceRequest) {
		return this._repository.createSellerService(service)
	}
}
