import "./assets/styles/main.css"
import "./assets/styles/responsive.css"
import "./service/translation"
import AppNavigator from "./navigation/AppNavigator"
import TranslationListener from "./components/TranslationListener/TranslationListener"
import ErrorListener from "./error/ErrorListener"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { observer } from "mobx-react"
import { useContainerInjection } from "./hooks/useContainerInjection"
import { Settings } from "./stores/Settings"

function App() {
	const settingsStore = useContainerInjection<Settings>("Settings")
	return (
		<div className="App">
			<GoogleOAuthProvider clientId={settingsStore.googleClientId || "InvalidCliendId"}>
				<ErrorListener />
				<TranslationListener />
				<AppNavigator />
			</GoogleOAuthProvider>
		</div>
	)
}

export default observer(App)
