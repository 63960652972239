import { action, makeObservable, observable } from "mobx"
import { UploadFile } from "antd"
import { BannerForm } from "./entities/BannerForm"

const SECONDARY_BANNERS_AMOUNT = 3
interface Props {
	microsite: string
	banners: BannerForm[]
}

export class BannersFormViewModel {
	selectedLanguage = "ES"

	microsite: string
	banners: BannerForm[] = []
	showSecondaryBanners = false

	constructor({ microsite, banners }: Props) {
		makeObservable(this, {
			selectedLanguage: observable,
			banners: observable,
			showSecondaryBanners: observable,
			microsite: observable,
			setSelectedLanguage: action,
			setBanners: action,
			addBanner: action,
			removeBanner: action,
			setShowSecondaryBanners: action,
			reorderBanners: action,
			setBannerHref: action,
			loadNewBannerImage: action,
			removeNewBannerImage: action,
			setMicrosite: action
		})
		this.microsite = microsite
		this.banners = banners
	}

	setBanners(banners: BannerForm[]) {
		this.banners = banners
		this.showSecondaryBanners = this.hasSecondaryBanners(this.selectedLanguage)
	}

	hasSecondaryBanners(language: string) {
		return this.banners.some(
			b => !b.deleted && b.type === "secondaryBanner" && b.language.toLowerCase() === language.toLowerCase()
		)
	}

	setSelectedLanguage(language: string) {
		this.selectedLanguage = language
		this.showSecondaryBanners = this.hasSecondaryBanners(this.selectedLanguage)
	}

	addBanner(banner: BannerForm) {
		this.addBanners([banner])
	}

	addBanners(banners: BannerForm[]) {
		banners.forEach(b => b.setChanged(true))
		this.banners = this.banners.concat(banners)
	}

	removeBanner(banner: BannerForm) {
		if (banner.desktopImage || banner.mobileImage) {
			this.getBanner(banner.viewId)?.delete()
		} else {
			// Remove from list if no uploaded images
			this.banners = this.banners.filter(b => b.viewId !== banner.viewId)
		}
		this.reorderBanners(banner.type, 0, 0)
	}

	setShowSecondaryBanners(show: boolean) {
		this.showSecondaryBanners = show
		const secondaryBanners = this.getBanners("secondaryBanner", true)
		secondaryBanners.forEach(b => (show ? b.undoDelete() : b.delete()))
		const currentAmount = secondaryBanners.length
		if (show && currentAmount < SECONDARY_BANNERS_AMOUNT) {
			const emptyBanners = [...new Array(SECONDARY_BANNERS_AMOUNT - currentAmount)].map(
				(_, i) =>
					new BannerForm({
						key: this.microsite,
						weight: currentAmount + i + 1,
						type: "secondaryBanner",
						subType: currentAmount + i === 0 ? "large" : "short",
						language: this.selectedLanguage
					})
			)
			this.addBanners(emptyBanners)
		} else if (!show) {
			// Remove empty secondary banners if hidden
			const nonEmptyBanners = this.banners.filter(
				b =>
					b.language !== this.selectedLanguage ||
					b.type !== "secondaryBanner" ||
					b.desktopImage ||
					b.mobileImage
			)
			this.banners = nonEmptyBanners
		}
	}

	getBanner(viewId: string) {
		return this.banners.find(b => b.viewId === viewId) ?? null
	}

	getBanners(type: "mainBanner" | "secondaryBanner", withDeleted = false) {
		return this.banners.filter(
			b =>
				(!b.deleted || withDeleted) &&
				b.type === type &&
				b.language.toLowerCase() === this.selectedLanguage.toLowerCase()
		)
	}

	reorderBanners(type: "mainBanner" | "secondaryBanner", startIndex: number, destinationIndex: number) {
		const banners = Array.from(this.getBanners(type))
		const [removed] = banners.splice(startIndex, 1)
		banners.splice(destinationIndex, 0, removed)
		const otherBanners = this.banners.filter(
			b => b.language.toLowerCase() !== this.selectedLanguage.toLowerCase() || b.type !== type || b.deleted
		)
		const orderedBanners = banners
			.map((b, idx) => new BannerForm({ ...b, weight: idx + 1 }, b.viewId, true))
			.concat(otherBanners)
		this.banners = orderedBanners
	}

	setBannerHref(viewId: string, href: string) {
		this.getBanner(viewId)?.setHref(href)
	}

	loadNewBannerImage(viewId: string, newImage: UploadFile, imageType: "desktop" | "mobile") {
		imageType === "desktop"
			? this.getBanner(viewId)?.setDesktopImage(newImage)
			: this.getBanner(viewId)?.setMobileImage(newImage)
		// Update reference to refresh view
		this.banners = [...this.banners]
	}

	removeNewBannerImage(viewId: string, imageType: "desktop" | "mobile") {
		imageType === "desktop"
			? this.getBanner(viewId)?.setDesktopImage(undefined)
			: this.getBanner(viewId)?.setMobileImage(undefined)
		// Update reference to refresh view
		this.banners = [...this.banners]
	}

	setMicrosite(microsite: string) {
		this.microsite = microsite
	}
}
