import { AutoComplete, Col, Form, Input, Row } from "antd"
import { User } from "../../../../domain/entities/User"
import { useTranslation } from "react-i18next"
import { SellersViewModel } from "../SellersViewModel"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { observer } from "mobx-react"
import ActivityIndicator from "../../../components/ActivityIndicator/ActivityIndicator"

const UserInformationFormSection = ({
	onChangeText,
	userData,
	disabled
}: {
	onChangeText?: (value: Partial<User>) => void
	userData: Partial<User>
	disabled?: boolean
}) => {
	const { t } = useTranslation("sellers", { keyPrefix: "form.fields" })
	const { t: tMainForm } = useTranslation("mainForm", { keyPrefix: "validations" })
	const viewModel = useContainerInjection<SellersViewModel>("SellersViewModel")
	const searchText = (searchText: string) => {
		viewModel.resetUsers()
		viewModel.fetchAutoCompleteSellers(searchText)
	}
	return (
		<>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={16} md={12} xs={24} style={{ display: "flex", flexDirection: "column" }}>
					<Form.Item
						labelCol={{ span: 24 }}
						label={t("email")}
						name={"email"}
						key={"email"}
						rules={[{ required: true, message: tMainForm("mandatoryField").toString() }]}
					>
						<AutoComplete
							options={viewModel.users.map(user => ({
								label: `${user?.id} - ${user?.email}`,
								value: `${user?.id} - ${user?.email}`,
								key: user?.email
							}))}
							notFoundContent={viewModel.isLoading ? <ActivityIndicator /> : t("noMatches")}
							allowClear
							defaultValue={userData.email}
							autoFocus
							onSelect={value => {
								const [id, email] = value.split(" - ")
								onChangeText && onChangeText({ email, id })
							}}
							onSearch={text => searchText(text)}
							disabled={disabled}
						>
							<Input placeholder={t("email").toString()} />
						</AutoComplete>
					</Form.Item>
				</Col>
			</Row>
		</>
	)
}

export default observer(UserInformationFormSection)
