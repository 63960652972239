import { Col, Divider, Modal, Row, Typography } from "antd"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import MainForm, { MainFormRef } from "../../../components/Form/MainForm"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { createRef, useEffect, useState } from "react"
import { FormField } from "../../../components/Form/FormField"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import { MicrositesViewModel } from "../../microSites/MicrositesViewModel"
import { TipsViewModel } from "../TipsViewModel"

type InitialData = {
	id?: number
	position: string
	new: boolean
	slug: string
}
export interface TipMicrositesModalProps {
	onConfirm?: (modalData: InitialData) => void
	onCancel?: () => void
	visible: boolean
	editting?: boolean
}

const TipMicrositesModal = ({ visible, onConfirm, onCancel }: TipMicrositesModalProps) => {
	const { t } = useTranslation("tips", { keyPrefix: "form.micrositesTips.modal" })
	const micrositeViewModel = useContainerInjection<MicrositesViewModel>("MicrositesViewModel")
	const viewModel = useContainerInjection<TipsViewModel>("TipsViewModel")
	const initialModalData: InitialData = {
		id: undefined,
		position: "",
		new: true,
		slug: ""
	}
	const [modalData, setModalData] = useState<InitialData>(initialModalData)
	const formRef = createRef<MainFormRef>()

	useEffect(() => {
		if (micrositeViewModel.microsites?.length === 0) {
			micrositeViewModel.fetchMicrosites()
		}
		setModalData(initialModalData)
	}, [visible])

	const tipMicrosites = viewModel.formData.microsites

	return (
		<Modal
			title={
				<div style={{ position: "relative", bottom: 24 }}>
					<Typography.Title level={5}>{t("modalTitle")}</Typography.Title>
					<Divider style={{ marginBottom: 0 }} />
				</div>
			}
			centered
			style={{ overflowY: "auto", padding: "60px 0px" }}
			open={visible}
			onOk={async () => {
				//TODO: check microsite tip modal validations.
				// await formRef.current?.customValidateFields(() => {
				// 	if (!modalData.id) {
				// 		throw { message: t("validations.serviceMandatory") }
				// 	}
				// })
				await formRef.current?.validateFields()
				onConfirm && onConfirm(modalData)
			}}
			onCancel={() => onCancel && onCancel()}
			width={400}
			okText={t("confirm")}
			cancelText={t("cancel")}
		>
			<MainForm
				initialValues={modalData}
				hideFormButtons
				key={"micrositeServiceModal"}
				onChangeFormValues={formData => {
					if (JSON.stringify(modalData) !== JSON.stringify(formData)) {
						setModalData({
							...modalData,
							...formData
						})
					}
				}}
				className="flex flex-col justify-center items-center"
				ref={formRef}
			>
				<Row gutter={[24, 0]} className="w-full">
					<Col md={24} xl={24} xs={24}>
						<Row gutter={[24, 0]}>
							<Col xl={18} md={18} xs={20} className="md:mx-auto">
								<FormField.SelectSearch
									key={"id"}
									label={t("microsite")}
									name={"id"}
									mandatory
									value={modalData.id}
									placeholder={t("micrositePlaceholder").toString()}
									onChange={micrositeSelected => {
										const slug =
											micrositeViewModel.microsites.find(
												//@ts-ignore
												msite => msite.id === micrositeSelected?.value
											)?.slug || ""
										setModalData({
											...modalData,
											// @ts-ignore
											id: micrositeSelected?.value,
											slug
										})
									}}
									options={micrositeViewModel.microsites
										.filter(
											msite =>
												msite.id &&
												!tipMicrosites?.find(
													tipMsite => tipMsite.micrositeId == Number(msite.id)
												)
										)
										.map(msite => ({
											label: `${msite.id} - ${msite.slug}` || "",
											value: msite.id || ""
										}))}
								/>
							</Col>
						</Row>
						<Row gutter={[24, 0]}>
							<Col xl={18} md={18} xs={20} className="md:mx-auto">
								<FormField.Input
									inputType={InputType.NUMBER}
									key={"position"}
									label={t("position")}
									name={"position"}
									mandatory
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</MainForm>
		</Modal>
	)
}

export default observer(TipMicrositesModal)
