import { Translation } from "../stores/TranslationStore"
import MultiSwitch from "../components/Switch/MultiSwitch"
import { useContainerInjection } from "../hooks/useContainerInjection"
import { AuthStore } from "../stores/AuthStore"
import PrivateRoutes from "./PrivateRoutes"
import PublicRoutes from "./PublicRoutes"
import { observer } from "mobx-react"

function AppNavigator() {
	const translation = useContainerInjection<Translation>("Translation")
	const { isAuthenticated } = useContainerInjection<AuthStore>("AuthStore")
	const position = isAuthenticated ? { top: "2.4rem" } : { top: "1.5rem" }
	return (
		<div>
			<MultiSwitch
				value={translation.currentLang || Translation.esES}
				options={[
					{ label: "ES", value: Translation.esES },
					{ label: "EN", value: Translation.enUS }
				]}
				onChange={value => translation.setCurrentLang(value)}
				style={{ position: "absolute", right: "6rem", display: "flex", zIndex: 1, ...position }}
			/>
			<PublicRoutes />
			<PrivateRoutes />
		</div>
	)
}

export default observer(AppNavigator)
