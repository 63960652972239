import { Form, DatePicker, Button, FormProps, Select, Space } from "antd"
import { FormField } from "../Form/FormField"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { Microsite } from "../../../domain/entities/Microsite"
import { useTranslation } from "react-i18next"
import { Filter } from "../../../domain/types/transactions/Filter"
import { MicrositesViewModel } from "../../pages/microSites/MicrositesViewModel"
import { SyncOutlined } from "@ant-design/icons"
import { observer } from "mobx-react"
import dayjs from "dayjs"
import { CountryService } from "../../../data/services/CountryService"
import { AuthStore } from "../../stores/AuthStore"
import { Roles } from "../../../domain/enum/roles.enum"
import { MicrositeCurrency } from "../../../domain/entities/Currency"

interface DataFilterProps extends FormProps {
	onSubmit?: (values: Filter) => Promise<void>
	onReset?: () => Promise<void>
}

export interface DataFilterRef {
	filter: Filter
}

const DataFilter = forwardRef(({ onSubmit, onReset }: DataFilterProps, forwardedRef: Ref<DataFilterRef>) => {
	const [submitting, setSubmitting] = useState(false)
	const [reseting, setReseting] = useState(false)
	const [countries, setCountries] = useState<{ name: string; id: string }[] | null>(null)
	const { t } = useTranslation("transactions", { keyPrefix: "dataFilter" })
	const { t: tSelectors } = useTranslation("selectors")
	const micrositeViewModel = useContainerInjection<MicrositesViewModel>("MicrositesViewModel")
	const countryService = useContainerInjection<CountryService>("CountryService")
	const [form] = Form.useForm<Filter>()
	const { userData } = useContainerInjection<AuthStore>("AuthStore")
	const [filters, setFilters] = useState<Filter>({} as Filter)
	const options: { label: string; value: string }[] = [{ label: "Microsite", value: "microsite" }]
	if (
		userData?.roles.includes(Roles.ADMIN) ||
		(userData?.roleAttributes?.marketplaces?.length ?? 0) > 0 ||
		(userData?.roleAttributes?.micrositeCurrencies?.length ?? 0) > 0
	) {
		options.push({ label: "Marketplace", value: "marketplace" })
	}
	useEffect(() => {
		getCountries()
	}, [])

	const getCountries = async () => {
		const countryList = await countryService.getCountries()
		setCountries(countryList)
	}

	const getOptionsBySalesSite = (
		salesSite: string,
		roleAttributes?: { marketplaces?: string[]; micrositeCurrencies?: number[] }
	) => {
		if (salesSite === "microsite") {
			return Microsite.businessTypes.map(type => ({ label: type.label, value: type.id }))
		} else {
			return (
				countries
					?.filter(
						c =>
							!roleAttributes ||
							roleAttributes?.micrositeCurrencies?.includes(
								MicrositeCurrency[c.id as keyof typeof MicrositeCurrency]
							) ||
							roleAttributes?.marketplaces?.includes(c.id)
					)
					.map(country => ({ label: country.name, value: country.id })) ?? []
			)
		}
	}

	useImperativeHandle(forwardedRef, () => ({
		filter: filters
	}))

	const handleSubmit = async () => {
		setSubmitting(true)
		try {
			const values = form.getFieldsValue()
			onSubmit && (await onSubmit(values))
		} catch (error) {
			console.log("@@data filter error@@", error)
		} finally {
			setSubmitting(false)
		}
	}

	const handleReset = async () => {
		if (reseting) return
		setReseting(true)
		try {
			form.resetFields()
			setFilters({} as Filter)
			onReset && (await onReset())
		} catch (error) {
			console.log("@@data filter error@@", error)
		} finally {
			setReseting(false)
		}
	}

	return (
		<Form layout="vertical" form={form} onFinish={handleSubmit} onReset={handleReset}>
			<Space direction="horizontal" align="end" size={"large"} className="flex gap-8">
				<FormField.Select
					allowClear
					onClear={() => form.resetFields()}
					name="salesSite"
					label={t("salesSite")}
					onChange={value => {
						form.resetFields(["micrositeType", "country"])
						setFilters({ ...filters, salesSite: value, micrositeType: undefined, country: undefined })
					}}
					options={options}
				/>
				{filters?.salesSite == "microsite" && (
					<FormField.Select
						allowClear
						mandatory={!!filters.salesSite}
						onChange={micrositeType => {
							form.resetFields(["microsite"])
							setFilters({ ...filters, micrositeType })
						}}
						options={getOptionsBySalesSite(
							filters.salesSite,
							!userData?.roles.includes(Roles.ADMIN) ? userData?.roleAttributes : undefined
						)}
						loading={countries === null}
						name={"micrositeType"}
						label={t("micrositeType")}
					/>
				)}
				{filters.salesSite == "marketplace" && (
					<FormField.Select
						allowClear
						mandatory={!!filters.salesSite}
						onChange={country => setFilters({ ...filters, country })}
						options={getOptionsBySalesSite(
							filters.salesSite,
							!userData?.roles.includes(Roles.ADMIN) ? userData?.roleAttributes : undefined
						)}
						loading={countries === null}
						name={"country"}
						label={t("country")}
					/>
				)}
				{filters?.micrositeType && (
					<Form.Item name={"microsite"} label={t("microsite")}>
						<Select
							allowClear
							showSearch
							filterOption={(input, option) =>
								option?.label && typeof option.label === "string"
									? option?.label.toLowerCase().includes(input.toLowerCase())
									: false
							}
							onChange={microsite => setFilters({ ...filters, microsite: Number(microsite) })}
							options={micrositeViewModel.microsites
								.filter(microsite => microsite.settings?.businessType == filters?.micrositeType)
								.map(microsite => ({
									label: `${microsite.id} - ${microsite.slug}`,
									value: microsite.id
								}))}
							loading={micrositeViewModel.isLoading}
							disabled={micrositeViewModel.isLoading}
							placeholder={tSelectors("defaultPlaceHolder")}
						/>
					</Form.Item>
				)}
				<Form.Item label={t("dateFromTo")} name="createdAt">
					<DatePicker.RangePicker
						allowClear
						onChange={dates => {
							if (dates == null) {
								setFilters({ ...filters, createdAt: undefined })
								return
							}
							const dateFrom = dayjs(dates?.[0]?.toDate())
							const dateTo = dayjs(dates?.[1]?.toDate())
							setFilters({
								...filters,
								createdAt: [dateFrom, dateTo]
							})
						}}
					/>
				</Form.Item>
				<Form.Item>
					<div className="flex flex-row">
						<Button className="mr-[0.15rem]" loading={submitting} htmlType="submit" type="primary">
							{t("filter")}
						</Button>
						<Button
							className={reseting ? "opacity-70" : ""}
							htmlType="reset"
							disabled={
								filters?.salesSite || (Array.isArray(filters.createdAt) && filters.createdAt[0])
									? false
									: true
							}
							icon={<SyncOutlined className={reseting ? "animate-spin" : ""} />}
							type="primary"
						/>
					</div>
				</Form.Item>
			</Space>
		</Form>
	)
})

export default observer(DataFilter)
