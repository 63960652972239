import { useContainerInjection } from "../../hooks/useContainerInjection"
import { CategoriesViewModel } from "./CategoriesViewModel"
import { observer } from "mobx-react"
import { Button, Col, Row, Typography } from "antd"
import CategoryTable from "./components/CategoryTable"
import { useNavigate } from "react-router-dom"
import { RoutePaths } from "../../navigation/RoutePaths.enum"
import SearchFilterField from "../../components/SearchFilterField/SearchFilterField"
import { Category } from "../../../domain/entities/Category"
import { useTranslation } from "react-i18next"
import { Card } from "../../styledComponents/CustomCard/Card"

const { Title } = Typography

const Categories = () => {
	const { t } = useTranslation("clusters")
	const viewModel = useContainerInjection<CategoriesViewModel>("CategoriesViewModel")
	const navigate = useNavigate()
	const filterFunction = (value: Category, searchInput: string | undefined) => {
		return searchInput && isNaN(parseInt(searchInput))
			? searchInput
				? !(value?.label?.toLowerCase().indexOf(searchInput.toLowerCase()) === -1)
				: true
			: searchInput
			? !(value?.id?.toString().indexOf(searchInput) === -1)
			: true
	}
	return (
		<>
			<SearchFilterField<Category>
				value={viewModel.searchValue}
				placeholder={t("searchBar").toString()}
				searchData={viewModel.categories}
				filterFunction={filterFunction}
				onChange={value => {
					viewModel.setSearchedCategories(value)
				}}
				onChangeText={value => (viewModel.searchValue = value)}
				style={{ marginBottom: "10px" }}
			/>
			<div className="tabled">
				<Row gutter={[24, 0]}>
					<Col xs="24" xl={24}>
						<Card
							bordered={false}
							className="criclebox tablespace mb-24"
							title={
								<div style={{ display: "flex", alignItems: "center" }}>
									<Title style={{ marginTop: 8 }} level={4}>
										{t("cardTitle")}
									</Title>
									<Button
										style={{ marginLeft: "1.5vw" }}
										type="primary"
										onClick={() => navigate(RoutePaths.CATEGORIES_ADD_NEW)}
									>
										{t("addNew")}
									</Button>
								</div>
							}
						>
							<CategoryTable data={viewModel.searchedCategories} />
						</Card>
					</Col>
				</Row>
			</div>
		</>
	)
}

export default observer(Categories)
