import { CardProps } from "antd/es/card"
import { Card as AntCard } from "antd"
import { colors } from "../../assets/styles/appStyles"

interface CustomCardProps extends CardProps {
	children: React.ReactNode
}

export const Card = ({ children, ...props }: CustomCardProps) => (
	<AntCard
		{...props}
		style={{
			...props.style,
			backgroundColor: props.style?.backgroundColor ? props.style.backgroundColor : colors.shadowBackground,
			boxShadow: "-3px 5px 10px 1px rgba(0,0,0,0.1)",
			transition: "all 0.5s ease-in-out"
		}}
	>
		{children}
	</AntCard>
)
