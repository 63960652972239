import { Col, Form, Row, UploadFile } from "antd"
import { Seller } from "../../../../domain/entities/Seller"
import { useTranslation } from "react-i18next"
import { InputAddons, InputType } from "../../../components/TextInput/const/InputType.enum"
import { FormField } from "../../../components/Form/FormField"
import MultimediaUploader from "../../../components/MultimediaUploader/MultimediaUploader"
import config from "../../../../config/config"

interface Props {
	inputDisabled?: boolean
	onChangeText?: (value: Partial<Seller>) => void
	seller?: Partial<Seller>
	onChangeLogo?: (value: any) => void
}

const SellerInformationFormSection = ({ inputDisabled, onChangeText, seller, onChangeLogo }: Props) => {
	const { t } = useTranslation("sellers", { keyPrefix: "form.fields" })
	return (
		<>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"sellerName"}
						label={t("name")}
						name={"sellerName"}
						mandatory
						onChange={(sellerName: string) => onChangeText && onChangeText({ sellerName })}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"contact"}
						label={t("contact")}
						name={"contact"}
						mandatory
						onChange={(contact: string) => onChangeText && onChangeText({ contact })}
					/>
				</Col>
			</Row>

			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"phone"}
						label={t("phone")}
						inputType={InputType.NUMBER}
						name={"phone"}
						mandatory
						onChange={(phone: string) => onChangeText && onChangeText({ phone })}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<FormField.Input
						key={"organization"}
						label={t("organization")}
						name={"organization"}
						onChange={(organization: string) => onChangeText && onChangeText({ organization })}
					/>
				</Col>
			</Row>
			<Row gutter={[24, 0]}>
				<Col span={24} xl={12} md={12} xs={24} style={{ paddingTop: 25 }}>
					<FormField.Input
						key={"web"}
						label={t("web")}
						name={"web"}
						addonBefore={InputAddons.HTTPS}
						onChange={(web: string) => onChangeText && onChangeText({ web })}
					/>
				</Col>
				<Col span={24} xl={12} md={12} xs={24}>
					<Form.Item label={t("logo")} labelCol={{ span: 24 }}>
						<MultimediaUploader
							containerStyle={{ width: "120px", height: "120px" }}
							mediaUrl={seller?.logo}
							manualUpload
							onClean={() => onChangeLogo && onChangeLogo(null)}
							onChange={logo => onChangeLogo && onChangeLogo(logo)}
							sizeLimit={config.ui.sellers.logo}
						/>
					</Form.Item>
				</Col>
				<Col xl={24} md={24} xs={24}>
					<FormField.Input
						key={"description"}
						label={t("description")}
						name={"description"}
						inputType={InputType.TEXTAREA}
						mandatory
						onChange={(description: string) => onChangeText && onChangeText({ description })}
					/>
				</Col>
			</Row>
		</>
	)
}

export default SellerInformationFormSection
