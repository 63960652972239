import type { SellerRepository } from "../../../repositories/sellerRepository"
import { UseCase } from "../../useCase"
import { CreateSellerResponse } from "./response"
import { CreateSellerRequest } from "./request"

export class CreateSellerUseCase implements UseCase<CreateSellerRequest, CreateSellerResponse> {
	private _repository
	constructor({ SellerRepository }: { SellerRepository: SellerRepository }) {
		this._repository = SellerRepository
	}

	public async exec({ seller }: CreateSellerRequest) {
		return await this._repository.createSeller(seller)
	}
}
