import { UseCase } from "../../useCase"
import { UpdateTipResponse } from "./response"
import { UpdateTipRequest } from "./request"
import { TipRepository } from "../../../repositories/tipRepository"

export class UpdateTipUseCase implements UseCase<UpdateTipRequest, UpdateTipResponse> {
	private _repository
	constructor({ TipRepository }: { TipRepository: TipRepository }) {
		this._repository = TipRepository
	}

	exec(tip: UpdateTipRequest) {
		return this._repository.updateTip(tip)
	}
}
