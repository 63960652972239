import { action, makeObservable, observable } from "mobx"
import { Login } from "../../../domain/entities/login"
import { LoginUseCase } from "../../../domain/useCases/auth/signIn"
import { AuthStore } from "../../stores/AuthStore"

interface ContructorParams {
	LoginUseCase: LoginUseCase
	AuthStore: AuthStore
}

export class SignInViewModel {
	private _loginUseCase
	private _authStore
	pagination: number = 0
	isLoading: boolean = false
	rememberSession: boolean = true

	constructor({ LoginUseCase, AuthStore }: ContructorParams) {
		makeObservable(this, {
			isLoading: observable,
			rememberSession: observable,
			setRememberSession: action
		})
		this._loginUseCase = LoginUseCase
		this._authStore = AuthStore
	}

	public async login(loginData: Login) {
		try {
			this.setLoading(true)
			const data = await this._loginUseCase.exec(loginData)
			this._authStore.onLogin(data.access_token, this.rememberSession)
			return this._authStore.userData
		} catch (error) {
			throw error
		} finally {
			this.setLoading(false)
		}
	}

	private setLoading(state: boolean) {
		this.isLoading = state
	}

	public setRememberSession(state: boolean) {
		this.rememberSession = state
	}
}
